import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardTitle, Col, Modal, Row, Table,} from "reactstrap";
import {useNavigate, useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Loader from "../../components/Common/Loader";
import {connect} from "react-redux";
import SearchableDropdown from "../../components/Common/SearchableDropdown";
import {createOrder, createOrderReset, GetCustomers, GetCustomerUsers, getOrderDetails, getProjectList, getStockPointConfig, getStockPoints} from "../../store/actions";
import {AvField, AvForm} from "availity-reactstrap-validation";
import DatePicker from "react-date-picker";
import {sumBy} from "lodash";
import {find, isEmpty} from "lodash-es";
import {getTenantAdmins} from "../../store/tenant/actions";
import {get} from "../../helpers/api_helper";
import {API_ERR_MSG} from "../../util/constants";
import {parseDateToString, preventNumberInputScroll} from "../../util/helperFunctions";
import {isStockable} from "../../util/articleUtil";
import cogoToast from "cogo-toast";
import {toastOptions} from "../../util/dataUtil";
import {evaluate} from "mathjs";
import {getProductDetailsShallow} from "../../helpers/fakebackend_helper";
import ProjectForm from "../Projects/ProjectForm";
import DeliveryAddressForm from "../Customer/DeliveryAddressForm";
import DropdownSelect from "../../components/Common/DropdownSelect";
import queryString from "query-string";
import withRouter from "../../components/Common/withRouter";
import CustomerUserFormContainer from "../Customer/CustomerUserFormContainer";

const OrderForm = (props) => {
    const {
        customerOptions, stockPoints, stockPointConfig, projects, customerUsers, tenantUsers,
        orderPending, orderSuccess, orderError, orderDetails,
        onGetCustomerOptions, onGetStockPoints, onGetStockPointConfig, onGetProjectOptions, onGetCustomerUsers,
        onGetTenantUsers, onCreateOrder, onCreateOrderReset, onGetOrderDetails, useModal, tog_standard,
        projectsLoading, orderDetailsLoading, customerUsersLoading, tenantUserOptionsLoading
    } = props;

    let params = useParams();
    let query = queryString.parse(window.location.search);
    const [customer, setCustomer] = useState(null)
    const [orderDate, setOrderDate] = useState(new Date());
    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [ourReference, setOurReference] = useState(null);
    const [deliveryTerms, setDeliveryTerms] = useState(null);
    const [paymentTerms, setPaymentTerms] = useState(null);
    const [stockPoint, setStockPoint] = useState(null);
    const [priceList, setPriceList] = useState(null);
    const [yourReference, setYourReference] = useState(null);
    const [project, setProject] = useState(null);
    const [yourOrderNumber, setYourOrderNumber] = useState(null);
    const [deliveryReference, setDeliveryReference] = useState(null);
    const [deliveryAddress, setDeliveryAddress] = useState(null);
    const [deliveryAddressList, setDeliveryAddressList] = useState([]);
    const [paymentTermList, setPaymentTermList] = useState([]);
    const [deliveryTermList, setDeliveryTermList] = useState([]);
    const [articleRows, setArticleRows] = useState([]);
    const [wayOfDelivery, setWayOfDelivery] = useState(null);
    const [wayOfDeliveryList, setWayOfDeliveryList] = useState([]);

    const [productOptions, setProductOptions] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);
    const [customerUserOptions, setCustomerUserOptions] = useState([]);
    const [tenantUserOptions, setTenantUserOptions] = useState([]);

    const [shipping, setShipping] = useState(0);
    const [subTotal, setSubTotal] = useState(0)
    const [tax, setTax] = useState(0)
    const [total, setTotal] = useState(0)
    const [discount, setDiscount] = useState(0)

    const [showAddDeliveryAddressModal, setShowAddDeliveryAddressModal] = useState(false);
    const [showAddProjectModal, setShowAddProjectModal] = useState(false);
    const [showAddCustomerUserModal, setShowAddCustomerUserModal] = useState(false);

    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [brandSettings, setBrandSettings] = useState(null);

    const [deliveryAddressListLoading, setDeliveryAddressListLoading] = useState(false);
    const [productOptionsLoading, setProductOptionsLoading] = useState(false);
    const [termsOfDeliveryListLoading, setTermsOfDeliveryListLoading] = useState(false);
    const [termsOfPaymentListLoading, setTermsOfPaymentListLoading] = useState(false);
    const [wayOfDeliveriesLoading, setWayOfDeliveriesLoading] = useState(false);
    const [priceListError, setPriceListError] = useState(null);

    const [deliveryName, setDeliveryName] = useState(null);
    const [deliveryAddress1, setDeliveryAddress1] = useState(null);
    const [deliveryCountry, setDeliveryCountry] = useState(null);
    const [deliveryState, setDeliveryState] = useState(null);
    const [deliveryCity, setDeliveryCity] = useState(null);
    const [deliveryZipCode, setDeliveryZipCode] = useState(null);
    const [phone, setPhone] = useState(null);

    let history = useNavigate();
    let user = JSON.parse(localStorage.getItem("authUser"));

    let newArticleRow = {
        article: null,
        quantity: 0,
        price: 0,
        discountedPrice: 0,
        discount: 0,
        total: 0,
        stockPoint: stockPoint,
    }

    useEffect(() => {
        setBrandSettings(user.brandConfig);

        onGetCustomerOptions();
        onGetStockPoints();
        onGetStockPointConfig();
        onGetTenantUsers(user?.tenantId);
        onGetTermsOfPaymentList()
        onGetTermsOfDeliveryList();
        onGetWayOfDeliveries();

        setArticleRows([newArticleRow]);



        return () => {
            onCreateOrderReset();
        }
    }, [])

    useEffect(() => {
        if (params.id && !useModal && !orderDetailsLoading) {
            onGetOrderDetails(params.id);
        }
    }, [params.id])

    useEffect(() => {
        // if(customerOptions && user?.customerId){
        //     setCustomerOption(customerOptions.find(co => co?.id === user?.customerId));
        // }
    }, [customerOptions])

    useEffect(() => {
        // if(customerOptions && user?.customerId){
        //     setCustomerOption(customerOptions.find(co => co?.id === user?.customerId));
        // }
        if (orderDetails || stockPointConfig) {
            setStockPoint(orderDetails ? orderDetails.StockPointId : stockPointConfig.defaultStockPointId);
        }
    }, [stockPointConfig, orderDetails])

    useEffect(() => {
        if (stockPointConfig) {
            setArticleRows(articleRows.map(ar => ({...ar, stockPoint: ar.stockPoint ? ar.stockPoint : stockPointConfig.defaultStockPointId})));
        }
    }, [stockPointConfig])

    useEffect(() => {
        if (customer) {
            onGetProductOptions(user?.tenantId, customer.id);
            onGetProjectOptions(user?.tenantId, customer.id);
            onGetCustomerUsers(customer.id);
            onGetDeliveryAddressList();
            setPriceList(customer?.priceListDetails)
        } else {
            setPriceList(null);
            setProductOptions([]);
            setProjectOptions([]);
            setCustomerUserOptions([]);
        }
    }, [customer])


    useEffect(() => {
        setProjectOptions(projects)
    }, [projects])

    useEffect(() => {
        setCustomerUserOptions(customerUsers)
    }, [customerUsers])

    useEffect(() => {
        setTenantUserOptions(tenantUsers)
    }, [tenantUsers])

    useEffect(() => {
        if(project){
            for(let i = 0; i < deliveryAddressList.length; i++){
                if(deliveryAddressList[i].projectId?.value == project?.id){
                    setDeliveryAddress(deliveryAddressList[i].id);
                    return;
                }
            }
        }
        setDeliveryAddress(null);
    }, [project])

    useEffect(() => {
        if (customerUserOptions) {
            let defaultVal = null;
            if (params.id && orderDetails && !useModal) {
                defaultVal = find(customerUserOptions, {
                    name: orderDetails.YourReference
                })
            }
            else {
                if (customer?.yourReference) {
                    defaultVal = find(customerUserOptions, {
                        name: customer?.yourReference
                    })
                }
                else if (customerUserOptions.length > 0) {
                    defaultVal = customerUserOptions[0]
                }
            }
            if (defaultVal) {
                setYourReference(defaultVal.id);
            } else if (params.id && !useModal) {
                setYourReference(null);
            }
        }
    }, [customerUserOptions, customer, orderDetails])

    useEffect(() => {
        const u = find(tenantUserOptions, { id: user.uid })
        setOurReference(u ? u.id: null);
    }, [tenantUserOptions, customer, orderDetails])

    useEffect(() => {
        if (articleRows) {
            const st = sumBy(articleRows, ar => ar.total)
            const tx = sumBy(articleRows, ar => ar.article?.vat ? ar.article.vat * ar.total / 100 : 0)
            const d = sumBy(articleRows, ar => ar.article?.salesPrice ? (ar.article?.salesPrice * ar.quantity) - ar.total : 0)
            setSubTotal(st);
            setTax(tx);
            setDiscount(d);
            setTotal(st + tx);
        }
    }, [articleRows])

    useEffect(() => {
        if (paymentTermList) {
            let defaultVal = null;
            if (orderDetails && params.id && !useModal) {
                defaultVal = find(paymentTermList, {
                    Code: orderDetails.TermsOfPayment
                })
            }
            else {
                if (customer?.termsOfPayment) {
                    defaultVal = find(paymentTermList, {
                        Code: customer?.termsOfPayment
                    })
                }
                else if (paymentTermList.length > 0) {
                    defaultVal = paymentTermList[0]
                }
            }
            if (defaultVal) {
                setPaymentTerms(defaultVal.Code);
            } else if (params.id && !useModal) {
                setPaymentTerms(null);
            }
        }
    }, [paymentTermList, customer, orderDetails]);

    useEffect(() => {
        if (deliveryTermList) {
            let defaultVal = null;
            if (orderDetails && params.id && !useModal) {
                defaultVal = find(deliveryTermList, {
                    Code: orderDetails.TermsOfDelivery
                })
            }
            else {
                if (customer?.termsOfDelivery) {
                    defaultVal = find(deliveryTermList, {
                        Code: customer?.termsOfDelivery
                    })
                } else if (deliveryTermList.length > 0) {
                    defaultVal = deliveryTermList[0]
                }
            }
            if (defaultVal) {
                setDeliveryTerms(defaultVal.Code);
            } else if (params.id && !useModal) {
                setDeliveryTerms(null)
            }
        }
    }, [deliveryTermList, customer, orderDetails]);

    useEffect(() => {
        if (wayOfDeliveryList) {
            let defaultVal = null;
            if (orderDetails && params.id && !useModal) {
                defaultVal = find(wayOfDeliveryList, {
                    Code: orderDetails.WayOfDelivery
                })
            }
            else {
                if (customer?.wayOfDelivery) {
                    defaultVal = find(wayOfDeliveryList, {
                        Code: customer?.wayOfDelivery
                    })
                } else if (wayOfDeliveryList.length > 0) {
                    defaultVal = wayOfDeliveryList[0]
                }
            }
            if (defaultVal) {
                setWayOfDelivery(defaultVal.Code);
            } else if (params.id && !useModal) {
                setWayOfDelivery(null)
            }
        }
    }, [wayOfDeliveryList, customer, orderDetails]);

    useEffect(() => {
        setLoading(orderPending);
    }, [orderPending])

    useEffect(() => {
        setLoading(false);
        if (isEmpty(orderSuccess)) return;
        if(useModal){
            tog_standard();
            cogoToast.success(props.t("Order created successfully!"), toastOptions)
        } else {
            history(`/orders/${orderSuccess.orderDetails.Order.DocumentNumber}`)
        } 
    }, [orderSuccess])

    useEffect(() => {
        if(orderError){
            if(useModal){
                cogoToast.error(props.t("Error occured while creating order!"), toastOptions)
            }
            setErrors([props.t(API_ERR_MSG)])
            setLoading(false);
            setTimeout(() => onCreateOrderReset(), 5000)
        }
    }, [orderError])

    useEffect(() => {
        if (params.id && orderDetails && !useModal) {
            let c = find(customerOptions, { customerNumber: orderDetails.CustomerNumber })
            if (c) {
                setCustomer(c);
                setPriceList(c.priceListDetails);
            }
            setDeliveryTerms(orderDetails.TermsOfDelivery);
            setPaymentTerms(orderDetails.TermsOfPayment);
            setOrderDate(orderDetails.OrderDate ? new Date(orderDetails.OrderDate) : null);
            setDeliveryDate(orderDetails.DeliveryDate ? new Date(orderDetails.DeliveryDate) : null);
            let urRef = find(customerUserOptions, { name: orderDetails.YourReference })?.id
            let ourRef = find(tenantUserOptions, { name: orderDetails.OurReference })?.id
            setOurReference(ourRef ? ourRef : null);
            setYourReference(urRef ? urRef : null);
            let p = find(projectOptions, { projectNumber: orderDetails.Project })
            setProject(p ? p : null);
            setYourOrderNumber(orderDetails.YourOrderNumber);
            let da = find(deliveryAddressList, { address: orderDetails.DeliveryAddress2 })?.id
            setDeliveryAddress(da ? da : null);
            setDeliveryReference(orderDetails.DeliveryAddress1);
            setDeliveryName(orderDetails?.DeliveryName);
            setDeliveryAddress1(orderDetails?.DeliveryAddress2);
            setDeliveryCountry(orderDetails?.DeliveryCountry);
            setDeliveryState(orderDetails?.DeliveryState);
            setDeliveryCity(orderDetails?.DeliveryCity);
            setDeliveryZipCode(orderDetails?.DeliveryZipCode)
            setPhone(orderDetails?.Phone1);

            /*let orderRows = processOrderRowsToHandleArticlePackages(orderDetails?.OrderRows)
            console.log("Rows ", orderRows)
            setArticleRows(orderRows?.map(or => or.ArticleNumber ?
                {
                    article: {
                        id: or.Article.articleNumber,
                        label: or.Article.displayName,
                        value: or.Article.articleNumber,
                        articlePackageType: or.Article?.articlePackageType,
                        articlePackage: or.Article?.articlePackage,
                        vat: or.Article?.vat,
                        salesPrice: or?.Article?.salesPrice
                    },
                    quantity: or.OrderedQuantity,
                    price: or.Price,
                    discount: or.Discount,
                    discountedPrice: or.Price - (or.Price * or.Discount / 100),
                    total: or.OrderedQuantity * or.Price,
                    stockPoint: or.StockPointId
                } :
                {
                    description: or.Description,
                    isTextRow: true,
                }
            ));*/
            setShipping(orderDetails.Freight);
        }
    }, [orderDetails, customerOptions]);

    const processOrderRowsToHandleArticlePackages = orderRows => {
        let processedRows = [];
        for (let i = 0; i < orderRows.length; i++){
            let orderRow = orderRows[i];
            if(!orderRow.ArticleNumber){
                processedRows.push(orderRow);
            }else if(orderRow?.Article?.articlePackageType === null && orderRow?.Article?.ContributionValue > 0 && checkIfArticlePartOfAnyPackage(orderRows, orderRow?.Article)){
                processedRows.push(orderRow);
            } else if(orderRow?.Article?.articlePackageType !== null){
                processedRows.push(orderRow);
            }
        }
        return processedRows;
    }

    const checkIfArticlePartOfAnyPackage = (orderRows, ar) => {
        for (let i = 0; i < orderRows.length; i++){
            let orderRow = orderRows[i];
            if (orderRow.ArticleNumber && orderRow?.Article?.articlePackageType != null){
                for (let j = 0; j < orderRow?.Article?.articlePackage?.packageItems?.length; j++){
                    let pArticle = orderRow?.Article?.articlePackage?.packageItems[j];
                    if(pArticle?.articleDto?.ArticleNumber === ar?.ArticleNumber){
                        return true;
                    }
                }
            }
        }
        return false;
    }


    useEffect(() => {

        if (projectOptions && orderDetails && params.id && !useModal) {
            let p = find(projectOptions, { projectNumber: orderDetails.Project })
            setProject(p ? p : null);
        } else {
            setProject(null);
        }
    }, [projectOptions, orderDetails])

    useEffect(() => {
        if(!deliveryAddress){
            if (deliveryAddressList && orderDetails && params.id && !useModal) {
                let da = find(deliveryAddressList, { address: orderDetails.DeliveryAddress2 })?.id
                setDeliveryAddress(da ? da : null);
            } else {
                setDeliveryAddress(null);
            }
        }
    }, [deliveryAddressList, orderDetails]);

    useEffect(() => {
        if (productOptions && !isEmpty(productOptions)) {
            if (orderDetails && params.id && !useModal) {
                let orderRows = processOrderRowsToHandleArticlePackages(orderDetails?.OrderRows)
                setArticleRows(orderRows.map(or => or.ArticleNumber ?
                    {
                        article: {id : or.Article.id,
                            label : or.Article.displayName,
                            value: or.Article.articleNumber,
                            articleNumber: or.Article.articleNumber,
                            description: or.Article.description,
                            articlePackageType: or.Article?.articlePackageType,
                            articlePackage: or.Article?.articlePackage,
                            vat: or.Article?.vat,
                            salesPrice: or?.Article?.salesPrice
                        },
                        quantity: or.OrderedQuantity,
                        price: or.Price,
                        discount: or.Discount,
                        discountedPrice: or.Price - (or.Price * or.Discount / 100),
                        total: or.OrderedQuantity * or.Price,
                        stockPoint: or.stockPoint
                    } :
                    {
                        description: or.Description,
                        isTextRow: true,
                    }
                ));
            } else {
                setArticleRowsWithUpdatedPriceList();
            }
        } else {
            setArticleRows([newArticleRow]);
        }
    }, [orderDetails, productOptions]);

    const clearErrors = () => setErrors([])

    const num = (val) => (val == "" ? 0 : val);

    const isDataLoading = loading 
        || deliveryAddressListLoading 
        || productOptionsLoading 
        || termsOfDeliveryListLoading 
        || termsOfPaymentListLoading 
        || wayOfDeliveriesLoading
        || orderDetailsLoading
        || projectsLoading
        || customerUsersLoading
        || tenantUserOptionsLoading

    function toggleAddDeliveryAddressModal(delAddress) {
        if(deliveryAddressList && delAddress){
            setDeliveryAddressList([...deliveryAddressList, delAddress]);
            setDeliveryAddress(delAddress.id);
        }
        setShowAddDeliveryAddressModal(!showAddDeliveryAddressModal)
    }

    function toggleAddProjectModal(proj) {
        if(projectOptions && proj){
            setProjectOptions([...projectOptions, proj]);
            setProject(proj);
        }
        setShowAddProjectModal(!showAddProjectModal)
    }

    function toggleAddCustomerUserModal(cu) {
        if(customerUserOptions && cu){
            setCustomerUserOptions([...customerUserOptions, cu]);
            setYourReference(cu.id);
        }
        setShowAddCustomerUserModal(!showAddCustomerUserModal)
    }

    function onGetDeliveryAddressList() {
        setDeliveryAddressListLoading(true)
        get(`/api/delivery-address?customerId=${customer.id}`)
            .then(data => {
                if (data?.status !== 200) {
                    setErrors([props.t(API_ERR_MSG)])
                } else {
                    setDeliveryAddressList(data?.data);
                }
                setDeliveryAddressListLoading(false)
            })
            .catch(err => {
                setErrors([props.t(API_ERR_MSG)])
                setDeliveryAddressListLoading(false)
            })
    }

    function onGetProductOptions() {
        setProductOptionsLoading(true)
        get(`/api/article/labelValue?published=true`)
            .then(data => {
                if (data?.status !== 200) {
                    setErrors([props.t(API_ERR_MSG)])
                } else {
                    setProductOptions(data?.data);
                }
                setProductOptionsLoading(false)
            })
            .catch(err => {
                setErrors([props.t(API_ERR_MSG)])
                setProductOptionsLoading(false)
            })
    }

    function onGetTermsOfPaymentList() {
        setTermsOfPaymentListLoading(true)
        get(`/api/orders/termsofpayments`)
            .then(data => {
                if (data?.status !== 200) {
                    setErrors([props.t(API_ERR_MSG)])
                } else {
                    setPaymentTermList(data?.data?.TermsOfPayments.map(p => (
                        {
                            ...p,
                            engdesc: p.Description.replace('dagar', props.t('days'))
                        }
                    )));
                }
                setTermsOfPaymentListLoading(false)
            })
            .catch(err => {
                setErrors([props.t(API_ERR_MSG)])
                setTermsOfPaymentListLoading(false)
            })
    }

    function onGetTermsOfDeliveryList() {
        setTermsOfDeliveryListLoading(true)
        get(`/api/orders/termsofdeliveries`)
            .then(data => {
                if (data?.status !== 200) {
                    setErrors([props.t(API_ERR_MSG)])
                } else {
                    setDeliveryTermList(data?.data?.TermsOfDeliveries);
                }
                setTermsOfDeliveryListLoading(false)
            })
            .catch(err => {
                setErrors([props.t(API_ERR_MSG)])
                setTermsOfDeliveryListLoading(false)
            })
    }

    const onGetWayOfDeliveries = async () => {
        setWayOfDeliveriesLoading(true)
        const response = await get(`/api/orders/wayofdeliveries`);
        if (response?.status === 200) {
            setWayOfDeliveryList(response.data.WayOfDeliveries)
        }
        setWayOfDeliveriesLoading(false);
    }

    const setArticleRowsWithUpdatedPriceList = async () => {
        let ors = []
        for (const idx in articleRows){
            const or = articleRows[idx]
            if(or.isTextRow) {
                ors.push(or)
            } else {
                let article = find(productOptions, { value: or.article?.articleNumber })
                if (!article || !or.article) {
                    return or;
                }
                let price = article.salesPrice;
                const response = await get(`/api/article/${article.id}/priceList/${customer.priceList}`);
                if (response?.status === 200) {
                    price = response.data?.Prices[0]?.Price;
                }
                let discountedPrice = price - (or.discount > 0 ? or.discount * price / 100 : 0)
                ors.push({
                    ...or,
                    price,
                    discountedPrice,
                    total: discountedPrice * or.quantity,
                })
            }
        }
        setArticleRows(ors);
    }

    const onGetArticlePriceList = async (article) => {
        const response = await get(`/api/article/${article.id}/priceList/${customer.priceList}`);
        if (response?.status === 200) {
            return response.data?.Prices[0]?.Price;
        }

        return article.salesPrice;
    }

    const checkForPriceList = (articleRow) => {
        setPriceListError(null);
        if(articleRow.article.articlePackageType == "MANUAL"){
            let articlePackage = find(articleRow.article.articlePackage, {quantity: parseInt(articleRow.quantity)});
            if(articlePackage) {
                let priceList = articlePackage?.articlePackagePriceLists.find(p => p.priceListCode == customer.priceList);
                if(!priceList){
                    setPriceListError("Manual package not configured for price list.");
                    return false;
                }
            }
        }
        return true;
    }

    async function updatePriceArticleRow(articleRow, index) {
        clearErrors();
        let updatedArticleRows = [...articleRows]
        let price = articleRow.price;
        let discountedPrice = price - (articleRow.discount * price / 100)
        let totalAmount = discountedPrice * articleRow.quantity
        if(articleRow?.article?.articlePackageType === "MANUAL"){
            let articlePackage = find(articleRow.article.articlePackage, {quantity: parseInt(articleRow.quantity)});
            if(articlePackage){
                price = getArticlePackagePrice(articlePackage);
                discountedPrice = price - (articleRow.discount * price / 100);
                totalAmount = discountedPrice;
            }
        }
        updatedArticleRows[index] = { ...articleRow, price, discountedPrice, total: totalAmount }
        setArticleRows(updatedArticleRows)
    }

    async function updateArticleRow(articleRow, index, isTextRow) {
        clearErrors();
        if(!isTextRow){
            let articleResponse = await getProductDetailsShallow(articleRow?.article?.value);
            articleRow.article = articleResponse?.data;
            if(!checkForPriceList(articleRow)){
                return;
            }
        }
        let updatedArticleRows = [...articleRows]
        if(isTextRow){
            updatedArticleRows[index] = { description: articleRow, isTextRow: true }
        } else {
            let price = articleRow.article ? await onGetArticlePriceList(articleRow.article) : 0;
            let discountedPrice = price - (articleRow.discount * price / 100)
            let totalAmount = discountedPrice * articleRow.quantity
            if(articleRow.article.articlePackageType === "MANUAL"){
                let articlePackage = find(articleRow.article.articlePackage, {quantity: parseInt(articleRow.quantity)});
                if(articlePackage){
                    price = getArticlePackagePrice(articlePackage);
                    discountedPrice = price - (articleRow.discount * price / 100);
                    totalAmount = discountedPrice;
                }
            }
            updatedArticleRows[index] = { ...articleRow, price, discountedPrice, total: totalAmount }
        }
        setArticleRows(updatedArticleRows)
    }

    function handleRemoveArticleRow(index, isTextRow) {
        clearErrors();
        let updatedArticleRows = [...articleRows]
        updatedArticleRows.splice(index, 1);
        setArticleRows(updatedArticleRows)
    }

    function handleMoveRowUp(index) {
        if(index != 0){
            let updatedArticleRows = [...articleRows]
            updatedArticleRows[index] = articleRows[index - 1]
            updatedArticleRows[index - 1] = articleRows[index]
            setArticleRows(updatedArticleRows)
        }
    }

    function handleMoveRowDown(index) {
        if(index != articleRows.length - 1){
            let updatedArticleRows = [...articleRows]
            updatedArticleRows[index] = articleRows[index + 1]
            updatedArticleRows[index + 1] = articleRows[index]
            setArticleRows(updatedArticleRows)
        }
    }

    const getArticlePackagePrice = (articlePackage) => {
        let mPrice = articlePackage?.price;
        if(articlePackage?.articlePackagePriceLists?.length > 0){
            let priceList = articlePackage?.articlePackagePriceLists.find(p => p.priceListCode == customer.priceList);
            if(priceList && priceList?.price){
                mPrice = priceList?.price;
            }
        }
        return mPrice;
    }

    const submitForm = (e) => {
        e.preventDefault();
        if (!customer) {
            setErrors(["Please select a customer."])
            return;
        }
        /*if (!deliveryAddress) {
            setErrors(["Please select a delivery address."])
            return;
        }*/
        if (isEmpty(articleRows)) {
            setErrors(["Please add alteast one article."])
            return;
        }
        if (articleRows.filter((ar, i) => !ar.isTextRow && (!ar.article || !ar.quantity)).length > 0) {
            setErrors(["Please select article and quantity in article row."])
            return;
        }
        if (articleRows.filter((ar, i) => ar.isTextRow && !ar.description).length > 0) {
            setErrors(["Please enter description in text row."])
            return;
        }

        if(!deliveryZipCode || !deliveryCity || !deliveryAddress1 || !deliveryCountry){
            setErrors(["Delivery address is mandatory"]);
            return;
        }

        /*let OrderRows1 = articleRows.map(ar => ar.isTextRow ?
            { 
                Description: ar.description
            } : 
            {
                ArticleNumber: ar.article.articleNumber,
                Description: ar.article.description,
                Discount: ar.discount,
                OrderedQuantity: ar.quantity,
                DeliveredQuantity: ar.quantity,
                Price: ar.price,
                StockPointId: ar.stockPoint
            }
        )*/

        let OrderRows = [];
        for(let i = 0; i < articleRows.length; i++){
            let ar = articleRows[i];
            if(ar.isTextRow){
                OrderRows.push({
                    Description: ar.description
                });
            } else {

                let articlePackage = ar.article.articlePackageType == "MANUAL" ? find(ar.article.articlePackage, {quantity: parseInt(ar.quantity)}) : ar?.article?.articlePackage[0];

                OrderRows.push({
                    ArticleNumber: ar.article.articleNumber,
                    Description: ar.article.articlePackageType == "MANUAL" ?  ar.article.description + " / "+ articlePackage?.name : ar.article.description,
                    Discount: ar.discount,
                    OrderedQuantity: ar.article.articlePackageType == "MANUAL" ? 1 : ar.quantity,
                    DeliveredQuantity: ar.article.articlePackageType == "MANUAL" ? 1 : ar.quantity,
                    Price: ar.article.articlePackageType == "MANUAL" ? getArticlePackagePrice(articlePackage) :ar.price,
                    StockPointId: ar.stockPoint
                })

                let packageItemOrders = articlePackage?.packageItems.map(pkgItem => ({
                    ArticleNumber: pkgItem.articleDto?.articleNumber,
                    Description: pkgItem.articleDto?.description,
                    OrderedQuantity: ar.article.articlePackageType == "DYNAMIC" ? parseExpression(ar.quantity, pkgItem?.operator, pkgItem?.quantity) : pkgItem?.quantity,
                    DeliveredQuantity: ar.article.articlePackageType == "DYNAMIC" ? parseExpression(ar.quantity, pkgItem?.operator, pkgItem?.quantity) : pkgItem.quantity,
                    Price: 0,
                    VAT: 0
                }));
                packageItemOrders?.forEach(o => OrderRows.push(o));
            }
        }

        const orderData = {
            copy: query?.copy,
            orderDetails: {
                Order: {
                    CustomerName: customer.name,
                    CustomerNumber: customer.customerNumber,
                    OrderRows,
                    Project: project?.projectNumber,
                    DocumentNumber: params.id && !useModal ? orderDetails.DocumentNumber : null,
                    YourReference: find(customerUserOptions, { id: parseInt(yourReference) })?.id,
                    OurReference: find(tenantUserOptions, { id: parseInt(ourReference) })?.name,
                    YourOrderNumber: yourOrderNumber,
                    DeliveryAddress1: deliveryReference,
                    DeliveryAddress2: deliveryAddress1,
                    DeliveryDate: deliveryDate ? parseDateToString(deliveryDate) : "",
                    DeliveryName: deliveryName,
                    DeliveryCountry: deliveryCountry,
                    DeliveryState: deliveryState,
                    DeliveryCity: deliveryCity,
                    DeliveryZipCode: deliveryZipCode,
                    Phone1:phone,
                    OrderDate: orderDate ? parseDateToString(orderDate) : "",
                    PriceList: priceList?.code,
                    Freight: shipping,
                    TermsOfDelivery: deliveryTerms,
                    TermsOfPayment: paymentTerms,
                    WayOfDelivery: wayOfDelivery,
                    StockPointId: stockPoint,
                    Total: parseInt(shipping) + (parseInt(shipping) * 0.25) + total
                }
            },
            deliveryAddressId: deliveryAddress,
        }

        onCreateOrder(orderData);
    }

    const parseExpression = (oprand1, operator, oprand2) => {
        operator = operator == "x" ? "*" : "/";
        return Math.round(evaluate(oprand1 + " " +  operator + " " + oprand2));
    }

    const getProjectDropdownOptions = () => {
        let options = [];
        options.push([...projectOptions]);
        return options;
    }

    const getDeliveryAddressLabel = (address) => {
        if(address){
            return address.name + " " + address.address + ", "+ address.city + " "+ address.zipCode + " " + address.country;
        }
        return "";
    }

    const handleChangeDeliveryAddress = (da) => {
        setDeliveryAddress(da);
        if(da) {
            let address = find(deliveryAddressList, {id: da});
            setDeliveryAddress1(address?.address);
            setDeliveryCountry(address?.country);
            setDeliveryState(address?.state);
            setDeliveryCity(address?.city);
            setDeliveryName(address?.name);
            setDeliveryZipCode(address?.zipCode);
            setPhone(address?.phone)
        } else {
            setDeliveryAddress1("");
            setDeliveryCountry("");
            setDeliveryState("");
            setDeliveryCity("");
            setDeliveryName("");
            setDeliveryZipCode("");
            setPhone("");
        }
    }

    return (
        /*<Card className={`mb-0${loading ? "low-opacity" : ''}`}>*/
        <>
            <AvForm
            >
                {/*<CardBody>*/}
                    {(!isDataLoading && !useModal) && <CardTitle className="h4">{params.id && !useModal ? query?.copy ? props.t("Copy Offer") : props.t("Update Order") : props.t("New Order")}</CardTitle>}
                    <div className={useModal ? null : "order-form-content"}>
                        <Loader loading={isDataLoading} />
                        <Row>
                            <Col>
                                <Card className="border px-4 pb-3 pt-4">
                                    <Row>
                                        <Col lg={4}>
                                            <div className="mb-3" key={customer?.id}>
                                                <label
                                                    htmlFor="example-text-input"
                                                >
                                                    {props.t("Customer")}
                                                </label>
                                                <SearchableDropdown
                                                    keyToDisplay={["name"]}
                                                    favorite={["favorite"]}
                                                    selectedValue={customer}
                                                    options={customerOptions ? customerOptions : []}
                                                    placeholder={props.t("Select customer")}
                                                    handleOnOptionClick={(co) => {
                                                        clearErrors();
                                                        setArticleRows([newArticleRow]);
                                                        setCustomer(co);
                                                    }}
                                                    disabled={loading}
                                                    required />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <AvField
                                                    name="priceList"
                                                    label={props.t("Price List")}
                                                    value={priceList?.description ? priceList?.description : priceList?.code}
                                                    className="form-control"
                                                    type="text"
                                                    disabled
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <label>{props.t("Delivery Terms")}</label>
                                                <DropdownSelect
                                                    defaultValue={{value : deliveryTerms, label : find(deliveryTermList, {Code : deliveryTerms})?.Description}}
                                                    values={deliveryTerms ? {value : deliveryTerms, label : find(deliveryTermList, {Code : deliveryTerms})?.Description} : null}
                                                    onChange={(e) => {
                                                        clearErrors();
                                                        setDeliveryTerms(e?.value);
                                                    }}
                                                    placeholder={props.t("Select Delivery Terms")}
                                                    disabled={loading}
                                                    options={deliveryTermList?.map(o => ({value: o.Code, label: o.Description}))}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <label>{props.t("Payment Terms")}</label>
                                                <DropdownSelect
                                                    defaultValue={{value : paymentTerms, label : find(paymentTermList, {Code : paymentTerms})?.engdesc}}
                                                    values={paymentTerms ? {value : paymentTerms, label : find(paymentTermList, {Code : paymentTerms})?.engdesc} : null}
                                                    onChange={(e) => {
                                                        clearErrors();
                                                        setPaymentTerms(e?.value);
                                                    }}
                                                    placeholder={props.t("Select Payment Terms")}
                                                    disabled={loading}
                                                    options={paymentTermList?.map(o => ({value: o.Code, label: o.engdesc}))}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <label>{props.t("Stockpoint")}</label>
                                                <DropdownSelect
                                                    defaultValue={{value : stockPoint, label : find(stockPoints, {id : stockPoint})?.name}}
                                                    values={stockPoint ? {value : stockPoint, label : find(stockPoints, {id : stockPoint})?.name} : null}
                                                    onChange={(e) => {
                                                        setStockPoint(e?.value);
                                                        setArticleRows(articleRows.map(ar => ({...ar, stockPoint: ar.stockPoint ? ar.stockPoint : e?.value})));
                                                    }}
                                                    placeholder={props.t("Select Stockpoint")}
                                                    disabled={loading}
                                                    options={stockPoints?.map(o => ({value: o.id, label: o.name}))}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <label
                                                htmlFor="example-text-input"
                                                className=""
                                            >
                                                {props.t("Order Date")}
                                            </label>
                                            <div className="mb-3">
                                                <DatePicker
                                                    onChange={(date) => {
                                                        setErrors([]);
                                                        setOrderDate(date);
                                                    }}
                                                    value={orderDate}
                                                    disabled={loading}
                                                    name={"orderDate"}
                                                    minDate={orderDate && orderDate < new Date() ? orderDate : new Date()}
                                                    format={"yyyy-MM-dd"}
                                                    placeholder={props.t("Enter Order Date")}
                                                    dayPlaceholder={"dd"}
                                                    monthPlaceholder={"mm"}
                                                    yearPlaceholder={"yyyy"} />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <label
                                                htmlFor="example-text-input"
                                                className=""
                                            >
                                                {props.t("Delivery Date")}
                                            </label>
                                            <div className="mb-3">
                                                <DatePicker
                                                    onChange={(date) => {
                                                        setErrors([]);
                                                        setDeliveryDate(date);
                                                    }}
                                                    value={deliveryDate}
                                                    disabled={loading}
                                                    name={"deliveryDate"}
                                                    minDate={deliveryDate && deliveryDate < new Date() ? deliveryDate : new Date()}
                                                    format={"yyyy-MM-dd"}
                                                    placeholder={props.t("Enter Delivery Date")}
                                                    dayPlaceholder={"dd"}
                                                    monthPlaceholder={"mm"}
                                                    yearPlaceholder={"yyyy"} />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <label>{props.t("Our Reference")}</label>
                                                <DropdownSelect
                                                    defaultValue={{value : ourReference, label : find(tenantUserOptions, {id : ourReference})?.name}}
                                                    values={ourReference ? {value : ourReference, label : find(tenantUserOptions, {id : ourReference})?.name} : null}
                                                    onChange={(e) => {
                                                        setOurReference(e?.value);
                                                    }}
                                                    placeholder={props.t("Select Our Reference")}
                                                    disabled={!customer || isDataLoading}
                                                    options={tenantUserOptions?.map(o => ({value: o.id, label: o.name}))}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <Row>
                                                <Col lg={10} className="pe-0">
                                                    <div className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                        >
                                                            {props.t("Project")}
                                                        </label>
                                                        {/*<SearchableDropdown
                                                            keyToDisplay={["description"]}
                                                            selectedValue={project}
                                                            options={projectOptions}
                                                            placeholder={props.t("Select Project")}
                                                            handleOnOptionClick={(p) => setProject(p)}
                                                            disabled={loading || !customer || isDataLoading}
                                                        />*/}

                                                        <DropdownSelect
                                                            defaultValue={project ? {value : project?.id, label : project?.description}: null}
                                                            values={project ? {value : project?.id, label :  project?.description} : null}
                                                            onChange={(e) => {
                                                                setProject(find(projectOptions, { id: e?.value}));
                                                            }}
                                                            placeholder={props.t("Select Project")}
                                                            disabled={loading || !customer || isDataLoading}
                                                            options={projectOptions?.map(o => ({value: o.id, label: o.description}))}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={2} className="d-flex align-items-end mb-3">
                                                    <div 
                                                      className="p-2 rounded" 
                                                      style={{
                                                        border: "1px solid lightgrey", 
                                                        cursor: "pointer", 
                                                        backgroundColor: customer && !isDataLoading ? 'white' : '#f1f3f5'
                                                      }}
                                                      onClick={() => customer && !isDataLoading ? setShowAddProjectModal(true) : null}
                                                      disabled={!customer}
                                                    >
                                                        <i className="uil uil-plus"></i>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={4}>
                                            <Row>
                                                <Col lg={10} className="pe-0">
                                                    <div className="mb-3">
                                                        <label>{props.t("Your Reference")}</label>
                                                        <DropdownSelect
                                                            defaultValue={{value : yourReference, label : find(customerUserOptions, {id : yourReference})?.name}}
                                                            values={yourReference ? {value : yourReference, label : find(customerUserOptions, {id : yourReference})?.name} : null}
                                                            onChange={(e) => {
                                                                setYourReference(e?.value);
                                                            }}
                                                            placeholder={props.t("Select Your Reference")}
                                                            disabled={!customer || isDataLoading}
                                                            options={customerUserOptions?.map(o => ({value: o.id, label: o.name}))}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={2} className="d-flex align-items-end mb-3">
                                                    <div 
                                                      className="p-2 rounded" 
                                                      style={{
                                                        border: "1px solid lightgrey", 
                                                        cursor: "pointer", 
                                                        backgroundColor: (customer && !isDataLoading) ? 'white' : '#f1f3f5'
                                                      }}
                                                      onClick={() => customer && !isDataLoading ? setShowAddCustomerUserModal(true) : null}
                                                    >
                                                        <i className="uil uil-plus"></i>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <AvField
                                                    name="yourOrderNumber"
                                                    label={props.t("Your Order Number")}
                                                    value={yourOrderNumber}
                                                    onChange={(e) => setYourOrderNumber(e.target.value)}
                                                    className="form-control"
                                                    placeholder={props.t("Your Order Number")}
                                                    type="text"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className="border px-4 pb-3 pt-4">
                                    <Row>
                                        <Col lg={4}>
                                            <Row>
                                                <Col lg={10} className="pe-0">
                                                    <div className="mb-3">
                                                        <label>{props.t("Delivery Address")}</label>
                                                        <DropdownSelect
                                                            defaultValue={{value : deliveryAddress, label : getDeliveryAddressLabel(find(deliveryAddressList, {id : deliveryAddress}))}}
                                                            values={deliveryAddress ? {value : deliveryAddress, label : getDeliveryAddressLabel(find(deliveryAddressList, {id : deliveryAddress}))} : null}
                                                            onChange={(e) => {
                                                                clearErrors();
                                                                handleChangeDeliveryAddress(e?.value);
                                                            }}
                                                            placeholder={props.t("Select Delivery Address")}
                                                            disabled={loading}
                                                            options={[
                                                                { label: props.t("Project"), options: deliveryAddressList?.filter(d => d.projectId != null && d.projectId.id == project?.id).map(o => ({value: o.id, label: getDeliveryAddressLabel(o)}))},
                                                                { label: props.t("Customer"), options: deliveryAddressList?.filter(d => d.projectId == null).map(o => ({value: o.id, label: getDeliveryAddressLabel(o)}))}]}
                                                        />

                                                    </div>
                                                </Col>
                                                <Col lg={2} className="d-flex align-items-end mb-3">
                                                    <div
                                                        className="p-2 rounded"
                                                        style={{
                                                            border: "1px solid lightgrey",
                                                            cursor: "pointer",
                                                            backgroundColor: (customer && !isDataLoading) ? 'white' : '#f1f3f5'
                                                        }}
                                                        onClick={() => customer && !isDataLoading ? setShowAddDeliveryAddressModal(true) : null}
                                                    >
                                                        <i className="uil uil-plus"></i>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <label>{props.t("Delivery Service")}</label>
                                                <DropdownSelect
                                                    defaultValue={{value : wayOfDelivery, label : find(wayOfDeliveryList, {Code : wayOfDelivery})?.Description}}
                                                    values={wayOfDelivery ? {value : wayOfDelivery, label : find(wayOfDeliveryList, {Code : wayOfDelivery})?.Description} : null}
                                                    onChange={(e) => {
                                                        clearErrors();
                                                        setWayOfDelivery(e?.value);
                                                    }}
                                                    placeholder={props.t("Select Delivery Service")}
                                                    disabled={loading}
                                                    options={wayOfDeliveryList?.map(o => ({value: o.Code, label: o.Description}))}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <AvField
                                                    name="deliveryReference"
                                                    label={props.t("Delivery Reference")}
                                                    value={deliveryReference}
                                                    onChange={(e) => setDeliveryReference(e.target.value)}
                                                    className="form-control"
                                                    placeholder={props.t("Delivery Reference")}
                                                    type="text"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <AvField
                                                    name="DeliveryName"
                                                    label={props.t("Delivery Name")}
                                                    value={deliveryName}
                                                    onChange={(e) => setDeliveryName(e.target.value)}
                                                    className="form-control"
                                                    placeholder={props.t("Delivery Name")}
                                                    type="text"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={8}>
                                            <div className="mb-3">
                                                <AvField
                                                    name="DeliveryAddress"
                                                    label={props.t("Address")}
                                                    value={deliveryAddress1}
                                                    onChange={(e) => setDeliveryAddress1(e.target.value)}
                                                    className="form-control"
                                                    placeholder={props.t("Address")}
                                                    type="text"
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <AvField
                                                    name="DeliveryCountry"
                                                    label={props.t("Country")}
                                                    value={deliveryCountry}
                                                    onChange={(e) => setDeliveryCountry(e.target.value)}
                                                    className="form-control"
                                                    placeholder={props.t("Country")}
                                                    type="text"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <AvField
                                                    name="DeliveryCity"
                                                    label={props.t("City")}
                                                    value={deliveryCity}
                                                    onChange={(e) => setDeliveryCity(e.target.value)}
                                                    className="form-control"
                                                    placeholder={props.t("City")}
                                                    type="text"
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <AvField
                                                    name="DeliveryZipCode"
                                                    label={props.t("Zipcode")}
                                                    value={deliveryZipCode}
                                                    onChange={(e) => setDeliveryZipCode(e.target.value)}
                                                    className="form-control"
                                                    placeholder={props.t("Zipcode")}
                                                    type="text"
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <AvField
                                                    name="Phone"
                                                    label={props.t("Phone")}
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    className="form-control"
                                                    placeholder={props.t("Phone")}
                                                    type="text"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                {priceListError && <>
                                    <Alert className="text-align-center" color="danger">
                                        {props.t(priceListError)}
                                    </Alert>
                                </>}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className="border pt-4">
                                    <Row className="px-4">
                                        <Col lg={3}>
                                            <label htmlFor="example-text-input">{props.t("Article")}</label>
                                        </Col>
                                        <Col lg={1}>
                                            <label htmlFor="example-text-input">{props.t("Quantity")}</label>
                                        </Col>
                                        <Col lg={2}>
                                            <label htmlFor="example-text-input">{props.t("Stockpoint")}</label>
                                        </Col>
                                        <Col lg={1}>
                                            <label htmlFor="example-text-input">{props.t("Price")}</label>
                                        </Col>
                                        <Col lg={1}>
                                            <label htmlFor="example-text-input">{props.t("Discount") + " (%)"}</label>
                                        </Col>
                                        <Col lg={2}>
                                            <label htmlFor="example-text-input">{props.t("Total")}</label>
                                        </Col>
                                        <Col lg={1}>
                                            <label htmlFor="example-text-input">{props.t("Remove")}</label>
                                        </Col>
                                        <Col lg={1}>
                                            <label htmlFor="example-text-input">{props.t("Move")}</label>
                                        </Col>
                                    </Row>
                                    {articleRows.map((ar, i) => ar.isTextRow ? 
                                    <Row key={i + productOptions.length} className="px-4">
                                        <Col lg={10}>
                                            <div className="mb-3">
                                                <AvField
                                                    name="text"
                                                    value={ar.description}
                                                    className="form-control"
                                                    placeholder={props.t("Enter Text")}
                                                    type="text"
                                                    onChange={(e) => updateArticleRow(
                                                        e.target.value, i, true)
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={1}>
                                            <div className="mb-3 d-flex align-items-center">
                                                <i
                                                    className={`uil uil-trash-alt text-danger font-size-20 mx-1 px-2 py-1 lang-edit-icon`}
                                                    onClick={() => handleRemoveArticleRow(i, true)}
                                                ></i>
                                            </div>
                                        </Col>
                                        <Col lg={1}>
                                            <div className="mb-3 d-flex align-items-center">
                                                <i
                                                    className={`uil uil-arrow-circle-up ${i == 0 ? 'text-muted' : 'text-warning'} font-size-20 me-1 px-2 py-1 lang-edit-icon`}
                                                    onClick={() => handleMoveRowUp(i)}
                                                    title={i == 0 && props.t('First row cannot be moved up')}
                                                ></i>
                                                <i
                                                    className={`uil uil-arrow-circle-down ${i == articleRows.length - 1 ? 'text-muted' : 'text-warning'} font-size-20 px-2 py-1 lang-edit-icon`}
                                                    onClick={() => handleMoveRowDown(i)}
                                                    title={i == articleRows.length - 1 && props.t('Last row cannot be moved down')}
                                                ></i>
                                            </div>
                                        </Col>
                                    </Row> :
                                    <>
                                        <Row key={i +  productOptions.length} className="px-4" key={ar?.article?.id}>
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <SearchableDropdown
                                                        keyToDisplay={["label"]}
                                                        selectedValue={ar?.article ? {label : ar?.article?.label ? ar?.article?.label : ar?.article?.displayName, value : ar?.article?.articleNumber} : null}
                                                        options={productOptions}
                                                        placeholder={props.t("Select Article")}
                                                        handleOnOptionClick={(article) => updateArticleRow(
                                                            {
                                                                ...ar,
                                                                article,
                                                            }, i)
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={1}>
                                                {ar?.article?.articlePackageType !== 'MANUAL' && <div className="mb-3">
                                                    <AvField
                                                        name="quantity"
                                                        value={ar.quantity}
                                                        className="form-control"
                                                        placeholder={props.t("Quantity")}
                                                        type="number"
                                                        validate={{
                                                            pattern: { value: '^[0-9]+$', errorMessage: 'Quantity must be integer numbers' },
                                                        }}
                                                        onChange={(e) => updatePriceArticleRow(
                                                            {
                                                                ...ar,
                                                                quantity: e.target.value == "" ? 0 : e.target.value,
                                                            }, i)
                                                        }
                                                        onWheel={preventNumberInputScroll}
                                                    />
                                                </div>}
                                                {ar?.article?.articlePackageType === 'MANUAL' && <div className="mb-3">
                                                    <select
                                                        className="form-control"
                                                        value={ar.quantity}
                                                        onChange={(e) => updatePriceArticleRow(
                                                            {
                                                                ...ar,
                                                                quantity: e.target.value == "" ? 0 : e.target.value,
                                                            }, i)
                                                        }>
                                                        <option value={0}>{props.t("Select Quantity")}</option>
                                                       {ar?.article?.articlePackage.map(pkg => <option value={pkg.quantity}>{pkg.name}</option>)}
                                                    </select>
                                                </div>}
                                            </Col>
                                            <Col lg={2}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="articleStockList"
                                                        value={ar.stockPoint}
                                                        className="form-control"
                                                        placeholder={props.t("")}
                                                        onChange={(e) => {
                                                            updateArticleRow(
                                                            {
                                                                ...ar,
                                                                stockPoint: e.target.value
                                                            }, i)
                                                        }}
                                                        type="select"
                                                        disabled={isDataLoading}
                                                    >
                                                        <option value={null}>{props.t("Select Stockpoint")}</option>
                                                        {stockPoints.map((sp, key) => <option value={sp.id}>{sp.name}</option>)}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col lg={1}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="price"
                                                        value={ar.price - ar.discountedPrice > 0 ? `${ar.discountedPrice} (${ar.price})` : ar.price}
                                                        className="form-control"
                                                        placeholder={props.t("0")}
                                                        validate={{
                                                            pattern: { value: '^[0-9]+$', errorMessage: 'Price must be integer numbers' },
                                                        }}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={1}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="discount"
                                                        value={ar.discount}
                                                        className="form-control"
                                                        type="number"
                                                        validate={{
                                                            pattern: { value: '^[0-9]+$', errorMessage: 'Discount must be integer numbers' },
                                                        }}
                                                        onChange={(e) => updatePriceArticleRow(
                                                            {
                                                                ...ar,
                                                                discount: e.target.value == "" ? 0 : e.target.value,
                                                            }, i)
                                                        }
                                                        onWheel={preventNumberInputScroll}
                                                        // onKeyDown={validateNumber}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="total"
                                                        value={ar.total}
                                                        className="form-control"
                                                        placeholder={props.t("0")}
                                                        validate={{
                                                            pattern: { value: '^[0-9]+$', errorMessage: 'Total must be integer numbers' },
                                                        }}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={1}>
                                                <div className="mb-3 d-flex align-items-center">
                                                    <i
                                                        className={`uil uil-trash-alt text-danger font-size-20 mx-1 px-2 py-1 lang-edit-icon`}
                                                        onClick={() => handleRemoveArticleRow(i)}
                                                    ></i>
                                                </div>
                                            </Col>
                                            <Col lg={1}>
                                                <div className="mb-3 d-flex align-items-center">
                                                    <i
                                                        className={`uil uil-arrow-circle-up ${i == 0 ? 'text-muted' : 'text-warning'} font-size-20 me-1 px-2 py-1 lang-edit-icon`}
                                                        onClick={() => handleMoveRowUp(i)}
                                                        title={i == 0 && props.t('First row cannot be moved up')}
                                                    ></i>
                                                    <i
                                                        className={`uil uil-arrow-circle-down ${i == articleRows.length - 1 ? 'text-muted' : 'text-warning'} font-size-20 px-2 py-1 lang-edit-icon`}
                                                        onClick={() => handleMoveRowDown(i)}
                                                        title={i == articleRows.length - 1 && props.t('Last row cannot be moved down')}
                                                    ></i>
                                                </div>
                                            </Col>
                                            {(ar?.article?.articlePackageType) && <Col lg={12}>
                                                {(ar?.article?.articlePackageType == "MANUAL") &&<>
                                                    <ol style={{marginLeft: "40px", fontSize: "12px"}}>
                                                        {find(ar?.article?.articlePackage, {quantity: parseInt(ar.quantity)})?.packageItems?.map(function (i) {
                                                            return (<li><label style={{width: "500px"}}>{i?.articleDto?.description} &nbsp;&nbsp;
                                                                {isStockable(i?.articleDto) && i?.articleDto?.disposableQuantity <= 0  && i?.quantity > 0 &&
                                                                    <span className="alert-danger border border-danger font-size-11 rounded p-1">{props.t("Out of Stock")}</span>}
                                                                {isStockable(i?.articleDto) && i?.articleDto?.disposableQuantity > 0 && i?.articleDto?.disposableQuantity < i?.quantity &&
                                                                    <span className="alert-warning border border-warning font-size-11 rounded p-1">
                                                                {props.t("Up to")} {parseInt(i?.articleDto?.disposableQuantity)} {props.t("item(s) is available in stock for delivery")}
                                                            </span>
                                                                }</label> {i?.quantity ? <label>{props.t("Qty")} - {i?.quantity}</label> : ''}
                                                            </li>)})}
                                                    </ol>
                                                </>}
                                                {(ar?.article?.articlePackageType == "DYNAMIC" && parseInt(ar.quantity) > 0) && <ol className='pb-0 ps-0 mb-0' style={{"width": '50%', "lineHeight": '35px'}}>
                                                    {ar?.article?.articlePackage[0]?.packageItems?.map(function (i, key) {
                                                        return (
                                                            <li className='d-flex justify-content-between'>
                                                                <label className='mb-0'>{(key + 1)}. {i?.articleDto?.description} &nbsp;&nbsp;
                                                                    {isStockable(i?.articleDto) && i?.articleDto?.disposableQuantity <= 0  && i?.quantity > 0 &&
                                                                        <span className="alert-danger border border-danger font-size-7 rounded p-1">{props.t("Out of Stock")}</span>}
                                                                    {isStockable(i?.articleDto) && i?.articleDto?.disposableQuantity > 0 && i?.articleDto?.disposableQuantity < i?.quantity &&
                                                                        <span className="alert-warning border border-warning font-size-7 rounded p-1">
                                                                        {props.t("Up to")} {parseInt(i?.articleDto?.disposableQuantity)} {props.t("item(s) is available in stock for delivery")}
                                                                        </span>
                                                                    }
                                                                </label>
                                                                {i?.quantity ? <label className='mb-0 ms-3'>{props.t("Qty")} - {parseExpression(ar.quantity , i?.operator, i?.quantity)}</label> : ''}
                                                            </li>)
                                                    })}
                                                </ol>}
                                            </Col>}
                                        </Row>
                                        {(isStockable(ar.article) && ar.article.disposableQuantity <= 0 && parseInt(ar.quantity) > 0) &&
                                            <Row>
                                                <Col lg="12">
                                                    <div className="d-flex px-2 mx-1 mb-3">
                                                        <span className="alert-danger border border-danger font-size-11 rounded p-1">
                                                            {props.t("Out of Stock")}
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>}
                                        {(isStockable(ar.article) && ar.article.disposableQuantity > 0 && ar.article.disposableQuantity < parseInt(ar.quantity)) &&
                                            <Row>
                                                <Col lg="12">
                                                    <div className="d-flex px-2 mx-1 mb-3" style={{ marginTop: "-10px" }}>
                                                        <span className="alert-warning border border-warning font-size-11 rounded p-1">
                                                            {props.t("Up to")} {parseInt(ar.article.disposableQuantity)} {props.t("item(s) is available in stock for delivery")}
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>}
                                    </>)}
                                    <Row className="px-4 pb-3">
                                        <Col align="left">
                                            <div className="d-flex mb-3">
                                                <div>
                                                    <Button
                                                        type="button"
                                                        color="secondary"
                                                        className="btn btn-success w-lg waves-effect waves-light me-3"
                                                        disabled={loading}
                                                        onClick={() => setArticleRows([...articleRows, newArticleRow])}
                                                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                    >
                                                        {props.t("Add Article")}
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        color="secondary"
                                                        className="btn btn-success w-lg waves-effect waves-light me-3"
                                                        disabled={loading}
                                                        onClick={() => setArticleRows([...articleRows, { description: "", isTextRow: true }])}
                                                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                    >
                                                        {props.t("Add Text")}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col align="right">
                                <div className="mb-1 d-flex justify-content-end">
                                    <Card className="border" style={{ minWidth: "350px" }}>
                                        <div className="card-header bg-transparent d-flex justify-content-center">
                                            <h5 className="font-size-16 mb-0">{props.t("Order Summary")}</h5>
                                        </div>
                                        <CardBody className="p-4 pt-0">
                                            <div className="table-responsive">
                                                <Table className="table mb-0">
                                                    <tbody>
                                                        <tr className="bt-0">
                                                            <td className="pt-4">{props.t("Shipping")}:</td>
                                                            <td className="text-end">
                                                                <AvField
                                                                    name="shipping"
                                                                    value={shipping}
                                                                    className="form-control"
                                                                    placeholder={props.t("0")}
                                                                    onChange={(e) => setShipping(e.target.value == "" ? 0 : e.target.value)}
                                                                    validate={{
                                                                        pattern: { value: '^[0-9]+$', errorMessage: 'Shipping must be integer numbers' },
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="pt-4">kr</td>
                                                        </tr>
                                                        <tr className="bt-0">
                                                            <td>{props.t("Sub Total")} :</td>
                                                            <td className="text-end">
                                                                {parseInt(shipping) + parseInt(subTotal)}
                                                            </td>
                                                            <td>kr</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{props.t("Tax")} :</td>
                                                            <td className="text-end">
                                                                {parseInt(tax) + (parseInt(shipping) * 0.25)}
                                                            </td>
                                                            <td>kr</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{props.t("Total")} :</td>
                                                            <td className="text-end">{parseInt(shipping) + (parseInt(shipping) * 0.25) + total}</td>
                                                            <td>kr</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col align="right">
                                <Button
                                    type="button"
                                    color="secondary"
                                    className="btn btn-secondary w-lg waves-effect waves-light me-3"
                                    onClick={() => useModal ? tog_standard() : history('/orders')}
                                    disabled={loading}
                                >
                                    {props.t("Cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="success"
                                    className="btn btn-success w-lg waves-effect waves-light"
                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                    disabled={loading}
                                    onClick={(e) => submitForm(e)}
                                >
                                    {props.t("Save")}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mt-3 text-center">
                            <Col>
                                {errors.length > 0 ? (
                                    <Alert color="danger">{errors.map((msg, index) => index === 0 ? msg : (<><br />{props.t(msg)}</>))}</Alert>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                {/*</CardBody>*/}
            </AvForm>

            <Modal
                isOpen={showAddProjectModal}
                backdrop="static"
                toggle={() => {
                    toggleAddProjectModal();
                }}
                className="add-order-modal"
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Create Project")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard();
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body m-2">
                    <ProjectForm
                        clearField={true}
                        useModal={true}
                        tog_standard={toggleAddProjectModal}
                        customerId={customer?.id}
                    />
                </div>
            </Modal>

            <Modal
                isOpen={showAddDeliveryAddressModal}
                backdrop="static"
                tog_standard={() => {
                    setShowAddDeliveryAddressModal(!showAddDeliveryAddressModal);
                }}
                className="add-delivery-address-modal"
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Add Delivery Address")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setShowAddDeliveryAddressModal(!showAddDeliveryAddressModal);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <DeliveryAddressForm
                        location={props.location}
                        clearField={true}
                        useModal={true}
                        tog_standard={toggleAddDeliveryAddressModal}
                        customerId={customer?.id}
                    />
                </div>
            </Modal>
            <CustomerUserFormContainer
                useModal={true}
                modal_standard={showAddCustomerUserModal}
                setmodal_standard={setShowAddCustomerUserModal}
                tog_standard={toggleAddCustomerUserModal}
                customerId={customer?.id}
            />
        </>
        /*</Card>*/
    );
};

const mapStateToProps = state => {
    return {
        customerOptions: state.Customer.customers,
        stockPoints: state.Config.stockPoints,
        stockPointConfig: state.Config.stockPointConfig,
        projects: state.Project.projects,
        projectsLoading: state.Project.loading,
        customerUsers: state.Customer.customerUsers,
        customerUsersLoading: state.Customer.customerUsersLoading,
        tenantUsers: state.tenant.tenantAdmins,
        tenantUserOptionsLoading: state.tenant.tenantAdminUsersLoading,
        orderSuccess: state.ecommerce.orderSuccess,
        orderPending: state.ecommerce.orderPending,
        orderError: state.ecommerce.orderError,
        orderDetails: state.ecommerce.orderDetails?.data,
        orderDetailsError: state.ecommerce.orderDetailsError,
        orderDetailsLoading: state.ecommerce.orderDetailsLoading,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetCustomerOptions: () => dispatch(GetCustomers(false)),
    onGetStockPoints: () => dispatch(getStockPoints()),
    onGetStockPointConfig: () => dispatch(getStockPointConfig()),
    onGetProjectOptions: (tenantId, customerId) => dispatch(getProjectList(customerId, null, tenantId, null,false)),
    onGetCustomerUsers: (customerId) => dispatch(GetCustomerUsers(customerId)),
    onGetTenantUsers: (tenantId) => dispatch(getTenantAdmins(tenantId, "active")),
    onCreateOrder: (orderData) => dispatch(createOrder(orderData)),
    onCreateOrderReset: () => dispatch(createOrderReset()),
    onGetOrderDetails: id => dispatch(getOrderDetails(id)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(OrderForm)));

import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useNavigate, useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {copyOrderToCart, copyOrderToCartReset, getOrderDetails, getOrderDetailsReset, getOrders} from "../../store/e-commerce/actions";
import {connect} from "react-redux";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Row, Table} from "reactstrap";
import _, {find} from "lodash";
import queryString from "query-string";
import logo from "../../assets/images/lightab_logo.svg";
import {API_ERR_MSG} from "../../util/constants";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import Loader from "../../components/Common/Loader";
import {downloadPDF} from "../../util/helperFunctions";
import TrackingNumber from "../Plugins/Shared/TrackingNumber";
import {del, get, put} from "../../helpers/api_helper";
import DeleteModal from "../../components/Common/DeleteModal";
import {getLabels} from "../../store/label/actions";
import {formatCurrency} from "../../helpers/currency_helper";
import withRouter from "../../components/Common/withRouter";

const OrderDetails = (props) => {
    const {
        orderDetails, onGetOrderDetails, onGetOrderDetailsReset, orderDetailsError,
        orderDetailsLoading, onCopyOrderToCart, copyToCartLoading, copyToCartError, onCopyOrderToCartReset, onGetLabels, labels, onGetOrders, orders} = props;
    const history = useNavigate();
    const user = JSON.parse(localStorage.getItem("authUser"));
    const [brandSettings, setBrandSettings] = useState(null);
    const [showDiscountColumn, setShowDiscountColumn] = useState(false);
    const [wayOfDelivery, setWayOfDelivery] = useState(null);
    const [yourReference, setYourReference] = useState(null);
    const [ourReference, setOurReference] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [showDeleteOrderModal, setShowDeleteOrderModal] = useState(false);
    const [orderCancelled, setOrderCancelled] = useState(false);
    const [termsOfDelivery, setTermsOfDelivery] = useState(null);
    const [termsOfPayment, setTermsOfPayment] = useState(null);
    const [singlebtn, setSinglebtn] = useState(false);
    const level = localStorage.getItem("level");

    const fortnoxConnectionStatus = localStorage.getItem("fortnox-connection-status") === "true";
    let params = useParams();
    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        onGetOrders("all");
    }, []);

    useEffect(() => {
        onGetOrderDetails(params.id);
        onGetLabels();
        return () => {
            onGetOrderDetailsReset();
            onCopyOrderToCartReset();
        }
    }, [params.id])

    useEffect(() => {
        setShowDiscountColumn(orderDetails?.OrderRows.filter(order => order.Discount > 0).length > 0);
        if (orderDetails && orderDetails.WayOfDelivery) {
            getWayOfDeliveryDescription(orderDetails.WayOfDelivery);
        }
        if (orderDetails && orderDetails.TermsOfDelivery) {
            getTermsOfDeliveryDescription(orderDetails.TermsOfDelivery);
        }
        if (orderDetails && orderDetails.TermsOfPayment) {
            getTermsOfPaymentDescription(orderDetails.TermsOfPayment);
        }
        if (orderDetails) {
            setCustomerDetails(orderDetails?.CustomerNumber);
            setUserReferenceDetails(orderDetails?.YourReference, true);
            setUserReferenceDetails(orderDetails?.OurReference, false);
            setOrderCancelled(orderDetails?.Cancelled);
        }
    }, [orderDetails]);

    useEffect(() => {
        if (orderDetailsError) {
            setTimeout(() => {
                onGetOrderDetailsReset()
            }, 5000);
        }
    }, [orderDetailsError])

    useEffect(() => {
        if (copyToCartError) {
            setTimeout(() => {
                onCopyOrderToCartReset()
            }, 5000);
        }
    }, [copyToCartError])

    const handleBack = () => {
        const level = localStorage.getItem("level");
        if (level == "TENANT") {
            history(-1)
            return;
        }
        history("/customer/orders");
    }
    const handleCartClick = () => {
        onCopyOrderToCart(params.id)
    }
    const getLink = () => {
        let queryParams = queryString.parse(props.location?.search);
        let link = "/orders";
        if (queryParams && queryParams.from) {
            link = queryParams.from
        } else if (user?.customerId) {
            link = '/customer/orders';
        }
        return link;
    }
    const getBreadcrumbs = () => {
        let crumbs = [
            {title: props.t("Orders"), link: getLink()},
        ];
        return crumbs;
    };

    const getBrandLogo = () => {
        if (user.brandConfig && user.brandConfig.brandLogo) {
            return BASE_IMAGE_URL + "/" + user.brandConfig.brandLogo;
        }
        return logo;
    }

    const getTableToolbarButtons = () => {
        const downloadPdfButton = {
            text: props.t("Download"),
            onClick: () => downloadPDF(params.id, 'orders'),
        }

        const approveButton  = {
            text: "Approve",
            onClick: () => handleLabelAction(find(labels, {"Id": 4}))
        };

        if (user?.customerId) {
            return [downloadPdfButton,
                {
                    text: <>
                        {copyToCartLoading ? props.t("Copying" + "...") : props.t("Copy Order")} </>,
                    onClick: handleCartClick,
                    disabled: copyToCartLoading
                }]
        } else {
            return [downloadPdfButton]
        }
    }

    const getLabelsLabels = () => {
        var availableLabels = [];
        if(orderDetails){
            for(let i = 0; i < labels.length; i++){
                var isPresent = false;
                for(let j = 0; j < orderDetails?.Labels?.length; j++){
                    if(orderDetails?.Labels[j].id === labels[i].Id){
                        isPresent = true;
                        break;
                    }
                }
                if(isPresent == false){
                    availableLabels.push(labels[i])
                }
            }
        }
        if(availableLabels.length > 0 && !orderCancelled){

            return (<>
                <Button
                    active
                    onClick={() => {
                        handleLabelAction(find(labels, {"Id": 4}))
                    }}
                    className="btn btn-success waves-effect waves-light mx-2 table-toolbar-btn"
                    /*style={{
                        marginRight: "10px",
                        backgroundColor: brandSettings?.secondaryColor,
                        borderColor: brandSettings?.secondaryColor
                    }}*/
                >
                    {props.t("Approve")}
                </Button></>)

            /*return (<>
                <Dropdown
                    isOpen={singlebtn}
                    toggle={() => setSinglebtn(!singlebtn)}
                >
                    <DropdownToggle tag="button"
                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                    className="btn btn-primary" caret>
                        {props.t("Action")}
                        <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                        {labels.map(function(label){
                            return <DropdownItem onClick={() => handleLabelAction(label)}>{label.Description}</DropdownItem>
                        })}

                    </DropdownMenu>
                </Dropdown>
            </>)*/
        }
        return null;
    }

    const handleLabelAction = async (label) => {
        let response = await put(`/api/orders/label/${params.id}`, label);
        if (response?.status === 200) {
            onGetOrderDetails(params.id);
        }
    }


    const getWayOfDeliveryDescription = async (code) => {
        const response = await get(`/api/orders/wayofdeliveries/${code}`);
        if (response?.status === 200) {
            setWayOfDelivery(response.data.WayOfDelivery.Description)
        }
    }

    const getTermsOfDeliveryDescription = async (code) => {
        const response = await get(`/api/orders/termsofdeliveries/${code}`);
        if (response?.status === 200) {
            setTermsOfDelivery(response.data.TermsOfDelivery.Description)
        }
    }

    const getTermsOfPaymentDescription = async (code) => {
        const response = await get(`/api/orders/termsofpayments/${code}`);
        if (response?.status === 200) {
            setTermsOfPayment(response.data.TermsOfPayment.Description)
        }
    }

    const setCustomerDetails = async (customerNumber) => {
        if (!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN")) {
            const response = await get(`/api/customers/byCustomerNumber/${customerNumber}`);
            if (response?.status === 200 && response.data?.id) {
                setCustomer(response.data)
            }
        }
    }

    const setUserReferenceDetails = async (name, isCustomerUser) => {
        if (name && ((isCustomerUser && user?.roles?.includes("ROLE_CUSTOMER_ADMIN")) || !user?.customerId)) {
            const response = await get(`/api/user/byName/${name}?isCustomerUser=${isCustomerUser}`);
            if (response?.status === 200 && response.data?.id) {
                if (isCustomerUser) {
                    setYourReference(response.data)
                } else {
                    setOurReference(response.data)
                }
            }
        }
    }

    const redirectToInvoice = () => {
        if (!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN")) {
            history((user?.customerId ? "/customer/invoice" : "/invoice") + `/${orderDetails?.InvoiceReference}`)
        }
    }

    const redirectToOffer = () => {
        history((user?.customerId ? "/customer/offers" : "/offers") + `/${orderDetails?.OfferReference}/types/all`)
    }

    function toggleDeleteOrderModal() {
        setShowDeleteOrderModal(!showDeleteOrderModal);
    }

    const handleDeleteOrder = async () => {
        let response = await del(`/api/orders/${params.id}`);
        if (response.status === 200) {
            setOrderCancelled(response.data?.Order.Cancelled);
        }
    };

    const handleEditOrder = () => history(`/order/${params.id}/edit`);

    const handleDuplicateOrder = () => history(`/order/${params.id}/edit?copy=true`);

    const disableEditDeleteOrder = () => {
        if(fortnoxConnectionStatus !== true){
            return true;
        }
        return user?.customerId
            || orderCancelled
            || (orderDetails?.InvoiceReference && orderDetails?.InvoiceReference > "0")
            || orderDetails?.WarehouseReady
    }


    const getOrderLabels = () => {
        return <>
            {orderDetails?.Labels.map(function(label){
                var l = find(labels, label)
                return <><div className="badge bg-warning bg-warning p-2 ms-2">{props.t(l?.Description)}</div></>
            })}
        </>
    }

    const OrderLabels = !_.isEmpty(orderDetails) &&
        <div className="d-flex justify-content-start">
            <div className="d-flex flex-column">
                <div className="d-flex">
                    {orderDetails.WarehouseReady && <div className="badge bg-success bg-light-success p-2">{props.t("Sent")}</div>}
                    {orderDetails.status === 'Ongoing' && <div className="badge bg-info bg-light-info p-2">{props.t(orderDetails.status)}</div>}
                    {orderDetails.status === 'Delayed' && <div className="badge bg-warning bg-light-warning p-2">{props.t(orderDetails.status)}</div>}
                    {orderCancelled && <div className="badge bg-danger bg-light-danger p-2">{props.t("Cancelled")}</div>}
                    {orderDetails.OfferReference > 0 &&
                        <div onClick={() => redirectToOffer()}>
                            <div className={`badge bg-primary bg-light-primary ms-2 p-2 cursor-pointer`}>
                                {props.t("Offer")}{" - " + orderDetails?.OfferReference}
                            </div>
                        </div>
                    }
                    {orderDetails.InvoiceReference > 0 &&
                        <div onClick={() => redirectToInvoice()}>
                            <div
                                className={`badge bg-warning bg-light-warning ms-2 p-2 ${!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN") ? "cursor-pointer" : ""}`}>
                                {props.t("Invoice")}{!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN") ? " - " + orderDetails?.InvoiceReference : ""}
                            </div>
                        </div>
                    }
                    {user.tenantKey == 'LIGHTAB' && orderDetails?.StockPointCode == 'LAGER' && orderDetails?.Labels?.length > 0 && getOrderLabels()}
                </div>
            </div>
        </div>

    const getTotal = (orderRow) => {
        if(orderRow?.Total == 0){
            return orderRow?.OrderedQuantity * orderRow?.Price;
        }
        return orderRow?.Total;
    }

    const handleOrderChange = (order) => {
        window.location.href = `/orders/${order?.DocumentNumber}`;
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        title={props.t("Order Details")}
                        breadcrumbs={getBreadcrumbs()}
                        backBtn={handleBack}
                    />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={props.t("Order Details")}
                                        buttons={getTableToolbarButtons()}
                                        labels={OrderLabels}
                                        titleDropdown={{
                                            isSearchableDropdown: true,
                                            value: orderDetails,
                                            options: orders,
                                            placeholder: props.t("Select Order"),
                                            isHeader: true,
                                            prefix: props.t("Order") + " - ",
                                            handleSelect: (op) => {
                                                handleOrderChange(op);
                                            },
                                            keyToDisplay: "DocumentNumber",
                                        }}
                                        canEdit={disableEditDeleteOrder() ? null : handleEditOrder}
                                        canDelete={disableEditDeleteOrder() ? null : toggleDeleteOrderModal}
                                        canDuplicate={fortnoxConnectionStatus ? handleDuplicateOrder : null}
                                        dropdownButton={user?.tenantKey == 'LIGHTAB' && level == "TENANT" && orderDetails?.StockPointCode == 'LAGER' && orderDetails?.WarehouseReady !== true && getLabelsLabels}
                                    />
                                </CardHeader>
                                <Loader loading={orderDetailsLoading} title="Orders Details"/>
                                {!orderDetailsLoading &&
                                    <CardBody>
                                        {copyToCartError &&
                                            <Alert className={"mt-4"} color="danger" role="alert">
                                                {props.t(API_ERR_MSG)}
                                            </Alert>
                                        }

                                        {orderDetailsError &&
                                            <Alert className={"mt-4"} color="danger" role="alert">
                                                {orderDetailsError}
                                            </Alert>
                                        }
                                        {!_.isEmpty(orderDetails) &&
                                            <>
                                                <Row>
                                                    <Col md="6">
                                                        
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="d-flex justify-content-end">
                                                            <div className="d-flex flex-column">
                                                                <div className="d-flex">
                                                                    <p className="p-0 m-0" style={{width: "140px"}}>{props.t("Order date")}</p>
                                                                    <p className="p-0 m-0">{new Date(orderDetails?.OrderDate).toLocaleDateString()}</p>
                                                                </div>
                                                                <div className="d-flex">
                                                                    <p className="p-0 m-0" style={{width: "140px"}}>{props.t("Order no")}</p>
                                                                    <p className="p-0 m-0">{orderDetails?.DocumentNumber}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col md={6}>
                                                        <p className="m-0 p-0">{props.t("Delivery Address")}</p>
                                                        <p className="m-0 p-0">{orderDetails?.DeliveryName}</p>
                                                        <p className="m-0 p-0">{orderDetails?.DeliveryAddress1}</p>
                                                        <p className="m-0 p-0">{orderDetails?.DeliveryAddress2}</p>
                                                        <p className="m-0 p-0">{orderDetails?.DeliveryZipCode + " " + orderDetails?.DeliveryCity}</p>
                                                        <p className="m-0 p-0">{orderDetails?.DeliveryCountry}</p>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div
                                                            className={`p-0 m-0 ${!user?.customerId && customer ? "cursor-pointer" : ""}`}
                                                            style={{color: !user?.customerId && customer ? "#0404bd" : "black"}}
                                                            onClick={() => {
                                                                if (customer && !user?.customerId) {
                                                                    history(`/customers/${orderDetails?.CustomerNumber}/profile`)
                                                                }
                                                            }}>
                                                            {orderDetails?.CustomerName}
                                                        </div>
                                                        <p className="m-0 p-0" style={{color: "black"}}>{orderDetails?.Address1}</p>
                                                        <p className="m-0 p-0" style={{color: "black"}}>{orderDetails?.Address2}</p>
                                                        <p className="m-0 p-0" style={{color: "black"}}>{orderDetails?.ZipCode + " " + orderDetails?.City}</p>
                                                        <p className="m-0 p-0" style={{color: "black"}}>{orderDetails?.Country}</p>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col md={6} className="mb-3">
                                                        <div className="d-flex">
                                                            <div className="d-flex flex-column">
                                                                <div className="d-flex">
                                                                    <div className="d-flex">
                                                                        <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Customer number")}</p>
                                                                        <div
                                                                            className={`p-0 m-0 ${!user?.customerId && customer ? "cursor-pointer" : ""}`}
                                                                            style={{color: `${!user?.customerId && customer ? "#0404bd" : ""}`}}
                                                                            onClick={() => {
                                                                                if (customer && !user?.customerId) {
                                                                                    history(`/customers/${orderDetails?.CustomerNumber}/profile`)
                                                                                }
                                                                            }}>
                                                                            {orderDetails?.CustomerNumber}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {orderDetails?.YourReference &&
                                                                    <div className="d-flex">
                                                                        <div className="d-flex">
                                                                            <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Your reference")}</p>
                                                                            <div
                                                                                className={`p-0 m-0 ${yourReference && customer && (!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN")) ? "cursor-pointer" : ""}`}
                                                                                style={{color: `${yourReference && customer && (!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN")) ? "#0404bd" : ""}`}}
                                                                                onClick={() => {
                                                                                    if (yourReference && customer && (!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN"))) {
                                                                                        history(`/customers/${customer?.id}/users/${yourReference.id}`)
                                                                                    }
                                                                                }}>
                                                                                {orderDetails?.YourReference}
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                {orderDetails.YourOrderNumber &&
                                                                    <div className="d-flex">
                                                                        <div className="d-flex">
                                                                            <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Your order number")}</p>
                                                                            <p className="p-0 m-0">{orderDetails?.YourOrderNumber}</p>
                                                                        </div>
                                                                    </div>}
                                                                {orderDetails.TermsOfDelivery &&
                                                                    <div className="d-flex">
                                                                        <div className="d-flex">
                                                                            <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Delivery conditions")}</p>
                                                                            <p className="p-0 m-0">{props.t(termsOfDelivery)}</p>
                                                                        </div>
                                                                    </div>}
                                                                {orderDetails.WayOfDelivery &&
                                                                    <div className="d-flex">
                                                                        <div className="d-flex">
                                                                            <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Delivery Service")}</p>
                                                                            <p className="p-0 m-0">{wayOfDelivery}</p>
                                                                        </div>
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md={6} className="mb-4">
                                                        <div className="d-flex">
                                                            <div className="d-flex flex-column">
                                                                {orderDetails?.OurReference &&
                                                                    <div className="d-flex">
                                                                        <div className="d-flex">
                                                                            <p className="p-0 m-0" style={{width: "150px"}}>{props.t("Our reference")}</p>
                                                                            <div
                                                                                className={`p-0 m-0 ${user?.customerId || !ourReference ? "" : "cursor-pointer"}`}
                                                                                style={{color: `${user?.customerId || !ourReference ? "" : "#0404bd"}`}}
                                                                                onClick={() => {
                                                                                    if (ourReference) {
                                                                                        history(`/settings/users/${ourReference.id}`)
                                                                                    }
                                                                                }}>
                                                                                {orderDetails?.OurReference}
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                                {orderDetails?.TermsOfPayment &&
                                                                    <div className="d-flex">
                                                                        <div className="d-flex">
                                                                            <p className="p-0 m-0" style={{width: "150px"}}>{props.t("Payment terms")}</p>
                                                                            <p className="p-0 m-0">{props.t(termsOfPayment)}</p>
                                                                        </div>
                                                                    </div>}
                                                                <div className="d-flex">
                                                                    <div className="d-flex">
                                                                        <p className="p-0 m-0" style={{width: "150px"}}>{props.t("Delivery date")}</p>
                                                                        <p className="p-0 m-0">{new Date(orderDetails?.DeliveryDate).toLocaleDateString()}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="py-2">
                                                    <div className="table-responsive">
                                                        <Table className="table-centered mb-0">
                                                            <thead>
                                                            <tr>
                                                                <th style={{width: "10%"}}>{props.t("Article Number")}</th>
                                                                <th style={{width: "60%"}}>{props.t("Item")}</th>
                                                                <th style={{width: "5%"}}>{props.t("Quantity")}</th>
                                                                <th style={{width: "5%"}}>{props.t("Delivered Quantity")}</th>
                                                                <th style={{width: "5%"}}>{props.t("Price")}</th>
                                                                {showDiscountColumn && <th style={{width: "5%"}}>{props.t("Discount")}</th>}
                                                                <th className="text-end" style={{width: "10%"}}>{props.t("Total")}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {_.map(
                                                                orderDetails?.OrderRows,
                                                                (item, key) => (
                                                                    <tr key={key}>
                                                                        <td>{item.ArticleNumber}</td>
                                                                        <td>
                                                                            <p className="font-size-15 mb-1">
                                                                                {user.tenantKey == 'LIGHTAB' && <TrackingNumber item={item.Description}/>}
                                                                                {user.tenantKey != 'LIGHTAB' && item.Description}
                                                                            </p>
                                                                        </td>
                                                                        <td>{item.OrderedQuantity != 0 ? item.OrderedQuantity : ""}</td>
                                                                        <td>{item.DeliveredQuantity != 0 ? item.DeliveredQuantity : ""}</td>
                                                                        <td>{item.Price != 0 ? formatCurrency(orderDetails?.Currency, item.Price) : ""}</td>
                                                                        {showDiscountColumn && <td>{item.Discount != 0 ? item.Discount + '%' : ""}</td>}
                                                                        <td className="text-end">{getTotal(item) ? formatCurrency(orderDetails?.Currency, getTotal(item)) : ""}</td>
                                                                    </tr>
                                                                )
                                                            )}
                                                            <tr>
                                                                <th colSpan="4" className="border-0 text-end p-0 pt-3 pe-2">{props.t("Shipping")}</th>
                                                                <td className="border-0 text-end p-0 pt-3 pe-2">{formatCurrency(orderDetails?.Currency, orderDetails?.Freight)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th colSpan="4" className="border-0 text-end p-0 pe-2">{props.t("Sub Total")}</th>
                                                                <td className="border-0 text-end p-0 pe-2">{formatCurrency(orderDetails?.Currency, orderDetails?.Net + orderDetails?.Freight)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th colSpan="4" className="border-0 text-end p-0 pe-2">
                                                                    {props.t("Tax")}</th>
                                                                <td className="border-0 text-end p-0 pe-2">{formatCurrency(orderDetails?.Currency, orderDetails?.TotalVAT)}</td>
                                                            </tr>
                                                            {orderDetails?.RoundOff != 0 &&
                                                                <tr>
                                                                    <th colSpan="4" className="border-0 text-end p-0 pe-2">{props.t("Round Off")}</th>
                                                                    <td className="border-0 text-end p-0 pe-2">{formatCurrency(orderDetails?.Currency, orderDetails?.RoundOff)}</td>
                                                                </tr>}
                                                            <tr>
                                                                <th colSpan="4" className="border-0 text-end p-0 pe-2"><h4 className="m-0 p-0">{props.t("Total")}</h4></th>
                                                                <td className="border-0 text-end p-0 pe-2"><h4 className="m-0 p-0">{formatCurrency(orderDetails?.Currency, orderDetails?.TotalToPay)}</h4></td>
                                                            </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </>}
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <DeleteModal
                deleteCallback={() => handleDeleteOrder()}
                element={"Order"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteOrderModal}
                toggleModal={() => toggleDeleteOrderModal()}
                alertMsg={props.t("Are you sure you want to cancel this order?")}
            />
        </React.Fragment>
    )
}

OrderDetails.prototype = {
    orderDetails: PropTypes.object,
    orderDetailsLoading: PropTypes.object,
    onGetOrderDetails: PropTypes.func,
    onGetOrderDetailsReset: PropTypes.func,
    onPostCartItems: PropTypes.func,
    labels: PropTypes.array,
    onGetLabels: PropTypes.func
}

const mapStateToProps = state => {
    return {
        orders: state.ecommerce.orders?.data?.Orders,
        orderDetails: state.ecommerce.orderDetails?.data,
        orderDetailsError: state.ecommerce.orderDetailsError,
        orderDetailsLoading: state.ecommerce.orderDetailsLoading,
        copyToCartError: state.Cart.copyToCartError,
        copyToCartLoading: state.Cart.copyToCartLoading,
        labels: state.Labels.labels
    }
}

const mapDispatchToProps = dispatch => ({
    onGetOrders: (type) => dispatch(getOrders(type)),
    onGetOrderDetails: id => dispatch(getOrderDetails(id)),
    onGetOrderDetailsReset: () => dispatch(getOrderDetailsReset()),
    onCopyOrderToCart: (orderId) => dispatch(copyOrderToCart(orderId)),
    onCopyOrderToCartReset: (orderId) => dispatch(copyOrderToCartReset(orderId)),
    onGetLabels: () => dispatch(getLabels())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(OrderDetails)));

import {withTranslation} from "react-i18next";
import queryString from "query-string";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Alert, Card, Col, Container, Row} from "reactstrap";
import {get} from "../../helpers/api_helper";
import Loader from "../../components/Common/Loader";
import {useNavigate} from "react-router-dom";

const FortnoxConfigActivation = (props) => {

    let queryParams = queryString.parse(window.location.search);

    const [errorCode, setErrorCode] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [state, setState] = useState(null);
    const [code, setCode] = useState(null);
    const [loading, setLoading] = useState(null);

    let history = useNavigate();

    useEffect(() => {
        setState(queryParams?.state);
        setErrorCode(queryParams?.error);
        setErrorMessage(queryParams?.error_description);
        setCode(queryParams?.code);
    }, [])

    useEffect( () => {
        (async () => {
            let lState = localStorage.getItem("state");
            if (code && state === lState) {
                setLoading(true)
                let url = localStorage.getItem("redirect_uri");
                let response = await get(`/api/fortnox/activation/${code}?uri=${url}`);
                if (response?.status == 202) {
                    history(`/settings/fortnox-config`);
                }
                if(response?.status == 203){
                    setErrorMessage(response?.data);
                    setErrorCode("Error");
                }
                setLoading(false);
            }
        })();
    }, [code]);


    return (<React.Fragment>
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            {errorCode ? (
                                <Alert color="danger">
                                    <h4>{errorMessage}</h4>
                                    <p>{errorCode}</p>
                                </Alert>
                            ) : null}
                            <Loader loading={loading}/>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>)
}


FortnoxConfigActivation.prototype = {}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => ({})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(FortnoxConfigActivation));

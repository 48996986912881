import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Modal, Row,} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getFavourites, getFavouritesReset} from "../../store/e-commerce/actions";
import {del, get, post} from "../../helpers/api_helper";
import DeleteModal from "../../components/Common/DeleteModal";
import TableToolbar from "../../components/Common/TableToolbar";
import {size} from "lodash-es";
import {API_ERR_MSG} from "../../util/constants";
import {isEmpty} from "underscore";
import Loader from "../../components/Common/Loader";
import SearchableDropdown from "../../components/Common/SearchableDropdown";
import {GetCustomers} from "../../store/actions";
import {orderBy} from "lodash";
import withRouter from "../../components/Common/withRouter";

const FavouriteList = (props) => {
    const {
        favourites,
        onGetFavourites,
        loading,
        getFavouriteError,
        onGetFavouritesReset, onGetCustomerOptions, customerOptions,
    } = props;

    const [modal_standard, setmodal_standard] = useState(false);
    const [modal_edit, setmodal_edit] = useState(false);
    const [modal_confirm, setmodal_confirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [name, setName] = useState(null);
    const [favouriteList, setFavouriteList] = useState([]);
    const [editFavourite, setEditFavourite] = useState(null);
    const [showDeleteFavouriteModal, setShowDeleteFavouriteModal] = useState(false);
    const [deleteFavouriteId, setDeleteFavouriteId] = useState(null);
    const [articleFavourites, setArticleFavourites] = useState([]);
    const [brandSettings, setBrandSettings] = useState(null);
    const [confirmation, setConfirmation] = useState(false);
    const [dropdownValues, setDropdownValues] = useState([{id: null, name: ""}]);
    const [customerOption, setCustomerOption] = useState(null)
    const history = useNavigate();
    const user = JSON.parse(localStorage.getItem("authUser"));
    const [sortState, setSortState] = useState(null);
    const numericColumns = [];

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function tog_confirm() {
        setmodal_confirm(!modal_confirm);
        removeBodyCss();
    }

    function toggleDeleteFavourite() {
        if (showDeleteFavouriteModal) {
            setDeleteFavouriteId(null);
        }
        setShowDeleteFavouriteModal(!showDeleteFavouriteModal);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        onGetFavourites();
        onGetCustomerOptions();
        return () => {
            onGetFavouritesReset();
        }
    }, [])

    useEffect(() => {
        if (deleteFavouriteId) {
            get(`/api/productLists/articles?productListId=${deleteFavouriteId}`)
                .then(data => {
                    if (data?.status !== 200) {
                        setErrorMessage(props.t(API_ERR_MSG))
                    } else {
                        setArticleFavourites(data?.data?.data);
                        if (isEmpty(data?.data?.data)) {
                            toggleDeleteFavourite();
                        }
                    }
                })
                .catch(err => {
                    setErrorMessage(props.t(API_ERR_MSG))
                })
        }
    }, [deleteFavouriteId])

    useEffect(() => {
        if (!isEmpty(articleFavourites)) {
            toggleDeleteFavourite();
        }
    }, [articleFavourites])

    useEffect(() => {
        if (getFavouriteError) {
            setErrorMessage(props.t(API_ERR_MSG))
            setTimeout(() => {
                setErrorMessage('')
            }, 5000);
            setTimeout(() => {
                onGetFavouritesReset()
            }, 5000);
        }
    }, [getFavouriteError])

    useEffect(() => {
        setFavouriteList(favourites);
    }, [favourites]);

    useEffect(() => {
      if(sortState?.column){
        const {column, direction} = sortState;
        setFavouriteList(orderBy(favouriteList, o => {
            if(column === 'customer') return o[column] ? o[column]?.name?.toLowerCase() : props.t('all')
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])

    useEffect(() => {
        if(customerOptions && user?.customerId){
            setCustomerOption(customerOptions.find(co => co?.id === user?.customerId));
        }
    }, [customerOptions])

    const handleSaveFavourite = async () => {
        let response = await post(`/api/productLists`, {
            id: editFavourite ? editFavourite.id : null,
            name: name,
            customer: customerOption
        });
        if (confirmation) {
            tog_confirm();
        }
        setConfirmation(false);
        setName(null);
        if(!(user?.customerId)){
            setCustomerOption(null);
        }
        onGetFavourites()
        tog_standard();


    };


    const handleDeleteFavourite = async () => {
        let response = await del(`/api/productLists/${deleteFavouriteId}`);
        onGetFavourites();
        setDeleteFavouriteId(null);
    };

    const getDatatableData = () => {
        let cols = [
            {
                label: props.t("Name"),
                field: "name",
                autoWidth: true,
            },
            {
                label: props.t("Customer"),
                field: "customer",
                width: 200,
            },
            {
                label: props.t("Created Date"),
                field: "createdAt",
                autoWidth: true,
            },
            {
                label: props.t("Actions"),
                field: "actions",
                autoWidth: true,
            },
        ]

        if(user?.customerId){
            cols.splice(1, 1);
        }

        let data = {
            columns: cols,
            rows: favouriteList?.map((item, key) => ({
                name: <div className="d-flex" onClick={() => handleOnRowClick(item)}>
                        {item.name}
                        {user.customerId && !item.customer?.name ? 
                        <div className="product-ribbon badge bg-soft-warning bg-warning mx-2 px-2 d-flex align-items-center justify-content-center">
                            {props.t("Global")}
                        </div> : ""}
                      </div>,
                customer: <div onClick={() => handleOnRowClick(item)}>{item.customer ? item.customer.name : props.t("All")}</div>,
                createdAt: <div onClick={() => handleOnRowClick(item)}>{new Date(item.createdAt).toLocaleString()}</div>,
                salesPrice: <div onClick={() => handleOnRowClick(item)}>{item.salesPrice}</div>,
                disposableQuantity: <div className={item.disposableQuantity > 0 ? "text-success" : "text-danger"}
                                         onClick={() => handleOnRowClick(item)}>{item.stockable === false ? "" : item.disposableQuantity ? item?.disposableQuantity : "0"}</div>,
                actions: (
                    <>
                        <i
                            className={`uil uil-pen ${item.type !== "DEFAULT" && (!user?.customerId || item.customer?.name) ? 'text-info' : 'text-muted'} font-size-18`}
                            onClick={() => {
                                if(item.type !== "DEFAULT" && (!user?.customerId || item.customer?.name)){
                                    handleEditFavourite(item)
                                }
                            }}
                        ></i>
                        <i
                            className={`uil uil-trash-alt ${item.type !== "DEFAULT" && (!user?.customerId || item.customer?.name) ? 'text-danger' : 'text-muted'} px-3 font-size-18`}
                            onClick={() => {
                                if(item.type !== "DEFAULT" && (!user?.customerId || item.customer?.name)){
                                    setDeleteFavouriteId(item.id);
                                }
                            }}
                        ></i>
                    </>
                ),
            })),
        };
        return data;
    };

    const handleOnRowClick = (item) => history(`${user?.customerId ? '/customer' : ''}/productLists/${item.id}/articles`)

    const handleEditFavourite = (item) => {
        setEditFavourite(item);
        setName(item.name);
        setCustomerOption(item.customer);
        tog_standard();
    };

    const handleAddFavourite = () => {
        setEditFavourite(null);
        setName(null);
        setCustomerOption(null);
        tog_standard();
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={props.t("Product Lists")}
                                        buttons={[{
                                            text: props.t("Add Product List"),
                                            onClick: handleAddFavourite,
                                        }]}
                                    />
                                </CardHeader>
                                <Loader loading={loading} title="Product Lists"/>
                                {!loading &&
                                    <CardBody>
                                        {errorMessage && (
                                            <Row>
                                                <Alert color="danger">{errorMessage}</Alert>
                                            </Row>
                                        )}
                                        <MDBDataTable
                                            key={size(favouriteList)}
                                            responsive
                                            searching={true}
                                            barReverse={true}
                                            hover
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            data={getDatatableData()}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            onSearch={(text) => console.log(text)}
                                            sortRows={[]}
                                            onSort={({column, direction}) => {
                                                if(sortState && sortState.column == column){
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {editFavourite ? props.t("Edit Product List") : props.t("Add Product List")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                        >
                            {props.t("Label")}
                        </label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                placeholder={props.t("Enter product list label")}
                                value={name}
                            />
                        </div>
                    </Row>
                    {!user?.customerId && <Row className="mb-3">
                        <label
                        htmlFor="example-text-input"
                        className="col-md-3 col-form-label"
                        >
                        {props.t("Customer")}
                        </label>
                        <div className="col-md-9">
                        <SearchableDropdown
                            keyToDisplay={["name"]}
                            selectedValue={customerOption} 
                            options={customerOptions} 
                            placeholder={"All"}
                            handleOnOptionClick={(co) => setCustomerOption(co)}
                            disabled={user?.customerId} />
                        </div>
                    </Row>}
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            if(!editFavourite){
                                setName(null);
                                setCustomerOption(null);
                            }
                            tog_standard();
                        }}
                        className="col-md-2 btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("Close")}
                    </button>
                    <Button
                        color="primary"
                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                        onClick={() => handleSaveFavourite(false)}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                        disabled={!name}
                    >
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>
            <DeleteModal
                deleteCallback={() => handleDeleteFavourite()}
                element={"Product List"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteFavouriteModal}
                toggleModal={() => toggleDeleteFavourite()}
                warningMsg={!isEmpty(articleFavourites) ? props.t("The Product List is referenced inside Articles.") : null}
            />
        </React.Fragment>
    );
};

FavouriteList.propTypes = {
    favourites: PropTypes.array,
    onGetFavourites: PropTypes.func,
    loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        favourites: state.ecommerce.favourites,
        getFavouritesError: state.ecommerce.getFavouritesError,
        loading: state.ecommerce.getFavouritesLoading,
        customerOptions: state.Customer.customers,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetFavourites: () => dispatch(getFavourites()),
    onGetFavouritesReset: () => dispatch(getFavouritesReset()),
    onGetCustomerOptions: () => dispatch(GetCustomers()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(FavouriteList)));

import React, {useEffect, useState} from "react";
import {Button, Col, Modal, Row,} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {get, post} from "../../helpers/api_helper";
import DatePicker from "react-date-picker";
import {parseDateToString} from "../../util/helperFunctions";
import {
    GetCustomers,
    GetCustomerUsers,
    GetCustomerUsersReset,
    getProducts,
    getProductsReset,
    getProjectList,
    getProjectListReset,
    getTaskCategories,
    getTaskList,
    getTaskPriorities
} from "../../store/actions";
import SearchableDropdown from "../../components/Common/SearchableDropdown";
import DropdownSelect from "../../components/Common/DropdownSelect";
import {find} from "lodash";
import {getTenantAdmins, getTenantAdminsReset, getTenantCustomers, getTenants} from "../../store/tenant/actions";
import cogoToast from 'cogo-toast';
import {toastOptions} from "../../util/dataUtil";
import withRouter from "../../components/Common/withRouter";

const TaskModal = (props) => {
    const {
        modal_standard,
        setmodal_standard,
        tog_standard,
        customFilter,
        onGetTasks,

        tenantOptions,
        tenantCustomerOptions,
        tenantCustomersLoading,
        customerOptions,
        productOptions,
        projectOptions,
        customerUserOptions, 
        customerUsersLoading, 
        tenantUserOptions,
        taskCategories, 
        taskPriorities,
        tenantUsersLoading,
        onGetTenantOptions,
        onGetCustomerOptions,
        onGetProductOptions,
        onGetProductsReset,
        onGetProjectOptions,
        onGetProjectsReset,
        onGetTenantUsers, 
        onGetTenantUsersReset,
        onGetCustomerUsers,
        onGetCustomerUsersReset,
        onGetTenantCustomers,
        onGetTaskCategories,
        onGetTaskPriorities,
        avoidRedirect,
        cid,
        projectId
    } = props;
    
    const [brandSettings, setBrandSettings] = useState(null);

    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState(null);
    const [category, setCategory] = useState('SUPPORT');
    const [status, setStatus] = useState('TODO');
    const [dueDate, setDueDate] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [tenantId, setTenantId] = useState(null);
    const [reporters, setReporters] = useState([]);
    const [assigned, setAssigned] = useState(null);
    const [article, setArticle] = useState(null);
    const [project, setProject] = useState(null);
    const [assignedOptions, setAssignedOptions] = useState([]);
    const [reporterOptions, setReporterOptions] = useState([]);
    const [taskCustomerOptions, setTaskCustomerOptions] = useState([]);
    const [taskType, setTaskType] = useState(null);
    const [hiddenToCustomer, setHiddenToCustomer] = useState(false);

    const [higherLevelAdminUser, setHigherLevelAdminUser] = useState(null);

    const [defaultReporters, setDefaultReporters] = useState([]);
    const [defaultAssignee, setDefaultAssignee] = useState(null);
    const [defaultCategory, setDefaultCategory] = useState(null);
    const [defaultPriority, setDefaultPriority] = useState(null);
    const [superAdmin, setSuperAdmin] = useState(null);
    const [showHiddenToCustomerCheck, setShpwHiddenToCustomerCheck] = useState(false);

    const user = JSON.parse(localStorage.getItem("authUser"));

    const SUPER_ADMIN_VIEW = 'SUPER_ADMIN_VIEW';
    const TENANT_VIEW = 'TENANT_VIEW';
    const CUSTOMER_VIEW = 'CUSTOMER_VIEW';

    const INTERNAL = 'INTERNAL';
    const ESCALATED = 'ESCALATED';
    const CUSTOMER = 'CUSTOMER';
    const TENANT = 'TENANT';
    const [view, setView] = useState(null);

    let STATUS_LIST = [{value : "TODO", label : props.t("TO DO")},
        {value : "IN_PROGRESS", label: props.t("IN PROGRESS")},
        {value: "REVIEW", label: props.t("REVIEW")},
        {value: "DONE", label: props.t("DONE")}]

    const history = useNavigate();
    
    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        onGetTaskCategories();
        onGetTaskPriorities();
        initializeView();
    }, [])

    useEffect( () => {
        if(modal_standard){
            (async () => {
                if(view === 'CUSTOMER_VIEW'){
                    let tenantAdminUser = await getHigherLevelAdminUsersByRole(user?.tenantId, 'ROLE_TENANT_ADMIN');
                    if (tenantAdminUser) {
                        tenantAdminUser['name'] = props.t('Tenant');
                        setHigherLevelAdminUser(tenantAdminUser);
                    }
                    setCustomerId(user?.customerId);
                    setTaskType(INTERNAL);
                    onGetProjectOptions(null, user?.customerId);
                    onGetProductOptions(user?.tenantId, user?.customerId);
                } else if(view === 'TENANT_VIEW'){
                    let superAdminUser = await getHigherLevelAdminUsersByRole(user?.tenantId, 'ROLE_SUPER_ADMIN');
                    if (superAdminUser) {
                        superAdminUser['name'] = props.t('Super Admin');
                        setHigherLevelAdminUser(superAdminUser);
                    }
                    setTenantId(user?.tenantId);
                    if(cid){
                        setCustomerId(cid);
                        setTaskType(CUSTOMER);
                    } else {
                        setTaskType(INTERNAL);
                    }
                    onGetCustomerOptions();
                    onGetProjectOptions(user?.tenantId, null);
                    onGetProductOptions(user?.tenantId, null);
                } else if(view === 'SUPER_ADMIN_VIEW'){
                    let superAdminUser = await getHigherLevelAdminUsersByRole(null, 'ROLE_SUPER_ADMIN');
                    if(superAdminUser){
                        setSuperAdmin(superAdminUser);
                    }
                    setTaskType(INTERNAL);
                    onGetTenantOptions();
                }
            })();
        }
    }, [view, modal_standard]);

    useEffect(() => {
        if(tenantId){
            onGetTenantUsers(tenantId);
            if(view === SUPER_ADMIN_VIEW){
                onGetTenantCustomers(tenantId);
                if(!customerId){
                    onGetProjectOptions(tenantId, null);
                    onGetProductOptions(tenantId, null);
                }
            }
        }
    }, [tenantId]);

    useEffect(() => {
        if(customerId){
            onGetCustomerUsers(customerId);
            onGetProjectOptions(tenantId, customerId);
            onGetProductOptions(tenantId, customerId);
        } else if (tenantId){
            onGetProjectOptions(tenantId, null);
            onGetProductOptions(tenantId, null);
        }
    }, [customerId]);

    useEffect(() => {
        if(customerUserOptions && tenantUserOptions){
            if(view === CUSTOMER_VIEW) {
                if(user?.uid != higherLevelAdminUser?.id){
                    let loggedInUser = find([...customerUserOptions], {id: user?.uid});
                    setReporters(loggedInUser ? [loggedInUser] : []);
                    setDefaultReporters(loggedInUser ? [loggedInUser] : []);
                } else {
                    setDefaultReporters([]);
                    setReporters([]);
                }
                setReporterOptions(customerUserOptions);
                
                const defAssignee = taskType === ESCALATED && higherLevelAdminUser ? higherLevelAdminUser : null;
                setDefaultAssignee(defAssignee);
                setAssigned(defAssignee);
                const aos = taskType === ESCALATED ? (higherLevelAdminUser ? [higherLevelAdminUser] : []) : [...customerUserOptions]
                setAssignedOptions([].concat.apply([], aos));
            } else if(view === TENANT_VIEW) {
                setDefaultReporters([]);
                const ros = taskType === CUSTOMER ? [...tenantUserOptions, ...customerUserOptions] : [...tenantUserOptions];
                if(user?.uid != higherLevelAdminUser?.id){
                    let loggedInUser = find(ros, {id: user?.uid});
                    setReporters(loggedInUser ? [loggedInUser] : []);
                    setDefaultReporters(loggedInUser ? [loggedInUser] : []);
                } else {
                    setDefaultReporters([]);
                    setReporters([]);
                }
                setReporterOptions([].concat.apply([], ros));
                
                const defAssignee = taskType === ESCALATED && higherLevelAdminUser ? higherLevelAdminUser : null;
                setDefaultAssignee(defAssignee);
                setAssigned(defAssignee);
                const aos = taskType === ESCALATED ? (higherLevelAdminUser ? [higherLevelAdminUser] : []) : (taskType === CUSTOMER ? [...tenantUserOptions, ...customerUserOptions] : [...tenantUserOptions])
                setAssignedOptions([].concat.apply([], aos));
            } else if(view === SUPER_ADMIN_VIEW) {
                const defReporters = taskType === INTERNAL && superAdmin ? [superAdmin] : [];
                setDefaultReporters(defReporters);
                setReporters(defReporters);
                
                const superAdminInArr = superAdmin ? [superAdmin] : [];
                const ros = taskType === INTERNAL ? (superAdmin ? [superAdmin] : []) : (customerId ? [...superAdminInArr, ...tenantUserOptions, ...customerUserOptions] : [...superAdminInArr, ...tenantUserOptions])
                setReporterOptions([].concat.apply([], ros));

                const defAssignee = taskType === INTERNAL && superAdmin ? superAdmin : null;
                setDefaultAssignee(defAssignee);
                setAssigned(defAssignee);
                const aos = taskType === INTERNAL ? (superAdmin ? [superAdmin] : []) : [...tenantUserOptions]
                setAssignedOptions([].concat.apply([], aos));
            }
        }
    }, [customerUserOptions, tenantUserOptions, taskType]);

    useEffect(() => {
        if(view === CUSTOMER_VIEW) {
            const defAssignee = taskType === ESCALATED && higherLevelAdminUser ? higherLevelAdminUser : null;
            setAssigned(defAssignee);
            setReporters(defaultReporters);
        }
        else if(view === TENANT_VIEW) {
            const defAssignee = taskType === ESCALATED && higherLevelAdminUser ? higherLevelAdminUser : null;
            setAssigned(defAssignee);
            if(taskType != CUSTOMER){
                setCustomerId(null);
            }
            setReporters(defaultReporters);
        }
        else if(view === SUPER_ADMIN_VIEW) {
            const defAssignee = taskType === INTERNAL && superAdmin ? superAdmin : null;
            const defReporters = taskType === INTERNAL && superAdmin ? [superAdmin] : [];
            setAssigned(defAssignee);
            setReporters(defReporters);
        }
    }, [taskType]);

    useEffect(() => {
        setTaskCustomerOptions(view === SUPER_ADMIN_VIEW ? tenantCustomerOptions : customerOptions)
    }, [customerOptions, tenantCustomerOptions])

    useEffect(() => {
        if(taskCategories){
            const supportCategory = find(taskCategories, {name: "SUPPORT"})
            if(supportCategory){
                setDefaultCategory(supportCategory.id)
                setCategory(supportCategory.id)
            }
        }
    }, [taskCategories])

    useEffect(() => {
        if(taskPriorities){
            const taskPriority = find(taskPriorities?.filter(type => type.enabled), {name: "Medium"})
            if(taskPriority){
                setDefaultPriority(taskPriority.id)
                setPriority(taskPriority.id)
            }
        }
    }, [taskPriorities])

    useEffect(() => {
        if(taskPriorities && priority){
            const taskPriorityDetails = find(taskPriorities, {id: parseInt(priority)})
            if(taskPriorityDetails){
                if(taskPriorityDetails.dueDays){
                    let dueDateUpdate = new Date();
                    dueDateUpdate.setDate(dueDateUpdate.getDate() + taskPriorityDetails.dueDays);
                    setDueDate(dueDateUpdate);
                } else {
                    setDueDate(null);
                }
            }
        }
    }, [priority, taskPriorities]);

    useEffect(() => {
        if(projectId && projectOptions){
            setProject(find(projectOptions, { projectNumber: projectId}));
        }
    }, [projectId, projectOptions])

    useEffect(() => {
        if((taskType == CUSTOMER || (taskType == TENANT && customerId)) && 
            (assigned && 
                (assigned.customer || assigned.role == "ROLE_CUSTOMER_USER" || assigned.role == "ROLE_CUSTOMER_ADMIN") && 
                reporters.filter(r => r.customer || r.role == "ROLE_CUSTOMER_USER" || r.role == "ROLE_CUSTOMER_ADMIN").length == reporters.length)){
            setShpwHiddenToCustomerCheck(true);
        } else {
            setHiddenToCustomer(false);
            setShpwHiddenToCustomerCheck(false);
        }
    }, [taskType, customerId, assigned, reporters])

    useEffect(() => {
        if(category && taskCategories){
            setHiddenToCustomer(find(taskCategories, { id: parseInt(category) })?.hiddenToCustomer);
        }
    }, [category])

    function initializeView(){
        if(!user?.customerId && !user?.tenantId){
            setView(SUPER_ADMIN_VIEW);
        } else if(user?.customerId){
            setView(CUSTOMER_VIEW);
        } else if(user?.tenantId){
            setView(TENANT_VIEW);
        }
    }

    const getHigherLevelAdminUsersByRole = async (tenantId, roleName) => {
        try {
            const response = await get(`/api/user/adminUserByRole?roleName=${roleName}${tenantId ? '&tenantId=' + tenantId : ''}`);
            if(response.status === 200) {
                return response.data;
            }
        } catch (err) {
        }
        return null;
    };

    const handleSaveTask = async () => {
        let createError = false;
        let response = null;
        try {
            response = await post(`/api/task`, {
                taskDetails: {
                    subject,
                    description,
                    priority: priority ? find(taskPriorities, {id: parseInt(priority)}) : null,
                    category: category ? find(taskCategories, {id: parseInt(category)}) : null,
                    status: status ? status : 'TODO',
                    dueDate: dueDate ? parseDateToString(dueDate): null,
                    customer: customerId ? {id: customerId} : null,
                    reporters: reporters.map(r => ({reporter: r})),
                    assigned: (view === SUPER_ADMIN_VIEW && !assigned) ? {id: user?.uid} : (assigned ? {id: assigned?.id} : null),
                    hiddenToCustomer,
                },
                articleId: article ? article?.id : null,
                projectId: project ? project?.id : null,
                tenantId: tenantId ? tenantId : null,
            });
            createError = response.status != 200;
        } catch (err) {
            createError = true;
        }
        if(!avoidRedirect){
            history(`${user?.customerId ? '/customer' : ''}/tasks`);
        } else {
            createError ? cogoToast.error(props.t("Error occured while creating task!"), toastOptions) : cogoToast.success(props.t("Task created successfully!"), toastOptions);
        }
        if (!createError) {
            clearModalData();
            onGetTasks(customFilter);
            tog_standard(response?.data);
        }
    };

    const clearModalData = () => {
        setSubject("");
        setDescription("");
        setPriority(defaultPriority);
        setCategory(defaultCategory);
        setStatus('TODO');
        setDueDate(null);
        setCustomerId(null);
        setReporters(defaultReporters);
        setAssigned(defaultAssignee);
        setArticle(null);
        setHiddenToCustomer(false);
        if(!projectId){
            setProject(null);
        }
        setTenantId(null);
        setTaskType(INTERNAL);
        onGetProductsReset();
        onGetProjectsReset();
        onGetTenantUsersReset();
        onGetCustomerUsersReset();
        // setView(null);
    }

    const getTaskTypes = () => {
        let types = [{value: INTERNAL, label: props.t(INTERNAL)}];
        if(view !== SUPER_ADMIN_VIEW){
            types.push({value: ESCALATED, label: props.t(ESCALATED)})
        }
        if(view === TENANT_VIEW){
            types.push({value: CUSTOMER, label: props.t(CUSTOMER)})
        }
        if(view === SUPER_ADMIN_VIEW){
            types.push({value: TENANT, label: props.t(TENANT)})
        }
        return types;
    }

  return (
    <Modal
        isOpen={modal_standard}
        backdrop="static"
        toggle={() => {
            tog_standard();
        }}
        className="add-task-modal"
    >
        <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
                {props.t("New Task")}
            </h5>
            <button
                type="button"
                onClick={() => {
                    setmodal_standard(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body m-2">
        {/* <form> */}
            <Row>
                <Col xl="7">
                    <div className="p-3">
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-3 col-form-label"
                            >
                                {props.t("Subject")}
                            </label>
                            <div className="col-md-9">
                                <input
                                    className="form-control"
                                    type="text"
                                    onChange={(e) => setSubject(e.target.value)}
                                    placeholder={props.t("Enter Subject")}
                                    value={subject}
                                    required
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-3 col-form-label"
                            >
                                {props.t("Description")}
                            </label>
                            <div className="col-md-9">
                                <textarea
                                    className="form-control task-textarea"
                                    type="text"
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder={props.t("Enter Description")}
                                    value={description}
                                    rows="7"
                                />
                            </div>
                        </Row>
                    </div>
                </Col>
                <Col xl="5">
                    <div className="border p-3 rounded">
                        <Row className="mb-3">
                            <label className="col-md-3 col-form-label">
                                {props.t("Type")}
                            </label>
                            <div className="col-md-9">
                                <DropdownSelect
                                    defaultValue={{value : taskType, label: props.t(taskType)}}
                                    values={taskType ? {value : taskType, label: props.t(taskType)} : null}
                                    onChange={(vals) => setTaskType(vals?.value)}
                                    disabled={cid}
                                    placeholder={props.t("Select Type")}
                                    options={getTaskTypes()}
                                />
                            </div>
                        </Row>
                        {(view === SUPER_ADMIN_VIEW && taskType === TENANT) && 
                        <Row className="mb-3">
                            <label className="col-md-3 col-form-label">
                                {props.t("Tenant")}
                            </label>
                            <div className="col-md-9">
                                <SearchableDropdown
                                    keyToDisplay={["name"]}
                                    selectedValue={find(tenantOptions, { id: tenantId})} 
                                    options={tenantOptions} 
                                    placeholder={props.t("Select Tenant")}
                                    handleOnOptionClick={(to) => setTenantId(to?.id)}
                                />
                            </div>
                        </Row>}
                        {((view === SUPER_ADMIN_VIEW && taskType === TENANT) || (view === TENANT_VIEW && taskType === CUSTOMER)) && 
                        <Row className="mb-3">
                            <label className="col-md-3 col-form-label">
                                {props.t("Customer")}
                            </label>
                            <div className="col-md-9">
                                <SearchableDropdown
                                    keyToDisplay={["name"]}
                                    favorite={["favorite"]}
                                    selectedValue={find(taskCustomerOptions, { id: parseInt(customerId)})}
                                    options={taskCustomerOptions} 
                                    placeholder={tenantCustomersLoading ? props.t("Loading...") : props.t("Select Customer")}
                                    handleOnOptionClick={(co) => setCustomerId(co?.id)}
                                    disabled={cid}
                                />
                            </div>
                        </Row>}
                        <Row className="mb-3">
                            <label className="col-md-3 col-form-label">
                                {props.t("Assignee")}
                            </label>
                            <div className="col-md-9">
                                <SearchableDropdown
                                    keyToDisplay={["name"]}
                                    selectedValue={assigned} 
                                    options={assignedOptions} 
                                    placeholder={customerUsersLoading || tenantUsersLoading ? props.t("Loading" + "...") : props.t("Select Assignee")}
                                    handleOnOptionClick={(o) => setAssigned(o)}
                                    disabled={customerUsersLoading || tenantUsersLoading}
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-3 col-form-label">
                                {props.t("Reporters")}
                            </label>
                            <div className="col-md-9">
                                <DropdownSelect
                                    options={reporterOptions.map(r => ({...r, label: r.name, value: r.name}))}
                                    values={(reporters.filter(o => find(reporters, {id: o.id}))).map(r => ({...r, label: r.name, value: r.name})) }
                                    searchBy="name"
                                    valueField="id"
                                    labelField="name"
                                    multi={true}
                                    color={brandSettings?.primaryBtnColor}
                                    onChange={(vals) => setReporters(vals ? [vals] : [])}
                                    clearable={false}
                                    placeholder={props.t("Select Reporter")}
                                />
                            </div>
                        </Row>
                        {showHiddenToCustomerCheck && 
                        <Row className="mb-3">
                            <label className="col-md-3 col-form-label">
                                {props.t("")}
                            </label>
                            <div className="col-md-9">
                                <input
                                    className="form-check-input me-2"
                                    type="checkbox"
                                    checked={hiddenToCustomer}
                                    onChange={(e) => setHiddenToCustomer(e.target.checked)}
                                />
                                <label className="mb-0">
                                    {props.t("Hidden to customer")}
                                </label>
                            </div>
                        </Row>}
                        <Row className="mb-3">
                            <label className="col-md-3 col-form-label">
                                {props.t("Status")}
                            </label>
                            <div className="col-md-9">
                                <DropdownSelect
                                    defaultValue={find(STATUS_LIST, {value : status})}
                                    values={status ? find(STATUS_LIST, {value : status}) : null}
                                    onChange={(vals) => setStatus(vals?.value)}
                                    /*disabled={cid}*/
                                    placeholder={props.t("Select Type")}
                                    options={STATUS_LIST}
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-3 col-form-label">
                                {props.t("Category")}
                            </label>
                            <div className="col-md-9">
                                <DropdownSelect
                                    defaultValue={{value : category, label : find(taskCategories, {id : category})?.name}}
                                    values={category ? {value : category, label : find(taskCategories, {id : category})?.name} : null}
                                    onChange={(e) => {
                                        setCategory(e?.value);
                                    }}
                                    placeholder={props.t("Select Category")}
                                    options={taskCategories?.filter(type => type.enabled).map(o => ({value: o.id, label: o.name}))}
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-3 col-form-label">
                                {props.t("Priority")}
                            </label>
                            <div className="col-md-9">
                                <DropdownSelect
                                    defaultValue={{value : priority, label : find(taskPriorities, {id : priority})?.name}}
                                    values={priority ? {value : priority, label : find(taskPriorities, {id : priority})?.name} : null}
                                    onChange={(e) => {
                                        setPriority(e?.value);
                                    }}
                                    placeholder={props.t("Select Priority")}
                                    options={taskPriorities?.filter(type => type.enabled).map(o => ({value: o.id, label: o.name}))}
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-3 col-form-label">
                                {props.t("Due Date")}
                            </label>
                            <div className="col-md-9">
                                <DatePicker
                                    onChange={(date) => {
                                        setDueDate(date)
                                    }} 
                                    value={dueDate ? dueDate : null}
                                    name={"Due Date"}
                                    format={"yyyy-MM-dd"}
                                    dayPlaceholder={"dd"}
                                    monthPlaceholder={"mm"}
                                    yearPlaceholder={"yyyy"}
                                    minDate={new Date()} />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-3 col-form-label">
                                {props.t("Project")}
                            </label>
                            <div className="col-md-9">
                                <SearchableDropdown
                                    keyToDisplay={["description"]}
                                    selectedValue={project} 
                                    options={projectOptions} 
                                    placeholder={props.t("Select Project")}
                                    handleOnOptionClick={(p) => {
                                        setArticle(null);
                                        setProject(p);
                                    }}
                                    disabled={projectId}
                                />
                            </div>
                        </Row>
                        <Row>
                            <label className="col-md-3 col-form-label">
                                {props.t("Article")}
                            </label>
                            <div className="col-md-9">
                                <SearchableDropdown
                                    keyToDisplay={["articleNumber","description"]}
                                    selectedValue={article} 
                                    options={productOptions} 
                                    placeholder={props.t("Select Article")}
                                    handleOnOptionClick={(a) => {
                                        setProject(null);
                                        setArticle(a);
                                    }}
                                    /*disabled={projectId}*/
                                />
                            </div>
                        </Row>
                    </div>
                </Col>
            </Row>
            {/* </form> */}
        </div>
        <div className="modal-footer justify-content-center">
            <button
                type="button"
                onClick={() => {
                    clearModalData();
                    tog_standard();
                }}
                className="col-md-2 btn btn-secondary waves-effect"
                data-dismiss="modal"
            >
                {props.t("Close")}
            </button>
            <Button
                color="primary"
                style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                onClick={() => handleSaveTask(false)}
                disabled={!subject}
                className="col-md-2 btn btn-primary waves-effect waves-light"
            >
                {props.t("Save")}
            </Button>
        </div>
    </Modal>
  )
}

const mapStateToProps = (state) => {
    return {
        customerOptions: state.Customer.customers,
        tenantOptions: state.tenant.tenantList,
        productOptions: state.ecommerce.products.data,
        projectOptions: state.Project.projects,
        tenantUserOptions: state.tenant.tenantAdmins,
        tenantUsersLoading: state.tenant.tenantAdminUsersLoading,
        customerUserOptions: state.Customer.customerUsers,
        customerUsersLoading: state.Customer.customerUsersLoading,
        tenantCustomersLoading: state.tenant.getTenantCustomersLoading,
        tenantCustomerOptions: state.tenant.customerList,
        taskCategories: state.task.taskCategories,
        taskPriorities: state.task.taskPriorities,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetTasks: (filter) => dispatch(getTaskList(filter)),
    onGetTenantOptions: () => dispatch(getTenants()),
    onGetCustomerOptions: () => dispatch(GetCustomers()),
    onGetProductOptions: (tenantId, customerId) => dispatch(getProducts(-1, -1, customerId != null, null, null, null, tenantId)),
    onGetProductsReset: () => dispatch(getProductsReset()),
    onGetProjectOptions: (tenantId, customerId) => dispatch(getProjectList(customerId, null, tenantId)),
    onGetProjectsReset: () => dispatch(getProjectListReset()),
    onGetCustomerUsers: (customerId) => dispatch(GetCustomerUsers(customerId, "active")),
    onGetCustomerUsersReset: () => dispatch(GetCustomerUsersReset()),
    onGetTenantUsers: (tenantId) => dispatch(getTenantAdmins(tenantId, "active")),
    onGetTenantUsersReset: () => dispatch(getTenantAdminsReset()),
    onGetTenantCustomers: (tenantId) => dispatch(getTenantCustomers(tenantId, "active")),
    onGetTaskCategories: () => dispatch(getTaskCategories()),
    onGetTaskPriorities: () => dispatch(getTaskPriorities())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(TaskModal)));

import React from "react";
import {Navigate} from "react-router-dom";
import {hasModuleEnabled} from "../../util/helperFunctions";
import NotFoundPage from "../../components/Error/NotFoundPage";

const Authmiddleware = (props) => {
    let {permission} = props;
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    if (!authUser) {
        return (
            <Navigate to={{pathname: "/login"}}/>
        );
    }
    if (!permission || (permission && hasModuleEnabled(authUser?.tenantFeatures, permission))) {
        return (
            <React.Fragment>
                {props.children}
            </React.Fragment>);
    } else {
        return <React.Fragment>
            <NotFoundPage/>
        </React.Fragment>
    }

};

export default Authmiddleware;

import {GET_LANGUAGE_LABELS, GET_LANGUAGE_LABELS_ERROR, GET_LANGUAGE_LABELS_SUCCESS, GET_LANGUAGE_LIST, GET_LANGUAGE_LIST_ERROR, GET_LANGUAGE_LIST_SUCCESS, GET_PHRASES, GET_PHRASES_ERROR, GET_PHRASES_SUCCESS} from "./actionTypes";


const INIT_STATE = {
    languageList: [],
    languageListLoading: false,
    languageListError: null,
    labelList: [],
    labelListLoading: false,
    labelListError: null,
    phraseList: [],
    phraseListLoading: false,
    phraseListError: null,
}

const Language = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LANGUAGE_LIST:
            return {
                ...state,
                languageListLoading: true
            }
        case GET_LANGUAGE_LIST_SUCCESS:
            return {
                ...state,
                languageList: action.payload,
                languageListLoading: false
            }
        case GET_LANGUAGE_LIST_ERROR:
            return {
                ...state,
                languageListLoading: false,
                languageListError: action.payload
            }
        case GET_LANGUAGE_LABELS:
            return {
                ...state,
                labelListLoading: true
            }
        case GET_LANGUAGE_LABELS_SUCCESS:
            return {
                ...state,
                labelList: action.payload,
                labelListLoading: false
            }
        case GET_LANGUAGE_LABELS_ERROR:
            return {
                ...state,
                labelListError: action.payload,
                labelListLoading: false
            }
        case GET_PHRASES:
            return {
                ...state,
                phraseList: [],
                phraseListLoading: true
            }
        case GET_PHRASES_SUCCESS:
            return {
                ...state,
                phraseList: action.payload,
                phraseListLoading: false,
                phraseListError: null
            }
        case GET_PHRASES_ERROR:
            return {
                ...state,
                phraseListError: action.payload,
                phraseListLoading: false
            }
        default:
            return state
    }
}

export default Language

import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {getProjectListByLeader, getProjectListByLeaderReset, getProjectListByParticipant, getProjectListByParticipantReset} from "../../store/project/actions";
import Loader from "../../components/Common/Loader";
import {MDBDataTable} from "mdbreact";
import React, {useEffect, useState} from "react";
import {projectIdContext} from "./ProjectList";
import ProjectActions from "./ProjectActions";
import {getUpdatedList} from "../../util/helperFunctions";
import withRouter from "../../components/Common/withRouter";

const ProjectListManagement = (props) => {
    let history = useNavigate();
    let {
        uid,
        projectByParticipant,
        projectByParticipantLoading,
        projectByLeader,
        projectByLeaderLoading,
        getProjectsError,
        onGetProjectsByParticipant,
        onGetProjectsByLeader,
        onGetProjectsByParticipantReset,
        onGetProjectsByLeaderReset
    } = props;

    const [projects, setProjects] = useState([]);
    const [projectsLoading, setProjectsLoading] = useState([]);

    useEffect(() => {
        onGetProjectsByParticipant(uid);
        onGetProjectsByLeader(uid);
    }, [uid])

    useEffect(() => {
        setProjects(getUpdatedList(projectByParticipant, projectByLeader));
    }, [projectByParticipant, projectByLeader])

    useEffect(() => {
        setProjectsLoading(projectByLeaderLoading || projectByParticipantLoading);
    }, [projectByLeaderLoading, projectByParticipantLoading])

    const getDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Description"),
                    field: "description",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Start Date"),
                    field: "startDate",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: props.t("Status"),
                    field: "status",
                    sort: "asc",
                },
            ],
            rows: projects?.map((project, key) => ({
                description: project.description,
                startDate: project.startDate,
                status: project.status,
                actions: (
                    <projectIdContext.Provider value={project.id}>
                        <ProjectActions projectId={project.id}/>
                    </projectIdContext.Provider>
                ),
                clickEvent: function () {
                    history(`/projects/${project.id}`)
                },
            })),
        };
        return data;
    }

    return (
        <React.Fragment>
            <Loader loading={projectsLoading} title="Projects" />
            {/*{getProjectsError &&
                <Alert className={"mt-4"} color="danger" role="alert">
                    {getProjectsError}
                </Alert>
            }*/}
            {!projectsLoading && (
                <MDBDataTable
                    responsive
                    hover
                    barReverse={true}
                    borderless
                    paginationLabel={[
                        props.t("Previous"),
                        props.t("Next"),
                    ]}
                    entriesLabel={props.t("Show entries")}
                    infoLabel={[
                        props.t("Showing"),
                        props.t("to"),
                        props.t("of"),
                        props.t("entries"),
                    ]}
                    searchLabel={props.t("Search") + "..."}
                    noBottomColumns={true}
                    data={getDatatableData()}
                    onSearch={(text) => console.log(text)}
                    searching={true}
                    entries={50}
                    entriesOptions={[50, 100, 500]}
                    noRecordsFoundLabel={props.t("No matching records found")}
                />
            )}
        </React.Fragment>)
}

ProjectListManagement.propTypes = {
    projects: PropTypes.array,
    onGetProjectsByParticipant: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        projectByParticipant: state.Project.projectByParticipant,
        projectByParticipantLoading: state.Project.projectByParticipantLoading,
        projectByLeader: state.Project.projectByLeader,
        projectByLeaderLoading: state.Project.projectByLeaderLoading,
        getProjectsError: state.Project.error,

    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetProjectsByParticipant: (id) => dispatch(getProjectListByParticipant(id)),
    onGetProjectsByParticipantReset: () => dispatch(getProjectListByParticipantReset()),
    onGetProjectsByLeader: (id) => dispatch(getProjectListByLeader(id)),
    onGetProjectsByLeaderReset: () => dispatch(getProjectListByLeaderReset())
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ProjectListManagement)));

import {Card, CardBody, CardHeader, Col, Container, Pagination, PaginationItem, PaginationLink, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import TableToolbar from "../../components/Common/TableToolbar";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Loader from "../../components/Common/Loader";
import {MDBDataTable} from "mdbreact";
import {get} from "../../helpers/api_helper";
import {find} from "lodash";
import {getTenants} from "../../store/tenant/actions";
import withRouter from "../../components/Common/withRouter";


const SocketAuditLog = (props) => {
    const {tenantOptions, onGetTenantOptions} = props;
    const [brandSettings, setBrandSettings] = useState(null)
    const [filter, setFilter] = useState();
    const [loading, setLoding] = useState(false);
    const [logData, setLogData] = useState([]);
    const history = useNavigate();
    const user = JSON.parse(localStorage.getItem("authUser"));
    const level = localStorage.getItem("level");
    const [metaData, setMetaData] = useState({size: 50, page: 1, totalPages: null, totalElements: null});


    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        onGetTenantOptions();
    }, []);


    useEffect(() => {
        //getLogs();
    }, [tenantOptions])


    const getLogs = async (tenantId = null, page) => {
        console.log("Console {}", metaData?.page);
        setLoding(true);
        let response = await get(`/api/socket-log?page=${page - 1}&size=${metaData?.size}${tenantId ? '&tenantId='+ tenantId:''}`);
        setLoding(false);
        if (response.status === 200) {
            setLogData(response?.data?.content);
            setMetaData({...metaData, page: page, totalPages: response?.data?.totalPages, totalElements: response?.data?.totalElements});
        }
    }


    useEffect(() => {
        getLogs(filter?.id, 1);
    }, [filter])

    const setPage = (page) => {
        console.log(page);
        //setMetaData({...metaData, page: page});
        getLogs(filter?.id, page);
    }

    const getPage = (totalPage) => {
        let pages = [];
        let count = metaData?.page == 1 ? metaData?.page + 5 : metaData?.page;
        for (let i = count - 5 > 0 ? count - 5 : 1; i < count + 5 && i <= totalPage; i++) {
            pages.push(i);
        }
        return pages;
    }


    const getDatatableData = () => {

        let cols = [
            {
                label: props.t("messageId"),
                field: "messageId",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("Created"),
                field: "created",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("Entity"),
                field: "entity",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("Entity Id"),
                field: "entityId",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("Type"),
                field: "type",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("Processed Time"),
                field: "processedTime",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("Status"),
                field: "status",
                sort: "asc",
                autoWidth: true,
            }
        ];

        if(level === "SUPERADMIN"){
            cols.push({
                label: props.t("Tenant"),
                field: "tenantid",
                sort: "asc",
                autoWidth: true,
            })
        }
        let data = {
            columns: cols,
            rows: logData?.map((item, key) => ({
                messageId: item?.messageId,
                created: new Date(item?.createdAt).toLocaleString(),
                processedTime: new Date(item?.processedTime).toLocaleString(),
                status: item?.status === "SUCCESS" ? <label className="badge bg-soft-success p-1">{props.t("Success")}</label> : <label className="badge bg-soft-danger p-1">{props.t("Failed")}</label>,
                tenantid: find(tenantOptions,{id: item?.tenantId})?.name,
                entity: item?.entity?.name,
                entityId: item?.entity?.id,
                type: item?.entity?.type
            })),
        };
        return data;
    };


    return (<React.Fragment>
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col className="col-12 article-table">
                        <Card>
                            <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                <TableToolbar
                                    title={props.t("Socket Log")}
                                    filters={level === "SUPERADMIN" ? [
                                        {
                                            selected: filter,
                                            options : [{
                                                isSearchableDropdown: true,
                                                value: filter,
                                                options: tenantOptions,
                                                placeholder: props.t("Select Tenant"),
                                                handleSelect: (option) => {
                                                    setFilter(option);
                                                },
                                                keyToDisplay: "name",
                                            }]
                                        }
                                    ] : []}
                                />
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col className="col-12 article-table" key={logData.length}>
                                        <Loader loading={loading} title="Logs"/>
                                        <MDBDataTable
                                            responsive
                                            searching={true}
                                            barReverse={true}
                                            hover
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            paging={false}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            data={getDatatableData()}
                                            /*entries={50}
                                            entriesOptions={[50, 100, 500]}*/
                                            onSearch={(text) => console.log(text)}
                                            sortRows={[]}
                                            /*onSort={({column, direction}) => {
                                                if (sortState && sortState.column == column) {
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}*/
                                        />
                                        <Row key={"page_" + metaData?.page}>
                                            <div>
                                                <Pagination className="pagination float-sm-end">
                                                    <PaginationItem disabled={metaData?.page === 1}>
                                                        <PaginationLink
                                                            previous
                                                            to="#"
                                                            onClick={() => setPage(metaData?.page - 1)}
                                                        />
                                                    </PaginationItem>
                                                    {getPage(metaData?.totalPages).map(function (i) {
                                                        return (<PaginationItem
                                                            active={i === metaData?.page}
                                                            key={"_pagination_" + i}
                                                        >
                                                            <PaginationLink
                                                                onClick={() => setPage(i)}
                                                                to="#"
                                                            >
                                                                {i}
                                                            </PaginationLink>
                                                        </PaginationItem>)
                                                    })}
                                                    <PaginationItem disabled={metaData?.page === metaData?.totalPages}>
                                                        <PaginationLink
                                                            next
                                                            to="#"
                                                            onClick={() => setPage(metaData?.page + 1)}
                                                        />
                                                    </PaginationItem>
                                                </Pagination>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>

    </React.Fragment>)
}

const mapStateToProps = (state) => {
    return {
        tenantOptions: state.tenant.tenantList,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetTenantOptions: () => dispatch(getTenants()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(SocketAuditLog)));

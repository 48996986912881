import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {useLocation, useNavigate, useParams} from "react-router-dom"
import {Card, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane,} from "reactstrap"
import classnames from 'classnames';


//Import Breadcrumb
// import charts
import {getCustomerRest, GetCustomers, getUserForImpersonation, impersonateUser} from "../../store/actions"
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import CustomerDetails from "./CustomerDetails";
import {withTranslation} from "react-i18next";
import CustomerUserManagement from "./CustomerUserManagement";
import CustomerOrderTable from "./CustomerOrderTable";
import CustomerInvoiceTable from "./CustomerInvoiceTable";
import CustomerOfferTable from "./CustomerOfferTable";
import CustomerAddressManagement from "./CustomerAddressManagement";
import CustomerProjectManagement from "../Projects/CustomerProjectManagement";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader"
import EventManagement from "../Audit/EventManagement"
import TaskManagement from "../Tasks/TaskManagement"
import NotFoundPage from "../../components/Error/NotFoundPage";
import {isEmpty} from "lodash/lang";
import CustomerTimeslips from "./CustomerTimeslips";
import {getCustomer} from "../../helpers/fakebackend_helper";
import {put} from "../../helpers/api_helper";
import TenantDashboard from "../Dashboard/TenantDashboard";
import {hasModuleEnabledForLoggedInUser} from "../../util/helperFunctions";

const CustomerProfile = props => {
    const {
        customerLoading,
        onGetCustomerDetails,
        onGetCustomerOptions,
        onRestCustomer,
        onImpersonateUser,
        onGetUserForImpersonation,
        customerOptions,
        onGetCustomerOption
    } = props

    let params = useParams();
    const [miniCards, setMiniCards] = useState([
        {
            title: "Completed Projects",
            iconClass: "bx-check-circle",
            text: "125",
        },
        {title: "Pending Projects", iconClass: "bx-hourglass", text: "12"},
        {title: "Total Revenue", iconClass: "bx-package", text: "$36,524"},
    ])

    const [activeTab, setActiveTab] = useState('1');
    const [customer, setCustomer] = useState();
    const [customerDetails, setCustomerDetails] = useState();
    const [initComplete, setInitComplete] = useState(false);
    const history = useNavigate();
    const [brandSettings, setBrandSettings] = useState(null)
    const location = useLocation();
    const fortnoxConnectionStatus = localStorage.getItem("fortnox-connection-status") === "true";
    const [favorite, setFavorite] = useState(false);
    const [favoriteLoading, setFavoriteLoading] = useState(false);

    const [hidden, setHidden] = useState(false);
    const [hiddenLoading, setHiddenLoading] = useState(false);

    const user = JSON.parse(localStorage.getItem("authUser"));
    const profiletoggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        onGetCustomerOptions();
        /*return () => {
            onRestCustomer();
            setCustomerDetails({});
        };*/
    }, []);

    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(obj?.brandConfig);

        const tab = JSON.parse(localStorage.getItem("customerProfile"));
        if(tab){
            setActiveTab(tab.toString());
        }
    }, [])

    useEffect( () => {
        (async () => {
            const user = JSON.parse(localStorage.getItem("authUser"))
            if (params && params.id) {
                //onGetCustomerDetails(params.id);
                const response = await getCustomer(params.id);
                setCustomer(response?.data);
                setInitComplete(true);
                onGetUserForImpersonation(user.tenantId, null, [
                    "ROLE_TENANT_ADMIN",
                    "ROLE_TENANT_USER",
                ]);
            }
        })();
    }, [params.id]);

    useEffect(() => {
        if (!isEmpty(customer)) {
            setCustomerDetails(customer);
            setFavorite(customer?.favorite);
            setHidden(customer?.hidden);
        }
    }, [customer]);

    useEffect(() => {
        if(activeTab){
            localStorage.setItem("customerProfile", activeTab);
        }
    }, [activeTab])

    const handleBack = () => {
        history("/customers")
    };

    const handleEdit = () => {
        history(`/customers/${customerDetails?.customerNumber}/profile/edit`)
    }

    const handleImpersonate = () => {
        onImpersonateUser({customerId: customer.id}, history, location)
    }

    function getImpersonateButton() {
        return <>
            <button onClick={() => handleImpersonate()} className="btn btn-primary" style={{marginLeft:"10px", backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}><i
                className="fa fa-sign-in-alt"></i></button></>
    }

    const handleCustomerChange = (customer) => {
        window.location.href =`/customers/${customer?.customerNumber}/profile`;
    }

    const handleCustomerFavorite =  () => {
        (async () => {
            setFavoriteLoading(true)
            let response = await put(`/api/customers/${customer?.customerNumber}/favorite`);
            setFavoriteLoading(false);
            setFavorite(!favorite);
        })();
    }

    const handleCustomerHidden =  () => {
        (async () => {
            setHiddenLoading(true)
            let response = await put(`/api/customers/${customer?.customerNumber}/hidden`);
            setHiddenLoading(false);
            setHidden(!hidden);
        })();
    }

    function favoriteButtonComponent() {
        return <>
            <button onClick={() => handleCustomerFavorite()} disabled={favoriteLoading} className="btn btn-primary"
                    style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor, marginRight: "1px"}}>
                {favorite && <i className="fa fa-star"></i>}
                {!favorite && <i className="far fa-star"></i>}
            </button>

            <button onClick={() => handleCustomerHidden()} disabled={hiddenLoading} className="btn btn-primary"
                    style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}>
                {hidden && <i className="fa fa-eye-slash"></i>}
                {!hidden && <i className="far fa-eye"></i>}
            </button>
        </>

    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        title={customerDetails?.name}
                        breadcrumbs={[
                            {title: props.t("Customers"), link: "/customers"},
                        ]}
                        /*backBtn={handleBack}
                        canEdit={handleEdit}
                        customComponent={getImpersonateButton}*/
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={customer?.name ? customer.name : props.t("Customer Details")}
                                        titleDropdown={{
                                            isSearchableDropdown: true,
                                            value: customer,
                                            options: customerOptions,
                                            placeholder: props.t("Select Customer"),
                                            isHeader: true,
                                            handleSelect: (op) => {
                                                handleCustomerChange(op);
                                            },
                                            keyToDisplay: "name",
                                            favorite: "favorite"
                                        }}
                                        backBtn={handleBack}
                                        canEdit={fortnoxConnectionStatus ? handleEdit : null}
                                        customComponent={favoriteButtonComponent}
                                        rightCustomComponent={getImpersonateButton}
                                        /*buttons={[{
                                            text: props.t("New User"),
                                            onClick: handleOnClick,
                                        }]}*/
                                    />
                                </CardHeader>
                            </Card>
                        </Col>
                        <Loader loading={!initComplete} title="Customer Profile" />
                        {initComplete && !customerDetails && <NotFoundPage/>}
                        {initComplete && !isEmpty(customerDetails) && <>

                        <Col xl="4">
                            <CustomerDetails customer={customerDetails}/>
                        </Col>

                        <Col xl="8">
                            <Card className="mb-0">
                                <Nav tabs className="nav-tabs-custom nav-justified">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '1'})}
                                            style={{height:"100%"}}
                                            onClick={() => {
                                                profiletoggle('1');
                                            }}
                                        >
                                            <i className="uil uil-user-circle font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Users")}</span>
                                        </NavLink>
                                    </NavItem>
                                    {hasModuleEnabledForLoggedInUser("ORDERS") && <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '2'})}
                                            style={{height:"100%"}}
                                            onClick={() => {
                                                profiletoggle('2');
                                            }}
                                        >
                                            <i className="uil uil-shopping-cart-alt font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Orders")}</span>
                                        </NavLink>
                                    </NavItem>}
                                    {hasModuleEnabledForLoggedInUser("INVOICES") && <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '3'})}
                                            style={{height:"100%"}}
                                            onClick={() => {
                                                profiletoggle('3');
                                            }}
                                        >
                                            <i className="uil uil-invoice font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Invoices")}</span>
                                        </NavLink>
                                    </NavItem>}
                                    {hasModuleEnabledForLoggedInUser("OFFERS") && <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '4'})}
                                            style={{height:"100%"}}
                                            onClick={() => {
                                                profiletoggle('4');
                                            }}
                                        >
                                            <i className="uil uil-bolt-alt font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Offers")}</span>
                                        </NavLink>
                                    </NavItem>}
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '5'})}
                                            style={{height:"100%"}}
                                            onClick={() => {
                                                profiletoggle('5');
                                            }}
                                        >
                                            <i className="uil uil-envelope-alt font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Delivery Addresses")}</span>
                                        </NavLink>
                                    </NavItem>
                                    {hasModuleEnabledForLoggedInUser("PROJECTS") &&
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '6'})}
                                            style={{height:"100%"}}
                                            onClick={() => {
                                                profiletoggle('6');
                                            }}
                                        >
                                            <i className="uil uil-apps font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Projects")}</span>
                                        </NavLink>
                                    </NavItem>}
                                    {hasModuleEnabledForLoggedInUser("TASKS") &&
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '8'})}
                                            style={{height:"100%"}}
                                            onClick={() => {
                                                profiletoggle('8');
                                            }}
                                        >
                                            <i className="uil-channel-add font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Tasks")}</span>
                                        </NavLink>
                                    </NavItem>}
                                    {hasModuleEnabledForLoggedInUser("TASKS") &&
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '9'})}
                                            style={{height:"100%"}}
                                            onClick={() => {
                                                profiletoggle('9');
                                            }}
                                        >
                                            <i className="uil uil-clock font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Timeslips")}</span>
                                        </NavLink>
                                    </NavItem>}
                                    {hasModuleEnabledForLoggedInUser("EVENTS") &&<NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '7'})}
                                            style={{height:"100%"}}
                                            onClick={() => {
                                                profiletoggle('7');
                                            }}
                                        >
                                            <i className="uil uil-clipboard-notes font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Events")}</span>
                                        </NavLink>
                                    </NavItem>}
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '10'})}
                                            style={{height:"100%"}}
                                            onClick={() => {
                                                profiletoggle('10');
                                            }}
                                        >
                                            <i className="uil uil-chart font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Statistics")}</span>
                                        </NavLink>
                                    </NavItem>

                                </Nav>
                                <TabContent activeTab={activeTab} className="p-4">
                                    <TabPane tabId="1">
                                        {customerDetails?.id && activeTab == "1" && <CustomerUserManagement customerId={customerDetails?.id} isTenant={true}/> }
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {customerDetails?.id && activeTab == "2" && <CustomerOrderTable customerId={customerDetails?.id} />}
                                    </TabPane>
                                    <TabPane tabId="3">
                                        {customerDetails?.id && activeTab == "3" && <CustomerInvoiceTable customerId={customerDetails?.id}/>}
                                    </TabPane>
                                    <TabPane tabId="4">
                                        {customerDetails?.id && activeTab == "4" && <CustomerOfferTable customerId={customerDetails?.id}/>}
                                    </TabPane>
                                    <TabPane tabId="5">
                                        {customerDetails?.id && activeTab == "5" && <CustomerAddressManagement customerId={customerDetails?.id}/>}
                                    </TabPane>
                                    <TabPane tabId="6">
                                        {customerDetails?.id && activeTab == "6" && <CustomerProjectManagement customerId={customerDetails?.id}/>}
                                    </TabPane>
                                    <TabPane tabId="7">
                                        {customerDetails?.id && activeTab == "7" && <EventManagement customerId={customerDetails?.id}/>}
                                    </TabPane>
                                    <TabPane tabId="8">
                                        {customerDetails?.id && activeTab == "8" && <TaskManagement cid={customerDetails?.id}/>}
                                    </TabPane>
                                    <TabPane tabId="9">
                                        {customerDetails?.id && activeTab == "9" && <CustomerTimeslips filter={{customerId:customerDetails?.id}} showAddTask={true}/>}
                                    </TabPane>
                                    <TabPane tabId="10">
                                        {customerDetails?.customerNumber  && activeTab == "10" && <TenantDashboard customerNumber={customerDetails?.customerNumber}/>}
                                    </TabPane>
                                </TabContent>
                            </Card>
                        </Col></>}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

CustomerProfile.propTypes = {
    customer: PropTypes.object,
    onGetCustomerDetails: PropTypes.func,
    onRestCustomer: PropTypes.func,
}

const mapStateToProps = (state) => ({
    customer: state.Customer.customer,
    customerLoading: state.Customer.customerLoading,
    customerOptions: state.Customer.customers,
})

const mapDispatchToProps = dispatch => ({
    /*onGetCustomerDetails: (id) => dispatch(GetCustomer(id)),*/
    onGetCustomerOptions: () => dispatch(GetCustomers(false)),
    onRestCustomer: () => dispatch(getCustomerRest()),
    onGetUserForImpersonation: (tenantId, customerId, roles) =>
        dispatch(getUserForImpersonation(tenantId, customerId, roles)),
    onImpersonateUser: (obj, history, location) =>
        dispatch(impersonateUser(obj, history, location)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(CustomerProfile));

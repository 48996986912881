import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Row} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next"
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getProjectList, getProjectListReset} from "../../store/actions";
import ProjectForm from "./ProjectForm";
import Loader from "../../components/Common/Loader";
import ButtonGroup from "../../components/Common/ButtonGroup";
import {orderBy} from "lodash";
import withRouter from "../../components/Common/withRouter";

export const customerProjectManagementContext = React.createContext(null)

const CustomerProjectManagement = (props) => {

    const {projects, onGetProjects, loading, customerId, onGetProjectsReset, error} = props
    const [editMode, setEditMode] = useState(false);
    const [selectedCustomerProject, setSelectedCustomerProject] = useState(undefined);
    const [projectList, setProjectList] = useState([])
    const [brandSettings, setBrandSettings] = useState(null)
    const [filter, setFilter] = useState('ALL')
    const user = JSON.parse(localStorage.getItem("authUser"));
    const [sortState, setSortState] = useState(null);
    const numericColumns = ['projectNumber'];
    const history = useNavigate();
    const fortnoxConnectionStatus = localStorage.getItem("fortnox-connection-status") === "true";

    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        //onGetProjects(customerId)
        //setProjectList(projects)
        return () => {
          onGetProjectsReset();
        }
    }, [])

    useEffect(() => {
      if(error){
        setTimeout(() => {onGetProjectsReset()}, 5000);
      }
    }, [error])

    useEffect(() => {
        if(projects){
            setProjectList(projects)
        }
    }, [projects])

    useEffect(() => {
        if(!projectList) return;
        if(sortState?.column){
            const {column, direction} = sortState;
            setProjectList(orderBy(projectList, o => {
                return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
            }, [direction]))
        }
    }, [sortState])

    useEffect(() => {
      if(filter){
        onGetProjects(customerId, filter);
      }
    }, [filter]);

    function handleAddEditProject() {
        onGetProjects(customerId)
        setEditMode(false);
    }

    const getProjectDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Description"),
                    field: "description",
                    sort: "asc",
                    width: 150,
                },
                {
                  label: props.t("Project Number"),
                  field: "projectNumber",
                  sort: "asc",
                  width: 150,
                },
                {
                    label: props.t("Start Date"),
                    field: "startDate",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: props.t("Status"),
                    field: "status",
                    sort: "asc"
                }
            ],
            rows: projectList?.map((project, key) => ({
                description: project.description,
                projectNumber: project.projectNumber,
                startDate: project.startDate ? project.startDate : "",
                status: project.status ? project.status : "",
                clickEvent: function () {
                    handleOpen(project)
                },
            }))
        }
        return data;
    }

    const handleFilter = (option) => {
      setFilter(option);
    }

    const handleOpen = (project) => {
        history(`/projects/${project.projectNumber}`)
    }
    return (
        <React.Fragment>
            {
                !editMode && <React.Fragment>
                    {error &&
                        <Alert className={"mt-4"} color="danger" role="alert">
                            {error}
                        </Alert>
                    }
                    <Row className="mb-3">
                        <Col className="col-12">
                            <div className="d-flex justify-content-end">
                                <ButtonGroup 
                                    filter={{
                                        selected: filter,
                                        options : [
                                            {name: props.t("All"), value:"ALL"},
                                            {name: props.t("Ongoing"), value:"ONGOING"},
                                            {name: props.t("Completed"), value:"COMPLETED"},
                                            {name: props.t("Not Started"), value: "NOTSTARTED"}
                                        ],
                                        onSelect: handleFilter
                                    }}
                                    brandSettings={brandSettings}     
                                />
                                {fortnoxConnectionStatus && <Button
                                    color="primary"
                                    className="btn btn-primary waves-effect waves-light ms-2"
                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                    onClick={() => {
                                        setEditMode(true);
                                        setSelectedCustomerProject(undefined);
                                    }}
                                >
                                    {props.t("New Project")}
                                </Button>}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12 article-table">
                            <Loader loading={loading} title="Projects" />
                            {!loading && projects != null && projects != undefined &&
                            <MDBDataTable
                                hover
                                barReverse={true}
                                responsive
                                borderless
                                paginationLabel={[props.t("Previous"), props.t("Next")]}
                                entriesLabel={props.t("Show entries")}
                                infoLabel={[props.t('Showing'), props.t('to'), props.t('of'), props.t('entries')]}
                                searchLabel={props.t("Search") + "..."}
                                noBottomColumns={true}
                                data={getProjectDatatableData() ? getProjectDatatableData() : {}}
                                entries={50}
                                entriesOptions={[50,100,500]}
                                noRecordsFoundLabel={props.t("No matching records found")}
                                sortRows={[]}
                                onSort={({column, direction}) => {
                                    if(sortState && sortState.column == column){
                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                    } else {
                                        setSortState({column, direction});
                                    }
                                }}
                            />}
                        </Col>
                    </Row>
                </React.Fragment>
            }
            {
                editMode && <React.Fragment>
                    <Row>
                        <Col>
                            <ProjectForm 
                                location={props.location} 
                                projectId={selectedCustomerProject} 
                                clearField={true} customerId={customerId}
                                setEditMode={setEditMode} 
                                callback={handleAddEditProject} 
                                avoidRedirect={true} />
                        </Col>
                    </Row>
                </React.Fragment>
            }
        </React.Fragment>
    );
};

CustomerProjectManagement.propTypes = {
    projects: PropTypes.array,
    onGetProjects: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        projects: state.Project?.projects,
        loading: state.Project.loading,
        error: state.Project.error
    }
}

const mapDispatchToProps = dispatch => ({
    onGetProjects: (customerId, filter) => dispatch(getProjectList(customerId, filter)),
    onGetProjectsReset: () => dispatch(getProjectListReset()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(CustomerProjectManagement)));

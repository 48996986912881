import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import {initReactI18next} from "react-i18next"
import Backend from "i18next-xhr-backend"

/*
import translationGr from "./locales/gr/translation.json"
import translationIT from "./locales/it/translation.json"
import translationRS from "./locales/rs/translation.json"
import translationSP from "./locales/sp/translation.json"
import translationENG from "./locales/eng/translation.json"

// the translations
const resources = {
  gr: {
    translation: translationGr,
  },
  it: {
    translation: translationIT,
  },
  rs: {
    translation: translationRS,
  },
  sp: {
    translation: translationSP,
  },
  eng: {
    translation: translationENG,
  },
}
*/
const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "en")
}


i18n
    .use(detector)
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: {
            order: ['localStorage', 'querystring', 'navigator'],
            lookupQuerystring: 'lng',
            lookupLocalStorage: 'I18N_LANGUAGE'
        },
        fallbackLng: "en",
        debug: false,
        backend: {
            loadPath: process.env.REACT_APP_APIBASEURL + '/locales/{{lng}}/{{ns}}' + '?domain=' + window.location.hostname,
            crossDomain: false,
            withCredentials: false,
        }
    });

export default i18n

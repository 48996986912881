import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {del, post, put} from "../../helpers/api_helper";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Modal, Row} from "reactstrap";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";
import {API_ERR_MSG} from "../../util/constants";
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {checkIfLoading} from "../../store/selector";
import {ecommerceActionTypes} from "../../store/e-commerce/actionTypes";
import {getFields} from "../../store/e-commerce/actions";
import {connect} from "react-redux";
import {isEmpty} from "underscore";
import {filter, find, includes} from "lodash-es";
import {withTranslation} from "react-i18next";
import SearchableDropdown from "../../components/Common/SearchableDropdown";
import DeleteModal from "../../components/Common/DeleteModal";
import {getProjectTemplateDetails} from "../../store/project-template/actions";
import withRouter from "../../components/Common/withRouter";

const ProjectTemplateDetails = (props) => {
    const {
        projectTemplateDetails,
        fields,
        getProjectTemplateDetails,
        loading,
        layoutError,
        onGetLayoutReset,
        getContentTypeError,
        onGetContentTypesReset,
        onGetFields
    } = props;

    let params = useParams();
    const [modal_standard, setmodal_standard] = useState(false);
    const [modal_edit, setmodal_edit] = useState(false);
    const [modal_confirm, setmodal_confirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedField, setSelectedField] = useState();
    const [permission, setPermission] = useState("ROLE_CUSTOMER_USER")
    const [unit, setUnit] = useState("");
    const [contentTypeList, setContentTypeList] = useState([]);
    const [editname, setEditname] = useState("");
    const [editContent, setEditContent] = useState(null);
    const history = useNavigate();
    const [searchResults, setSearchResults] = useState([]);
    const [search, setSearch] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [showDeleteContentModal, setShowDeleteContentModal] = useState(false);
    const [showDeleteLayoutModal, setShowDeleteLayoutModal] = useState(false);
    const [deleteContentId, setDeleteContentId] = useState(null);
    const [articleContents, setArticleContents] = useState([]);
    const [deleteLayoutId, setDeleteLayoutId] = useState(null);
    const [layoutArticles, setLayoutArticles] = useState([]);
    const [brandSettings, setBrandSettings] = useState(null);
    const [confirmation, setConfirmation] = useState(false);

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function tog_confirm() {
        setmodal_confirm(!modal_confirm);
        removeBodyCss();
    }
    function toggleDeleteContent() {
        if(showDeleteContentModal){
            setDeleteContentId(null);
        }
        setShowDeleteContentModal(!showDeleteContentModal);
        removeBodyCss();
    }

    function toggleDeleteLayout() {
        if(showDeleteLayoutModal){
            setDeleteLayoutId(null);
        }
        setShowDeleteLayoutModal(!showDeleteLayoutModal);
        removeBodyCss();
    }

    function tog_edit() {
        setmodal_edit(!modal_edit)
        removeBodyCss()
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
    }, [])


    useEffect(() => {
        if(deleteLayoutId){
            toggleDeleteLayout();
        }
    }, [deleteLayoutId])

    useEffect(() => {
        if(!isEmpty(articleContents)){
            toggleDeleteContent();
        }
    }, [articleContents])

    useEffect(() => {
        if(!isEmpty(layoutArticles)){
            toggleDeleteLayout();
        }
    }, [layoutArticles])

    useEffect(() => {
        if(modal_standard){
            onGetFields();
        }
    }, [modal_standard])

    useEffect(() => {
        if(layoutError){
            setErrorMessage(props.t(API_ERR_MSG))
            setTimeout(() => {setErrorMessage('')}, 5000);
            setTimeout(() => {onGetLayoutReset()}, 5000);
        }
    }, [layoutError])

    useEffect(() => {
        if(getContentTypeError){
            setErrorMessage(props.t(API_ERR_MSG))
            setTimeout(() => {setErrorMessage('')}, 5000);
            setTimeout(() => {onGetContentTypesReset()}, 5000);
        }
    }, [getContentTypeError])

    const handleSearch = () => {
        if (!isEmpty(search)) {
            const results = filter(
                projectTemplateDetails.fieldList,
                (fieldType) =>
                    includes(fieldType.name?.toLowerCase(), search.toLowerCase()) ||
                    includes(fieldType.description?.toLowerCase(), search.toLowerCase()) ||
                    filter(fieldType.categories, (c) =>
                        includes(c.name?.toLowerCase(), search.toLowerCase())
                    ).length > 0
            );

            setSearchResults(results);
        } else {
            setSearchResults(projectTemplateDetails.fieldList);
        }
        setSearchValue(search);
    };

    useEffect(() => {
        if (params && params.id) {
            getProjectTemplateDetails(params.id);
        }
    }, [params]);



    const handleSaveField = async (editPermissionForAllArticles = false) => {
        //console.log(selectedField);
        /*if(editContent?.permission != permission && confirmation === false){
            setConfirmation(true);
            tog_confirm();
            return;
        }*/
        let response = await put(`/api/project-template/${params.id}?fieldId=${selectedField?.id}`, );
        if(confirmation){
            tog_confirm();
        }
        setConfirmation(false);
        setSelectedField(null);
        getProjectTemplateDetails(params.id);
        tog_standard();
    };


    const handleDeleteContent = async () => {
        let response = await del(`/api/project-template/${projectTemplateDetails.id}/fields/${deleteContentId}`);
        setDeleteContentId(null);
        getProjectTemplateDetails(params.id);
    };

    const getDatatableData = () => {
        const rows = projectTemplateDetails?.fieldList?.filter((row) =>
            row?.name?.toLowerCase().includes(search?.toLowerCase())
        );
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Unit"),
                    field: "unit",
                    autoWidth: true,
                },
                {
                    label: props.t("Type"),
                    field: "type",
                    sort: "asc",
                    autoWidth: true,
                },/*,
                {
                    label: props.t("Permission"),
                    field: "permission",
                    sort: "asc",
                    autoWidth: true,
                },*/
                {
                    label: props.t("Created Date"),
                    field: "created",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Actions"),
                    field: "actions",
                    sort: "asc",
                    autoWidth: true,
                },
            ],
            rows: rows?.map((item, key) => ({
                name:
                    <>
                        <i
                            className="uil uil-arrow-circle-up px-3 text-primary font-size-18"
                            onClick={() => handleOrder("UP", item)}
                        ></i>
                        <i
                            className="uil uil-arrow-circle-down px-3 text-primary font-size-18"
                            onClick={() => handleOrder("DOWN", item)}
                        ></i>
                        {item.contentType === "HEADER" ? (
                            <label className="font-bold font-size-16">{item.name}</label>
                        ) : (
                            item.name
                        )}</>,
                unit: item.unit,
                type: getContentType(item.fieldType),
                created: new Date(item.createdAt).toLocaleString(),
                /*permission: item.permission ? item.permission === 'ROLE_CUSTOMER_USER' ? props.t("Customer") : props.t("Tenant") : "",*/
                actions: (
                    <>
                        <i
                            className="uil uil-trash-alt px-3 text-danger font-size-18"
                            onClick={() => {
                                setDeleteContentId(item.id);
                                toggleDeleteContent();
                            }}
                        ></i>
                    </>
                ),
            })),
        };
        return data;
    };

    const handleEdit = async () => {
        setEditname(projectTemplateDetails.name);
        tog_edit();
    };

    const handleEditSave = async () => {
        let response = await post(`/api/project-template`, { id: projectTemplateDetails.id, name: editname });
        setEditname("");
        tog_edit();
        getProjectTemplateDetails(projectTemplateDetails.id);
    };

    const handleDeleteLayout = async () => {
        let response = await del(`/api/project-template/${projectTemplateDetails.id}`);
        setDeleteLayoutId(null);
        toggleDeleteLayout();
        history("/project-templates");
    };


    const handleAddContent = () => {
        setEditContent(null);
        setSelectedField(null);
        tog_standard();
    };

    const handleOrder = async (type, item) => {
        let response = await put(
            `/api/project-template/order/${type}?id=${item.id}&&templateId=${params.id}`
        );
        getProjectTemplateDetails(params.id);
    };

    const getContentType = (type) => {
        if (type === "INPUT_FIELD") {
            return props.t("Text");
        } else if (type === "FILE") {
            return props.t("File");
        } else if (type === "NUMBER_FIELD") {
            return props.t("Number");
        } else if (type === "HEADER") {
            return props.t("Header");
        } else if (type === "BOOLEAN") {
            return props.t("Boolean");
        } else if(type === "FREE_TEXT"){
            return props.t("Free Text");
        } else if(type === "DROPDOWN"){
            return props.t("Dropdown");
        } else if(type === "DROPDOWN_MULTISELECT"){
            return props.t("Dropdown MultiSelect");
        } else if(type === "RANGE") {
            return props.t("Range");
        } else {
            return props.t("");
        }
    };

    const getBreadcrumbs = () => {
        let crumbs = [
            { title: props.t("Projects"), link: "#" },
            { title: props.t("Templates"), link: "/project-template" },
        ];
        return crumbs;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        // title={layout?.name}
                        // breadcrumbs={[{ title: props.t("Tabs"), link: "/templates" }]}
                        noBackBtn={true}
                        title={projectTemplateDetails?.name}
                        breadcrumbs={getBreadcrumbs()}

                    />

                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={projectTemplateDetails?.name}
                                        buttons={[{
                                            text: props.t("Add Field"),
                                            onClick: handleAddContent,
                                        }]}
                                        canEdit={() => handleEdit()}
                                        /*canDuplicate={() => handleCopy()}*/
                                        canDelete={() => setDeleteLayoutId(params.id)}
                                    />
                                </CardHeader>
                                <Loader loading={loading} title="Fields" />
                                {!loading &&
                                    <CardBody>
                                        {errorMessage && (
                                            <Row>
                                                <Alert color="danger">{errorMessage}</Alert>
                                            </Row>
                                        )}
                                        <MDBDataTable
                                            responsive
                                            searching={true}
                                            barReverse={true}
                                            hover
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            data={getDatatableData()}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            onSearch={(text) => console.log(text)}
                                        />
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {editContent ? props.t("Edit Content") : props.t("Add Content")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <label className="col-md-3 col-form-label">
                            {props.t("Field")}
                        </label>
                        <div className="col-md-9">
                            <SearchableDropdown
                                selectedValue={selectedField}
                                options={fields.filter(field => !find(projectTemplateDetails?.fieldList, {id: field.id}))}
                                placeholder={"Select Field"}
                                keyToDisplay={["name"]}
                                handleOnOptionClick={(field) => setSelectedField(field)} />
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard();
                        }}
                        className="col-md-2 btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("Close")}
                    </button>
                    <Button
                        color="primary"
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        onClick={() => handleSaveField(false)}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>

            <Modal
                isOpen={modal_edit}
                toggle={() => {
                    tog_edit();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Edit Template")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_edit(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                        >
                            {props.t("Label")}
                        </label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setEditname(e.target.value)}
                                placeholder={props.t("Enter fieldType label")}
                                value={editname}
                            />
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_edit();
                        }}
                        className="col-md-2 btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("Close")}
                    </button>
                    <Button
                        color="primary"
                        onClick={handleEditSave}
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>

            <Modal
                isOpen={modal_confirm}
                toggle={() => {
                    tog_confirm();
                }}
            >
                <div className="modal-header">
                    <h4 className="modal-title">
                        {props.t("Warning")}!
                    </h4>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <h4>{props.t("Do you want to change permission of content for existing articles?")}</h4>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            handleSaveField(false);
                        }}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("No only new articles")}
                    </button>
                    <Button
                        color="primary"
                        onClick={() => {handleSaveField(true)}}
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        className="btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Yes all articles")}
                    </Button>
                </div>
            </Modal>
            <DeleteModal
                deleteCallback={() => handleDeleteContent()}
                element={"Content"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteContentModal}
                toggleModal={() => toggleDeleteContent()}
                warningMsg={!isEmpty(articleContents) ? "The content fieldType is referenced inside Articles." : null}
            />
            <DeleteModal
                deleteCallback={() => handleDeleteLayout()}
                element={"Layout"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteLayoutModal}
                toggleModal={() => toggleDeleteLayout()}
                warningMsg={!isEmpty(layoutArticles) ? "The layout is referenced inside Articles." : null}
            />
        </React.Fragment>
    );
};

ProjectTemplateDetails.propTypes = {
    projectTemplate: PropTypes.object,
    onGetLayout: PropTypes.func,
    loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        projectTemplateDetails: state.ProjectTemplate.projectTemplateDetails,
        layoutError: state.ecommerce.getLayoutError,
        getContentTypeError: state.ecommerce.getContentTypeError,
        loading: checkIfLoading(state, ecommerceActionTypes.GET_LAYOUT),
        fields: state.ecommerce.fields,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getProjectTemplateDetails: (id) => dispatch(getProjectTemplateDetails(id)),
    onGetFields: () => dispatch(getFields("PROJECT_FIELD_TYPE")),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ProjectTemplateDetails)));


import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";

import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Modal, Row,} from "reactstrap";

import {find, isEmpty} from "lodash";

// RangeSlider
import "nouislider/distribute/nouislider.css";

import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import {put} from "../../helpers/api_helper";
//Import data
//import { discountData, colorData } from "../../../common/data";
//Import actions
import {getArticleGroupList, getCateoryList, getProducts, getProductsReset} from "../../store/e-commerce/actions";

import articlePlaceholderImg from "../../assets/images/product/article-placeholder.png";
import {ARTICLE_EXPORT_URL, BASE_IMAGE_URL} from "../../helpers/url_helper";
import {withTranslation} from "react-i18next";
import {Tooltip} from "@material-ui/core";
import {findIndex, isEqual} from "lodash-es";
import {debounce, getUpdatedList} from "../../util/helperFunctions";
import InfiniteScrollbarWithLoader from "../../components/Common/InfiniteScrollbarWithLoader";
import SearchableDropdown from "../../components/Common/SearchableDropdown";
import {isStockable} from "../../util/articleUtil";
import ArticleListTable from "./ArticleListTable";
import Loader from "../../components/Common/Loader";
import withRouter from "../../components/Common/withRouter";


const Articles = (props) => {
    const {products, onGetProducts, searchResult, metaInfo, loading, onGetProductsReset, error, onGetGroupList, onGetCategoryList, categories, groupList} = props;
    const [isTable, setIsTable] = useState({
        value: false,
        label: props.t("Show grid"),
    });
    const history = useNavigate();
    let params = useParams();
    const queryString = new URLSearchParams(window.location.search);

    const [productList, setProductList] = useState([]);
    const [tableProductList, setTableProductList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [totalItems, setTotalItems] = useState();

    const [search, setSearch] = useState("");

    const [brandSettings, setBrandSettings] = useState(null)
    const [filter, setFilter] = useState(null)
    const [modal_standard, setmodal_standard] = useState(false);
    const [articleGroups, setArticleGroups] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [articleGroup, setArticleGroup] = useState(queryString.get("groupId") ? {id: queryString.get("groupId")} : null)
    const [category, setCategory] = useState(queryString.get("categoryId") ? {id: queryString.get("categoryId")} : null);
    const [publishFilter, setPublishFilter] = useState(null)
    const [sortState, setSortState] = useState(null);
    const numericColumns = ['disposableQuantity', 'reservedQuantity', 'salesPrice'];
    const nonSortingColumns = ['category', 'articleGroup', 'stockable', 'export'];


    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetGroupList();
        onGetCategoryList();

        const prevCustomSearchFilter = JSON.parse(localStorage.getItem("articlesCustomSearchFilter"));
        if (prevCustomSearchFilter) {
            setCategory(prevCustomSearchFilter.category);
            setArticleGroup(prevCustomSearchFilter.articleGroup);
            setFilter(prevCustomSearchFilter.filter);
        } else {
            setFilter("all");
        }

        console.log("Params ", JSON.stringify(queryString.get("categoryId")));
        return () => {
            onGetProductsReset();
        }
    }, [])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                onGetProductsReset()
            }, 5000);
        }
    }, [error])

    useEffect(() => {
        setPage(1);
        setProductList([]);
        setTableProductList([])

        if (filter) {
            /*if (!isTable.value) {
                onGetProducts(-1, -1, search, filter, {articleGroup, category});
            } else {
                onGetProducts(page - 1, sizePerPage, search, filter, {articleGroup, category});
            }*/
            onGetProducts(page - 1, sizePerPage, search, filter, {articleGroup, category});
        }
    }, [isTable]);

    useEffect(() => {
        if (categories) {
            let subCats = []
            categories.map(c => c.subCategories?.map(sc => subCats.push(sc)))
            setCategoryList(subCats);
            if (queryString.get("categoryId")) {
                let category = find(subCats, {id: parseInt(queryString.get("categoryId"))})
                setCategory(category);
            }
        }
    }, [categories])

    useEffect(() => {
        if (groupList) {
            setArticleGroups(groupList);
        }
    }, [groupList])

    useEffect(() => {
        if(!isEmpty(products)){
            if (Array.isArray(productList) && Array.isArray(products) && !isEmpty(products) && !isEqual(products, productList)) {
                setProductList(getUpdatedList(productList, products));
                setProductResult(products)
            } else {
                setProductResult(products);
                setProductList(products ? products : []);
            }
        }
    }, [products]);

    useEffect(() => {
        try {
            if (sortState && productList) {
                const {column, direction} = sortState;
                if (!nonSortingColumns.includes(column)) {
                    if (page == 1 && column && direction) {
                        onGetProducts(0, sizePerPage, search, filter, {articleGroup, category}, sortState);
                    } else {
                        setPage(1);
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    }, [sortState])

    useEffect(() => {
        // when modal opens, populate fields with persisted filter state
        if (modal_standard) {
            const prevSearchFilter = JSON.parse(localStorage.getItem("articlesCustomSearchFilter"));
            setArticleGroup(prevSearchFilter?.articleGroup)
            setCategory(prevSearchFilter?.category)
            setFilter(prevSearchFilter?.filter)
        }
    }, [modal_standard]);

    useEffect(() => {
        setTotalPage(metaInfo.totalPages);
        setTotalItems(metaInfo.totalItems);
    }, [metaInfo]);

    useEffect(() => {
        if (
            page !== 1 &&
            productList?.length < totalItems &&
            !loading &&
            page <= totalPage
        ) {
            if (isTable.value) {
                onGetProducts(page - 1, sizePerPage, search, filter, {articleGroup, category});
            } else {
                onGetProducts(page - 1, sizePerPage, search, filter, {articleGroup, category}, sortState);
            }
        } else if (page == 1) {
            if (isTable.value) {
                onGetProducts(0, sizePerPage, search, filter, {articleGroup, category});
            } else {
                onGetProducts(0, sizePerPage, search, filter, {articleGroup, category}, sortState);
            }
        }
    }, [page]);


    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const handleAddFilter = async (articleGroup, category) => {
        const customSearchFilter = {articleGroup, category, filter: publishFilter};
        localStorage.setItem("articlesCustomSearchFilter", JSON.stringify(customSearchFilter));
        setProductList([])
        /*if (!isTable.value) {
          onGetProducts(-1, -1, null, publishFilter, {articleGroup, category});
        } else {
          onGetProducts(0, sizePerPage, null, publishFilter, {articleGroup, category});
        }*/
        onGetProducts(0, sizePerPage, null, publishFilter, {articleGroup, category});
        //tog_standard();
    };

    const handleClearFilter = async (toggleModal) => {
        setArticleGroup(null);
        setCategory(null);
        localStorage.setItem("articlesCustomSearchFilter", null);
        setProductList([])
        setFilter('all');
        onGetProducts(0, sizePerPage, null, filter, null);
        if (toggleModal) {
            tog_standard();
        }
    };


    const togglePublish = async (item) => {
        let response = await put(`/api/article/publish-toggle/${item.articleNumber}`);
        if (response.status === 200) {
            let updatedProducts = [...productList];
            var index = findIndex(updatedProducts, {id: item.id});
            updatedProducts.splice(index, 1, response.data);
            setProductList(updatedProducts);
        }
    };

    const getTitle = (name) => {
        return name.length > 94 ? name.substr(0, 91) + "..." : name;
    };

    const toggleGridView = () => {
        setIsTable({
            value: !isTable.value,
            label: props.t("Show grid"),
        });
    };

    const handleExportToExcel = (article) => {
        if (article) {
            window.location.href = ARTICLE_EXPORT_URL + article.uuid;
        }
    }

    const setProductResult = (result) => {
        if (searchResult && search) {
            if (searchResult === search) {
                setTableProductList(result);
            }
            return;
        }
        setTableProductList(result);
    }


    const resetFilter = () => {
        setCategory(null);
        setArticleGroup(null);
        handleFilter("all", true)
    }

    const handleFilter = (option, isReset) => {
        setFilter(option)
        if (option) {
            let customSearchFilter = {articleGroup: isReset ? null : articleGroup, category: isReset ? null : category, filter: option};
            setPublishFilter(option);
            localStorage.setItem("articlesCustomSearchFilter", JSON.stringify(customSearchFilter));
            setProductList([]);
            setPage(1);
            onGetProducts(0, sizePerPage, null, option, customSearchFilter);
        }
    }

    const handleClearSearch = (e) => {
        if (e.key === 'Backspace' && search.length == 0) {
            setProductList([]);
            setTableProductList([])
            setPage(1);
            onGetProducts(0, sizePerPage, null, filter, {articleGroup, category});
        }
    }

    const handlePublishFilter = (option) => {
        console.log("Filter -", option)
        setFilter(option);
        handleFilter(option);
        //   handleAddFilter(true);
    }

    const processChange = debounce((arg) => {
        setSearch(arg?.target?.value)
        setProductList([]);
        setPage(1);
        onGetProducts(0, sizePerPage, arg?.target?.value, filter, {articleGroup, category})
    });


    const getProductView = (product, key) => {
        if (!product) {
            return "";
        }
        return (
            <div className="col-md-4 col-sm-6 col-xl-3 mb-3" key={"_col_" + key}>
                <Card>
                    <CardBody style={{ padding: "0" }}>
                        <div className="product-box" style={{marginTop:"0 !important", marginBottom:"0 !important"}}>
                            <div className="d-flex justify-content-between align-items-center m-2">
                                <div className="d-flex">
                                    {product.publish == true ? (
                                        <div className="product-ribbon badge bg-soft-success d-flex align-items-center">
                                            {props.t("Published")}
                                        </div>
                                    ) : null}
                                    {!product.publish ? (
                                        <div className="product-ribbon badge bg-soft-warning d-flex align-items-center">
                                            {props.t("New")}
                                        </div>
                                    ) : null}
                                    {["MANUAL", "DYNAMIC"].includes(product.articlePackageType) &&
                                        <div className="product-ribbon badge bg-soft-info d-flex align-items-center mx-2">{props.t("Package")}</div>}
                                    <div onClick={() => handleExportToExcel(product)} className="mx-2"><i className="fa fa-download font-size-10"></i></div>
                                </div>
                                <div className="form-check form-switch float-right">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked={product.publish}
                                        style={product.publish ? {
                                            backgroundColor: brandSettings?.primaryBtnColor,
                                            borderColor: brandSettings?.primaryBtnColor
                                        } : {}}
                                        onChange={() => togglePublish(product)}
                                    />
                                </div>
                            </div>
                            <div className="product-img pt-4 px-4">
                                <img
                                    src={
                                        product.articleImages &&
                                        product.articleImages.length > 0
                                            ? BASE_IMAGE_URL +
                                            "/" +
                                            product.articleImages[0].media.s3Key
                                            : articlePlaceholderImg
                                    }
                                    alt=""
                                    className="img-fluid mx-auto d-block"
                                    onClick={() =>
                                        history(
                                            `/articles/${product.articleNumber}`
                                        )
                                    }
                                />
                            </div>
                            <div
                                className="product-content p-4"
                                onClick={() =>
                                    history(
                                        `/articles/${product.articleNumber}`
                                    )
                                }
                            >
                                <Tooltip
                                    title={
                                        product.displayName
                                            ? product.displayName
                                            : product.description
                                    }
                                >
                                    <h5 className="mb-1 article-title">
                                        <Link
                                            to={`/articles/${product.articleNumber}`}
                                            className="text-dark"
                                        >
                                            {getTitle(
                                                product.displayName
                                                    ? product.displayName
                                                    : product.description
                                            )}{" "}
                                        </Link>
                                    </h5>
                                </Tooltip>
                                <p className="text-muted article-title font-size-13">
                                    {product.shortDescription?.length > 100
                                        ? product.shortDescription.substr(0, 100) +
                                        ".."
                                        : product.shortDescription}
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        {!isStockable(product) ?
                                            <div className="badge bg-soft-danger p-2">
                                                {props.t("Non-stockable")}
                                            </div> :
                                            <div className="badge bg-soft-primary p-2">
                                                {props.t("Stockable")}
                                            </div>}
                                    </div>
                                    <div className="col-md-6">
                                        {isStockable(product) &&
                                            <p className="text-muted font-size-13">
                                                <p className={product.quantityInStock > 0 ? "text-success" : "text-danger"}>{props.t("Quantity In Stock")}{" "}
                                                    {product.quantityInStock}</p>
                                            </p>}
                                    </div>

                                </div>

                                {product.salesPrice &&
                                    <h5 className="mt-3 mb-0">
                            <span className="text-muted me-2">
                                <span>{product.salesPrice}kr</span>
                            </span>
                                    </h5>}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        title={props.t("Articles")}
                        breadcrumbs={[{title: props.t("Products"), link: "#"}]}
                        toggle={{
                            active: isTable?.value,
                            onClick: toggleGridView,
                            label: (
                                <label
                                    className="form-check-label"
                                    htmlFor="customSwitchsizelg"
                                >
                                    {props.t("Show grid")}
                                </label>
                            ),
                        }}
                    />

                    <Loader loading={loading && page == 1} title="Articles"/>
                    <Row>
                        <Col className="col-12 article-table">
                            <Card style={{marginBottom:"0px"}}>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                <TableToolbar
                                    title={props.t("Articles")}
                                    /*buttons={[{
                                    text: props.t("Custom"),
                                    onClick: tog_standard,
                                    deactive: !category && !articleGroup
                                    }]}*/
                                    filters={[{
                                        selected: filter,
                                        options: [
                                            {name: props.t("All"), value: "all"},
                                            {name: props.t("Published"), value: "published"},
                                            {name: props.t("Unpublished"), value: "unpublished"}
                                        ],
                                        onSelect: handlePublishFilter
                                    }, {
                                        selected: articleGroup,
                                        options: [{
                                            isSearchableDropdown: true,
                                            value: articleGroup,
                                            options: articleGroups,
                                            placeholder: props.t("Select Group"),
                                            handleSelect: (op) => {
                                                console.log("Selected ", op);
                                                setArticleGroup(op);
                                                handleAddFilter(op, category);
                                            },
                                            keyToDisplay: "name"
                                        }]
                                    }, {
                                        selected: category,
                                        options: [{
                                            isSearchableDropdown: true,
                                            value: category,
                                            options: categoryList,
                                            placeholder: props.t("Select Category"),
                                            handleSelect: (op) => {
                                                setCategory(op);
                                                handleAddFilter(articleGroup, op);
                                            },
                                            keyToDisplay: "name",
                                        }]
                                    }]}
                                    buttons={[{
                                        disabled: !articleGroup && !category,
                                        text: props.t("Reset"),
                                        onClick: resetFilter,
                                    }]}

                                />
                            </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    {error &&
                        <Alert className={"mt-4"} color="danger" role="alert">
                            {error}
                        </Alert>
                    }
                    {isTable.value &&
                        <Row>
                            <Col className="col-12 article-table mt-4 mb-2 ms-1">
                                <div className="form-inline searchWidth">
                                    <div className="search-box">
                                        <div className="position-relative ">
                                            <Form
                                                className="app-search d-lg-block "
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                <Input
                                                    type="text"
                                                    key={1}
                                                    id="article-search-input"
                                                    className="form-control bg-light border-light rounded toolbar-search ms-2"
                                                    placeholder={props.t("Search") + "..."}
                                                    onChange={processChange}
                                                    onKeyUp={(e) => handleClearSearch(e)}
                                                />
                                                <i className="mdi mdi-magnify search-icon"></i>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    }
                    {!isTable.value && (
                        <Row>
                            <Col className="col-12 article-table">
                                <Card>
                                    <CardBody className="py-0 pt-4">
                                        <ArticleListTable filter={filter} customFilter={{articleGroup, category}}/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    {isTable.value &&
                        <Row style={{'max-width': '1400px'}}>
                            <Col lg="12" xl="12">
                                <div>
                                    <Row className="min-vh-100">
                                        {productList && productList.length === 0 && (
                                            <Row className="min-height-400">
                                                <h6
                                                    align="center"
                                                    className="margin-top-10 font-color-grey"
                                                >
                                                    {props.t("No articles for this category")}
                                                </h6>
                                            </Row>
                                        )}
                                        {!isEmpty(productList) &&
                                            <InfiniteScrollbarWithLoader
                                                items={productList}
                                                loading={loading}
                                                totalItems={totalItems}
                                                renderCallback={getProductView}
                                                page={page}
                                                setPage={setPage}
                                                type={props.t("Articles")}
                                            />}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>

            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Add Custom Filter")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label={props.t("Close")}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                        >
                            {props.t("Published")}
                        </label>
                        <div className="col-md-9">
                            <select
                                className="form-control"
                                value={publishFilter}
                                onChange={(e) => setPublishFilter(e.target.value)}
                            >
                                <option value="all">{props.t("All")}</option>
                                <option value="published">{props.t("Published")}</option>
                                <option value="unpublished">{props.t("Unpublished")}</option>
                            </select>
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                        >
                            {props.t("Article Group")}
                        </label>
                        <div className="col-md-9">
                            <SearchableDropdown
                                selectedValue={articleGroup}
                                options={articleGroups}
                                placeholder={"Select Article Group"}
                                handleOnOptionClick={(et) => {
                                    setArticleGroup(et);
                                    console.log(et)
                                }}
                                keyToDisplay={["name"]}
                            />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                        >
                            {props.t("Category")}
                        </label>
                        <div className="col-md-9">
                            <SearchableDropdown
                                selectedValue={category}
                                options={categoryList}
                                placeholder={"Select Category"}
                                handleOnOptionClick={(et) => setCategory(et)}
                                keyToDisplay={["name"]}
                            />
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            const prevCustomSearchFilter = JSON.parse(localStorage.getItem("articlesCustomSearchFilter"));
                            if (prevCustomSearchFilter) {
                                setCategory(prevCustomSearchFilter.category);
                                setArticleGroup(prevCustomSearchFilter.articleGroup);
                                setFilter(prevCustomSearchFilter.filter);
                            }
                            tog_standard();
                        }}
                        className="col-md-2 btn btn-secondary"
                        data-dismiss="modal"
                    >
                        {props.t("Close")}
                    </button>
                    <Button
                        color="warning"
                        onClick={() => handleClearFilter(true)}
                        className="col-md-2 btn btn-warning waves-effect waves-light"
                    >
                        {props.t("Clear")}
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleAddFilter}
                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Apply")}
                    </Button>
                </div>
            </Modal>
        </React.Fragment>
    );
};

Articles.propTypes = {
    products: PropTypes.array,
    history: PropTypes.object,
    onGetProducts: PropTypes.func,
    metaInfo: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        products: state.ecommerce.products.data,
        searchResult: state.ecommerce.products.search,
        metaInfo: {
            page: state.ecommerce.products.currentPage + 1,
            totalPages: state.ecommerce.products.totalPages,
            totalItems: state.ecommerce.products.totalItems,
        },
        error: state.ecommerce.getArticlesError,
        loading: state.ecommerce.getArticlesPending,
        articleConfig: state.Config.articleConfig,
        categories: state.ecommerce.categoryList,
        groupList: state.ecommerce.groupList,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetProducts: (page, pageSize, search, filter, customFilter, sortState) => dispatch(
        getProducts(page, pageSize, false, search, filter, customFilter, null, null, sortState?.column, sortState?.direction?.toUpperCase())),
    onGetProductsReset: () => dispatch(getProductsReset()),
    onGetGroupList: () => dispatch(getArticleGroupList()),
    onGetCategoryList: () => dispatch(getCateoryList()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(Articles)));

 import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {withTranslation} from "react-i18next"
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getLanguageLabels, getPhrases} from "../../store/language/actions";
import {Alert, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";
import {MDBDataTable} from "mdbreact";
import {LANGUAGE_MASTER_LIST} from "../../util/constants";
import {post, del, postMultipart, get} from "../../helpers/api_helper";
import { TRANSLATION_EXPORT_URL } from "../../helpers/url_helper";
import { orderBy } from "lodash";
import cogoToast from "cogo-toast";
import { toastOptions } from "../../util/dataUtil";
 import withRouter from "../../components/Common/withRouter";

const LanguageList = (props) => {
    const {labelList, onGetLabels, loading, error, onGetPhrases, phraseList} = props;
    const [brandSettings, setBrandSettings] = useState(null)
    const [search, setSearch] = useState("");
    const history = useNavigate();
    const [label, setLabel] = useState("");
    const [value, setValue] = useState("");
    const [editTranslationId, setEditTranslationId] = useState("");
    const [editPhraseId, setEditPhraseId] = useState("");
    const [localLabelList, setLocalLabelList] = useState([]);
    const user = JSON.parse(localStorage.getItem("authUser"));
    const [globalTranslationMap, setGlobalTranslationMap] = useState({});
    const [sortState, setSortState] = useState(null);
    const [filter, setFilter] = useState(null)
    const numericColumns = [];
    let params = useParams();

    useEffect(() => {
        setBrandSettings(user?.brandConfig);
    }, [])

    useEffect(() => {
        onGetPhrases();
        if (params && params.locale) {
            setFilter('all');
            setupGlobalTranslationMap();
        }

    }, [params.locale]);

    useEffect(() => {
        if(labelList){
            setLocalLabelList(labelList);
        }
    }, [labelList]);

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setLocalLabelList(orderBy(localLabelList, o => {
            if(column === 'label') return o.phrase.label.toLowerCase()
            if(column === 'createdAt') return o.phrase.createdAt ? Date.parse(o.phrase.createdAt) : 0;
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])

    useEffect(() => {
        if(filter){
            onGetLabels(params.locale, user?.tenantId, filter);
        }
    }, [filter]);

    const setupGlobalTranslationMap = async () => {
        const response = await get(`/api/translation/label/${params.locale}`);
        if(response.status === 200) {
            let globalT = {};
            response.data.map(l => {
                if(!l.tenant && l.value && l.value != ""){
                    const label = l.phrase.label
                    globalT[label] = l.value;
                }
            })
            setGlobalTranslationMap(globalT);
        }
    }

    const getDatatableData = () => {
        const rows = localLabelList?.filter(row => row?.phrase.label.toLowerCase().includes(search?.toLowerCase()))
        let data = {
            columns: [
                {
                    label: props.t("Label"),
                    field: "label",
                    autoWidth: false,
                    width: "40%"
                },
                {
                    label: props.t("Value"),
                    field: "value",
                    autoWidth: false,
                    width: "40%"
                },
                {
                    label: props.t("Label Created Date"),
                    field: "createdAt",
                    autoWidth: false,
                    width: "40%"
                },
                {
                    label: props.t("Action"),
                    field: "action",
                    autoWidth: false,
                    width: "20%"
                }

            ],
            rows: labelList?.map((item, key) => ({
                label: item.phrase.label,
                value: <input
                        key={key}
                        className="form-control"
                        type="text"
                        onChange={(e) => setValue(e.target.value)}
                        placeholder={user?.tenantId && globalTranslationMap[item.phrase.label] ? globalTranslationMap[item.phrase.label] :  props.t("Enter value in " + getLanguageName(params.locale))}
                        value={editPhraseId === item.phrase.id ? value : (item.value && (!user?.tenantId || item.tenant) ? item.value : "")}
                        disabled={editPhraseId !== item.phrase.id}
                    />,
                createdAt: item.phrase.createdAt ? new Date(item.phrase.createdAt).toLocaleString() : null,
                action: <div key={key}>
                    {editPhraseId !== item.phrase.id && 
                    <i
                        className="uil uil-pen text-primary font-size-18 mx-1 px-2 py-1 lang-edit-icon"
                        onClick={() => handleEdit(item)}
                    ></i>}
                    {editPhraseId === item.phrase.id && 
                    <i
                        className="uil uil-check text-success font-size-18 mx-1 px-2 py-1 lang-edit-icon"
                        onClick={() => handleSave(item)}
                    ></i>}
                    {editPhraseId === item.phrase.id && 
                    <i
                        className="uil uil-multiply text-warning font-size-18 mx-1 px-2 py-1 lang-edit-icon"
                        onClick={() => clearEdit()}
                    ></i>}
                    <i
                        className={`uil uil-trash-alt ${item.id ? 'text-danger' : 'text-primary'} font-size-18 mx-1 px-2 py-1 lang-edit-icon`}
                        onClick={() => {
                            if(item.id){
                                handleDeleteLabel(item);
                            }
                        }}
                    ></i>
                </div>
            }))
        }
        return data;
    }

    const getLanguageName = (code) => {
        for (let i = 0; i < LANGUAGE_MASTER_LIST.length; i++) {
            if (LANGUAGE_MASTER_LIST[i].key == code) {
                return LANGUAGE_MASTER_LIST[i].value;
            }
        }
        return code;
    }

    const handleSave = async (item) => {
        /* 
        if value is set to empty then delete the translation record for the cases:
            case 1: if there is not logged in tenant then translation value can be updated
            case 2: if tenant is logged in then translation must be tenant specific because tenant cannot override global translations
        */
        if(!value || value === ''){
            if(user?.tenant && !item?.tenant){
                cogoToast.error(props.t("Could not delete global translation setting."), toastOptions);
                return;
            }
            handleDeleteLabel(item);
        } else {
            let response = await post("/api/translation/label", {id: editTranslationId === "" ? null : editTranslationId, phrase: {id: editPhraseId === "" ? null : editPhraseId}, value: value, locale: params.locale});
            if(response.status === 200) {
                const index = localLabelList.findIndex(l => l.phrase.label === label);
                if(index >=0){
                    localLabelList[index] = response.data;
                    setLocalLabelList([...localLabelList]);
                }
            }
        }
        setEditTranslationId("");
        setEditPhraseId("");
        setValue("");
        setLabel("");
        // onGetLabels(params.locale);
    }

    const handleEdit = (item) => {
        setEditTranslationId(item.id);
        setEditPhraseId(item.phrase.id);
        setLabel(item.phrase.label);
        setValue(!user?.tenantId || item.tenant ? item.value : "");
    }

    const handleDelete = async () => {
        let response = await del(`/api/translation/language/${params.locale}`);
        history(`/translations`)
    }

    const handleDeleteLabel = async (item) => {
        if(item.id){
            let response = await del(`/api/translation/label/${item.id}`);
            if(response.status === 202) {
                const index = localLabelList.findIndex(l => l.id === item.id);
                if(index >=0){
                    localLabelList[index] = {...localLabelList[index], value: user?.tenantId && globalTranslationMap[item.phrase.label] ? globalTranslationMap[item.phrase.label] : null, id: null, tenant: null}
                    setLocalLabelList([...localLabelList]);
                }
            }
        }
        // onGetLabels(params.locale);
    }

    const handleBack = () => {
        history(`/translations`)
    }

    const clearEdit = () => {
        setEditTranslationId("");
        setEditPhraseId("");
        setLabel("");
        setValue("");
    }

    const handleImport = async (file) => {
        let formData = new FormData();
        formData.append("file", file);
        let response = await postMultipart(`/api/media/translation/label/import/${params.locale}`, formData);
        if (response.status === 202) {
            cogoToast.success(props.t("Translations data imported successfully."), toastOptions);
            setTimeout(() => window.location.reload(), 3000)
        } else {
            cogoToast.error(props.t("There was an error while importing translations data."), toastOptions);
        }
    }

    const handleExport = () => {
        const tid = user?.tenantId ? '?tenantId=' + user.tenantId : '';
        window.location.href = TRANSLATION_EXPORT_URL + params.locale + tid;
    }

    const ImportTranslationButton = () => (
        <label
            onChange={(e) => handleImport(e.target.files[0])}
            htmlFor="formId"
            className="btn btn-primary btn waves-effect waves-light m-0"
            style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
        >
            <input
                type="file"
                name="xls"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                hidden
                id="formId"
            />
            {props.t("Import Translations")}
        </label>)

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        title={getLanguageName(params.locale)}
                        backBtn={handleBack}
                        canDelete={handleDelete}
                        breadcrumbs={[{title: props.t("Language"), link: "/translations"}]}
                    />
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={getLanguageName(params.locale)}
                                        buttons={[
                                            {
                                                text: props.t("Export Translations"),
                                                onClick: handleExport,
                                            }
                                        ]}
                                        customComponent={ImportTranslationButton}
                                        filter={{
                                        selected: filter,
                                        options : [
                                            {name: props.t("All"), value:"all"},
                                            {name: props.t("Empty"), value:"empty"}
                                        ],
                                        onSelect: (option) => setFilter(option)
                                        }}
                                    />
                                </CardHeader>
                                <Loader loading={loading} title="Labels"/>
                                {!loading &&
                                    <CardBody>
                                        {error &&
                                            <Alert className={"mt-4"} color="danger" role="alert">
                                                {error}
                                            </Alert>
                                        }
                                        <MDBDataTable
                                            responsive
                                            hover
                                            barReverse={true}
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            data={getDatatableData()}
                                            searching={true}
                                            onSearch={(text) => setSearch(text)}
                                            disableRetreatAfterSorting={true}
                                            sortRows={[]}
                                            onSort={({column, direction}) => {
                                                if(sortState && sortState.column == column){
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

}


LanguageList.propTypes = {
    labelList: PropTypes.array,
    onGetLabels: PropTypes.func,
    onGetPhrases: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.object,
}

const mapStateToProps = state => {
    return {
        labelList: state.Language.labelList,
        phraseList: state.Language.phraseList,
        error: state.Language.labelListError,
        loading: state.Language.labelListLoading
    }
}

const mapDispatchToProps = dispatch => ({
    onGetLabels: (lang, tenantId, filter) => dispatch(getLanguageLabels(lang, tenantId, filter)),
    onGetPhrases: () => dispatch(getPhrases())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(LanguageList)));

import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types'
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {post} from "../../helpers/api_helper";
import {API_ERR_MSG} from "../../util/constants";
import TableToolbar from "../../components/Common/TableToolbar";
import {useNavigate} from "react-router-dom";
import moment from "moment-timezone";
import Select from "react-select";

const timezones = moment.tz.names();
const options = timezones.map((tz) => {
    return { value: tz, label: tz }
});

const DateConfig = (props) => {

    const history = useNavigate();

    const {} = props

    const [dateConfig, setDateConfig] = useState({});
    const [minInterval, setMinInterval] = useState(5);
    const [timeslipFutureTimeLog, setTimeslipFutureTimeLog] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [brandSettings, setBrandSettings] = useState(null);
    const [timezone, setTimezone] = useState(null);

    const clearError = () => setError(null)
    const clearSuccess = () => setSuccess(null)

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);

        const startOfTheWeek = user?.config?.startOfTheWeek;
        setDateConfig(startOfTheWeek);

        const minDiff = user?.config?.minInterval;
        if(minDiff){
            setMinInterval(minDiff);
        }

        const zone = user?.timezone;
        if(zone){
            setTimezone(zone);
        }
        setTimeslipFutureTimeLog(user?.config?.timeslipFutureTimeLog)
    }, [])


    const submitDateConfigForm = (event) => {
        post('/api/settings/date-config', {"startOfTheWeek": dateConfig, "minInterval": minInterval, "timeslipFutureTimeLog": timeslipFutureTimeLog, "timezone": timezone})
            .then(data => {
                if (data?.status === 200) {
                    setSuccess(props.t("Date configuration updated successfully"))
                    setTimeout(clearSuccess, 5000)
                    const authUser = JSON.parse(localStorage.getItem("authUser"));
                    authUser.config = {
                        startOfTheWeek: dateConfig,
                        minInterval: minInterval,
                        timeslipFutureTimeLog: timeslipFutureTimeLog
                    }
                    authUser.timezone = timezone;
                    localStorage.setItem("authUser", JSON.stringify(authUser));
                } else {
                    setError(API_ERR_MSG)
                    setTimeout(clearError, 5000)
                }
            }).catch(error => {
                console.log("Error ", error)
                setError("Fail to set article config.");
                setTimeout(clearError, 5000)
             });
    }

    const handleTimezoneChange = selectedOption => {
        setTimezone(selectedOption.value);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader
                                    className="table-header-bg"
                                    style={{backgroundColor: brandSettings?.primaryColor}}
                                >
                                    <TableToolbar title={props.t("Date Config")}/>
                                </CardHeader>

                                    <CardBody>
                                        {error ? (
                                            <Alert color="danger">{error}</Alert>
                                        ) : null}

                                        {success ? (
                                            <Alert color="success">{success}</Alert>
                                        ) : null}

                                        <form onSubmit={(event) => {
                                            event.preventDefault()
                                            submitDateConfigForm(event)
                                        }}>
                                            <Row className="mb-3 article-config-quantity-setting">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label article-config-quantity-setting-label"
                                                >
                                                    {props.t("First Day of Week")}
                                                </label>
                                                <div className="col-md-3 col-sm-10">
                                                    <select
                                                        className="form-control"
                                                        id="articleQuantityConfig"
                                                        onChange={e => setDateConfig(e.target.value)}
                                                        value={dateConfig}
                                                    >

                                                        <option value={"SUN"}>
                                                            {props.t("Sunday")}
                                                        </option>
                                                        <option value={"MON"}>
                                                            {props.t("Monday")}
                                                        </option>
                                                    </select>
                                                </div>
                                            </Row>
                                            <Row className="mb-3 article-config-quantity-setting">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label article-config-quantity-setting-label"
                                                >
                                                    {props.t("Minute Interval")}
                                                </label>
                                                <div className="col-md-3 col-sm-10">
                                                    <select
                                                        className="form-control"
                                                        id="articleQuantityConfig"
                                                        onChange={e => setMinInterval(e.target.value)}
                                                        value={minInterval}
                                                    >

                                                        <option value={"5"}>
                                                            {props.t("5")}
                                                        </option>
                                                        <option value={"10"}>
                                                            {props.t("10")}
                                                        </option>
                                                        <option value={"15"}>
                                                            {props.t("15")}
                                                        </option>
                                                        <option value={"30"}>
                                                            {props.t("30")}
                                                        </option>
                                                    </select>
                                                </div>
                                            </Row>
                                            <Row className="mb-3 article-config-quantity-setting">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label article-config-quantity-setting-label"
                                                >
                                                    {props.t("Allow timelog in future")}
                                                </label>
                                                <div className="col-md-3 col-sm-10 margin-top-7px">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={timeslipFutureTimeLog}
                                                        style={timeslipFutureTimeLog ? {
                                                            backgroundColor: brandSettings?.primaryBtnColor,
                                                            borderColor: brandSettings?.primaryBtnColor
                                                        } : {}}
                                                        onChange={() => setTimeslipFutureTimeLog(!timeslipFutureTimeLog)}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label article-config-quantity-setting-label"
                                                >
                                                    {props.t("Timezone")}
                                                </label>
                                                <div className="col-md-3 col-sm-10 margin-top-7px">
                                                    <input type="hidden" name="timezone" value={timezone} />
                                                    <Select
                                                        value={{label: timezone, value: timezone}}
                                                        onChange={handleTimezoneChange}
                                                        options={options}
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className={"col-md-2 col-form-label article-config-quantity-setting-label"}></label>
                                                <Col align="left">
                                                    <Button
                                                        type="submit"
                                                        color="success"
                                                        className="btn btn-success w-lg waves-effect waves-light me-2"
                                                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                    >
                                                        {props.t("Save")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

DateConfig.propTypes = {
    dateConfig: PropTypes.array,
    loading: PropTypes.bool,
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => (
    {}
)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(DateConfig));

import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {useNavigate, useParams} from "react-router-dom"
import {Button, Card, CardBody, CardHeader, Col, Container, Input, Row} from "reactstrap"


//Import Breadcrumb
// import charts
import {GetCustomer, getCustomerRest, getUserForImpersonation} from "../../store/actions"
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import {withTranslation} from "react-i18next";
import TableToolbar from "../../components/Common/TableToolbar";
import {API_ERR_MSG} from "../../util/constants"
import Loader from "../../components/Common/Loader"
import {del, postMultipart, put} from "../../helpers/api_helper";
import loadingIcon from "../../assets/images/loading.gif";
import PersonIcon from "@material-ui/icons/Person";
import avatar from "../../assets/images/company-placeholder.png";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import {Avatar, IconButton} from "@material-ui/core";

const CustomerProfileEdit = props => {
    const {
        customer,
        users,
        customerLoading,
        onGetCustomerDetails,
        onGetUserForImpersonation,
        onRestCustomer,

    } = props
    let params = useParams();
    const [customerDetails, setCustomerDetails] = useState();
    const history = useNavigate();
    const [brandSettings, setBrandSettings] = useState(null)
    const [accountManager, setAccountManager] = useState("");
    const [defaultContact, setDefaultContact] = useState(null);
    const [imageUploading, setImageUploading] = useState(false);
    const [profileImageId, setProfileImageId] = useState(null);
    const [error, setError] = useState(null);
    const [deleteBtnText, setDeleteBtnText] = useState("Delete Profile Picture");
    const ErrorMessage = "Error occurred while updating profile. If issue persists, Please contact system administrator";

    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(obj?.brandConfig);
        return () => {
            onRestCustomer();
            setCustomerDetails({});
        };
    }, []);
    
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"))
        if (params && params.id) {
            onGetCustomerDetails(params.id);
            onGetUserForImpersonation(user.tenantId, null, [
                "ROLE_TENANT_ADMIN",
                "ROLE_TENANT_USER",
                "ROLE_CUSTOMER_ADMIN",
                "ROLE_CUSTOMER_USER"
            ]);
        }
    }, [params.id]);

    useEffect(() => {
        if (customer) {
            setCustomerDetails(customer);
            setAccountManager(customer?.accountManager?.id);
            setProfileImageId(customer?.profileImage?.s3Key)
            setDefaultContact(customer?.defaultContactId);
            console.log("Default Contact", customer?.defaultContactId);
        }
    }, [customer]);

    const updateCustomer = async () => {
        let response = await put(`/api/customers/${customerDetails.id}`, {userId:accountManager, defaultContactId : defaultContact});
        history(`/customers/${customerDetails?.customerNumber}/profile`)
    }

    const handleBack = () => {
        history(-1);
    };

    const handleAcceptedFile = async (e) => {
        if(e.target.files){
            setImageUploading(true);
            let formData = new FormData();
            formData.append("files[]", e.target.files[0]);
            let response;
            try {
                response = await postMultipart("/api/media", formData);

            } catch (ex){
                setError(props.t(ErrorMessage));
                setTimeout(() => setError(null), 3000);
            }
            if (response && response.status === 200) {
                setProfileImageId(response.data[0].s3Key);
                try {
                    const editResponse = await put(`/api/customers/${customerDetails?.id}/editProfile/${response.data[0]?.id}`);
                    setTimeout(() => {
                        history(`/customers/${customerDetails?.customerNumber}/profile`)
                    }, 3000);
                } catch (ex){
                    setError(props.t(ErrorMessage));
                    setTimeout(() => setError(null), 3000);
                }
            } else {
                setError(props.t(ErrorMessage));
                setTimeout(() => setError(null), 3000);
            }

            setImageUploading(false);
        }
    }

    const handleDeleteProfilePicture = async () => {
        try {
            setDeleteBtnText("Deleting");
            let response = await del(`/api/customers/${customerDetails?.id}/profile-picture`);
            setProfileImageId(null);
            setDeleteBtnText("Delete Profile Picture");
            history(`/customers/${customerDetails?.customerNumber}/profile`)
        } catch (err){
            setError(props.t(API_ERR_MSG));
            setTimeout(() => setError(null), 3000);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        title={customerDetails?.name}
                        breadcrumbs={[
                            {title: props.t("Customers"), link: "/customers"},
                        ]}
                        backBtn={handleBack}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={customer?.name ? customer.name : props.t("Customer Details")}
                                    />
                                </CardHeader>
                            </Card>
                        </Col>
                        <Loader loading={customerLoading} title="Customer Profile" />
                        {!customerLoading && <>
                            <Col xl="12">
                                <Card className="card h-100">
                                    <CardBody>
                                        <Row>
                                            <div className="text-center">
                                                <input
                                                accept='image/*'
                                                id='icon-button-file'
                                                type='file'
                                                onChange={handleAcceptedFile}
                                                style={{
                                                display: 'none'
                                                }}
                                                />
                                                <label htmlFor='icon-button-file' className="mb-0">
                                                    <IconButton
                                                    color='primary'
                                                    aria-label='upload picture'
                                                    component='span'
                                                    className="mb-0 pb-0"
                                                    >
                                                        <Avatar
                                                            variant="rounded"
                                                            src={imageUploading ? loadingIcon : profileImageId == null ? avatar : BASE_IMAGE_URL + "/" + profileImageId}
                                                            className="customer-logo"
                                                        >
                                                        <PersonIcon/>
                                                        </Avatar>
                                                    </IconButton>
                                                </label>
                                                <div className="d-flex justify-content-center align-items-center mb-2">
                                                    <i className="uil uil-info-circle me-1 text-muted font-size-13"></i>
                                                    <p className="text-muted font-size-12 m-0">{props.t("Recommended image dimensions are 400 x 100 pixels")}</p>
                                                </div>
                                                {profileImageId && <h6>
                                                    <Button className="btn-sm btn-danger mb-3"
                                                        onClick={handleDeleteProfilePicture}
                                                        color="danger">
                                                        {props.t(deleteBtnText)}
                                                    </Button>
                                                </h6>}
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Name")}
                                            </label>
                                            <div className="col-md-10">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={customerDetails?.name}
                                                    disabled={true}
                                                />
                                            </div>
                                        </Row>

                                        <Row className="mb-3">
                                            <label
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Mobile")}
                                            </label>
                                            <div className="col-md-10">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={customerDetails?.phone}
                                                    disabled={true}
                                                />
                                            </div>
                                        </Row>

                                        <Row className="mb-3">
                                            <label
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Email")}
                                            </label>
                                            <div className="col-md-10">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={customerDetails?.email}
                                                    disabled={true}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Address")}{" 1"}
                                            </label>
                                            <div className="col-md-10">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={customerDetails?.address1}
                                                    disabled={true}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Address") + " 2"}
                                            </label>
                                            <div className="col-md-10">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={customerDetails?.address2}
                                                    disabled={true}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("City")}
                                            </label>
                                            <div className="col-md-10">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={customerDetails?.city}
                                                    disabled={true}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Zip")}
                                            </label>
                                            <div className="col-md-10">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={customerDetails?.zipCode}
                                                    disabled={true}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Price List")}
                                            </label>
                                            <div className="col-md-10">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={customerDetails?.priceList}
                                                    disabled={true}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Account Manager")}
                                            </label>
                                            <div className="col-md-10">
                                                <select className="form-control" value={accountManager} onChange={(e) => setAccountManager(e.target.value)}>
                                                    <option value="">{props.t("Select account manager")}</option>
                                                    {users?.filter(u => ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"].includes(u.authority.authority)).map((user) => {
                                                        return (<option value={user.id}>{user.name}</option>)
                                                    })}
                                                </select>
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Default Contact")}
                                            </label>
                                            <div className="col-md-10">
                                                <select className="form-control" value={defaultContact} onChange={(e) => setDefaultContact(e.target.value)}>
                                                    <option value="">{props.t("Select default contact")}</option>
                                                    {users?.filter(u => ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER_USER"].includes(u.authority.authority)).map((user) => {
                                                        return (<option value={user.id}>{user.name}</option>)
                                                    })}
                                                </select>
                                            </div>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col align="center">
                                                <Button
                                                    type="button"
                                                    color="secondary"
                                                    className="btn btn-secondary w-lg waves-effect waves-light"
                                                    onClick={() => {
                                                        if (props.setEditMode) {
                                                            props.setEditMode(false);
                                                        } else {
                                                            history(-1)
                                                        }
                                                    }}
                                                >
                                                    {props.t("Cancel")}
                                                </Button>
                                                {" "}
                                                <Button
                                                    type="submit"
                                                    color="success"
                                                    className="btn btn-success w-lg waves-effect waves-light"
                                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                    onClick={updateCustomer}
                                                    disabled={imageUploading}
                                                >
                                                    {props.t("Update")}
                                                </Button>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </Col>

                            </>}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

CustomerProfileEdit.propTypes = {
    customer: PropTypes.object,
    onGetCustomerDetails: PropTypes.func,
    onRestCustomer: PropTypes.func,
}

const mapStateToProps = (state) => ({
    customer: state.Customer.customer,
    customerLoading: state.Customer.customerLoading,
    users: state.UserImpersonation.users,
})

const mapDispatchToProps = dispatch => ({
    onGetCustomerDetails: (id) => dispatch(GetCustomer(id)),
    onRestCustomer: () => dispatch(getCustomerRest()),
    onGetUserForImpersonation: (tenantId, customerId, roles) =>
        dispatch(getUserForImpersonation(tenantId, customerId, roles)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(CustomerProfileEdit));

import PropTypes from 'prop-types';
import React, {useState} from "react";

import {connect} from "react-redux";
// Reactstrap
import {Button, ButtonDropdown, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input} from "reactstrap";

import {Link, useNavigate} from "react-router-dom";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import images
//i18n
import {withTranslation} from "react-i18next";

// Redux Store
import {showRightSidebarAction, toggleLeftmenu,} from "../../store/actions";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import {equalsAny, hasAnyModuleEnabled, hasModuleEnabled} from "../../util/helperFunctions";
import UserImpersonation from "../../pages/Authentication/UserImpersonation";
import FortnoxStatus from "../Status/FortnoxStatus";
import OfferFormModalContainer from "../../pages/Tenant/OfferFormModalContainer";
import TaskModal from "../../pages/Tasks/TaskModal";
import TimeslipModal from "../../pages/Tasks/TimeslipModal";
import ProjectFormModalContainer from "../../pages/Projects/ProjectFormModalContainer";
import OrderFormModalContainer from "../../pages/Tenant/OrderFormModalContainer";

const Header = props => {
    const [search, setsearch] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [socialDrp, setsocialDrp] = useState(false);

    const [showTimeslipTaskModal, setShowTimeslipTaskModal] = useState(false);
    const [newItemToggle, setNewItemToggle] = useState(false);

    const [showAddTaskModal, setShowAddTaskModal] = useState(false);
    const [showAddOrderModal, setShowAddOrderModal] = useState(false);
    const [showAddOfferModal, setShowAddOfferModal] = useState(false);
    const [showAddProjectModal, setShowAddProjectModal] = useState(false);

    const history = useNavigate();
    const user = JSON.parse(localStorage.getItem("authUser"));
    const brandSettings = user?.brandConfig;
    const fortnoxConnectionStatus = localStorage.getItem("fortnox-connection-status") === "true";


    function toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    function tToggle() {
        var body = document.body;
        var windowSize = document.documentElement.clientWidth;

        body.classList.toggle("vertical-collpsed");
        body.classList.toggle("sidebar-enable");
        if (windowSize > 991) {
            (body.getAttribute('data-sidebar-size') === 'sm') && windowSize > 991 ? body.setAttribute('data-sidebar-size', 'lg') : body.setAttribute('data-sidebar-size', 'sm');
        }
    }

    function toggleAddTaskModal() {
        setShowAddTaskModal(!showAddTaskModal)
    }

    function toggleAddOrderModal() {
        setShowAddOrderModal(!showAddOrderModal)
    }

    function toggleAddOfferModal() {
        setShowAddOfferModal(!showAddOfferModal)
    }

    function toggleAddProjectModal() {
        setShowAddProjectModal(!showAddProjectModal)
    }

    function toggleAddTimeslipModal() {
        setShowTimeslipTaskModal(!showTimeslipTaskModal)
    }

    const getBrandLogo = () => {
        if (user?.brandConfig && user?.brandConfig?.brandLogo) {
            return BASE_IMAGE_URL + "/" + user.brandConfig.brandLogo;
        }
        return "";
    }
    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box" style={{position: "relative;"}}>
                            <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={getBrandLogo()} alt="" height="22"/>
                </span>
                                <span className="logo-lg">
                  <img src={getBrandLogo()} alt="" height="20"/>
                </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={getBrandLogo()} alt="" height="22"/>
                </span>
                                <span className="logo-lg">
                  <img src={getBrandLogo()} alt="" height="20"/>
                </span>
                            </Link>
                        </div>

                        <button
                            type="button"
                            onClick={() => {
                                tToggle();
                            }}
                            className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
                            id="vertical-menu-btn"
                        >
                            <i className="fa fa-fw fa-bars"/>
                        </button>

                        <Form className="app-search d-none d-lg-block">
                            <div className="position-relative">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={props.t("Search") + "..."}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                                <span className="uil-search"></span>
                            </div>
                        </Form>
                        <div className="cs-hide-sm p-2">
                            {equalsAny(user?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER", "ROLE_ACTING_USER"]) && (
                                <UserImpersonation/>
                            )}
                        </div>
                    </div>
                    <div className="d-flex">

                        <Dropdown
                            className="d-inline-block d-lg-none ms-2"
                            onClick={() => {
                                setsearch(!search);
                            }}
                            type="button"
                        >
                            <DropdownToggle
                                className="btn header-item noti-icon waves-effect"
                                id="page-header-search-dropdown"
                                tag="button"
                            > <i className="uil-search"/>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                                <Form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <Input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username"/>
                                            <div className="input-group-append">
                                                <Button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></Button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </DropdownMenu>
                        </Dropdown>

                        {(!user?.customerId && user?.tenantId) && hasAnyModuleEnabled(user?.tenantFeatures, ["OFFERS", "ORDERS", "TASKS"]) &&
                            <ButtonDropdown
                                isOpen={newItemToggle}
                                toggle={() => setNewItemToggle(!newItemToggle)}
                                className="sm-btn mx-2 my-3">
                                <DropdownToggle
                                    caret
                                    className="dropdown-toggle-split "
                                    id="page-header-project-dropdown"
                                    style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor, borderRadius: "4px"}}>
                                    <span>{props.t("New")} &nbsp;&nbsp;</span>
                                    <i className="mdi mdi-chevron-down"/>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    {fortnoxConnectionStatus && <>

                                        {hasModuleEnabled(user?.tenantFeatures, "OFFERS") && <>
                                            <DropdownItem onClick={() => setShowAddOfferModal(true)}>
                                                {props.t("Offer")}
                                            </DropdownItem> </>
                                        }
                                        {hasModuleEnabled(user?.tenantFeatures, "ORDERS") && <>
                                            <DropdownItem onClick={() => setShowAddOrderModal(true)}>
                                                {props.t("Order")}
                                            </DropdownItem></>
                                        }
                                        <DropdownItem onClick={() => setShowAddProjectModal(true)}>
                                            {props.t("Project")}
                                        </DropdownItem> </>}
                                    {hasModuleEnabled(user?.tenantFeatures, "TASKS") && <>
                                        <DropdownItem onClick={() => setShowAddTaskModal(true)}>
                                            {props.t("Task")}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => setShowTimeslipTaskModal(true)}>
                                            {props.t("Timeslip")}
                                        </DropdownItem>
                                    </>}
                                </DropdownMenu>
                            </ButtonDropdown>
                        }
                        <LanguageDropdown/>
                        <Dropdown className="d-none d-lg-inline-block ms-1">
                            <button
                                type="button"
                                onClick={() => {
                                    toggleFullscreen();
                                }}
                                className="btn header-item noti-icon waves-effect"
                                data-toggle="fullscreen"
                            >
                                <i className="uil-minus-path"></i>
                            </button>
                        </Dropdown>

                        <div onClick={() => {
                            props.showRightSidebarAction(!props.showRightSidebar);
                        }}
                             className="dropdown d-inline-block">
                            <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                                <i className="uil-cog"></i>
                            </button>
                        </div>
                        <ProfileMenu/>
                    </div>
                </div>
                <FortnoxStatus/>
            </header>
            <OrderFormModalContainer
                useModal={true}
                modal_standard={showAddOrderModal}
                setmodal_standard={setShowAddOrderModal}
                tog_standard={toggleAddOrderModal}
            />
            <OfferFormModalContainer
                useModal={true}
                modal_standard={showAddOfferModal}
                setmodal_standard={setShowAddOfferModal}
                tog_standard={toggleAddOfferModal}
            />
            <ProjectFormModalContainer
                useModal={true}
                modal_standard={showAddProjectModal}
                setmodal_standard={setShowAddProjectModal}
                tog_standard={toggleAddProjectModal}
            />
            <TaskModal
                modal_standard={showAddTaskModal}
                setmodal_standard={setShowAddTaskModal}
                tog_standard={toggleAddTaskModal}
                avoidRedirect={true}
            />
            <TimeslipModal
                modal_standard={showTimeslipTaskModal}
                setmodal_standard={setShowTimeslipTaskModal}
                tog_standard={toggleAddTimeslipModal}
                avoidRedirect={true}
            />
        </React.Fragment>
    );
};

Header.propTypes = {
    // changeSidebarType: PropTypes.func,
    leftMenu: PropTypes.any,
    leftSideBarType: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
    const {
        layoutType,
        showRightSidebar,
        leftMenu,
        leftSideBarType,
    } = state.Layout;
    return {layoutType, showRightSidebar, leftMenu, leftSideBarType};
};

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    // changeSidebarType,
})(withTranslation()(Header));

import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";
import React, {useEffect, useState} from "react";
import classnames from 'classnames';
import PropTypes from "prop-types";
import {checkIfLoading} from "../../store/selector";
import {GET_ARTICLE_GROUP} from "../../store/e-commerce/actionTypes";
import {getArticleGroup, getArticleGroupReset, getProductByGroup, getProducts} from "../../store/e-commerce/actions";
import {del, get, put} from "../../helpers/api_helper";
import {BASE_IMAGE_URL, DOWNLOAD_URL} from "../../helpers/url_helper";
import SearchableDropdown from "../../components/Common/SearchableDropdown";
import ImageGallery from "react-image-gallery";
import {find, orderBy} from "lodash";
import ArticleListTable from "../Articles/ArticleListTable";
import CustomerOfferTable from "../Customer/CustomerOfferTable";
import CustomerInvoiceTable from "../Customer/CustomerInvoiceTable";
import {downloadPDF, getFilterClassName, hasModuleEnabledForLoggedInUser} from "../../util/helperFunctions";
import {formatCurrency} from "../../helpers/currency_helper";
import CustomerOrderTable from "../Customer/CustomerOrderTable";
import withRouter from "../../components/Common/withRouter";
import TenantDashboard from "../Dashboard/TenantDashboard";

const ArticleGroupDetails = (props) => {
    const {
        articleGroup,
        onGetArticleGroup,
        loading,
        articlesByGroup,
        orders, onGetOrders, errorOrder, orderLoading,
    } = props;

    const history = useNavigate();
    let params = useParams();
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [activeTab, setActiveTab] = useState('1');
    const [brandSettings, setBrandSettings] = useState(null);
    let user = JSON.parse(localStorage.getItem("authUser"));
    const [articleList, setArticleList] = useState([]);
    const [activeDescriptionTab, setDescriptionActiveTab] = useState(1);
    const [selectProduct, setSelectProduct] = useState(null);
    const [modal_standard, setmodal_standard] = useState(false);
    const [products, setProducts] = useState(null);
    const [sortState, setSortState] = useState(null);
    const [getArticlesPending, setGetArticlesPending] = useState(false);
    const [reloadArticles, setReloadArticles] = useState(new Date());
    const [orderType, setOrderType] = useState('all');

    const numericColumns = [];


    useEffect( () => {
        (async () => {
            onGetArticleGroup(params.id);
            onGetProducts();
        })();
        //onGetOrders(orderType, null, null, null, params.id, null, null, null)
    }, [params.id]);

    useEffect(() => {
        if(articleGroup){
            if(articleGroup?.inheritedTabList?.length > 0){
                setDescriptionActiveTab(articleGroup?.inheritedTabList[0]?.id);
            }
        }
    }, [articleGroup])


    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        const tab = JSON.parse(localStorage.getItem("ArticleGroupDetails"));
        if(tab){
            setActiveTab(tab.toString());
        }
    }, [])

    useEffect(() => {
        try {
            if (sortState && articleList) {
                const {column, direction} = sortState;
                setArticleList(orderBy(articleList, o => {
                    if (column === 'description') return o.displayName ? o.displayName?.toLowerCase() : o[column]?.toLowerCase()
                    return numericColumns?.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
                }, [direction]))
            }
        } catch (e) {
            console.error(e);
        }
    }, [sortState])

    const profiletoggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const handleEdit = () => {
        history(`/groups/edit/${articleGroup.id}`)
    }

    const onGetProducts = () => {
        setGetArticlesPending(true);
        get(`/api/article/labelValue`)
            .then(data => {
                setGetArticlesPending(false);
                if (data?.status == 200) {
                    setProducts(data?.data);
                }
            }).catch(err => {
                setGetArticlesPending(false);
            })
    }

    const handleDelete = async () => {
        if(articleGroup?.id){
            let response = await del(`/api/article/group/${articleGroup.id}`)
            history("/groups")
        }

    }

    const toggledescription = (tab) => {
        if (activeDescriptionTab !== tab) {
            setDescriptionActiveTab(tab);
        }
    };

    const getFieldValue = (field) => {
        var result = []
        for(var i = 0; i < articleGroup?.fieldList?.length; i++){
            if(articleGroup?.fieldList[i]?.field?.id === field?.id){
                result.push(articleGroup.fieldList[i]);
            }
        }
        return result;
    }

    const handleDownload = (url) => {
        window.location.href = url;
    };

    function tog_standard() {
        setmodal_standard(!modal_standard);
    }

    const handleAddToGroup = async () => {
        if(selectProduct){
            let response = await put(`/api/article/group/add-article?groupId=${articleGroup.id}&&articleId=${selectProduct?.id}`)
            setSelectProduct(null);
            setReloadArticles(new Date());
            tog_standard();
        }
    }

    const getRangeValue = (fieldValue) => {
        let unit = fieldValue.field?.unit ? fieldValue.field?.unit : "";
        if (fieldValue.minValue && fieldValue.maxValue) {
            return Number(parseFloat(fieldValue.minValue)).toLocaleString('sv') + " " + unit + " - " + Number(parseFloat(fieldValue.maxValue)).toLocaleString('sv') + " " + unit;
        } else if(fieldValue.minValue){
            return Number(parseFloat(fieldValue.minValue)).toLocaleString('sv') + " " + unit;
        }
        return "";
    }

    const handleFilter = (value) => {
        setOrderType(value);
    }

    const openOrderDetails = (order) => {
        let fromUrl = "";
        if(props.from){
            fromUrl = `?from=${props.from}`;
        }
        history(`/orders/${order.DocumentNumber + fromUrl}`)
    }

    const getDatatableData = () => {
        let cols = [
            {
                label: props.t("Document Number"),
                field: "DocumentNumber",
                width: 150,
            },
            {
                label: props.t("Status"),
                field: "status",
                width: 150,
            },
            {
                label: props.t("Total"),
                field: "Total",
                width: 150,
            },
            {
                label: props.t("OrderDate"),
                field: "OrderDate",
                width: 150,
            },
            {
                label: props.t("Download"),
                field: "download",
            }
        ]

        let data = {
            columns: cols,
            rows: orders?.map((order, key) => ({
                status: <div onClick={() => openOrderDetails(order)} className={`badge ${getFilterClassName(order?.status)} p-1`}>{props.t(order.status)}</div>,
                DocumentNumber: <div onClick={() => openOrderDetails(order)}>{order?.DocumentNumber}</div>,
                Total: <div onClick={() => openOrderDetails(order)}>{formatCurrency(order?.Currency, order.Total)}</div>,
                OrderDate: <div onClick={() => openOrderDetails(order)}>{order?.OrderDate}</div>,
                download: <div onClick={() => downloadPDF(order?.DocumentNumber, 'orders')}><i className="fa fa-download font-size-15"></i></div>
            }))

        }
        return data;
    }

    /*useEffect(() => {
        if(orderType){
            onGetOrders(orderType, null, null, null, articleGroup?.id, null, null, null)
        }
    }, [orderType])*/

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        breadcrumbs={[{title: props.t("Article Group"), link: "/groups"}]}
                        title={articleGroup?.name}

                    />
                    <>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                        <TableToolbar
                                            title={articleGroup ? articleGroup.name : props.t("Article Group")}
                                            canEdit={handleEdit}
                                            canDelete={handleDelete}
                                        />
                                    </CardHeader>
                                </Card>
                            </Col>

                            {successMessage && (
                                <Col className="col-12">
                                    <Alert color="success">{successMessage}</Alert>
                                </Col>
                            )}
                            {errorMessage && (
                                <Col className="col-12">
                                    <Alert color="danger">{errorMessage}</Alert>
                                </Col>
                            )}

                            <Loader loading={loading} title="Group Details"/>
                            {!loading && <>
                                <Col xl="12">
                                    <Card className="mb-0">
                                        <Nav tabs className="nav-tabs-custom nav-justified">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '1'})}
                                                    onClick={() => {
                                                        profiletoggle('1');
                                                    }}
                                                >
                                                    <i className="uil uil-users-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Details")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '2'})}
                                                    onClick={() => {
                                                        profiletoggle('2');
                                                    }}
                                                >
                                                    <i className="uil uil-shopping-cart-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Articles")}</span>
                                                </NavLink>
                                            </NavItem>
                                            {hasModuleEnabledForLoggedInUser("OFFERS") &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '3'})}
                                                    onClick={() => {
                                                        profiletoggle('3');
                                                    }}
                                                >
                                                    <i className="uil uil-bolt-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Offers")}</span>
                                                </NavLink>
                                            </NavItem>}
                                            {hasModuleEnabledForLoggedInUser("ORDERS") &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '4'})}
                                                    onClick={() => {
                                                        profiletoggle('4');
                                                    }}
                                                >
                                                    <i className="uil uil-shopping-cart-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Orders")}</span>
                                                </NavLink>
                                            </NavItem>}
                                            {hasModuleEnabledForLoggedInUser("INVOICES") &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '5'})}
                                                    onClick={() => {
                                                        profiletoggle('5');
                                                    }}
                                                >
                                                    <i className="uil uil-invoice font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Invoices")}</span>
                                                </NavLink>
                                            </NavItem>}
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '6'})}
                                                    onClick={() => {
                                                        profiletoggle('6');
                                                    }}
                                                >
                                                    <i className="uil uil-chart font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Statistics")}</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={activeTab} className="p-4">
                                            <TabPane tabId="1">
                                                {activeTab == "1" && <div className="product-desc margin-top-5" style={{'max-width':'1200px'}}>
                                                    <Row>
                                                        <Col xl="6">
                                                            {articleGroup !== null && articleGroup?.articleGroupImages && (
                                                                <ImageGallery
                                                                    items={
                                                                        // images
                                                                        articleGroup?.articleGroupImages?.map((item) => {
                                                                            return {
                                                                                original: BASE_IMAGE_URL + "/" + item.media.s3Key,
                                                                                thumbnail: BASE_IMAGE_URL + "/" + item.media.s3Key,
                                                                            };
                                                                        })
                                                                    }
                                                                />
                                                            )}
                                                        </Col>
                                                        <Col xl="6">
                                                            <div className="text-muted margin-left-10">
                                                                <div className="table-responsive mt-4">
                                                                    <div>
                                                                        <p className="mb-1">{props.t("Name")} :</p>
                                                                        <h5 className="font-size-16">{articleGroup?.name}</h5>
                                                                    </div>

                                                                    {articleGroup?.description && <div className="mt-4">
                                                                        <p className="mb-1">{props.t("Description")} :</p>
                                                                        <h5 className="font-size-16">{articleGroup?.description}</h5>
                                                                    </div>}

                                                                    {articleGroup?.fields?.length > 0 && <p className="mt-4">{props.t("Conditions")} :</p>}

                                                                    {articleGroup?.fields?.map(function (content) {
                                                                        return(
                                                                            <h5 className="font-size-16">{content?.name}</h5>)
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="margin-top-10">
                                                    <Nav tabs className="nav-tabs-custom">
                                                        {articleGroup?.inheritedTabList?.map(function (tab) {
                                                            return (
                                                                <NavItem className="cursor-pointer">
                                                                    <NavLink
                                                                        className={classnames({
                                                                            active:
                                                                                activeDescriptionTab === tab.id,
                                                                        })}
                                                                        onClick={() => {
                                                                            toggledescription(tab.id);
                                                                        }}
                                                                    >
                                                                        {props.t(tab.name)}
                                                                    </NavLink>
                                                                </NavItem>
                                                            );
                                                        })}
                                                    </Nav>
                                                    <TabContent
                                                        activeTab={activeDescriptionTab}
                                                        className="border border-top-0 p-4"
                                                    >
                                                        {articleGroup?.inheritedTabList?.map(function (tab){
                                                            if (tab.categoryType === "FILES") {
                                                                return (<TabPane tabId={tab.id}>
                                                                    {tab.fields?.map(function (field) {
                                                                        return (<div className="col-lg-12 mt-2">
                                                                            {getFieldValue(field).map(fieldValue => <button
                                                                                className="btn btn-primary btn-sm m-2"
                                                                                style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                                                onClick={() => handleDownload(DOWNLOAD_URL + "/" + fieldValue.value)}
                                                                            >
                                                                                {fieldValue?.downloadLabel}
                                                                            </button>)}
                                                                        </div>);
                                                                    })}
                                                                </TabPane>)
                                                            } else if (tab.categoryType === "IMAGES") {
                                                                return (<TabPane tabId={tab.id}>
                                                                    <div className="row">
                                                                        {tab.fields?.map(function (field) {
                                                                            return (<>
                                                                                {getFieldValue(field).map(fieldValue => <div className="col-lg-2">
                                                                                    <img className="certificate-thumbnail height-150" src={DOWNLOAD_URL + "/" + fieldValue.value}/>
                                                                                </div>)}
                                                                            </>);
                                                                        })}
                                                                    </div>
                                                                </TabPane>)
                                                            } else if (tab.categoryType === "FREE_TEXT") {
                                                                return (<TabPane tabId={tab.id}>
                                                                    <div className="row">
                                                                        {tab.fields?.map(function (field) {
                                                                            return (<>
                                                                                {getFieldValue(field).map(fieldValue => fieldValue.field.fieldType === 'FREE_TEXT' &&
                                                                                    <div className="col-lg-12" dangerouslySetInnerHTML={{__html: fieldValue.value}}></div>)}
                                                                            </>);
                                                                        })}
                                                                    </div>
                                                                </TabPane>)
                                                            } else {
                                                                return (<TabPane tabId={tab.id}>
                                                                    <table className="table table-borderless table-hover">
                                                                        <tbody>
                                                                        {tab.fields?.map(function (field) {
                                                                            return (<>
                                                                                {getFieldValue(field).map((fieldValue) => (

                                                                                    <>{fieldValue?.value && <> {fieldValue?.field?.fieldType === "FILE" ?
                                                                                        (<tr>
                                                                                            <td colSpan={2}>
                                                                                                <p>
                                                                                                    <a
                                                                                                        className={"btn btn-primary btn-sm"}
                                                                                                        href={DOWNLOAD_URL + "/" + fieldValue.value}
                                                                                                    >
                                                                                                        {fieldValue.name}
                                                                                                    </a>
                                                                                                </p>
                                                                                            </td>
                                                                                        </tr>)
                                                                                        : fieldValue?.field?.fieldType === "HEADER" ? (
                                                                                            <tr>
                                                                                                <td colSpan={2}>
                                                                                                    <h4> {fieldValue.field?.name} </h4>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ):fieldValue?.field?.fieldType === "BOOLEAN" ? (
                                                                                            <tr>
                                                                                                <td>{fieldValue.field?.name}</td>
                                                                                                <td>
                                                                                                    {fieldValue?.value == "true" && <i className="uil uil-check-circle font-size-18" style={{color:"green"}}></i> }
                                                                                                    {fieldValue?.value == "false" && <i className="uil uil-times-circle font-size-18" style={{color:"red"}}></i> }
                                                                                                    {fieldValue?.value == null && props.t("Not applicable")}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ):fieldValue?.field?.fieldType == "DROPDOWN_MULTISELECT" ? (
                                                                                            <tr>
                                                                                                <td>{fieldValue.field?.name}</td>
                                                                                                <td>
                                                                                                    {fieldValue?.value.split(",").map((value) => <><span className="bg-primary badge bg-secondary multi-select-label">{value}</span></>)}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ):fieldValue?.field?.fieldType == "NUMBER_FIELD" ? (
                                                                                            <tr>
                                                                                                <td>{fieldValue.field?.name}</td>
                                                                                                <td>
                                                                                                    <span>
                                                                                                        {getRangeValue(fieldValue)}
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ):(<tr>
                                                                                            <td className="min-width-200">
                                                                                                {fieldValue?.field?.name} {fieldValue?.field?.contentTypes}
                                                                                            </td>
                                                                                            <td>
                                                                                                  <span
                                                                                                      dangerouslySetInnerHTML={{
                                                                                                          __html: fieldValue.value + " " + (fieldValue.field?.unit ? fieldValue.field?.unit : ""),
                                                                                                      }}
                                                                                                  ></span>
                                                                                            </td>
                                                                                        </tr>)}</>}
                                                                                    </>
                                                                                ))}
                                                                            </>);
                                                                        })}
                                                                        </tbody>
                                                                    </table>
                                                                </TabPane>)
                                                            }
                                                        })}
                                                    </TabContent>
                                                    </Row>
                                                </div>}
                                            </TabPane>
                                            <TabPane tabId="2">
                                                {activeTab == "2" && <><Row className="mb-3">
                                                    <Col className="col-12">
                                                        <div className="d-flex justify-content-end">
                                                            <Button
                                                                style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                                className="btn btn-primary waves-effect waves-light mx-1 float-end"
                                                                onClick={tog_standard}
                                                            >{props.t("Add Article")}
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <CardBody>
                                                    {articleGroup?.id && <ArticleListTable key={reloadArticles} customFilter={{articleGroup: articleGroup}} columns={[
                                                        {
                                                            label: props.t("Name"),
                                                            field: "description",
                                                            width: 300,
                                                        },
                                                        {
                                                            label: props.t("Article Number"),
                                                            field: "articleNumber",
                                                            width: 100,
                                                        },
                                                        {
                                                            label: props.t("Category"),
                                                            field: "category",
                                                            width: 200,
                                                        },
                                                        {
                                                            label: props.t("Disposable Quantity"),
                                                            field: "disposableQuantity",
                                                            width: 100,
                                                        },
                                                        {
                                                            label: props.t("Sales Price"),
                                                            field: "salesPrice",
                                                            width: 100
                                                        },
                                                        {
                                                            label: props.t("Published"),
                                                            field: "publish",
                                                            width: 200,
                                                        },
                                                        {
                                                            label: props.t("Action"),
                                                            field: "groupAction",
                                                            width: 100
                                                        }
                                                    ]}/>}
                                                </CardBody></>}
                                            </TabPane>
                                            <TabPane tabId="3">
                                                {activeTab == "3" && <CustomerOfferTable articleGroupId={articleGroup?.id}/>}
                                            </TabPane>
                                            <TabPane tabId="4">
                                                {activeTab == "4" && <CustomerOrderTable articleGroupId={articleGroup?.id} from={encodeURIComponent(window.location.pathname)}/>}
                                                {/*<Row>
                                                    {(errorOrder) &&
                                                        <Alert className={"mt-4"} color="danger" role="alert">
                                                            {props.t(API_ERR_MSG)}
                                                        </Alert>
                                                    }
                                                    <Row className="mb-3">
                                                        <Col className="col-12">
                                                            <div className="d-flex justify-content-end">
                                                                <ButtonGroup
                                                                    filter={{
                                                                        selected: orderType,
                                                                        options : [
                                                                            {name: props.t("All"), value:"all"},
                                                                            {name: props.t("Ongoing"), value:"invoicenotcreated"},
                                                                            {name: props.t("Invoiced"), value:"invoicecreated"},
                                                                            {name: props.t("Delayed"), value: "expired"},
                                                                            {name: props.t("Cancelled"), value: "cancelled"}
                                                                        ],
                                                                        onSelect: handleFilter
                                                                    }}
                                                                    brandSettings={brandSettings}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Loader loading={orderLoading} title="Orders" />

                                                    {!(orderLoading) &&
                                                        <Row>
                                                            <Col className="col-12 article-table">
                                                                <MDBDataTable
                                                                    responsive
                                                                    hover
                                                                    barReverse={true}
                                                                    borderless
                                                                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                                                                    entriesLabel={props.t("Show entries")}
                                                                    infoLabel={[props.t('Showing'), props.t('to'), props.t('of'), props.t('entries')]}
                                                                    searchLabel={props.t("Search") + "..."}
                                                                    noRecordsFoundLabel={props.t("No matching records found")}
                                                                    noBottomColumns={true}
                                                                    data={getDatatableData()}
                                                                    entries={50}
                                                                    entriesOptions={[50, 100, 500]}
                                                                    onSearch={(text) => console.log(text)}
                                                                    sortRows={[]}
                                                                    onSort={({column, direction}) => {
                                                                        if(sortState && sortState.column == column){
                                                                            setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                                        } else {
                                                                            setSortState({column, direction});
                                                                        }
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>}
                                                </Row>*/}
                                            </TabPane>
                                            <TabPane tabId="5">
                                                {activeTab == "5" && <CustomerInvoiceTable articleGroupId={articleGroup?.id} />}
                                            </TabPane>
                                            <TabPane tabId="6">
                                                {activeTab == "6" && <TenantDashboard  articleGroupId={articleGroup?.id}/>}
                                            </TabPane>
                                        </TabContent>
                                    </Card>
                                </Col></>}
                        </Row>
                    </>
                </Container>
            </div>

            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Add Article to Group")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label={props.t("Close")}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                        >
                            {props.t("Select article")}
                        </label>
                        <div className="col-md-9">
                            <SearchableDropdown
                                selectedValue={selectProduct}
                                options={products?.filter(p => !find(articlesByGroup, {id: p.id}))}
                                placeholder={getArticlesPending ? props.t("Loading articles..") : props.t("Select Article Group")}
                                handleOnOptionClick={(et) => setSelectProduct(et)}
                                disabled={getArticlesPending}
                                keyToDisplay={["label"]}
                            />
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={tog_standard}
                        className="col-md-2 btn btn-secondary"
                        data-dismiss="modal"
                    >
                        {props.t("Close")}
                    </button>

                    <Button
                        color="primary"
                        onClick={handleAddToGroup}
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>
        </React.Fragment>
    );
}


ArticleGroupDetails.propTypes = {
    articleGroup: PropTypes.object,
    onGetArticleGroup: PropTypes.func,
    articlesByGroup: PropTypes.object
}

const mapStateToProps = (state) => {
    return {
        articleGroup: state.ecommerce.articleGroup,
        error: state.ecommerce.articleGroupError,
        loading: checkIfLoading(state, GET_ARTICLE_GROUP),
        articlesByGroup: state.ecommerce.products.data,
        /*orders: state.ecommerce?.orders?.data?.Orders,
        orderLoading: checkIfLoading(state, ecommerceActionTypes.GET_ORDERS),
        errorOrder: state.ecommerce.getOrdersError,*/
    }
}

const mapDispatchToProps = (dispatch) => ({
    onGetArticleGroup: (id) => dispatch(getArticleGroup(id)),
    onGetArticleGroupReset: () => dispatch(getArticleGroupReset()),
    onGetProductsByGroup:(id, page, pageSize, search)  => dispatch(getProductByGroup(id, page, pageSize, search, true)),
    onGetProducts: (page, pageSize, search, filter, customFilter) => dispatch(getProducts(page, pageSize, true, search, filter, customFilter)),
    //onGetOrders: (orderType, customerId, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference) => dispatch(getOrders(orderType, customerId, articleNumber, null, null, projectNumber, articleGroupId, manufacturer, ourReference, yourReference)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ArticleGroupDetails)));

import React, {useEffect, useState} from "react";
import {Alert, Card, CardBody, CardHeader, Col, Container, Row, Table} from "reactstrap";
import {useNavigate, useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import queryString from "query-string";
import PropTypes from "prop-types";
import {getInvoiceDetail, getInvoiceDetailReset, getInvoices} from "../../store/actions";
import {connect} from "react-redux";

// import images
import logo from "../../assets/images/lightab_logo.svg"
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import TableToolbar from "../../components/Common/TableToolbar";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import Loader from "../../components/Common/Loader";
import {downloadPDF} from "../../util/helperFunctions";
import {get} from "../../helpers/api_helper";
import {formatCurrency} from "../../helpers/currency_helper";
import withRouter from "../../components/Common/withRouter";

const InvoiceDetail = (props) => {
    const {invoice, onGetInvoiceDetail, invoiceDetailError, invoiceDetailLoading, onGetInvoiceDetailReset, invoices, onGetInvoices} = props
    const [invoiceDetail, setInvoiceDetail] = useState(null)
    const history = useNavigate();
    const user = JSON.parse(localStorage.getItem("authUser"));
    const [brandSettings, setBrandSettings] = useState(null);
    const [showDiscountColumn, setShowDiscountColumn] = useState(false);
    const [invoiceCancelled, setInvoiceCancelled] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [wayOfDelivery, setWayOfDelivery] = useState(null);
    const [yourReference, setYourReference] = useState(null);
    const [ourReference, setOurReference] = useState(null);
    const [termsOfDelivery, setTermsOfDelivery] = useState(null);
    const [termsOfPayment, setTermsOfPayment] = useState(null);
    let params = useParams();

    useEffect(() => {
        if (params && params.id) {
            onGetInvoiceDetail(params.id);
        }
        setBrandSettings(user?.brandConfig);
        onGetInvoices('all');
        return () => {
            onGetInvoiceDetailReset()
        }
    }, [])

    useEffect(() => {
        if (invoiceDetailError) {
            setTimeout(() => {
                onGetInvoiceDetailReset()
            }, 5000);
        }
    }, [invoiceDetailError])

    useEffect(() => {
        if (!_.isEmpty(invoice)) setInvoiceDetail(invoice)
    }, [invoice])

    useEffect(() => {
        if (invoiceDetail && invoiceDetail.WayOfDelivery) {
            getWayOfDeliveryDescription(invoiceDetail.WayOfDelivery);
        }
        if (invoiceDetail && invoiceDetail.TermsOfDelivery) {
            getTermsOfDeliveryDescription(invoiceDetail.TermsOfDelivery);
        }
        if (invoiceDetail && invoiceDetail.TermsOfPayment) {
            getTermsOfPaymentDescription(invoiceDetail.TermsOfPayment);
        }
        setUserReferenceDetails(invoiceDetail?.YourReference, true);
        setUserReferenceDetails(invoiceDetail?.OurReference, false);
        setCustomerDetails(invoiceDetail?.CustomerNumber);
        setShowDiscountColumn(invoiceDetail?.InvoiceRows.filter(i => i.Discount > 0).length > 0);
        setInvoiceCancelled(invoiceDetail?.Cancelled);
    }, [invoiceDetail]);

    //Print the Invoice
    const printInvoice = () => {
        window.print()
    }

    const handleBack = () => {
        history(-1)
    }

    const getBrandLogo = () => {
        if (user.brandConfig && user.brandConfig.brandLogo) {
            return BASE_IMAGE_URL + "/" + user.brandConfig.brandLogo;
        }
        return logo;
    }

    const getOrderDetailsLink = () => {
        if (user.customerId) {
            return `/customer/orders/${invoiceDetail?.OrderReference}`
        } else {
            return `/orders/${invoiceDetail?.OrderReference}`
        }
    }

    const getLink = () => {
        let queryParams = queryString.parse(props?.location?.search);
        let link = "/invoice";
        if (queryParams && queryParams.from) {
            link = queryParams.from
        } else if (user?.customerId) {
            link = '/customer/invoices';
        }
        return link;
    }

    const setCustomerDetails = async (customerNumber) => {
        if (!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN")) {
            const response = await get(`/api/customers/byCustomerNumber/${customerNumber}`);
            if (response?.status === 200 && response.data?.id) {
                setCustomer(response.data)
            }
        }
    }

    const getWayOfDeliveryDescription = async (code) => {
        const response = await get(`/api/orders/wayofdeliveries/${code}`);
        if (response?.status === 200) {
            setWayOfDelivery(response.data.WayOfDelivery.Description)
        }
    }

    const getTermsOfDeliveryDescription = async (code) => {
        const response = await get(`/api/orders/termsofdeliveries/${code}`);
        if (response?.status === 200) {
            setTermsOfDelivery(response.data.TermsOfDelivery.Description)
        }
    }

    const getTermsOfPaymentDescription = async (code) => {
        const response = await get(`/api/orders/termsofpayments/${code}`);
        if (response?.status === 200) {
            setTermsOfPayment(response.data.TermsOfPayment.Description)
        }
    }

    const setUserReferenceDetails = async (name, isCustomerUser) => {
        if (name && ((isCustomerUser && user?.roles?.includes("ROLE_CUSTOMER_ADMIN")) || !user?.customerId)) {
            const response = await get(`/api/user/byName/${name}?isCustomerUser=${isCustomerUser}`);
            if (response?.status === 200 && response.data?.id) {
                if (isCustomerUser) {
                    setYourReference(response.data)
                } else {
                    setOurReference(response.data)
                }
            }
        }
    }

    const redirectToOrder = () => {
        history((user?.customerId ? "/customer/orders" : "/orders") + `/${invoiceDetail?.OrderReference}`)
    }

    const redirectToOffer = () => {
        history((user?.customerId ? "/customer/offers" : "/offers") + `/${invoiceDetail?.OfferReference}/types/all`)
    }

    const OfferLabels = !_.isEmpty(invoiceDetail) &&
        <div className="d-flex justify-content-start">
            <div className="d-flex flex-column">
                <div className="d-flex">
                    {invoiceDetail.WarehouseReady && <div className="badge bg-success bg-light-success p-2">{props.t("Sent")}</div>}
                    {invoiceCancelled && <div className="badge bg-danger bg-light-danger p-2">{props.t("Cancelled")}</div>}
                    {invoiceDetail.OfferReference > 0 &&
                        <div onClick={() => redirectToOffer()}>
                            <div className={`badge bg-primary bg-light-primary ms-2 p-2 cursor-pointer`}>
                                {props.t("Offer")}{" - " + invoiceDetail?.OfferReference}
                            </div>
                        </div>
                    }
                    {invoiceDetail?.status &&
                        <>
                            {invoiceDetail?.status === 'UN_SENT' && <span><div className="badge bg-dark bg-light-dark ms-2 p-2">{props.t("Unsent")}</div></span>}
                            {invoiceDetail?.status === 'PAID' && <span><div className="badge bg-success bg-light-success ms-2 p-2">{props.t("Paid")}</div></span>}
                            {invoiceDetail?.status === 'PARTIAL' && <span><div className="badge bg-warning bg-light-warning ms-2 p-2">{props.t("Partial")}</div></span>}
                            {invoiceDetail?.status === 'SENT' && <span><div className="badge bg-info bg-light-info ms-2 p-2">{props.t("Sent")}</div></span>}
                            {invoiceDetail?.status === 'OVERDUE' && <span><div className="badge bg-danger bg-light-danger ms-2 p-2">{props.t("Overdue")}</div></span>}
                            {invoiceDetail?.status === 'CANCELLED' && <span><div className="badge bg-danger bg-light-danger ms-2 p-2">{props.t("Cancelled")}</div></span>}
                        </>
                    }
                    {invoiceDetail.OrderReference > 0 &&
                        <div onClick={() => redirectToOrder()}>
                            <div className={`badge bg-warning bg-light-warning ms-2 p-2 ${!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN") ? "cursor-pointer" : ""}`}>
                                {props.t("Order")}{!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN") ? " - " + invoiceDetail?.OrderReference : ""}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>

    const handleInvoiceChange = (invoice) => {
        window.location.href = `/invoice/${invoice?.DocumentNumber}`;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        title={props.t("Invoice Details")}
                        breadcrumbs={[{title: props.t("Invoices"), link: getLink()}]}
                        backBtn={handleBack}
                    />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={props.t("Invoice Details")}
                                        buttons={[{
                                            text: props.t("Download"),
                                            onClick: () => downloadPDF(params.id, 'invoices'),
                                        }]}
                                        titleDropdown={{
                                            isSearchableDropdown: true,
                                            value: invoiceDetail,
                                            options: invoices,
                                            placeholder: props.t("Select Invoice"),
                                            isHeader: true,
                                            prefix: props.t("Invoice") + " - ",
                                            handleSelect: (op) => {
                                                handleInvoiceChange(op);
                                            },
                                            keyToDisplay: "DocumentNumber",
                                        }}
                                        labels={OfferLabels}
                                    />
                                </CardHeader>
                                <Loader loading={invoiceDetailLoading} title="Invoice Details"/>
                                {!invoiceDetailLoading &&
                                    <CardBody>
                                        {invoiceDetailError &&
                                            <Alert color="danger" role="alert">
                                                {invoiceDetailError}
                                            </Alert>}
                                        {!_.isEmpty(invoiceDetail) && <>
                                            <Row>
                                                <Col md="6">
                                                </Col>
                                                <Col md="6">
                                                    <div className="d-flex justify-content-end">
                                                        <div className="d-flex flex-column">
                                                            <div className="d-flex">
                                                                <p className="p-0 m-0" style={{width: "140px"}}>{props.t("Invoice date")}</p>
                                                                <p className="p-0 m-0">{new Date(invoiceDetail?.InvoiceDate).toLocaleDateString()}</p>
                                                            </div>
                                                            <div className="d-flex">
                                                                <p className="p-0 m-0" style={{width: "140px"}}>{props.t("Invoice no")}</p>
                                                                <p className="p-0 m-0">{invoiceDetail?.DocumentNumber}</p>
                                                            </div>
                                                            <div className="d-flex">
                                                                <p className="p-0 m-0" style={{width: "140px"}}>{props.t("OCR")}</p>
                                                                <p className="p-0 m-0">{invoiceDetail?.OCR}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col md={6}>
                                                    <p className="m-0 p-0">{props.t("Delivery Address")}</p>
                                                    <p className="m-0 p-0">{invoiceDetail?.DeliveryName}</p>
                                                    <p className="m-0 p-0">{invoiceDetail?.DeliveryAddress1}</p>
                                                    <p className="m-0 p-0">{invoiceDetail?.DeliveryAddress2}</p>
                                                    <p className="m-0 p-0">{invoiceDetail?.DeliveryZipCode + " " + invoiceDetail?.DeliveryCity}</p>
                                                    <p className="m-0 p-0">{invoiceDetail?.DeliveryCountry}</p>
                                                </Col>
                                                <Col md={6}>
                                                    <div
                                                        className={`p-0 m-0 ${!user?.customerId && customer ? "cursor-pointer" : ""}`}
                                                        style={{color: !user?.customerId && customer ? "#0404bd" : "black"}}
                                                        onClick={() => {
                                                            if (customer && !user?.customerId) {
                                                                history(`/customers/${customer.customerNumber}/profile`)
                                                            }
                                                        }}>
                                                        {invoiceDetail?.CustomerName}
                                                    </div>
                                                    <p className="m-0 p-0" style={{color: "black"}}>{invoiceDetail?.Address1}</p>
                                                    <p className="m-0 p-0" style={{color: "black"}}>{invoiceDetail?.Address2}</p>
                                                    <p className="m-0 p-0" style={{color: "black"}}>{invoiceDetail?.ZipCode + " " + invoiceDetail?.City}</p>
                                                    <p className="m-0 p-0" style={{color: "black"}}>{invoiceDetail?.Country}</p>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col md={6}>
                                                    <div className="d-flex">
                                                        <div className="d-flex flex-column">
                                                            <div className="d-flex">
                                                                <div className="d-flex">
                                                                    <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Customer number")}</p>
                                                                    <div
                                                                        className={`p-0 m-0 ${!user?.customerId && customer ? "cursor-pointer" : ""}`}
                                                                        style={{color: `${!user?.customerId && customer ? "#0404bd" : ""}`}}
                                                                        onClick={() => {
                                                                            if (customer && !user?.customerId) {
                                                                                history(`/customers/${customer.customerNumber}/profile`)
                                                                            }
                                                                        }}>
                                                                        {invoiceDetail?.CustomerNumber}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {invoiceDetail?.YourReference &&
                                                                <div className="d-flex">
                                                                    <div className="d-flex">
                                                                        <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Your reference")}</p>
                                                                        <div
                                                                            className={`p-0 m-0 ${yourReference && customer && (!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN")) ? "cursor-pointer" : ""}`}
                                                                            style={{color: `${yourReference && customer && (!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN")) ? "#0404bd" : ""}`}}
                                                                            onClick={() => {
                                                                                if (yourReference && customer && (!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN"))) {
                                                                                    history(`/customers/${customer?.id}/users/${yourReference.id}`)
                                                                                }
                                                                            }}>
                                                                            {invoiceDetail?.YourReference}
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            {invoiceDetail.YourOrderNumber &&
                                                                <div className="d-flex">
                                                                    <div className="d-flex">
                                                                        <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Your order number")}</p>
                                                                        <p className="p-0 m-0">{invoiceDetail?.YourOrderNumber}</p>
                                                                    </div>
                                                                </div>}
                                                            {invoiceDetail.TermsOfDelivery &&
                                                                <div className="d-flex">
                                                                    <div className="d-flex">
                                                                        <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Delivery conditions")}</p>
                                                                        <p className="p-0 m-0">{props.t(termsOfDelivery)}</p>
                                                                    </div>
                                                                </div>}
                                                            {invoiceDetail.WayOfDelivery &&
                                                                <div className="d-flex">
                                                                    <div className="d-flex">
                                                                        <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Delivery Service")}</p>
                                                                        <p className="p-0 m-0">{wayOfDelivery}</p>
                                                                    </div>
                                                                </div>}
                                                            {invoiceDetail?.Remarks &&
                                                                <div className="d-flex">
                                                                    <div className="d-flex">
                                                                        <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Remark")}</p>
                                                                        {/*<p className="p-0 m-0">{invoiceDetail?.Remarks}</p>*/}
                                                                        <p className="p-0 m-0">
                                                                            {invoiceDetail?.Remarks?.split('\n').map((line, index) => (
                                                                                <React.Fragment key={index}>
                                                                                    {line}
                                                                                    <br/>
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </p>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="d-flex">
                                                        <div className="d-flex flex-column">
                                                            {invoiceDetail?.OurReference &&
                                                                <div className="d-flex">
                                                                    <div className="d-flex">
                                                                        <p className="p-0 m-0" style={{width: "150px"}}>{props.t("Our reference")}</p>
                                                                        <div
                                                                            className={`p-0 m-0 ${user?.customerId || !ourReference ? "" : "cursor-pointer"}`}
                                                                            style={{color: `${user?.customerId || !ourReference ? "" : "#0404bd"}`}}
                                                                            onClick={() => {
                                                                                if (ourReference) {
                                                                                    history(`/settings/users/${ourReference.id}`)
                                                                                }
                                                                            }}>
                                                                            {invoiceDetail?.OurReference}
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            {invoiceDetail?.TermsOfPayment &&
                                                                <div className="d-flex">
                                                                    <div className="d-flex">
                                                                        <p className="p-0 m-0" style={{width: "150px"}}>{props.t("Payment terms")}</p>
                                                                        <p className="p-0 m-0">{props.t(termsOfPayment)}</p>
                                                                    </div>
                                                                </div>}
                                                            <div className="d-flex">
                                                                <div className="d-flex">
                                                                    <p className="p-0 m-0" style={{width: "150px"}}>{props.t("Due date")}</p>
                                                                    <p className="p-0 m-0">{new Date(invoiceDetail?.DueDate).toLocaleDateString()}</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="d-flex">
                                                                    <p className="p-0 m-0" style={{width: "150px"}}>{props.t("Delivery date")}</p>
                                                                    <p className="p-0 m-0">{new Date(invoiceDetail?.DeliveryDate).toLocaleDateString()}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="py-2 mt-4">
                                                <h5 className="font-size-15">{props.t("Order summary")}</h5>
                                                <div className="table-responsive">
                                                    <Table className="table-nowrap table-centered mb-0">
                                                        <thead>
                                                        <tr>
                                                            <th style={{width: "70px"}}>{props.t("Article Number")}</th>
                                                            <th>{props.t("Item")}</th>
                                                            <th>{props.t("Quantity")}</th>
                                                            <th>{props.t("Price")}</th>
                                                            {showDiscountColumn && <th>{props.t("Discount")}</th>}
                                                            <th className="text-end" style={{width: "120px"}}>{props.t("Total")}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {_.map(
                                                            invoiceDetail?.InvoiceRows,
                                                            (item, key) => (
                                                                <tr key={key}>
                                                                    <td>{item.ArticleNumber}</td>
                                                                    <td>
                                                                        <h5 className="font-size-15 mb-1">{item.Description}</h5>
                                                                    </td>
                                                                    <td>{item.DeliveredQuantity != 0 ? item.DeliveredQuantity : ""}</td>
                                                                    <td>{item.Price != 0 ? formatCurrency(invoiceDetail?.Currency, item.Price) : ""}</td>
                                                                    {showDiscountColumn && <td>{item.Discount != 0 ? item.Discount + "%" : ""}</td>}
                                                                    <td className="text-end">{item.Total != 0 ? formatCurrency(invoiceDetail?.Currency, item.Total) : ""}</td>
                                                                </tr>
                                                            )
                                                        )}
                                                        <tr>
                                                            <th colSpan="4" className="text-end p-0">{props.t("Shipping")}</th>
                                                            <td className="text-end p-0">{formatCurrency(invoiceDetail?.Currency, invoiceDetail?.Freight)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan="4" className="text-end p-0">{props.t("Sub Total")}</th>
                                                            <td className="text-end p-0">{formatCurrency(invoiceDetail?.Currency, invoiceDetail?.Net + invoiceDetail?.Freight)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan="4" className="border-0 text-end p-0">
                                                                {props.t("Tax")}</th>
                                                            <td className="border-0 text-end p-0">{formatCurrency(invoiceDetail?.Currency, invoiceDetail?.TotalVAT)}</td>
                                                        </tr>
                                                        {invoiceDetail?.RoundOff != 0 &&
                                                            <tr>
                                                                <th colSpan="4" className="border-0 text-end p-0">
                                                                    {props.t("RoundOff")}</th>
                                                                <td className="border-0 text-end p-0">{formatCurrency(invoiceDetail?.Currency, invoiceDetail?.RoundOff)}</td>
                                                            </tr>}
                                                        <tr>
                                                            <th colSpan="4" className="border-0 text-end p-0"><h4 className="m-0 p-0">{props.t("Total")}</h4></th>
                                                            <td className="border-0 text-end p-0"><h4 className="m-0 p-0">{formatCurrency(invoiceDetail?.Currency, invoiceDetail?.TotalToPay)}</h4></td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </>}
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
};

InvoiceDetail.propTypes = {
    invoiceDetail: PropTypes.object,
    invoiceDetailError: PropTypes.object,
    onGetInvoiceDetail: PropTypes.func,
    onGetInvoiceDetailReset: PropTypes.func,
    invoices: PropTypes.array,
    onGetInvoices: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        invoice: state.invoices.invoiceDetail,
        invoiceDetailError: state.invoices.invoiceDetailError,
        invoiceDetailLoading: state.invoices.invoiceDetailLoading,
        invoices: state.invoices.invoices?.Invoices,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetInvoices: (invoiceType) => dispatch(getInvoices(invoiceType)),
    onGetInvoiceDetail: (invoiceId) => dispatch(getInvoiceDetail(invoiceId)),
    onGetInvoiceDetailReset: () => dispatch(getInvoiceDetailReset())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(InvoiceDetail)));

import axios from "axios";
import {del, get, getWithRequestParams, post, postMultipart, put} from "./api_helper";
import * as url from "./url_helper";
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = (data) => post(url.POST_FAKE_REGISTER, data)

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPassword
const postForgetPassword = (data, domain) => post(`${url.POST_FORGET_PASSWORD}?emailId=${data}&domain=${domain}`)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
      .post(url, data)
      .then(response => {
        if (response.status >= 200 || response.status <= 299) return response.data
        throw response.data
      })
      .catch(err => {
        let message
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 404:
              message = "Sorry! the page you are looking for could not be found"
              break
            case 500:
              message =
                  "Sorry! something went wrong, please contact our support team"
              break
            case 401:
              message = "Invalid credentials"
              break
            default:
              message = err[1]
              break
          }
        }
        throw message
      })
}

// Login Method
const postJwtLogin = data => post(url.POST_JWT_LOGIN, data)

export const getBranding = (domain) => get(`${url.GET_LOGIN_BRANDING}?domain=${domain}`);

export const getCurrencyList = () => get(`${url.GET_CURRENCY}`)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
export const getProducts = (page, pagesize, search, filter, customFilter, tenantId, customerId, sortColumn, sortDirection) => getWithRequestParams(`${url.GET_PRODUCTS}`,
    {
      page, size: pagesize, search, type: filter, articleGroupId: customFilter?.articleGroup?.id,
      categoryId: customFilter?.category?.id, manufacturer: customFilter?.manufacturer, tenantId, customerId, sortColumn, sortDirection
    }
)

export const getFavoriteProducts = (productListId, page, pagesize, search) => get(`${url.GET_FAVORITE_PRODUCTS}/articles?page=${page}&size=${pagesize}&search=${search}${productListId ? '&productListId=' + productListId : ''}`)

export const getProductByCategory = (id, page, pageSize, search) => get(`${url.GET_PRODUCTS_BY_CATEGORY}?page=${page}&size=${pageSize}&categoryId=${id}${search ? `&search=${search}`: ''}`)

export const getProductByGroup = (id, page, pageSize, search, isAll) => get(`${url.GET_ALL_PRODUCTS_BY_GROUP}?articleGroupId=${id}&page=${page}&size=${pageSize}${search ? `&search=${search}`: ''}`)

export const getContentTypes = () => get(`${url.GET_CONTENT_TYPES}`)

export const getFields = (type) => get(`${url.GET_FIELDS}?type=${type}`)

export const getContentTypesForArticles = (id) => get(`${url.GET_CONTENT_TYPES_FOR_ARTICLE}/${id}`)

export const getContentTypesByLayout = categoryId => get(`${url.GET_CONTENT_TYPES}/${categoryId}`)

export const getLayout = () => get(`${url.GET_LAYOUT}`)

export const getLayoutById = (id) => get(`${url.GET_LAYOUT}/${id}`);

export const getDefaultLayout = (articleId) => get(`${url.GET_DEFAULT_LAYOUT}/${articleId}`)

// get Product detail
export const getProductDetail = id =>
    get(`${url.GET_PRODUCTS_DETAIL}/${id}`)

export const getProductDetailsShallow = id => get(`${url.GET_PRODUCTS_DETAIL}/${id}?sync=false`)
export const getSubCategory = id => get(`${url.GET_CATEGORY_LIST}/${id}`)

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
    del(url.DELETE_EVENT, {headers: {event}})

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
    get(`${url.GET_MESSAGES}/${roomId}`, {params: {roomId}})

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = (type, customerId, articleNumber, startDate, endDate, projectNumber, articleGroupId, manufacturer, ourReference, yourReference)=> getWithRequestParams(`${url.GET_ORDERS}`, {type, customerId, articleNumber, startDate, endDate, projectNumber, articleGroupId, manufacturer, ourReference, yourReference})

// post order
export const postOrder = (data) => post(url.GET_ORDERS, data)

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// get shops
export const getShops = () => get(url.GET_SHOPS)


// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = (type, customerId, startDate, endDate, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference) => {
  return getWithRequestParams(url.GET_INVOICES, {type, customerId, startDate, endDate, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference})
}

// get invoice details
export const getInvoiceDetail = ({invoiceId}) =>
    get(url.GET_CUSTOMER_INVOICE_DETAIL(invoiceId))

export const getOffers = (offerType, customerId, startDate, endDate, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference) => {
  return getWithRequestParams(url.GET_OFFERS, {offerType, customerId, startDate, endDate, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference})
}

export const getInvoiceMetricsAPI = (startDate, endDate) => get(`${url.GET_INVOICE_METRICS(startDate, endDate)}`)

export const addOffer = () => {
  return post(url.GET_OFFERS)
}

export const getOffersDetails = (offerId) => {
  return get(`${url.GET_OFFERS}/details/${offerId}`)
}

export const getOfferMetrics = (startDate, endDate) => {
  return get (`${url.GET_OFFERS}/metrics?startDate=${startDate}&endDate=${endDate}`)
}

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
    get(`${url.GET_PROJECT_DETAIL}/${id}`, {params: {id}})

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get contacts
export const getUsers = () => get(url.GET_USERS)

export const getUserProfile = () => get(url.GET_USER_PROFILE)

export const getTenantAdmins = (tenantId, filter) => get(`${url.GET_TENANT_ADMINS}/${tenantId}${filter ? '?filter=' + filter : ''}`)

export const getRegenerateToken = userId => get(`${url.REGENERATE_TOKEN}${userId}`)

export const activeUser = userId => get(`${url.ACTIVATE_USER}/${userId}`)

export const getUser = userId => get(`${url.GET_USER}${userId}`)

export const deleteUser = userId => del(`${url.DELETE_USER}${userId}`)

export const getUsersForImpersonation = data => {
  return post(`${url.GET_USER_IMPERSONATION}`, data)
}

export const postImpersonateUser = data => post(url.POST_IMPERSONATE_USER, data)

export const getImpersonateUserExit = () => get(url.GET_IMPERSONATE_USER_EXIT)

export const getAudit = (filter) => filter ? getWithRequestParams(url.GET_AUDIT, filter) : get(url.GET_AUDIT)

export const getLabels = () => get(`${url.GET_LABELS}`)

export const tenantAdminGetUser = (filter) => get(`${url.TENANT_ADMIN_GET_USERS}${filter ? '?filter=' + filter : ''}`)

export const tenantCustomerGetUser = (filter, page, pageSize, search, sortColumn, sortDirection) => {
  sortColumn = sortColumn ? `&sortColumn=${sortColumn}` : '';
  sortDirection = sortDirection ? `&sortDirection=${sortDirection}` : '';
  return post(`${url.TENANT_CUSTOMER_GET_USERS}?search=${search}${filter ? '&filter=' + filter : ''}${page && pageSize ? `&page=${page - 1}&pageSize=${pageSize}` : ''}${sortColumn}${sortDirection}`,
      {
        roles: [
          "ROLE_CUSTOMER_ADMIN",
          "ROLE_CUSTOMER_USER",
          "ROLE_CONTACT_USER"
        ]
      }
  )
};

export const getSystemConfig = () => get(url.GET_SYSTEM_CONFIG)

export const getTenantConfig = () => get(url.GET_TENANT_CONFIG)

export const getSMTPConfig = () => get(url.GET_SMTP_CONFIG)

export const getArticleConfig = () => get(url.GET_ARTICLE_CONFIG)

export const getProjectConfig = () => get(url.GET_PROJECT_CONFIG)

export const getStockPointConfig = () => get(url.GET_STOCK_POINT_CONFIG)

export const getStockPoints = () => get(url.GET_STOCK_POINTS)

export const getCronLogs = () => get(url.GET_CRON_LIST)

export const getTenantList = () => get(url.GET_TENANT_LIST)

export const getTenantDetails = (id) => get(`${url.GET_TENANT_DETAILS}/${id}`)

export const getTenantCustomers = (tenantId, filter) => get(`${url.GET_TENANT_LIST}/${tenantId}/customers${filter ? '?filter=' + filter : ''}`)

export const getCustomerList = (sync, hidden) => get(`${url.GET_CUSTOMER_LIST}?sync=${sync ? sync : false}&hidden=${hidden ? hidden : false}`)

export const getBrandingSettings = () => get(url.GET_BRAND_SETTINGS)

export const getBrandSettingsWithSuperAdminSettings = () => get(url.GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS)

export const postBrandSettings = (brandSettings) => postMultipart(url.GET_BRAND_SETTINGS, brandSettings);

export const postResetBrandColors = () => post(url.POST_RESET_BRAND_COLORS);

export const getCustomerListForImpersonation = () => get(`${url.GET_CUSTOMER_LIST}?sync=false`)

export const getCustomer = (customerId) => get(`${url.GET_CUSTOMER_LIST}/${customerId}`)

export const getCustomersForAm = (userId) => get(`${url.GET_CUSTOMERS_FOR_AM}/${userId}`)

export const getCustomerUsers = (customerId, filter) => get(`${url.GET_CUSTOMER_USERS}${customerId}/users${filter ? '?filter=' + filter : ''}`)

// customer addresses
export const getAddressList = (customerId, projectId) => get(url.GET_ADDRESS_LIST(customerId, projectId))
export const getAddress = (addressId) => get(url.GET_ADDRESS(addressId))
export const postAddress = ({customerId, addressData}) => post(url.POST_ADDRESS(customerId), addressData)
export const putAddress = ({customerId, addressId, addressData}) => put(url.PUT_ADDRESS(customerId, addressId), addressData)

export const getCategoryList = () => get(url.GET_CATEGORY_LIST)

export const getProjectsList = (tenantId, customerId, status, filter, sync) => getWithRequestParams(`${url.GET_PROJECT_LIST}`, { tenantId, status, filter, sync, customerId })

export const getImpersonationProjectsList = (customerId, sync) => getWithRequestParams(`${url.GET_PROJECT_LIST}`, { customerId, sync})

export const getProjectTemplateList = () => get(url.GET_PROJECT_TEMPLATE);

export const getProjectTemplateDetails = (id) => get(`${url.GET_PROJECT_TEMPLATE}/${id}`);

export const getProjectListByParticipant = (userId) => get(url.GET_PROJECT_LIST_BY_PARTICIPANT(userId))

export const getProjectListByLeader = (userId) => get(url.GET_PROJECT_LIST_BY_LEADER(userId))

export const getProjectsUsers = (projectId) => get(`${url.GET_PROJECT_USERS}/${projectId}`)

export const getCartItems = () => get(url.GET_CART_ITEMS)

export const postAddCartItem = data => post(url.GET_CART_ITEMS, data)

export const putUpdateCartItem = (data) => put(`${url.GET_CART_ITEMS}`, data)

export const putChangePassword = ({userId, existingPassword, newPassword}) => {
  return put(url.CHANGE_PASSWORD(userId), {existingPassword, newPassword});
}

export const putEditProfile = (user) => {
  return put(url.PUT_USER_PROFILE(user.userId), user);
}

export const getPriceList = ({articleId, fromQuantity}) => get(url.GET_PRICE_LIST(articleId, fromQuantity));

export const getOrderDetails = (orderId) => get(`${url.GET_ORDERS}/${orderId}`)

export const copyOrderToCart = (orderId) => post(url.COPY_ORDER_TO_CART(orderId));

export const getGlobalSearch = (search) => get(`${url.GET_SEARCH}?value=${search}`)

export const getOrderForAM = (userId, orderType) => getWithRequestParams(`${url.GET_ORDERS_FOR_AM}/${userId}`, {type: orderType});

export const getOrdersByReference = (userId, orderType) => getWithRequestParams(url.GET_ORDERS_BY_REFERENCE(userId), {type: orderType});

export const getArticleGroupList = () => get(url.GET_ARTICLE_GROUP_LIST);

export const getArticleGroupById = id => get(`${url.GET_ARTICLE_GROUP_LIST}/${id}`);

export const getGroupsByArticle = id => get(`${url.GET_GROUP_BY_ARTICLE}?articleId=${id}`);

export const getTemplateList = () => get(url.GET_TEMPLATE_LIST);

export const getTemplateDetails = (id) => get(`${url.GET_TEMPLATE_LIST}/${id}`)

export const getFavourites = () => get(`${url.GET_FAVOURITES}`)

export const getOrderMetrics = (startDate, endDate) => get(`${url.GET_ORDERS}/metrics?startDate=${startDate}&endDate=${endDate}`)

export const getDashboardLineChartMetrics = (startDate, endDate, type, filter) => get(`${url.DASHBOARD_LINE_CHART}?startDate=${startDate}&endDate=${endDate}&type=${type}${filter?.articleNumber ? `&articleNumber=${filter?.articleNumber}` : ''}${filter?.customerNumber ? `&customerNumber=${filter?.customerNumber}` : ''}${filter?.articleGroupId ? `&articleGroupId=${filter?.articleGroupId}`: ''}${filter?.manufacturer ? `&manufacturer=${filter?.manufacturer}` : ''}${filter?.projectNumber ? `&projectNumber=${filter?.projectNumber}` : ''}${filter?.ourReference ? `&ourReference=${filter?.ourReference}` : ''}${filter?.yourReference ? `&yourReference=${filter?.yourReference}`: ''}`);

export const getDashboardMetricsListAPI = (startDate, endDate, type, filter) => get(`${url.DASHBOARD_REPORT_METRICS_LIST}?startDate=${startDate}&endDate=${endDate}&type=${type}${filter?.articleNumber ? `&articleNumber=${filter?.articleNumber}` : ''}${filter?.customerNumber ? `&customerNumber=${filter?.customerNumber}` : ''}${filter?.articleGroupId ? `&articleGroupId=${filter?.articleGroupId}`: ''}${filter?.manufacturer ? `&manufacturer=${filter?.manufacturer}` : ''}${filter?.projectNumber ? `&projectNumber=${filter?.projectNumber}` : ''}${filter?.ourReference ? `&ourReference=${filter?.ourReference}` : ''}${filter?.yourReference ? `&yourReference=${filter?.yourReference}`: ''}`);

export const getPriceListForTenant = () => get(url.PRICE_LIST_FOR_TENANT);

// tasks
export const getTaskList = (filter) => filter ? getWithRequestParams(url.GET_TASKS, filter) : get(url.GET_TASKS)
export const getTask = (taskId) => get(url.GET_TASK(taskId))
export const getTaskCommentList = (taskId) => get(url.GET_TASK_COMMENTS(taskId))

export const getTaskWorkLog = (taskId) => get(url.GET_TASK_WORKLOG(taskId))

export const getWorkLogType = () => get(`${url.GET_WORKLOG_TYPE}`);

export const getTaskCategories = () => get(`${url.GET_TASK_CATEGORIES}`);

export const getAllUsers = () => get(url.GET_ALL_USERS)

export const getLanguageList = () => get(url.GET_LANGUAGE_LIST)
export const getLablesList = (locale, tenantId, filter) => getWithRequestParams(url.GET_LABLES_LIST(locale), {tenantId, filter})

export const getManufacturer = () => get(url.GET_MANUFACTURER)

export const getManufacturerDetails = (id) => get(url.GET_MANUFACTURER + "/"+ id)

export const getPhraseList = () => get(url.GET_PHRASE_LIST);

export const getWorkLogForCustomer = (customerId) => get(`${url.GET_WORK_LOG_FOR_CUSTOMER}/${customerId}`)

export const getTaskByProject = (projectId) => get(`${url.GET_TASKS_BY_PROJECT}/${projectId}`);

export const getWorkLogByProject = (projectId) => get(`${url.GET_WORKLOG_BY_PROJECT}/${projectId}`);

export const getWorkLog = (filter) => filter ? getWithRequestParams(url.GET_WORKLOGS, filter) : get(url.GET_WORKLOGS);

export const getWorkLogForSuperAdmin = () => get(url.GET_WORKLOG_FOR_SUPERADMIN);

export const getTaskPriorities = () => get(`${url.GET_TASK_PRIORITES}`);

export const getWorkLogForUser = (userId) => get(`${url.GET_WORK_LOG_FOR_USER}?userId=${userId}`)

export const getDefaultTimeslipType = () => get(url.GET_DEFAULT_TIMESLIP_TYPE)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postForgetPassword,
  postJwtRegister,
  postJwtLogin,
  postJwtProfile,
}


import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {useNavigate, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import {AvField} from "availity-reactstrap-validation";
import {countries, toastOptions} from "../../util/dataUtil";
import {addAddress, editAddress, getAddress, getProjectList, resetData} from "../../store/actions";
import {isEmpty} from "lodash-es";
import Loader from "../../components/Common/Loader";
import cogoToast from "cogo-toast";
import Select from "react-select";
import withRouter from "../../components/Common/withRouter";

const DeliveryAddressForm = (props) => {
    const { address, errorResponse, addEditSuccess, loading, onGetAddress, projectId, id, inlineForm,
        createShippingInfo, updateShippingInfo, onResetData, disableFieldsByDefault,
        useModal, tog_standard, projects, onGetProjects} = props;

    let params = useParams();
    let shippingInfoId = params.id;
    if(inlineForm || useModal){
        shippingInfoId = null;
    }
    if(id){
        shippingInfoId = id;
    }

    const [customerId, setCustomerId] = useState(null);
    const [error, setError] = useState([]);
    const history = useNavigate();
    const [brandSettings, setBrandSettings] = useState(null);
    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        
        setBrandSettings(user.brandConfig);
    }, [])
    
    const shippingInfoDefaultObject = {
        name: "",
        address: "",
        country: "Sverige",
        city: "",
        zipCode: "",
        phone: "",
        projectId: projectId ? projectId : ""
    }

    const [shippingFormFields, setShippingFormFields] = useState(shippingInfoDefaultObject);

    const clearError = () => setError([])

    const [disabled, setDisabled] = useState(false);
    const [project, setProject] = useState(null);

    const toggleDisableState = () => {
      setDisabled(!disabled);
    };

    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(obj?.brandConfig);
        onResetData();

        if(props.customerId){
            setCustomerId(props.customerId)
        } else if (localStorage.getItem("authUser")) {
            setCustomerId(obj?.customerId)
        }
        onGetProjects(customerId, "ALL");

        return () => onResetData();
    }, [])

    useEffect( () => {
        (async() => {
            if (shippingInfoId) {
                //onGetProjects(customerId, "ALL");
                onGetAddress(shippingInfoId)
            }
        })();
    }, [customerId, shippingInfoId]);

    useEffect(() => {
        if(addEditSuccess){
            onResetData();
            if(useModal){
                tog_standard(address);
                cogoToast.success(props.t("Delivery address added successfully!"), toastOptions);
                return;
            }
            if(props.callback) {
                // setShippingFormFields(shippingInfoDefaultObject);
                setDisabled(true);
                props.callback(address?.id);
            } else {
                window.location.href = "/customer/deliveryAddresses";
            } 
        }
        else if(!isEmpty(address)){
            setShippingFormFields(address);
            setProject({value: address?.projectId?.value, label: address?.projectId?.label})
        } else if(props.callback){
            // setShippingFormFields(shippingInfoDefaultObject);
        }
    }, [address, addEditSuccess])

    useEffect(() => {
        if(errorResponse){
            setError([props.t(errorResponse)])
            onResetData();
            if(useModal){
                cogoToast.error(props.t("Error occured while adding delivery address!"), toastOptions)
            }
        }
    }, [errorResponse])

    useEffect(()=> {
        document.querySelector('select[name=country]').value = shippingFormFields.country
    }, [shippingFormFields])

    useEffect(()=> {
        setDisabled(disableFieldsByDefault);
    }, [disableFieldsByDefault])

    const handleSelectProject = (value) => {
        shippingFormFields.projectId = value;
    }

    const submitForm = (e, fields) => {
        if(disableFieldsByDefault && disabled){
            toggleDisableState();
        } else {
            setShippingFormFields(fields);

            (shippingInfoId && !useModal) ? updateShippingInfo(customerId, shippingInfoId, fields) : createShippingInfo(customerId, fields)
            setDisabled(true);
        }
    }

    const getButtonName = () => {
        let btnName = "Save";
        if(!useModal){
            if(disableFieldsByDefault && disabled){
                btnName = "Edit";
            }
            else if(shippingInfoId){
                btnName = "Update";
            }
        }

        return btnName;
    }

    return (
            <Card className={loading ? "low-opacity" : null}>
                <AvForm
                    onValidSubmit={(e, v) => {
                        e.preventDefault();
                        submitForm(e, v)
                    }}
                >
                    <CardBody>
                        {(!disabled && !useModal) && <CardTitle className="h4">{props.t(shippingInfoId ? "Update Delivery Address" : "Add Delivery Address")}</CardTitle>}
                        {error.length > 0 ? (
                            <Alert color="danger">{error.map((msg, index) => index === 0 ?  msg : (<><br />{msg}</>))}</Alert>
                        ) : null}
                        
                        <Loader loading={loading} />
                        <Row className="mb-3">
                            <Col lg={12}>
                                <AvField
                                    name="name" 
                                    label={props.t("Name")}
                                    className="form-control"
                                    type="text"
                                    placeholder={props.t("Enter name")}
                                    value={shippingFormFields.name}
                                    required
                                    disabled={loading || disabled}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col lg={12}>
                                <AvField
                                    className="form-control"
                                    label={props.t("Delivery Address")}
                                    type="text"
                                    placeholder={props.t("Enter delivery address")}
                                    name="address"
                                    value={shippingFormFields.address}
                                    disabled={loading || disabled}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col lg={4}>
                                <AvField
                                    className="form-control"
                                    name="country"
                                    label={props.t("Country")}
                                    placeholder={props.t("Enter Country")}
                                    type="select"
                                    value={shippingFormFields.country}
                                    disabled={loading || disabled}
                                >
                                    {Object.keys(countries).map((key) => (<option key={key} value={countries[key]}>{countries[key]}</option>))}
                                </AvField>
                            </Col>
                            <Col lg={4}>
                                <AvField
                                    className="form-control"
                                    label={props.t("City")}
                                    type="text"
                                    placeholder={props.t("Enter city")}
                                    name="city"
                                    value={shippingFormFields.city}
                                    disabled={loading || disabled}
                                />
                            </Col>
                            <Col lg={4}>
                                <AvField
                                    className="form-control"
                                    label={props.t("Zip Code")}
                                    type="text"
                                    placeholder={props.t("Enter zip code")}
                                    name="zipCode"
                                    value={shippingFormFields.zipCode}
                                    disabled={loading || disabled}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col lg={12}>
                                <AvField
                                    className="form-control"
                                    label={props.t("Phone")}
                                    type="text"
                                    placeholder={props.t("Enter phone")}
                                    name="phone"
                                    value={shippingFormFields.phone}
                                    disabled={loading || disabled}
                                />
                            </Col>
                        </Row>
                        {projectId && <AvField type="hidden" name="projectId" value={projectId} />}
                        {!projectId && <Row className="mb-3">
                            <Col lg={12}>
                                <label>{props.t("Project")}</label>
                                <AvField type="hidden" name="projectId" value={project?.value} />
                                <Select
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={project}
                                    onChange={(vals) => {
                                        setProject(vals);
                                        handleSelectProject(vals?.value);
                                    }}
                                    options={projects?.map(o => ({value: o.id, label: o.description}))}
                                />
                            </Col>
                        </Row>}

                        {shippingInfoId && <AvField type="hidden" name="id" value={shippingInfoId} />}
                        {customerId && <AvField type="hidden" name="customerId" value={customerId} />}
                        <Row className="mb-3">
                            <Col align="center">
                                {!disableFieldsByDefault &&
                                <>
                                <Button
                                    type="button"
                                    color="secondary"
                                    className="btn btn-secondary w-lg waves-effect waves-light"
                                    onClick={()=> {
                                        if(useModal){
                                            tog_standard();
                                            return;
                                        }
                                        if(props.setEditMode){
                                            props.setEditMode(false);
                                        } else {
                                            history("/customer/deliveryAddresses");
                                        }
                                    }}
                                >
                                    {props.t("Cancel")}
                                </Button>{" "}</>}
                                <Button
                                    type="submit"
                                    color="success"
                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                    className="btn btn-success w-lg waves-effect waves-light"
                                    disabled={loading}
                                >
                                    {props.t(getButtonName())}
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </AvForm>
            </Card>);
};

DeliveryAddressForm.propTypes = {
    address: PropTypes.object,
    onGetAddress: PropTypes.func,
    createShippingInfo: PropTypes.func,
    updateShippingInfo: PropTypes.func,
    errorResponse: PropTypes.string,
    loading: PropTypes.bool,
    addEditSuccess: PropTypes.bool,
    onResetData: PropTypes.func,
    projects: PropTypes.array,
    onGetProjects: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        address: state.Customer.address,
        loading: state.Customer.loading,
        errorResponse: state.Customer.error,
        addEditSuccess: state.Customer.addEditSuccess,
        projects: state.Project.projects,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetAddress: (addressId) => dispatch(getAddress(addressId)),
    createShippingInfo: (customerId, addressData) => dispatch(addAddress(customerId, addressData)),
    updateShippingInfo: (customerId, addressId, addressData) => dispatch(editAddress(customerId, addressId, addressData)),
    onResetData: () => dispatch(resetData()),
    onGetProjects: (customerId, filter) => dispatch(getProjectList(customerId, filter)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(DeliveryAddressForm)));

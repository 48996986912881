import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Modal, Row,} from "reactstrap";
import {useNavigate, useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getContentTypes, getContentTypesReset, getFields, getLayout, getLayoutReset} from "../../store/e-commerce/actions";
import {del, get, post, put} from "../../helpers/api_helper";
import DeleteModal from "../../components/Common/DeleteModal";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import {filter, find, includes, isArray, orderBy} from "lodash-es";
import {API_ERR_MSG} from "../../util/constants";
import {isEmpty} from "underscore";
import {ecommerceActionTypes} from "../../store/e-commerce/actionTypes";
import {checkIfLoading} from "../../store/selector";
import Loader from "../../components/Common/Loader";
import SearchableDropdown from "../../components/Common/SearchableDropdown";
import cogoToast from "cogo-toast";
import {toastOptions} from "../../util/dataUtil";
import withRouter from "../../components/Common/withRouter";

const ContentTypeList = (props) => {
    const {
        contentTypes,
        onGetContentTypes,
        layout,
        fields,
        onGetLayout,
        loading,
        layoutError,
        onGetLayoutReset,
        getContentTypeError,
        onGetContentTypesReset,
        onGetFields
    } = props;

    const [modal_standard, setmodal_standard] = useState(false);
    const [modal_edit, setmodal_edit] = useState(false);
    const [modal_confirm, setmodal_confirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedField, setSelectedField] = useState();
    const [permission, setPermission] = useState("ROLE_CUSTOMER_USER")
    const [unit, setUnit] = useState("");
    const [contentTypeList, setContentTypeList] = useState([]);
    const [editname, setEditname] = useState("");
    const [editContent, setEditContent] = useState(null);
    const history = useNavigate();
    const [searchResults, setSearchResults] = useState([]);
    const [search, setSearch] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [showDeleteContentModal, setShowDeleteContentModal] = useState(false);
    const [showDeleteLayoutModal, setShowDeleteLayoutModal] = useState(false);
    const [deleteContentId, setDeleteContentId] = useState(null);
    const [articleContents, setArticleContents] = useState([]);
    const [deleteLayoutId, setDeleteLayoutId] = useState(null);
    const [layoutArticles, setLayoutArticles] = useState([]);
    const [brandSettings, setBrandSettings] = useState(null);
    const [confirmation, setConfirmation] = useState(false);
    const [fieldList, setFieldList] = useState([]);
    const [sortState, setSortState] = useState(null);
    const numericColumns = [];
    let params = useParams();

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function tog_confirm() {
        setmodal_confirm(!modal_confirm);
        removeBodyCss();
    }

    function toggleDeleteContent() {
        if (showDeleteContentModal) {
            setDeleteContentId(null);
        }
        setShowDeleteContentModal(!showDeleteContentModal);
        removeBodyCss();
    }

    function toggleDeleteLayout() {
        if (showDeleteLayoutModal) {
            setDeleteLayoutId(null);
        }
        setShowDeleteLayoutModal(!showDeleteLayoutModal);
        removeBodyCss();
    }

    function tog_edit() {
        setmodal_edit(!modal_edit)
        removeBodyCss()
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        return () => {
            onGetLayoutReset();
            onGetContentTypesReset();
        }
    }, [])

    useEffect(() => {
        if (deleteContentId) {
            get(`/api/content/${deleteContentId}/articleContents`)
                .then(data => {
                    if (data?.status !== 200) {
                        setErrorMessage(props.t(API_ERR_MSG))
                    } else {
                        setArticleContents(data?.data);
                        if (isEmpty(data?.data)) {
                            toggleDeleteContent();
                        }
                    }
                })
                .catch(err => {
                    setErrorMessage(props.t(API_ERR_MSG))
                })
        }
    }, [deleteContentId])

    useEffect(() => {
        if (deleteLayoutId) {
            get(`/api/template/${deleteLayoutId}/articles`)
                .then(data => {
                    if (data?.status !== 200) {
                        setErrorMessage(props.t(API_ERR_MSG))
                    } else {
                        setLayoutArticles(data?.data);
                        if (isEmpty(data?.data)) {
                            toggleDeleteLayout();
                        }
                    }
                })
                .catch(err => {
                    setErrorMessage(props.t(API_ERR_MSG))
                })
        }
    }, [deleteLayoutId])

    useEffect(() => {
        if (!isEmpty(articleContents)) {
            toggleDeleteContent();
        }
    }, [articleContents])

    useEffect(() => {
        if (!isEmpty(layoutArticles)) {
            toggleDeleteLayout();
        }
    }, [layoutArticles])

    useEffect(() => {
        if (modal_standard) {
            onGetFields();
        }
    }, [modal_standard])

    useEffect(() => {
        if (layoutError) {
            setErrorMessage(props.t(API_ERR_MSG))
            setTimeout(() => {
                setErrorMessage('')
            }, 5000);
            setTimeout(() => {
                onGetLayoutReset()
            }, 5000);
        }
    }, [layoutError])

    useEffect(() => {
        if (getContentTypeError) {
            setErrorMessage(props.t(API_ERR_MSG))
            setTimeout(() => {
                setErrorMessage('')
            }, 5000);
            setTimeout(() => {
                onGetContentTypesReset()
            }, 5000);
        }
    }, [getContentTypeError])

    useEffect(() => {
        if (layout?.fieldList) {
            setFieldList(layout?.fieldList)
        }
    }, [layout]);

    useEffect(() => {
        if (sortState) {
            const {column, direction} = sortState;
            setFieldList(orderBy(fieldList, o => {
                if (column === 'fieldType') return getContentType(o[column])
                return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
            }, [direction]))
        }
    }, [sortState])

    const handleSearch = () => {
        if (!isEmpty(search)) {
            const results = filter(
                layout.contentList,
                (fieldType) =>
                    includes(fieldType.name?.toLowerCase(), search.toLowerCase()) ||
                    includes(fieldType.description?.toLowerCase(), search.toLowerCase()) ||
                    filter(fieldType.categories, (c) =>
                        includes(c.name?.toLowerCase(), search.toLowerCase())
                    ).length > 0
            );

            setSearchResults(results);
        } else {
            setSearchResults(layout.contentList);
        }
        setSearchValue(search);
    };

    useEffect(() => {
        if (params && params.id) {
            //Change here.
            onGetContentTypes(params.id);
            onGetLayout(params.id);
        }
    }, [params]);

    useEffect(() => {
        setContentTypeList(contentTypes);
    }, [contentTypes]);

    const handleSaveField = async (editPermissionForAllArticles = false) => {
        console.log(selectedField);
        if (editContent?.permission != permission && confirmation === false) {
            setConfirmation(true);
            tog_confirm();
            return;
        }
        let response = await post(`/api/content?all=${editPermissionForAllArticles}`, {
            templateId: params.id,
            fieldId: selectedField?.id
        });
        if (confirmation) {
            tog_confirm();
        }
        setConfirmation(false);
        setSelectedField(null);
        onGetLayout(params.id);
        tog_standard();
    };


    const handleDeleteContent = async () => {
        let response = await del(`/api/content/templates/${layout.id}/fields/${deleteContentId}`);
        setDeleteContentId(null);
        onGetLayout(params.id);
    };

    const getDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Unit"),
                    field: "unit",
                    autoWidth: true,
                },
                {
                    label: props.t("Type"),
                    field: "fieldType",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Permission"),
                    field: "permission",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Created Date"),
                    field: "createdAt",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Actions"),
                    field: "actions",
                    sort: "asc",
                    autoWidth: true,
                },
            ],
            rows: fieldList?.map((item, key) => ({
                name:
                    <>
                        <i
                            className="uil uil-arrow-circle-up px-3 text-primary font-size-18"
                            onClick={() => handleOrder("UP", item)}
                        ></i>
                        <i
                            className="uil uil-arrow-circle-down px-3 text-primary font-size-18"
                            onClick={() => handleOrder("DOWN", item)}
                        ></i>
                        {item.contentType === "HEADER" ? (
                            <label className="font-bold font-size-16">{item.name}</label>
                        ) : (
                            item.name
                        )}</>,
                unit: item.unit,
                fieldType: getContentType(item.fieldType),
                createdAt: new Date(item.createdAt).toLocaleString(),
                permission: item.permission ? item.permission === 'ROLE_CUSTOMER_USER' ? props.t("Customer") : props.t("Tenant") : "",
                actions: (
                    <>
                        <i
                            className="uil uil-trash-alt px-3 text-danger font-size-18"
                            onClick={() => {
                                setDeleteContentId(item.id);
                            }}
                        ></i>
                    </>
                ),
            })),
        };
        return data;
    };

    const handleBack = () => {
        history("/templates");
    };

    const handleCopy = async () => {
        let response = await get(`/api/template/copy/${layout.id}`);
        history("/templates");
    };

    const handleEdit = async () => {
        setEditname(layout.name);
        tog_edit();
    };

    const handleEditSave = async () => {
        let response = await post(`/api/template`, {id: layout.id, name: editname});
        setEditname("");
        tog_edit();
        onGetLayout(layout.id);
    };

    const handleDeleteLayout = async () => {
        try {
            await del(`/api/template/${layout.id}`);
            setDeleteLayoutId(null);
            toggleDeleteLayout();
            history("/tabs");
            return false;
        } catch (err) {
            const errData = err.response.data;
            if (errData && isArray(errData) && errData.length > 0) {
                cogoToast.error(props.t(errData), toastOptions)
            } else {
                cogoToast.error(props.t(API_ERR_MSG), toastOptions)
            }
            return true;
        }
    };

    const handleEditContent = (item) => {
        setEditContent(item);
        setSelectedField(item);
        tog_standard();
    };

    const handleAddContent = () => {
        setEditContent(null);
        setSelectedField(null);
        tog_standard();
    };

    const handleOrder = async (type, item) => {
        let response = await put(
            `/api/content/order/${type}?id=${item.id}&&templateId=${params.id}`
        );
        onGetContentTypes(params.id);
        onGetLayout(params.id);
    };

    const getContentType = (type) => {
        if (type === "INPUT_FIELD") {
            return props.t("Text");
        } else if (type === "FILE") {
            return props.t("File");
        } else if (type === "NUMBER_FIELD") {
            return props.t("Number");
        } else if (type === "HEADER") {
            return props.t("Header");
        } else if (type === "BOOLEAN") {
            return props.t("Boolean");
        } else if (type === "FREE_TEXT") {
            return props.t("Free Text");
        } else if (type === "DROPDOWN") {
            return props.t("Dropdown");
        } else if (type === "DROPDOWN_MULTISELECT") {
            return props.t("Dropdown MultiSelect");
        } else if (type === "RANGE") {
            return props.t("Range");
        } else if (type === "ASSOCIATION_FIELD") {
            return props.t("Association")
        } else {
            return props.t("");
        }
    };

    const getBreadcrumbs = () => {
        let crumbs = [
            {title: props.t("Products"), link: "#"},
            {title: props.t("Tabs"), link: "/tabs"},
        ];
        return crumbs;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        // title={layout?.name}
                        // breadcrumbs={[{ title: props.t("Tabs"), link: "/templates" }]}
                        noBackBtn={true}
                        title={layout?.name}
                        breadcrumbs={getBreadcrumbs()}
                    />
                    {/* <Row>
                        <Col className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">{layout?.name}</h4>
                            </div>
                        </Col>
                    </Row> */}
                    {/* <Row className="mb-3">
            <Col className="col-12">
              <Button
                color="primary"
                onClick={handleBack}
                className="btn btn-secondary waves-effect waves-light"
              >
                <i className="fa fa-arrow-left"></i> &nbsp;&nbsp;{" "}
                {props.t("Back")}
              </Button>{" "}
              <Button
                color="primary"
                onClick={handleAddContent}
                className="btn btn-primary waves-effect waves-light float-end me-2"
              >
                {props.t("Add Field")}
              </Button>
              <Button
                color="danger"
                onClick={toggleDeleteLayout}
                className="btn btn-primary waves-effect waves-light float-end me-2"
              >
                {props.t("Delete")}
              </Button>{" "}
              <Button
                color="primary"
                onClick={handleEdit}
                className="btn btn-primary waves-effect waves-light float-end me-2"
              >
                {props.t("Edit")}
              </Button>{" "}
              <Button
                color="primary"
                onClick={handleCopy}
                className="btn btn-primary waves-effect waves-light float-end me-2"
              >
                {props.t("Copy")}
              </Button>{" "}
            </Col>
          </Row> */}
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg"
                                            style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={layout?.name}
                                        buttons={[{
                                            text: props.t("Add Field"),
                                            onClick: handleAddContent,
                                        }]}
                                        canEdit={() => handleEdit()}
                                        canDuplicate={() => handleCopy()}
                                        canDelete={() => setDeleteLayoutId(params.id)}
                                    />
                                </CardHeader>
                                <Loader loading={loading} title="Fields"/>
                                {!loading &&
                                    <CardBody>
                                        {errorMessage && (
                                            <Row>
                                                <Alert color="danger">{errorMessage}</Alert>
                                            </Row>
                                        )}
                                        <MDBDataTable
                                            responsive
                                            searching={true}
                                            barReverse={true}
                                            hover
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            data={getDatatableData()}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            onSearch={(text) => console.log(text)}
                                            sortRows={[]}
                                            onSort={({column, direction}) => {
                                                if (sortState && sortState.column == column) {
                                                    setSortState({
                                                        ...sortState,
                                                        direction: sortState.direction == 'asc' ? 'desc' : 'asc'
                                                    });
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {editContent ? props.t("Edit Content") : props.t("Add Content")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <label className="col-md-3 col-form-label">
                            {props.t("Field")}
                        </label>
                        <div className="col-md-9">
                            <SearchableDropdown
                                selectedValue={selectedField}
                                options={fields.filter(field => !find(fieldList, {id: field.id}))}
                                placeholder={"Select Field"}
                                keyToDisplay={["name"]}
                                handleOnOptionClick={(field) => setSelectedField(field)}/>
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard();
                        }}
                        className="col-md-2 btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("Close")}
                    </button>
                    <Button
                        color="primary"
                        style={{
                            backgroundColor: brandSettings?.primaryBtnColor,
                            borderColor: brandSettings?.primaryBtnColor
                        }}
                        onClick={() => handleSaveField(false)}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>

            <Modal
                isOpen={modal_edit}
                toggle={() => {
                    tog_edit();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Add Content")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_edit(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                        >
                            {props.t("Label")}
                        </label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setEditname(e.target.value)}
                                placeholder={props.t("Enter fieldType label")}
                                value={editname}
                            />
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_edit();
                        }}
                        className="col-md-2 btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("Close")}
                    </button>
                    <Button
                        color="primary"
                        onClick={handleEditSave}
                        style={{
                            backgroundColor: brandSettings?.primaryBtnColor,
                            borderColor: brandSettings?.primaryBtnColor
                        }}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>

            <Modal
                isOpen={modal_confirm}
                toggle={() => {
                    tog_confirm();
                }}
            >
                <div className="modal-header">
                    <h4 className="modal-title">
                        {props.t("Warning")}!
                    </h4>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <h4>{props.t("Do you want to change permission of content for existing articles?")}</h4>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            handleSaveField(false);
                        }}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("No only new articles")}
                    </button>
                    <Button
                        color="primary"
                        onClick={() => {
                            handleSaveField(true)
                        }}
                        style={{
                            backgroundColor: brandSettings?.primaryBtnColor,
                            borderColor: brandSettings?.primaryBtnColor
                        }}
                        className="btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Yes all articles")}
                    </Button>
                </div>
            </Modal>
            <DeleteModal
                deleteCallback={() => handleDeleteContent()}
                element={"Content"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteContentModal}
                toggleModal={() => toggleDeleteContent()}
                warningMsg={!isEmpty(articleContents) ? "The content fieldType is referenced inside Articles." : null}
            />
            <DeleteModal
                deleteCallback={() => handleDeleteLayout()}
                element={"Layout"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteLayoutModal}
                toggleModal={() => toggleDeleteLayout()}
                warningMsg={!isEmpty(layoutArticles) ? "The layout is referenced inside Articles." : null}
                confirmation={true}
            />
        </React.Fragment>
    );
};

ContentTypeList.propTypes = {
    contentTypes: PropTypes.array,
    onGetContentTypes: PropTypes.func,
    layout: PropTypes.object,
    onGetLayout: PropTypes.func,
    loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        contentTypes: state.ecommerce.contentTypes,
        layout: state.ecommerce.layout,
        layoutError: state.ecommerce.getLayoutError,
        getContentTypeError: state.ecommerce.getContentTypeError,
        loading: checkIfLoading(state, ecommerceActionTypes.GET_LAYOUT),
        fields: state.ecommerce.fields,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetContentTypes: (id) => dispatch(getContentTypes(id)),
    onGetLayout: (id) => dispatch(getLayout(id)),
    onGetLayoutReset: () => dispatch(getLayoutReset()),
    onGetContentTypesReset: () => dispatch(getContentTypesReset()),
    onGetFields: () => dispatch(getFields("ARTICLE_FIELD_TYPE")),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ContentTypeList)));

import PropTypes from 'prop-types'
import React, {useEffect} from "react"
import {Alert, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row} from "reactstrap";

// Redux
import {connect, useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {Link} from "react-router-dom";
import withRouter from "../../components/Common/withRouter"


// Formik Validation
import * as Yup from "yup";
import {useFormik} from "formik";

// action
import {userForgetPassword} from "../../store/actions";

// import images
import {withTranslation} from "react-i18next";
import {LOGO_URL} from "../../helpers/url_helper";

const ForgetPasswordPage = props => {


    const dispatch = useDispatch();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required(props.t("Please Enter Your Email")),
        }),
        onSubmit: (values) => {
            dispatch(userForgetPassword(values, props.history));
        }
    });

    useEffect(() => {
        document.body.className = "authentication-bg";
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    });

    const selectForgotPasswordState = (state) => state.ForgetPassword;
    const ForgotPasswordProperties = createSelector(
        selectForgotPasswordState,
        (forgetPassword) => ({
            forgetError: forgetPassword.forgetError,
            forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
        })
    );

    const {
        forgetError,
        forgetSuccessMsg
    } = useSelector(ForgotPasswordProperties);

    return (
        <React.Fragment>
            {/*<div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="mdi mdi-home-variant h2"></i>
                </Link>
            </div>*/}

            <div className="account-pages my-5  pt-sm-5">
                <Container>
                    <Row className="justify-content-center">

                        <Col md={8} lg={6} xl={5}>
                            <div>

                                <Link to="/" className="mb-5 d-block auth-logo">
                                    <img src={`${LOGO_URL}?domain=${window.location.hostname}`} alt="" height="40" className="logo logo-dark"/>
                                    <img src={`${LOGO_URL}?domain=${window.location.hostname}`} alt="" height="40" className="logo logo-light"/>
                                </Link>
                                <Card>

                                    <CardBody className="p-4">

                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">{props.t("Reset Password")}</h5>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <div className="alert alert-success text-center mb-4" role="alert">
                                                {props.t("Enter your Email and instructions will be sent to you!")}
                                            </div>
                                            {forgetError && forgetError ? (
                                                <Alert color="danger" className="text-center mb-4" style={{marginTop: "13px"}}>
                                                    {forgetError}
                                                </Alert>
                                            ) : null}
                                            {forgetSuccessMsg ? (
                                                <Alert color="success" className="text-center mb-4" style={{marginTop: "13px"}}>
                                                    {forgetSuccessMsg}
                                                </Alert>
                                            ) : null}

                                            <Form
                                                className="form-horizontal"
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                            >
                                                <div className="mb-3">
                                                    <Input name="domain" type="hidden" value={window.location.hostname}/>
                                                    <Label className="form-label">Email</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder={props.t("Enter email")}
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <Row className="mb-0">
                                                    <Col xs={12} className="text-end">
                                                        <button
                                                            className="btn btn-primary w-md waves-effect waves-light"
                                                            type="submit"
                                                        >
                                                            {props.t("Reset")}
                                                        </button>
                                                    </Col>
                                                </Row>
                                                <div className="mt-4 text-center">
                                                    <p className="mb-0">{props.t("Remember It ?")} <Link to="/login" className="fw-medium text-primary"> {props.t("Signin")} </Link></p>
                                                </div>
                                            </Form>
                                        </div>

                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>© {new Date().getFullYear()} {props.t("Flowsense")}. <a href="http://flowsense.se/" target="_blank">{props.t("Design & Develop by Flowsense")}</a></p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

ForgetPasswordPage.propTypes = {
    forgetError: PropTypes.any,
    forgetSuccessMsg:
    PropTypes.any,
    history:
    PropTypes.object,
    userForgetPassword:
    PropTypes.func
}

const mapStatetoProps = state => {
    const {forgetError, forgetSuccessMsg, forgetPasswordLoading} = state.ForgetPassword
    return {forgetError, forgetSuccessMsg, loading: forgetPasswordLoading}
}

export default withRouter(
    connect(mapStatetoProps, {
        userForgetPassword
    })(withRouter(withTranslation()(ForgetPasswordPage)))
)

import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Row,} from "reactstrap";
import {useNavigate, useParams} from "react-router-dom";
import {get, post, put} from "../../helpers/api_helper";
import {withTranslation} from "react-i18next";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";
import moment from "moment-timezone";
import Select from "react-select";

const timezones = moment.tz.names();
const options = timezones.map((tz) => {
    return {value: tz, label: tz}
});


const AddTenant = (props) => {
    const {userId} = useParams();
    const [error, setError] = useState([]);
    const {header} = ""//..props.location.state;
    const [loading, setLoading] = useState(false);
    let history = useNavigate();
    const [formFields, setFormFields] = useState({
        domain: "", email: "", id: 0, name: "", phone: "", tenantName: "", organizationNumber: "", roles: [{name: "ROLE_TENANT_ADMIN"}], timezone: "Europe/Stockholm"
    });

    const clearError = () => setError([])
    const [brandSettings, setBrandSettings] = useState(null);
    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user.brandConfig);
    }, [])

    useEffect(() => {
        if (userId) {
            get(`/api/user/${userId}`).then(response => {
                let data = response.data;
                console.log(data);
                setFormFields({
                    ...data, tenantName: data?.tenant?.name, domain: data?.tenant?.domain, organizationNumber: data?.tenant?.organizationNumber, timezone: data?.tenant?.timezone
                })
            });
        }
    }, []);

    const createUser = (fields) => {
        setLoading(true);
        post('/api/user/tenantAdmin', fields.reduce((obj, cur) => ({...obj, [cur.id]: cur.value}), {}))
            .then(data => {
                if (data?.status !== 200) {
                    setError([props.t("There was an error. Please contact admin.")])
                } else {
                    history('/tenants')
                }
                setLoading(false);
            })
    }

    const updateUser = (fields) => {
        setLoading(true);
        let formData = fields.reduce((obj, cur) => ({...obj, [cur.id]: cur.value}), {});
        put(`/api/user/tenantAdmin/${formData.id}`, formData)
            .then(data => {
                if (data?.status !== 200) {
                    setError([props.t("There was an error. Please contact admin.")])
                } else {
                    history('/tenants')
                }
                setLoading(false);
            })
    }

    const validations = {
        tenantName: ["required"],
        domain: ["required"],
        name: ["required"],
        email: ["required", 'regex::^[a-zA-Z0-9_.]+@[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9][.][a-zA-Z]{2,}$'],
        phone: ["required"],
        organizationNumber: ['required'],
        timezone: ["required"]
    }

    const submitForm = event => {
        const err = [];
        const fields = Array.from(event.target.getElementsByTagName('input'))
        fields.forEach(field => {
            validations[field.id]?.forEach(type => {
                let validationType = type.split('::')[0]
                switch (validationType) {
                    case 'required':
                        !(field.value && field.value.length > 0) && err.push(`${field.id} is required field`)
                        break
                    case 'regex':
                        if (field.value?.length > 0 && !(new RegExp(type.split('::')[1])).test(field.value)) err.push(`Please enter valid input in field ${field.id}`)
                        break
                }
            })
        })
        setError(err)
        if (err.length === 0) {
            userId ? updateUser(fields) : createUser(fields)
        }
    }

    const handleBack = () => {
        history(`/tenants`)
    }

    const handleTimezoneChange = selectedOption => {
        setFormFields({...formFields, timezone: selectedOption.value})
    };

    return (<React.Fragment>
        <div className="page-content">
            <Container fluid>
                <PageToolbarTop
                    noBackBtn={true}
                    breadcrumbs={[{title: props.t("Tenants"), link: "/tenants"}]}
                    title={props.t("Create Tenant")}
                />


                <Row>
                    <Col>
                        <Card>
                            <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                <TableToolbar
                                    title={"Create Tenant"}
                                />
                            </CardHeader>
                            <Loader loading={loading}/>
                            {!loading && <form
                                onSubmit={(event) => {
                                    event.preventDefault()
                                    submitForm(event)
                                }}
                            >
                                <CardBody>
                                    <CardTitle className="h4">{header}</CardTitle>
                                    <p className="card-title-desc"></p>

                                    {error.length > 0 ? (<Alert color="danger">{error.map((msg, index) => <p key={index}>{msg}</p>)}</Alert>) : null}

                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            {props.t("Name")}
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder={props.t("Enter name")}
                                                id="tenantName"
                                                defaultValue={formFields?.tenantName}
                                                onChange={clearError}
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            {props.t("Organization Number")}
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder={props.t("Enter organization number")}
                                                id="organizationNumber"
                                                defaultValue={formFields?.organizationNumber}
                                                onChange={clearError}
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            {props.t("URL")}
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder={props.t("Enter domain")}
                                                id="domain"
                                                defaultValue={formFields?.domain}
                                                onChange={clearError}
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            {props.t("Contact")}
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder={props.t("Enter contact")}
                                                id="name"
                                                defaultValue={formFields?.name}
                                                onChange={clearError}
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-email-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            {props.t("Contact Email")}
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                className="form-control"
                                                type="email"
                                                placeholder={props.t("Enter contact email")}
                                                id="email"
                                                defaultValue={formFields?.email}
                                                onChange={clearError}
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            {props.t("Contact Phone")}
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder={props.t("Enter contact phone")}
                                                id="phone"
                                                defaultValue={formFields?.phone}
                                                onChange={clearError}
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            {props.t("Timezone")}
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                className="form-control"
                                                type="hidden"
                                                id="timezone"
                                                name="timezone"
                                                defaultValue={formFields?.timezone}
                                            />
                                            <Select
                                                value={{label: formFields.timezone, value: formFields.timezone}}
                                                onChange={handleTimezoneChange}
                                                options={options}
                                            />
                                        </div>
                                    </Row>
                                    {userId && <input type="hidden" id="id" defaultValue={formFields.id}/>}
                                    <input type="hidden" id="roleName" defaultValue={formFields?.roles?.length > 0 ? formFields?.roles[0]?.name : null}/>
                                    <Row className="mb-3">
                                        <Col align="center">
                                            <Button
                                                type="submit"
                                                color="success"
                                                style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                className="btn btn-success w-lg waves-effect waves-light"
                                            >
                                                {props.t("Save")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </form>}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>);
};

export default withTranslation()(AddTenant);

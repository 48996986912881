import React from "react";
import {Card, CardBody, Container, Modal} from "reactstrap";
import {withTranslation} from "react-i18next";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import OrderForm from "./OrderForm";
import {useParams} from "react-router-dom";
import queryString from "query-string";

const OrderFormModalContainer = (props) => {
    let {modal_standard, tog_standard, useModal} = props;
    const params = useParams();
    let query = queryString.parse(window?.location?.search);

    return <React.Fragment>
        <Modal
            isOpen={modal_standard}
            backdrop="static"
            toggle={() => {
                tog_standard();
            }}
            className="add-order-modal"
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {props.t("New Order")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        tog_standard();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body m-2">
                <OrderForm useModal={useModal} tog_standard={tog_standard}/>
            </div>
        </Modal>
    </React.Fragment>

};

export default withTranslation()(OrderFormModalContainer);

import React, {useEffect, useState} from "react";
import {Alert, Card, CardBody, CardHeader, Col, Container, Row,} from "reactstrap";
import {useNavigate, useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import queryString from "query-string";
import {getTenantUserDetails} from "../../store/users/tenant/actions";
import {
    TENANT_ACTIVATE_USER,
    TENANT_ADMIN_DELETE_USER,
    TENANT_ADMIN_REGENERATE_TOKEN,
    TENANT_ADMIN_RESET_DELETE_USER,
    TENANT_ADMIN_RESET_REGENERATE_TOKEN,
} from "../../store/users/tenant/actionTypes";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import store from "../../store";
import OverridePwdModal from "../../components/Common/OverridePwdModal";
import DeleteModal from "../../components/Common/DeleteModal";
import avatar from "../../assets/images/users/user-img-placeholder-2.png";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import Loader from "../../components/Common/Loader";
import withRouter from "../../components/Common/withRouter";

export const userDetailContext = React.createContext(null);

const SuperAdminUserDetails = (props) => {
    const history = useNavigate();
    //const location = useLocation();

    const tenantId = useParams().tenantId;
    const userId = useParams().id;
    const {
        user,
        onGetUserDetails,
        tokenRegenerate,
        userDeleted,
        onResetRegenerateToken,
        onResetDeleteUser,
        userActivated,
        error,
        loading
    } = props;

    const [activeUser, setActiveUser] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showOverridePwdModal, setShowOverridePwdModal] = useState(false);
    const [modal_standard, setmodal_standard] = useState(false);
    const [brandSettings, setBrandSettings] = useState(null);
    const [activeTab, setActiveTab] = useState('1');
    const [search, setSearch] = useState("");

    const profiletoggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
    }, [])

    useEffect(() => {
        onGetUserDetails(userId);
    }, [userId]);

    useEffect(() => {
        setActiveUser(user);
    }, [user])

    useEffect(() => {
        setErrorMessage(error);
    }, [error]);

    useEffect(() => {
        if (tokenRegenerate && tokenRegenerate.status) {
            setSuccessMessage(props.t("Activation link sent to user."));
            setTimeout(() => {
                setSuccessMessage("");
                onResetRegenerateToken();
            }, 3000);
        } else if (tokenRegenerate) {
            setErrorMessage(props.t("Error sending activation link"));
            setTimeout(() => {
                setErrorMessage("");
                onResetRegenerateToken();
            }, 3000);
        }
    }, [tokenRegenerate]);

    useEffect(() => {
        if (userDeleted && userDeleted.status) {
            setSuccessMessage(props.t("User deactivated successfully."));
            setTimeout(() => {
                setSuccessMessage("");
                onResetDeleteUser();
            }, 3000);
            onGetUserDetails(userId);
        } else if (userDeleted) {
            setErrorMessage(props.t("Error deleting user."));
            setTimeout(() => {
                setErrorMessage("");
                onResetDeleteUser();
            }, 3000);
        }
    }, [userDeleted]);

    useEffect(() => {
        if (userActivated && userActivated.status) {
            setSuccessMessage(props.t("User activated successfully."));
            setTimeout(() => {
                setSuccessMessage("");
                onResetDeleteUser();
            }, 3000);
            onGetUserDetails(userId);
        } else if (userActivated) {
            setErrorMessage(props.t("Error activated user."));
            setTimeout(() => {
                setErrorMessage("");
                onResetDeleteUser();
            }, 3000);
        }
    }, [userActivated]);

    function getRoleName(role) {
        if (role === "ROLE_TENANT_ADMIN") {
            return props.t("Tenant Admin");
        } else if (role === "ROLE_TENANT_USER") {
            return props.t("Tenant User");
        } else if (role === "ROLE_CUSTOMER_ADMIN") {
            return props.t("Customer Admin");
        } else if (role === "ROLE_CUSTOMER_USER") {
            return props.t("Customer User");
        } else if (role === "ROLE_CONTACT_USER") {
            return props.t("Contact");
        } else if (role === "ROLE_SUPER_ADMIN") {
            return props.t("Super Admin");
        }
        return role;
    }

    const handleOnClick = () => {
        history("/settings/users/add");
    };

    const handleRegenerateClick = () =>
        store.dispatch({
            type: TENANT_ADMIN_REGENERATE_TOKEN,
            userId: activeUser.id,
        });

    const handleActiveUser = async () => {
        store.dispatch({type: TENANT_ACTIVATE_USER, userId: activeUser.id});
    };

    function togglePwdModal() {
        setShowOverridePwdModal(!showOverridePwdModal);
    }

    function tog_standard() {
        setmodal_standard(!modal_standard);
        document.body.classList.add("no_padding");
    }

    const handleDeleteUser = async () => {
        await store.dispatch({type: TENANT_ADMIN_DELETE_USER, userId: activeUser.id});
        tog_standard();
    };

    const handleEdit = async () => {
        if (props.setEditMode && props.setSelectedTenantUser) {
            await props.setSelectedTenantUser(activeUser.id)
            props.setEditMode(true);
            return;
        }
        history(`/settings/users/edit/${activeUser.id}`);
    }

    const getActionButtons = () => {
        let actions = [
            {
                text: props.t("Override Password"),
                onClick: togglePwdModal,
            },
        ];

        if (activeUser) {
            if (activeUser.role != "ROLE_CONTACT_USER") {
                actions.push({
                    text: props.t("Send Activation Email"),
                    onClick: handleRegenerateClick,
                });
            }

            if (activeUser.enabled == true) {
                actions.push({
                    text: props.t("De-activate"),
                    onClick: tog_standard,
                });
            } else {
                actions.push({
                    text: props.t("Activate"),
                    onClick: handleActiveUser,
                });
            }
        }

        return actions;
    };

    const getLink = () => {
        let queryParams = queryString.parse(window.location.search);
        let link = "/settings/users";
        if(queryParams && queryParams.from){
            link = queryParams.from
        }
        return link;
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        breadcrumbs={[{title: props.t("Users"), link: getLink()}]}
                        title={activeUser ? activeUser?.name : "User"}
                    />
                    {successMessage && (
                        <Row>
                            <Col className="col-12">
                                <Alert color="success">{successMessage}</Alert>
                            </Col>
                        </Row>
                    )}
                    {errorMessage && (
                        <Row>
                            <Col className="col-12">
                                <Alert color="danger">{errorMessage}</Alert>
                            </Col>
                        </Row>
                    )}

                    <Loader loading={loading} title="Tenant User Details"/>
                    {!loading && (
                        <>
                            <Row>
                                <Col className="col-12">
                                    <Card>
                                        <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                            <TableToolbar
                                                title={activeUser ? activeUser?.name : props.t("User")}
                                                buttons={getActionButtons()}
                                            />
                                        </CardHeader>
                                    </Card>
                                </Col>
                                <Col xl="4">
                                    <Card className="card h-100">
                                        <CardBody>
                                            <div className="text-center">
                                                <div>
                                                    <img
                                                        src={user?.profileImage == null ? avatar : BASE_IMAGE_URL + "/" + user?.profileImage?.s3Key}
                                                        alt=""
                                                        className="avatar-lg rounded-circle img-thumbnail"
                                                    />
                                                </div>
                                                <h5 className="mt-3 mb-1">{user?.name}</h5>
                                                <p className="text-muted">{user?.email}</p>
                                            </div>

                                            <hr className="my-4"/>

                                            <div className="text-muted">
                                                <div className="table-responsive mt-4">
                                                    <div>
                                                        <p className="mb-1">{props.t("Name")} :</p>
                                                        <h5 className="font-size-16">{user?.name}</h5>
                                                    </div>
                                                    {props.user?.phone && <div className="mt-4">
                                                        <p className="mb-1">{props.t("Phone")} :</p>
                                                        <h5 className="font-size-16">{user?.phone}</h5>
                                                    </div>}
                                                    <div className="mt-4">
                                                        <p className="mb-1">{props.t("E-mail")} :</p>
                                                        <h5 className="font-size-16">{user?.email}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="8">
                                </Col>
                            </Row>
                        </>
                    )}
                </Container>

                <DeleteModal
                    deleteCallback={() => handleDeleteUser()}
                    element={"User"}
                    t={(text) => props.t(text)}
                    showDeleteModal={modal_standard}
                    toggleModal={() => tog_standard()}
                />
                <OverridePwdModal
                    t={(text) => props.t(text)}
                    showOverridePwdModal={showOverridePwdModal && activeUser?.id}
                    toggleModal={() => togglePwdModal()}
                    userId={activeUser?.id}
                />
            </div>
        </React.Fragment>
    );
};

SuperAdminUserDetails.propTypes = {
    users: PropTypes.array,
    onGetUserDetails: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        user: state.TenantAdmin.user,
        tokenRegenerate: state.TenantAdmin.tokenRegenerate,
        userDeleted: state.TenantAdmin.userDeleted,
        userActivated: state.TenantAdmin.userActivated,
        loading: state.TenantAdmin.userLoading,
        error: state.TenantAdmin.adminUsersError
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetUserDetails: (id) => dispatch(getTenantUserDetails(id)),
    onResetRegenerateToken: () =>
        dispatch({
            type: TENANT_ADMIN_RESET_REGENERATE_TOKEN,
        }),
    onResetDeleteUser: () =>
        dispatch({
            type: TENANT_ADMIN_RESET_DELETE_USER,
        }),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(SuperAdminUserDetails)));

import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {get, put} from "../../helpers/api_helper";
import {Card, CardBody, Col, Row} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {Avatar} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import avatar from "../../assets/images/company-placeholder.png";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";

const CustomerDetails = props => {

    const {customer} = props

    const [customerDetails, setCustomerDetails] = useState({})
    const [accountManager, setAccountManager] = useState("");
    const [brandSettings, setBrandSettings] = useState(null);
    const [wayOfDelivery, setWayOfDelivery] = useState(null);
    const [termsOfDelivery, setTermsOfDelivery] = useState(null);
    const [termsOfPayment, setTermsOfPayment] = useState(null);
    const [profileImageId, setProfileImageId] = useState(null);

    const history = useNavigate();

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user.brandConfig);
    }, [])

    useEffect(() => {
        if (customer?.wayOfDelivery) {
            getWayOfDeliveryDescription(customer.wayOfDelivery);
        }
        if (customer?.termsOfDelivery) {
            getTermsOfDeliveryDescription(customer.termsOfDelivery);
        }
        if (customer?.termsOfPayment) {
            getTermsOfPaymentDescription(customer.termsOfPayment);
        }
        setCustomerDetails(customer)
        setAccountManager(customer?.accountManager?.id);
        setProfileImageId(customer?.profileImage?.s3Key)
    }, [customer])

    const updateAccountManager = async () => {
        let response = await put(`/api/customers/${customer.id}/account-manager/${accountManager}`);
    }

    const getWayOfDeliveryDescription = async (code) => {
        try{
            const response = await get(`/api/orders/wayofdeliveries/${code}`);
            if (response?.status === 200) {
                setWayOfDelivery(response.data.WayOfDelivery.Description)
                return;
            }
        }catch (e){
            console.error(e);
        }
        setWayOfDelivery(code);

    }

    const getTermsOfDeliveryDescription = async (code) => {
        try{
            const response = await get(`/api/orders/termsofdeliveries/${code}`);
            if (response?.status === 200) {
                setTermsOfDelivery(response.data.TermsOfDelivery.Description)
                return;
            }
        }catch(e){
            console.error(e)
        }
        setTermsOfDelivery(code);
    }

    const getTermsOfPaymentDescription = async (code) => {
        try{
            const response = await get(`/api/orders/termsofpayments/${code}`);
            if (response?.status === 200) {
                setTermsOfPayment(response.data.TermsOfPayment.Description)
            }
        }catch (e){
            console.error(e);
        }
        setTermsOfPayment(code);
    }

    return (<React.Fragment>
        {customerDetails && <React.Fragment>
            <Card className="card">
                <CardBody>
                        {customer?.costCenter && <div className="badge bg-soft-info p-2">{customer?.costCenter}</div>}
                        <Row>
                            <Col>
                                <div className="text-center">
                                    <label htmlFor='icon-button-file'>
                                            <Avatar
                                                variant="rounded"
                                                src={profileImageId == null ? avatar : BASE_IMAGE_URL + "/" + profileImageId}
                                                className="customer-logo"
                                            >
                                                <PersonIcon/>
                                            </Avatar>
                                    </label>
                                    <h5 className="mb-1 mt-2">{customerDetails?.name}</h5>
                                </div>
                            </Col>
                        </Row>
                        <hr className="my-4"/>
                        <div className="mb-3">
                        <Row>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Customer number")} :</p>
                                    <p className="mb-0 pb-0">{customerDetails?.customerNumber}</p>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Type")} :</p>
                                    <p className="mb-0 pb-0">{props.t("COMPANY")}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Name")} :</p>
                                    <p className="mb-0 pb-0">{customerDetails?.name}</p>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Organisation number")} :</p>
                                    <p className="mb-0 pb-0">{customerDetails?.organisationNumber}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Account manager")} :</p>
                                    <p
                                        className={`mb-0 pb-0 ${customer?.accountManager?.id ? "cursor-pointer" : ""}`}
                                        style={{color: `${customer?.accountManager?.id ? "#0404bd" : ""}`}}
                                        onClick={() => {
                                            if (customer?.accountManager?.id) {
                                                history(`/settings/users/${customer?.accountManager?.id}`)
                                            }
                                        }}>
                                        {customer?.accountManager?.name}
                                    </p>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Country code")} :</p>
                                    <p className="mb-0 pb-0">{customerDetails?.countryCode}</p>
                                </div>
                            </Col>
                        </Row><Row>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Default contact")} :</p>
                                    <p
                                        className={`mb-0 pb-0 ${customer?.defaultContactId ? "cursor-pointer" : ""}`}
                                        style={{color: `${customer?.defaultContactId ? "#0404bd" : ""}`}}
                                        onClick={() => {
                                            if (customer?.defaultContactId) {
                                                history(`/customers/${customer?.id}/users/${customer?.defaultContactId}`)
                                            }
                                        }}>
                                        {customer?.defaultContactName}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="mb-3">
                        <Row>
                            <Col>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Customer template")} :</p>
                                    <p className="mb-0 pb-0">{props.t("Template Name")}</p>
                                </div>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Price list")} :</p>
                                    <p className="mb-0 pb-0">{customer?.priceList}</p>
                                </div>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Delivery terms")} :</p>
                                    <p className="mb-0 pb-0">{wayOfDelivery}</p>
                                </div>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Payment terms")} :</p>
                                    <p className="mb-0 pb-0">{termsOfPayment}</p>
                                </div>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Currency")} :</p>
                                    <p className="mb-0 pb-0">{customer?.currency}</p>
                                </div>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Delivery service")} :</p>
                                    <p className="mb-0 pb-0">{wayOfDelivery}</p>
                                </div>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Invoice administration fee")} :</p>
                                    <p className="mb-0 pb-0">{customer?.invoiceAdministrationFee}</p>
                                </div>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Invoice freight")} :</p>
                                    <p className="mb-0 pb-0">{customer?.invoiceFreight}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="mb-3">
                        <Row>
                            <p className="mt-2 mb-0 pb-0">{props.t("Billing address")}</p>
                        </Row>
                        <Row>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Address")}{" 1:"}</p>
                                    <p className="mb-0 pb-0">{customerDetails?.address1}</p>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Phone")}{" 1:"}</p>
                                    <p className="mb-0 pb-0">{customerDetails?.phone}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Address")}{" 2:"}</p>
                                    <p className="mb-0 pb-0">{customerDetails?.address2}</p>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Phone")}{" 2:"}</p>
                                    <p className="mb-0 pb-0">{customerDetails?.phone2}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Zip code")} :</p>
                                    <p className="mb-0 pb-0">{customerDetails?.zipCode}</p>
                                    <p className="ms-2 text-muted mb-0 pb-0">{props.t("City")} :</p>
                                    <p className="mb-0 pb-0">{customerDetails?.city}</p>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="text-muted mb-0 pb-0">{props.t("Email")} :</p>
                                    <p className="mb-0 pb-0">{customerDetails?.email}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Country")} :</p>
                                    <p className="mb-0 pb-0">{customerDetails?.country}</p>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("WWW")} :</p>
                                    <p className="mb-0 pb-0">{customerDetails?.www}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="mb-3">
                        <Row>
                            <p className="mt-2 mb-0 pb-0">{props.t("Visiting address")}</p>
                        </Row>
                        <Row>
                            <Col>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Visiting address")} :</p>
                                    <p className="mb-0 pb-0">{customer?.visitingAddress}</p>
                                </div>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Visiting zip code")} :</p>
                                    <p className="mb-0 pb-0">{customer?.visitingZipCode}</p>
                                    <p className="mx-2 text-muted mb-0 pb-0">{props.t("Visiting city")} :</p>
                                    <p className="mb-0 pb-0">{customer?.visitingCity}</p>
                                </div>
                                <div className="d-flex">
                                    <p className="me-2 text-muted mb-0 pb-0">{props.t("Visiting country")} :</p>
                                    <p className="mb-0 pb-0">{customer?.visitingCountry}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>

        </React.Fragment>}
    </React.Fragment>)

}

CustomerDetails.prototype = {
    customer: PropTypes.object,
}

export default connect()(withTranslation()(CustomerDetails))

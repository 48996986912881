
import { find, orderBy } from 'lodash';
import { findIndex } from 'lodash';
import { MDBDataTable } from 'mdbreact';
import React, { useEffect, useState } from 'react'
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Alert, Card, CardBody, CardHeader, Col, Container, Row, Modal, Button, } from 'reactstrap';
import DeleteModal from '../../components/Common/DeleteModal';
import Loader from '../../components/Common/Loader';
import TableToolbar from '../../components/Common/TableToolbar';
import { del, post } from '../../helpers/api_helper';
import { getTaskCategories, getWorkLogType } from '../../store/actions';
import withRouter from "../../components/Common/withRouter";


const TaskCategory = (props) => {

    let { onGetTaskCategories, taskCategories, taskCategoriesLoading, taskCategoriesError, workLogTypes, onGetWorkLogTypes } = props;

    const [brandSettings, setBrandSettings] = useState(null);
    const [modal_standard, setmodal_standard] = useState(false);
    const [name, setName] = useState(null);
    const [editTaskCategory, setEditTaskCategory] = useState(null);
    const [deleteTaskCategoryId, setDeleteTaskCategoryId] = useState(null);
    const [showDeleteTaskCategoryModal, setShowDeleteTaskCategoryModal] = useState(false);
    const [deleteTaskCategoryTaskReferenceCount, setDeleteTaskCategoryTaskReferenceCount] = useState(0);
    const [taskCategoryList, setTaskCategoryList] = useState([]);
    const [sortState, setSortState] = useState(null);
    const numericColumns = [];
    const user = JSON.parse(localStorage.getItem("authUser"));
    const [defaultTimeslipType, setDefaultTimeslipType] = useState(null);
    const [hiddenToCustomer, setHiddenToCustomer] = useState(false);

    useEffect(() => {
        setBrandSettings(user?.brandConfig);

        onGetTaskCategories();
        onGetWorkLogTypes();
    }, [])

    useEffect(() => {
        if (deleteTaskCategoryId) {
            toggleDeleteTaskCategory();
        }
    }, [deleteTaskCategoryId])

    useEffect(() => {
        if(taskCategories){
            setTaskCategoryList(taskCategories);
        }
    }, [taskCategories]);

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setTaskCategoryList(orderBy(taskCategoryList, o => {
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])

    const handleSaveTaskCategory = async () => {
        const timeslipType = find(workLogTypes, {id:parseInt(defaultTimeslipType)});
        let response = await post(`/api/taskCategories`, { ...editTaskCategory, name, hiddenToCustomer, defaultTimeslipType: timeslipType ? timeslipType : null });
        setName(null);
        setDefaultTimeslipType(null);
        setHiddenToCustomer(false);
        onGetTaskCategories();
        tog_standard();
    };

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function toggleDeleteTaskCategory() {
        if (showDeleteTaskCategoryModal) {
            setDeleteTaskCategoryId(null);
        }
        setShowDeleteTaskCategoryModal(!showDeleteTaskCategoryModal);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const getDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Default Timeslip Type"),
                    field: "defaultTimeslipType",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Hidden to customer"),
                    field: "hiddenToCustomer",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Actions"),
                    field: "actions",
                    autoWidth: true,
                },
            ],
            rows: taskCategories?.map((taskCategory, key) => ({
                name: taskCategory.name,
                defaultTimeslipType: taskCategory.defaultTimeslipType?.name,
                hiddenToCustomer: taskCategory.hiddenToCustomer ? props.t("Yes") : props.t("No"),
                actions: (
                    <div className='d-flex'>
                        <i
                            className={`uil uil-pen me-3 text-warning font-size-18`}
                            title={(!user.tenantId || taskCategory.tenant) ? null : 'Task Category is generic and cannot be edited.'}
                            onClick={() => handleEditTaskCategory(taskCategory)}
                        ></i>
                        <i
                            className={`uil uil-trash-alt px-3 ${(!user.tenantId || taskCategory.tenant) && taskCategory.taskReferenceCount == 0 ? 'text-danger' : 'text-muted'} font-size-18`}
                            title={taskCategory.taskReferenceCount > 0 ? `Task Category cannot be deleted because it contains ${taskCategory.taskReferenceCount} task record(s).` : 
                                ((!user.tenantId || taskCategory.tenant) ? null : 'Task Category is generic and cannot be deleted.')}
                            onClick={() => {
                                if((!user.tenantId || taskCategory.tenant) && taskCategory.taskReferenceCount == 0){
                                    setDeleteTaskCategoryId(taskCategory.id);
                                }
                            }}
                        ></i>
                        <div className="form-check form-switch ms-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={taskCategory.enabled}
                                style={taskCategory.enabled ? {
                                    backgroundColor: brandSettings?.primaryBtnColor,
                                    borderColor: brandSettings?.primaryBtnColor
                                } : {}}
                                onChange={() => toggleTaskCategoryState(taskCategory)}
                            />
                            {taskCategory.enabled && <div className="badge bg-soft-success p-1 ms-2">{props.t("Enabled")}</div>}
                            {!taskCategory.enabled && <div className="badge bg-soft-warning p-1 ms-2">{props.t("Disabled")}</div>}
                        </div>
                    </div>
                ),
            })),
        };
        return data;
    };

    const toggleTaskCategoryState = async (item) => {
        let response = item.enabled ? await post(`/api/taskCategories/disabled/${item.id}`) 
            : await del(`/api/taskCategories/disabled/${item.id}`);
        if(response.status == 202) {
            let updatedTypes = [...taskCategoryList];
            var index = findIndex(updatedTypes, {id: item.id});
            updatedTypes[index] = {...updatedTypes[index], enabled: !item.enabled}
            setTaskCategoryList(updatedTypes);
        }
    };

    const handleAddTaskCategory = () => {
        setEditTaskCategory(null);
        setName(null);
        setHiddenToCustomer(false);
        setDefaultTimeslipType(null);
        tog_standard();
    };

    const handleEditTaskCategory = (taskCategory) => {
        setEditTaskCategory(taskCategory);
        setName(taskCategory.name);
        setHiddenToCustomer(taskCategory.hiddenToCustomer);
        setDefaultTimeslipType(taskCategory.defaultTimeslipType ? taskCategory.defaultTimeslipType.id : null);
        tog_standard();
    };

    const handleDeleteTaskCategory = async () => {
        let response = await del(`/api/taskCategories/${deleteTaskCategoryId}`);
        setDeleteTaskCategoryTaskReferenceCount(0)
        onGetTaskCategories();
        setDeleteTaskCategoryId(null);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor }}>
                                    <TableToolbar
                                        title={props.t("Task Categories")}
                                        buttons={[{
                                            text: props.t("New Task Category"),
                                            onClick: handleAddTaskCategory,
                                        }]}
                                    />
                                </CardHeader>
                                <Loader loading={taskCategoriesLoading} title="Task Categories" />
                                {!taskCategoriesLoading && (
                                    <CardBody>
                                        {taskCategoriesError && (
                                            <Row>
                                                <Col className="col-12">
                                                    <Alert color="danger">{taskCategoriesError}</Alert>
                                                </Col>
                                            </Row>
                                        )}

                                        <MDBDataTable
                                            responsive
                                            hover
                                            barReverse={true}
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t(
                                                "No matching records found"
                                            )}
                                            noBottomColumns={true}
                                            data={getDatatableData()}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            onSearch={(text) => console.log(text)}
                                            searching={true}
                                            key={taskCategoryList.length}
                                            sortRows={[]}
                                            onSort={({column, direction}) => {
                                                if(sortState && sortState.column == column){
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />
                                    </CardBody>)}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {editTaskCategory ? props.t("Edit Task Category") : props.t("Add Task Category")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-5 col-form-label"
                        >
                            {props.t("Task Category")}
                        </label>
                        <div className="col-md-7">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                placeholder={props.t("Enter Task Category")}
                                value={name}
                                disabled={editTaskCategory && !(!user.tenantId || editTaskCategory.tenant)}
                            />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-5 col-form-label"
                        >
                            {props.t("Default Timeslip Type")}
                        </label>
                        <div className="col-md-7">
                            <select
                                className="form-control"
                                onChange={(e) => setDefaultTimeslipType(e.target.value)}
                                value={defaultTimeslipType}
                            >
                                <option value="">{props.t("Select Timeslip Type")}</option>
                                {workLogTypes.map((workLogType) => (<option value={workLogType.id}>{workLogType.name}</option>))}
                            </select>
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-5 col-form-label">
                            {props.t("")}
                        </label>
                        <div className="col-md-7">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                defaultChecked={hiddenToCustomer}
                                onChange={(e) => setHiddenToCustomer(e.target.checked)}
                            />
                            <label className="mb-0">
                                {props.t("Hidden to customer")}
                            </label>
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <Button
                        color="primary"
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        onClick={() => handleSaveTaskCategory()}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                        disabled={!name}
                    >
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>
            <DeleteModal
                deleteCallback={() => handleDeleteTaskCategory()}
                element={"Task Category"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteTaskCategoryModal}
                toggleModal={() => toggleDeleteTaskCategory()}
                confirmation={true}
                warningMsg={deleteTaskCategoryTaskReferenceCount > 0 ? "The task category is referenced inside Tasks." : null}
            />

        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        taskCategories: state.task.taskCategories,
        taskCategoriesLoading: state.task.taskCategoriesLoading,
        taskCategoriesError: state.task.taskCategoriesError,
        workLogTypes: state.task.workLogType,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetTaskCategories: () => dispatch(getTaskCategories()),
    onGetWorkLogTypes: () => dispatch(getWorkLogType())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(TaskCategory)));


import {all, fork} from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import changePasswordSaga from "./auth/changepwd/saga"
import LayoutSaga from "./layout/saga"
import userSaga from "./users/saga";
import UserImpersonationSaga from "./users/impersonation/saga"
import projectsSaga from "./project/saga";
import languageSaga from "./language/saga";
import tenantSaga from "./tenant/saga";
import tenantAdminSaga from "./users/tenant/saga"
import taskSaga from "./task/saga"
import customerSaga from "./customer/saga"
import ecommerceSaga from "./e-commerce/saga";
import auditSaga from "./audit/saga";
import invoiceSaga from "./invoices/saga"
import offerSaga from "./offer/saga"
import projectTemplateSaga from "./project-template/saga"
import labelSaga from "./label/saga"
import cartSaga from "./e-commerce/cart/saga"
import manufacturerSaga from "./manufacturer/saga"
import configSaga from "./config/saga"
import brandSettings from "./brandsettings/saga"

export default function* rootSaga() {
    yield all([
        //public
        AccountSaga(),
        fork(AuthSaga),
        ProfileSaga(),
        ForgetSaga(),
        fork(LayoutSaga),
        fork(UserImpersonationSaga),
        fork(userSaga),
        fork(projectsSaga),
        fork(languageSaga),
        fork(tenantSaga),
        fork(tenantAdminSaga),
        fork(taskSaga),
        fork(customerSaga),
        fork(ecommerceSaga),
        fork(auditSaga),
        fork(changePasswordSaga),
        fork(invoiceSaga),
        fork(offerSaga),
        fork(projectTemplateSaga),
        fork(labelSaga),
        fork(cartSaga),
        fork(manufacturerSaga),
        fork(configSaga),
        fork(brandSettings)
    ])
}

// noinspection BadExpressionStatementJS

import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {BreadcrumbItem, Button, Card, CardBody, Col, Container, Form, Input, Label, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap"

import classnames from "classnames"
import {Link, useNavigate, useParams} from "react-router-dom"
import {Editor} from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"


//Import Breadcrumb
import {getArticleGroupList, getCateoryList, getContentTypes, getDefaultLayout, getGroupsByArticle, getLayoutList, getProductDetail, getTemplateList} from "../../store/e-commerce/actions";
import Dropzone from "react-dropzone"
import {del, get, post, postMultipart, put} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {ARTICLE_EXPORT_URL, BASE_IMAGE_URL, DOWNLOAD_URL} from "../../helpers/url_helper";
import {find, isEmpty} from "lodash";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import {withTranslation} from "react-i18next";
import ContentItem from "../Content/ContentItem";
import CustomField from "./CustomField";
import ArticleDownloadTab from "./ArticleDownloadTab";
import ArticleCertificateTab from "./ArticleCertificateTab";
import PageToolbarTop from "../../components/Common/PageToolbarTop"
import Loader from "../../components/Common/Loader";
import {isStockable} from "../../util/articleUtil"
import cogoToast from "cogo-toast"
import {toastOptions} from "../../util/dataUtil"
import EditArticlePackage from "../ArticlePackage/EditArticlePackage";

const ArticleEdit = props => {
    const {
        article,
        onGetProductDetail,
        categoryList,
        onGetCategoryList,
        layoutList,
        onGetLayoutList,
        defaultLayout,
        onGetDefaultLayout,
        loading,
        groupList,
        onGetGroupList,
        groupByArticle,
        onGetGroupListByArticle,
        templateList,
        onGetTemplates
    } = props
    let params = useParams();
    const [product, setProduct] = useState(article)
    const [activeTab, setactiveTab] = useState(2);
    const [activeTabV, setactiveTabV] = useState(0)
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [editField, setEditField] = useState({});
    const [fileUpload, setFileUpload] = useState([]);
    const [deleteImage, setDeleteImage] = useState([]);
    const [subCategoryDropdown, setSubCategoryDropdown] = useState([]);
    const [deleteContent, setDeleteContent] = useState([]);
    const [deleteCategory, setDeleteCategory] = useState([]);
    const [addCategory, setAddCategory] = useState([]);
    const [selectCategoryValue, setSelectCategoryValue] = useState("");
    const [tabs, setTabs] = useState([]);
    const [modal_standard, setmodal_standard] = useState(false);
    const [modal_fields, setmodal_fields] = useState(false);
    const [tabName, setTabName] = useState("");
    const [tabType, setTabType] = useState("");
    const [editTabId, setEditTabId] = useState("");
    const [activeCustomTab, setActiveCustomTab] = useState(null);
    const [tabLayout, setTabLayout] = useState(null);
    const [changeOrder, setChangeOrder] = useState(new Date());
    const [brandSettings, setBrandSettings] = useState(null);
    const [tabPermission, setTabPermission] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [groups, setGroups] = useState(null);
    const [activeGroup, setActiveGroup] = useState("");
    const [removeGroup, setRemoveGroup] = useState([]);
    const [groupErrorMsg, setGroupErrorMsg] = useState("");
    const [template, setTemplate] = useState(null);
    const [productFieldList, setProductFieldList] = useState([]);
    const [starChange, setStarChange] = useState(new Date());
    const [hasPackage, setHasPackage] = useState(true);
    const [articlePackages, setArticlePackages] = useState([]);
    const [deleteArticlePackage, setDeleteArticlePackage] = useState([]);
    const [packageType, setPackageType] = useState(null);

    const history = useNavigate();
    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user.brandConfig);

        const tab = JSON.parse(localStorage.getItem("articleEdit"));
        if(tab){
            setactiveTab(tab);
        }
    }, [])

    useEffect(() => {
        if (params && params.id) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            setBrandSettings(obj?.brandConfig);
            onGetProductDetail(params.id)
            onGetCategoryList()
            onGetLayoutList()
            onGetGroupList();
            onGetDefaultLayout(params.id);
            onGetGroupListByArticle(params.id)
            onGetTemplates()
        }

        return () => {
            setUploadedFiles([]);
        }
    }, [])

    useEffect(() => {
        //setGroups(groupByArticle);
        if(groupByArticle?.length > 0){
            setActiveGroup(groupByArticle[0])
            /*if(groupByArticle[0]?.tabTemplate){
                setTemplate(groupByArticle[0]?.tabTemplate?.id);
            }*/
        }
    }, [groupByArticle])

    /*useEffect(() => {
        setTabs(defaultLayout);
        if(activeCustomTab){
            setactiveTabV(activeCustomTab)
        } else if (defaultLayout && defaultLayout.length > 0) {
            setactiveTabV(0)
        }
    }, [article, defaultLayout])*/


    useEffect(() => {
        if (!isEmpty(article)) {
            setProduct(article)
            setProductFieldList(article?.fieldList);
            setHasPackage(article?.articlePackageType ? true : false)
            setPackageType(article?.articlePackageType);
            setTemplate(article?.tabTemplate?.id);
            article.articleImages.map(function(articleImage, index){
                let exist = false;
                for(let i = 0; i < uploadedFiles.length; i++){
                    if(uploadedFiles[i].id == articleImage.media.id){
                        exist = true;
                    }

                }
                if(!exist){
                    setUploadedFiles(prevState => [...prevState, articleImage.media])
                }
            });
            setTabs(article?.tabs);
            if(activeCustomTab){
                setactiveTabV(activeCustomTab)
            } else if (article?.tabs && article?.tabs.length > 0) {
                setactiveTabV(0)
            }
        }
    }, [article]);


    const evaluateCategory = (id, level, categoryList) => {
        let category = null;
        categoryList.map(function (cat, index) {
            if (cat.id == id) {
                category = cat;
            }
        });
        if (category == null) {
            return;
        }
        setSelectCategoryValue(category.id);
        if (category != null) {

            if (level === 0) {
                setSubCategoryDropdown([category]);
            } else if (level < subCategoryDropdown.length) {
                subCategoryDropdown.splice(level, subCategoryDropdown.length - level);
                let categoies = [];
                for (let i = 0; i < subCategoryDropdown.length; i++) {
                    categoies.push(subCategoryDropdown[i]);
                }
                setSubCategoryDropdown(categoies);
                if (categoies[categoies.length - 1].subCategories != null && categoies[categoies.length - 1].subCategories.length === 0) {
                    handleAddCategory(categoies[categoies.length - 1]);
                    setSubCategoryDropdown([]);
                    setSelectCategoryValue("")
                }
            } else if (category.subCategories != null && category.subCategories.length > 0) {
                setSubCategoryDropdown(prevState => ([...prevState, category]))
            } else if (category.subCategories != null && category.subCategories.length === 0) {
                handleAddCategory(category);
                setSubCategoryDropdown([]);
                setSelectCategoryValue("")
            }
        }
    }

    function toggleTab(tab) {


        //Confirm only if any edit on page.
        if (tab === 8 && !isConfirmEnabled()) {
            return;
        }

        if (activeTab !== tab) {
            if (tab >= 2 && tab <= 8) {
                localStorage.setItem("articleEdit", tab);
                setactiveTab(tab)
            }
        }
    }

    function toggleV(tab) {
        if (activeTabV !== tab) {
            setactiveTabV(tab)
        }
    }

    const isConfirmEnabled = () => {
        return !(Object.keys(editField).length === 0 && deleteImage.length === 0)
            || uploadedFiles.length > 0
            || deleteImage.length > 0
            || deleteContent.length > 0
            || addCategory.length > 0
            || deleteCategory.length > 0
            || removeGroup.length > 0
            || selectedGroup.length > 0
            || articlePackages.length > 0
            || deleteArticlePackage.length > 0
            || template != null;
    }


    const handleAcceptedFiles = async (files) => {
        let formData = new FormData();
        files.map(file => formData.append("files[]", file));
        let response = await postMultipart("/api/media", formData);
        if (response.status === 200) {
            setUploadedFiles(prevState => ([...uploadedFiles, ...response.data]));
        }
    }

    const handleChange = (key, name, value) => {
        editField[key] = {name: name, value: value};
        setEditField(editField);
    }

    const handleEditorFieldChange = (key, name, editorState) => {
        editField[key] = {name: name, value: draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        setEditField(editField);
    }

    const handleCustomFieldChange = (tab, field, value) => {
        var accessTo = getAccessLevelValue(field);
        if(editField[field.id]){
            accessTo = editField[field.id].accessTo;
        }
        editField[field.id] = {field: field, value: value, type: tab, accessTo : accessTo}
        setEditField(editField);
    }

    const handleCustomRangeMinFieldChange = (tab, field, value, minValue) => {
        var accessTo = getAccessLevelValue(field);
        var maxValue = getContentValue(field)?.maxValue;
        if(editField[field.id]){
            accessTo = editField[field.id].accessTo;
            maxValue = editField[field.id].maxValue;
        }
        editField[field.id] = {field: field, value: value, minValue: minValue, maxValue: maxValue, type: tab, accessTo : accessTo}
        setEditField(editField);
    }

    const handleCustomRangeMaxFieldChange = (tab, field, value, maxValue) => {
        var accessTo = getAccessLevelValue(field);
        var minValue = getContentValue(field)?.minValue;
        if(editField[field.id]){
            accessTo = editField[field.id].accessTo;
            minValue = editField[field.id].minValue;
        }
        editField[field.id] = {field: field, value: value, minValue: minValue, maxValue: maxValue, type: tab, accessTo : accessTo}
        setEditField(editField);
    }

    const handleAccessLevel = (field, value) => {
        var articleFieldValue = getContentValue(field);
        if(editField[field.id]){
            editField[field.id].accessTo = value;
        } else {
            editField[field.id] = {articleFieldValueId: articleFieldValue.id ,accessTo: value};
        }
        articleFieldValue.accessTo = value;
    }

    const handleImportantChange = (field, value) => {
        var articleFieldValue = getContentValue(field);
        if(editField[field.id]){
            editField[field.id].important = value;
        } else {
            editField[field.id] = {articleFieldValueId: articleFieldValue.id, important: value};
        }
        field.important = value;
        articleFieldValue.important = value;
    }

    const handleCustomFreeTextFieldChange = (tab, field, editorState) => {
        var accessTo = getAccessLevelValue(field);
        if(editField[field.id]){
             accessTo = editField[field.id].accessTo;
        }
        editField[field.id] = {field: field, value: draftToHtml(convertToRaw(editorState.getCurrentContent())), type: tab, accessTo: accessTo}
        setEditField(editField);
    }

    const handleAcceptedFilesCustomContent = async (type, field, files) => {
        let formData = new FormData();
        files.map(file => formData.append("files[]", file));
        let response = await postMultipart("/api/media", formData);
        if (response.status === 200) {
            editField[field.id] = {name: field.name, value: response.data[0], contentId: field.id, type: type.name, content: field}
            setEditField(editField);

            var obj = {content: field.id, file: response.data[0]};
            setFileUpload(prevState => ([...fileUpload, obj]));
        }
    }

    const handleAcceptedMediaContent = async (tab, field, data) => {
        editField[data.name] = {name: data.name, value: data.id, type: tab, field: field}
        setEditField(editField);
    }

    const handleUpdate = async (publish) => {
        var data = {
            fields: [],
            images: [],
            deleteImage: [],
            publish: publish,
            removeContent: [],
            addCategory: [],
            removeCategory: [],
            selectedGroup: selectedGroup,
            removeGroup: [],
            articlePackages: [],
            deleteArticlePackage: [],
            templateId: template
        }

        Object.keys(editField).map(key => {
            data.fields.push({key: key,
                name: editField[key]?.name,
                value: editField[key].value,
                minValue: editField[key].minValue,
                maxValue: editField[key].maxValue,
                fieldId: editField[key].field?.id,
                accessTo: editField[key].accessTo,
                articleFieldValueId: editField[key].articleFieldValueId,
                important: editField[key].important
            });
        });
        uploadedFiles.map(image => data.images.push(image.id));
        deleteImage.map(image => data.deleteImage.push(image.id));
        deleteContent.map(content => data.removeContent.push(content.id));
        addCategory.map(category => data.addCategory.push(category.id));
        deleteCategory.map(category => data.removeCategory.push(category.id));
        removeGroup.map(group => data.removeGroup.push(group.id));
        articlePackages.map(aPack => data.articlePackages.push(aPack));
        deleteArticlePackage.map(dPack => data.deleteArticlePackage.push(dPack?.id));

        let response = await put(`/api/article/${product.articleNumber}`, data);
        if (response.status === 200) {
            onGetProductDetail(product.articleNumber);
            toggleTab(2);
            history(`/articles/${article.articleNumber}`)
        }
    }

    const handleDeleteImg = (image, index) => {
        setDeleteImage(prevState => ([...prevState, image]));

        let deletedList = uploadedFiles;
        deletedList.splice(index, 1);
        setUploadedFiles(deletedList);
    }

    const getContentValue = (field) => {
        let value = {value: "", minValue: "", maxValue: "", accessTo: field?.permission, important: false, inherited: false, overridden: false};

        if(field?.fieldType != "ASSOCIATION_FIELD"){
            product?.fieldList?.map(function (fieldValue, index) {
                if (fieldValue.field?.id === field.id) {
                    value = fieldValue;
                }
            });
        } else {
            value.value = product[field.associationField];
        }

        if(value.value == null){
            value.value = "";
            value.minValue = "";
            value.maxValue = "";
        }
        return value;
    }

    const getEditorContent = (field) => {
        let value = "";
        if(field){
            product?.fieldList?.map(function (fieldValue, index) {
                if (fieldValue?.field?.id === field?.id) {
                    value = fieldValue.value;
                }
            });
        }
        const contentBlock = htmlToDraft(value);
        if (contentBlock) {
            return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock));
        }
        return EditorState.createWithText(value);
    }

    const getAccessLevelValue = (field) => {
        let value = "ROLE_CUSTOMER_USER";
        if (product && product.fieldList) {
            product.fieldList.map(function (articleFieldValue, index) {
                if (articleFieldValue.contentValue?.id === field.id) {
                    value = articleFieldValue.accessTo;
                }
            });
        }
        return value;
    }


    const getEditorValue = (value) => {
        if(!value){
            value = "";
        }
        const contentBlock = htmlToDraft(value);
        if (contentBlock) {
            return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock));
        }
        return EditorState.createWithText(value);
    }


    const getFileContent = (content) => {
        let value = "";
        let id = "";
        if (product && product.contentList) {
            product.contentList.map(function (pContent, index) {
                if (pContent.content?.id === content?.id) {
                    value = pContent.value;
                    id = pContent.id;
                }
            });
        }
        if (value && id) {
            return <Col><a href={DOWNLOAD_URL + "/" + value}>{props.t("Download")}</a> &nbsp;&nbsp;&nbsp; <i className="uil uil-trash-alt text-danger font-size-18 cursor-pointer"
                                                                                                onClick={() => handleDeleteContent(content, value, id)}></i></Col>
        }
        return "";
    }

    const handleDeleteContent = (content, value, id) => {
        var obj = {content: content, value: value, id: id}
        setDeleteContent(prevState => [...prevState, obj]);
        var removeIndex = -1;
        if (product && product.contentList) {
            product.contentList.map(function (pContent, index) {
                if (pContent?.content?.id === content?.id) {
                    removeIndex = index;
                }
            });
            if (removeIndex !== -1) {
                product.contentList.splice(removeIndex, 1);
                setProduct(product);
            }
        }
    }

    const handleDeleteCategory = (category) => {
        if (product && product.categories) {
            let catIndex = -1;
            product.categories.map(function (cat, index) {
                if (cat.id == category.id) {
                    catIndex = index;
                }
            })
            if (catIndex !== -1) {
                setDeleteCategory(prevState => [...prevState, category]);
                product.categories.splice(catIndex, 1);
                setProduct(product);
            }
        }
    }

    const handleDeleteGroup = (group) => {
        removeGroup.push(group.id);
        let tempGroups = groups;
        for(var i =0; i < groups.length; i++){
            if(group.id == groups[i].id){
                tempGroups.splice(i, 1);
            }
        }
        setGroups(tempGroups);
    }

    const handleAddCategory = (category) => {
        if (product) {
            if (!product.categories) {
                product.categories = []
            }
            let categoryAlredyExist = false;
            product.categories.map(function (cat, index) {
                if (cat.id == category.id) {
                    categoryAlredyExist = true;
                }
            })
            if (!categoryAlredyExist) {
                product.categories.push(category)
                setAddCategory(prevState => [...prevState, category])
            }
        }
    }

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function tog_field() {
        setmodal_fields(!modal_fields)
        removeBodyCss()
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const handleAddTab = () => {
        setEditTabId("");
        setTabName("");
        setTabLayout(null);
        tog_standard();
    }

    const handleAddTabSave = async () => {
        setActiveCustomTab(activeTabV)
        if(!tabName){
            return;
        }
        let url = `/api/content/type/article/${params.id}`;
        if(tabLayout){
            url += `?layoutId=${tabLayout}`;
        }
        let response = await post(url, {id: editTabId, name: tabName, categoryType: tabType});
        tog_standard();
        if(!editTabId){
            setTabs(prevState =>([...prevState, response.data]));
        } else {
            let tablist = tabs;
            for(let i = 0; i < tablist.length; i++){
                if(tablist[i].id === editTabId){
                    tablist[i].name = tabName;
                }
            }
            setTabs(tablist);
        }
        setTabName("")
        setEditTabId("")
        onGetDefaultLayout(params.id);
    }

    const handleDeleteCustomTab = async(id) => {
        let response = await del(`/api/content/type/${id}`);
        /*onGetDefaultLayout(params.id);*/
        setTabs(tabs?.filter(t => id != t.id));
    }

    const handleEditCustomTab = async(id, name, layoutId, type) => {
        setEditTabId(id);
        setTabName(name);
        setTabLayout(layoutId);
        setTabType(type)
        tog_standard();
    }

    const reloadDefaultLayout = () => {
        setActiveCustomTab(activeTabV)
        onGetDefaultLayout(params.id);
    }

    const handleBack = () => {
        history(`/articles/${article.articleNumber}`)
    }

    const swapArrayElements = function(arr, indexA, indexB) {
        let temp = arr[indexA];
        arr[indexA] = arr[indexB];
        arr[indexB] = temp;
    };

    const handleUp = (f, index) => {
        if(index > 0){
            var orderedList = uploadedFiles;
            swapArrayElements(orderedList, index, index - 1);
            setUploadedFiles(orderedList);
            setChangeOrder(new Date());
        }
    }

    const handleDown = (f, index) => {
        if(index < uploadedFiles.length - 1){
            var orderedList = uploadedFiles;
            swapArrayElements(orderedList, index, index + 1);
            setUploadedFiles(orderedList);
            setChangeOrder(new Date());
        }
    }

    const checkIfGroupIsEligible = async (groupId) => {
        setGroupErrorMsg("");
        if(!groupId){
            removeGroup.push(activeGroup);
            setActiveGroup("");
            setTemplate("")
            setTabs(defaultLayout);
            return true;
        }

        const existingTabs = tabs;
        for(let i = 0; i < activeGroup?.tabList?.length; i++){
            for(let j = 0; j < tabs?.length; j++){
                if(activeGroup?.tabList[i].id === tabs[j].id){
                    existingTabs.splice(j, 1);
                }
            }
        }


        //TODO Check with Jonas the group assignment part if we need to check for content.
        var selectedGroup = await getGroupById(groupId);
        setSelectedGroup([groupId])
        setGroups([selectedGroup])
        setActiveGroup(selectedGroup);

        //setTemplate(selectedGroup?.tabTemplate?.id);
        if(selectedGroup?.fieldList){
            product?.fieldList?.push(...selectedGroup.fieldList)
        }
        setProduct(product);
        if(selectedGroup?.tabList?.length > 0){
            existingTabs.push(...selectedGroup?.inheritedTabList);
        }
        setTabs(existingTabs);
        if(tabs == null || tabs.length == 0){
            setTabs(selectedGroup.inheritedTabList);
        }
        return true;
    }

    function checkIfGroupAlreadyExist(groupId){
        for(var i = 0; i < groups?.length; i++){
            if(groups[i].id == groupId){
                return true;
            }
        }
        return false;
    }

    function contains(haystack, needles) {
        return needles.map(function (needle) {
            return haystack.indexOf(needle);
        }).indexOf(-1) == -1;
    }


    const getGroupById = async (id) => {
        let response =  await get(`${url.GET_ARTICLE_GROUP_LIST}/${id}`);
        return response.data;
    }

    const handleTabOrder = async (tab, orderType) => {
        let response = await get(`/api/content/type/taborder/${orderType}?tabId=${tab.id}&&articleId=${params.id}`)
        onGetDefaultLayout(params.id)
    }

    const handleExportToExcel = () => {
        if(article){
            window.location.href = ARTICLE_EXPORT_URL + article.articleNumber;
        }
    }

    const handleImportToExcel = async (file) => {
        if(article){
            let formData = new FormData();
            formData.append("file", file);
            let response = await postMultipart(`/api/media/article/import/${article.articleNumber}`, formData);
            if (response.status === 200) {
                cogoToast.success(props.t("Article data imported successfully."), toastOptions);
                setTimeout(() => window.location.reload(), 3000)
            } else {
                cogoToast.error(props.t("There was an error while importing article data."), toastOptions);
            }
        }
    }

    const handleChooselayout = (id) => {
        let name = "";
        for(var i = 0; i < layoutList.length; i++){
            if(layoutList[i].id == id){
                name = layoutList[i].name;
                break;
            }
        }
        setTabName(name);
    }

    const handleSelectTemplate = (id) => {
        setTemplate(id);
        var template =  null;
        templateList?.map((temp) => {
            if(temp.id == id){
                template = temp;
            }
        })
        if(template != null){
            setTabs(template.tabList);
        } else {
            setTabs(defaultLayout);
        }
    }

    const handleDeleteField = async (fieldValue) => {
        var fieldListArray = productFieldList;
        let index = -1;
        for (let i = 0; i < fieldListArray.length; i++) {
            if (fieldListArray[i].id === fieldValue.id) {
                index = i;
                break;
            }
        }
        if (index != -1) {
            fieldListArray?.splice(index, 1);
            setProductFieldList([...fieldListArray]);
            let response = await del(`/api/article/${article.articleNumber}/field-value/${fieldValue.id}`);
        }

    }

    const checkIfDisabled = () => {
        return false;
    }

    const toggleInheritance = async (content, tab, field) => {
        product?.fieldList?.map(function (fieldValue, index) {
            if (fieldValue.field?.id === content.field.id) {
                fieldValue.inherited = true;
                fieldValue.overridden = !fieldValue.overridden;

                if(fieldValue.overridden){
                    product?.originalFieldList?.map(function(gFieldValue, index){
                        if(gFieldValue.field.id == content.field.id){
                            fieldValue.value = gFieldValue.value;
                            handleCustomFieldChange(tab, field, fieldValue.value)
                            return;
                        }
                    })
                } else {
                    activeGroup?.fieldList?.map(function(gFieldValue, index){
                        if(gFieldValue.field.id == content.field.id){
                            fieldValue.value = gFieldValue.value;
                            handleCustomFieldChange(tab, field, "")
                            return;
                        }
                    })
                }
            }
        });
        setStarChange(new Date());
    }

    const handleAddEditPackage = (value) => {
        let item = {
            id: value?.id,
            name: value?.name,
            type: value?.type,
            packageItems: [],
            quantity: value?.quantity,
            price: value?.price,
            articlePackagePriceLists: value?.articlePackagePriceLists
        };
        value?.packageItems?.map(a => {
            if(a?.articleDto){
                item?.packageItems?.push({
                    id : a?.id,
                    quantity : a?.quantity,
                    articleDto: { id : a?.articleDto?.id },
                    operator: a?.operator,
                    displayOrder: a?.displayOrder
                })
            }
        })
        setArticlePackages(prevState => [...prevState, item])
    }

    const handleDeleteArticlePackage = (item) => {
        setDeleteArticlePackage(prevState => [...prevState, item]);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <PageToolbarTop
                        noBackBtn={true}
                        title={article.displayName ? article.displayName : article.description}
                        breadcrumbs={[{ title: props.t("Articles"), link: "/articles" }]}
                        backBtn={handleBack}
                    />
                    <Row>
                        <Col lg="12 mt-2">
                            <div className="wizard clearfix">
                                <div className="steps clearfix">
                                    <ul>
                                        <NavItem className={classnames({current: activeTab === 2})}>
                                            <NavLink
                                                className={classnames({active: activeTab === 2})}
                                                style={{backgroundColor: brandSettings?.secondaryBackgroundColor, filter: activeTab === 2 ? 'brightness(92%)' : 'brightness(100%)'}}
                                                onClick={() => {
                                                    toggleTab(2)
                                                }}>
                                                <span className="number me-2" style={{backgroundColor:activeTab === 2 ? brandSettings?.primaryColor: 'inherit', borderColor: brandSettings?.primaryColor}}>1.</span>{" "}
                                                {props.t("Information")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={classnames({current: activeTab === 3})}>
                                            <NavLink
                                                className={classnames({active: activeTab === 3})}
                                                style={{backgroundColor: brandSettings?.secondaryBackgroundColor, filter: activeTab === 3 ? 'brightness(92%)' : 'brightness(100%)'}}
                                                onClick={() => {
                                                    toggleTab(3)
                                                }}>
                                                <span className="number me-2" style={{backgroundColor: activeTab === 3 ? brandSettings?.primaryColor: 'inherit', borderColor: brandSettings?.primaryColor}}>2.</span>{" "}
                                                {props.t("Categories")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={classnames({current: activeTab === 4})}>
                                            <NavLink
                                                className={classnames({active: activeTab === 4})}
                                                style={{backgroundColor: brandSettings?.secondaryBackgroundColor, filter: activeTab === 4 ? 'brightness(92%)' : 'brightness(100%)'}}
                                                onClick={() => {
                                                    toggleTab(4)
                                                }}>
                                                <span className="number me-2" style={{backgroundColor: activeTab === 4 ? brandSettings?.primaryColor: 'inherit', borderColor: brandSettings?.primaryColor}}>3.</span>{" "}
                                                {props.t("Groups")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={classnames({current: activeTab === 5})}>
                                            <NavLink
                                                className={classnames({active: activeTab === 5})}
                                                style={{backgroundColor: brandSettings?.secondaryBackgroundColor, filter: activeTab === 5 ? 'brightness(92%)' : 'brightness(100%)'}}
                                                onClick={() => {
                                                    toggleTab(5)
                                                }}>
                                                <span className="number me-2" style={{backgroundColor: activeTab === 5 ? brandSettings?.primaryColor: 'inherit', borderColor: brandSettings?.primaryColor}}>4.</span>{" "}
                                                {props.t("Tabs")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={classnames({current: activeTab === 6})}>
                                            <NavLink
                                                className={classnames({active: activeTab === 6})}
                                                style={{backgroundColor: brandSettings?.secondaryBackgroundColor, filter: activeTab === 6 ? 'brightness(92%)' : 'brightness(100%)'}}
                                                onClick={() => {
                                                    toggleTab(6)
                                                }}
                                            >
                                                <span className="number" style={{backgroundColor: activeTab === 6 ? brandSettings?.primaryColor: 'inherit', borderColor: brandSettings?.primaryColor}}>5.</span>{" "}
                                                {props.t("Images")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={classnames({current: activeTab === 7})}>
                                            <NavLink
                                                className={classnames({active: activeTab === 7})}
                                                style={{backgroundColor: brandSettings?.secondaryBackgroundColor, filter: activeTab === 7 ? 'brightness(92%)' : 'brightness(100%)'}}
                                                onClick={() => {
                                                    toggleTab(7)
                                                }}
                                            >
                                                <span className="number" style={{backgroundColor: activeTab === 7 ? brandSettings?.primaryColor: 'inherit', borderColor: brandSettings?.primaryColor}}>6.</span>
                                                {props.t("Packages")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={classnames({current: activeTab === 8})}>
                                            <NavLink
                                                className={classnames({active: activeTab === 8})}
                                                style={{backgroundColor: brandSettings?.secondaryBackgroundColor, filter: activeTab === 8 ? 'brightness(92%)' : 'brightness(100%)'}}
                                                onClick={() => {
                                                    toggleTab(8)
                                                }}>
                                                <span className="number" style={{backgroundColor: activeTab === 8 ? brandSettings?.primaryColor: 'inherit', borderColor: brandSettings?.primaryColor}}>7.</span>{" "}
                                                {props.t("Confirm & Save")}
                                            </NavLink>
                                        </NavItem>
                                    </ul>
                                </div>

                                <Loader loading={loading} title="Edit Article" />
                                {!loading && <>
                                <div className="content clearfix padding-side-0">
                                    <div className="body">
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId={2}>
                                                <Card>
                                                    <CardBody>
                                                        <div>
                                                            <Form>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label>
                                                                                {props.t("Display Name")}
                                                                            </Label>
                                                                            <Input
                                                                                id="displayName"
                                                                                type="text"
                                                                                className="form-control"
                                                                                onChange={(e) => handleChange("displayName", "Display Name", e.target.value)}
                                                                                defaultValue={product.displayName}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label>
                                                                                {props.t("Name")}
                                                                            </Label>
                                                                            <Input
                                                                                id="name"
                                                                                type="text"
                                                                                readOnly={true}
                                                                                className="form-control"
                                                                                onChange={(e) => handleChange("description", "Description", e.target.value)}
                                                                                defaultValue={product.description}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="12">
                                                                        <div className="mb-3">
                                                                            <Label>{props.t("Short Description")}</Label>
                                                                            <textarea className="form-control"
                                                                                      onChange={(e) => handleChange("shortDescription", "Short Description", e.target.value)}
                                                                                      defaultValue={product.shortDescription}></textarea>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="12">
                                                                        <div className="mb-3">
                                                                            <Label>{props.t("Long Description")}</Label>
                                                                            <Editor
                                                                                toolbarClassName="toolbarClassName"
                                                                                wrapperClassName="wrapperClassName"
                                                                                toolbar={{options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history']}}
                                                                                editorClassName="editorClassName"
                                                                                defaultEditorState={getEditorValue(product.longDescription)}
                                                                                onEditorStateChange={(editorState) => handleEditorFieldChange("longDescription", "Long Description", editorState)}
                                                                            />

                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label>
                                                                                 {props.t("Article Number")}
                                                                            </Label>
                                                                            <Input
                                                                                id="articleNumber"
                                                                                type="text"
                                                                                className="form-control"
                                                                                readOnly={true}
                                                                                onChange={(e) => handleChange("articleNumber", "Article Number", e.target.value)}
                                                                                defaultValue={product.articleNumber}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label>
                                                                                {props.t("Disposable Quantity")}
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                readOnly={true}
                                                                                onChange={(e) => handleChange("disposableQuantity", "Disposable Quantity", e.target.value)}
                                                                                defaultValue={isStockable(product) ? product.disposableQuantity : ""}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label>
                                                                                {props.t("EAN")}
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                readOnly={true}
                                                                                onChange={(e) => handleChange("ean", "EAN", e.target.value)}
                                                                                defaultValue={product.ean}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label>
                                                                                {props.t("Sales Price")}
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                readOnly={true}
                                                                                onChange={(e) => handleChange("salesPrice", "Sales Price", e.target.value)}
                                                                                defaultValue={product.salesPrice}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label>
                                                                                {props.t("Quantity In Stock")}
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                readOnly={true}
                                                                                onChange={(e) => handleChange("quantityInStock", "Quantity In Stock", e.target.value)}
                                                                                defaultValue={isStockable(product) ? product.quantityInStock : ""}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label>
                                                                                {props.t("Reserved Quantity")}
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                readOnly={true}
                                                                                onChange={(e) => handleChange("reservedQuantity", "Reserved Quantity", e.target.value)}
                                                                                defaultValue={isStockable(product) ? product.reservedQuantity : ""}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label>
                                                                                {props.t("Stock Place")}
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                readOnly={true}
                                                                                onChange={(e) => handleChange("stockPlace", "Stock Place", e.target.value)}
                                                                                defaultValue={product.stockPlace}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label>
                                                                                {props.t("Unit")}
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                readOnly={true}
                                                                                onChange={(e) => handleChange("unit", "Unit", e.target.value)}
                                                                                defaultValue={product.unit}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="6">
                                                                        <div className="mb-3">
                                                                            <Label>
                                                                                {props.t("Vat")}
                                                                            </Label>
                                                                            <Input
                                                                                type="text"
                                                                                className="form-control"
                                                                                readOnly={true}
                                                                                onChange={(e) => handleChange("vat", "Vat", e.target.value)}
                                                                                defaultValue={product.vat}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </TabPane>
                                            <TabPane tabId={3}>
                                                <Card>
                                                    <CardBody style={{maxWidth: "600px"}}>
                                                        <Row className="mb-2">
                                                            <label className="col-md-2 col-form-label py-0">{props.t("Category")}</label>
                                                            <div className="col-md-10">
                                                                {product.categories == null || product.categories.length === 0 &&
                                                                <label className="margin-top-7px font-weight-400">{props.t("No Category assigned for this article")}</label>}
                                                                {product.categories &&
                                                                product.categories.map(function (category, index) {
                                                                    return (
                                                                        <div className="col-md-12 margin-bottom-10px">
                                                                            <div className="badge bg-soft-success font-size-14">
                                                                                <ol className="breadcrumb margin-top-bottom-2px">
                                                                                    {category.parents.map(function (cat, index) {
                                                                                        return (
                                                                                            <BreadcrumbItem>
                                                                                                <span key={"bcat-" + index}>{cat.name}</span>
                                                                                            </BreadcrumbItem>
                                                                                        )
                                                                                    })}
                                                                                    <BreadcrumbItem>
                                                                                        <span>{category.name}  &nbsp;&nbsp; <i onClick={() => handleDeleteCategory(category)}
                                                                                                                               className={"uil uil-trash-alt text-danger cursor-pointer"}></i></span>
                                                                                    </BreadcrumbItem>
                                                                                </ol>
                                                                            </div>
                                                                        </div>)
                                                                })}
                                                            </div>
                                                        </Row>
                                                        <hr />
                                                        <Row className="mb-2">
                                                            <div className="col-md-6">    
                                                                <label className="col-form-label">{props.t("Add Category")}</label>
                                                                <div className="">
                                                                    <select className="form-control" value={selectCategoryValue} onChange={(e) => evaluateCategory(e.target.value, 0, categoryList)}>
                                                                        <option value="">{props.t("Select Category")}</option>
                                                                        {categoryList != null && 
                                                                        categoryList.filter(c => c.subCategories.length != product.categories?.filter(sc => find(sc.parents, {id: c.id})).length)
                                                                        .map(function (category, index) {
                                                                            return (<option key={"cat2-" + category.id} value={category.id}>{category.name}</option>)
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                {subCategoryDropdown != null && subCategoryDropdown.map(function (subCategory, index) {
                                                                return (
                                                                    <React.Fragment key={"child_" + index}>
                                                                            {/* <label className="col-form-label">{props.t("Select Subcategory in")} {subCategory.name}</label> */}
                                                                            <label className="col-form-label">{props.t("Add Subcategory")}</label>
                                                                            <div className="">
                                                                                <select className="form-control" onChange={(e) => evaluateCategory(e.target.value, index + 1, subCategory.subCategories)}>
                                                                                    <option value="">{props.t("Select Subcategory")}</option>
                                                                                    {subCategory != null && subCategory.subCategories.filter(sc => !find(product.categories, {id: sc.id})).map(function (cat, index) {
                                                                                        return (<option key={"cat-" + cat.id} value={cat.id}>{cat.name}</option>)
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                    </React.Fragment>)
                                                                })}
                                                            </div>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </TabPane>
                                            <TabPane tabId={4}>
                                                <Card>
                                                    <CardBody style={{maxWidth: "600px"}}>
                                                        <Row className="mb-3">
                                                            <label className="col-md-3 col-form-label">{props.t("Select Group")}</label>
                                                            <div className="col-md-9">
                                                                <select className="form-control" value={activeGroup?.id} onChange={(e) => checkIfGroupIsEligible(e.target.value)}>
                                                                    <option value="">{props.t("Select group")}</option>
                                                                    {groupList.map(function(group){
                                                                        return (<option value={group.id}>{group.name}</option>)
                                                                    })}
                                                                </select>
                                                                <br/>
                                                                <h5 style={{color:"red",fontSize:"12px"}}>{groupErrorMsg}</h5>
                                                            </div>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </TabPane>
                                            <TabPane tabId={5}>
                                                <Card key={"product-content-"+ product?.fieldList?.length}>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={2}>
                                                                <label style={{marginTop:"10px"}}>{props.t("Select Template")}</label>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <select className="form-control" defaultValue={template ? template : activeGroup?.tabTemplate?.id} onChange={(e) => handleSelectTemplate(e.target.value)} disabled={activeGroup?.tabTemplate}>
                                                                    <option value="">{props.t("Select")}</option>
                                                                    {templateList?.map((template) => {
                                                                        return <option value={template.id}>{template.name}</option>
                                                                    })}
                                                                </select>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Button
                                                                    color="primary"
                                                                    className="btn btn-secondary btn-block waves-effect waves-light pull-right float-end"
                                                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                                    onClick={tog_field}>
                                                                    {props.t("View Fields")}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                                <Card>
                                                    <CardBody>
                                                        <Row className="mb-3">
                                                            <Col md={3}>
                                                                <Nav pills className="flex-column">
                                                                    {tabs && tabs.map(function (value, index) {
                                                                            return (
                                                                                <NavLink
                                                                                    style={{cursor: "pointer"}}
                                                                                    className={classnames('mb-2', {
                                                                                        active: activeTabV === index,
                                                                                    })}
                                                                                    style={activeTabV === index ? { backgroundColor: brandSettings?.primaryColor, borderColor: brandSettings?.primaryColor } : {}}

                                                                                >
                                                                                    {value.inherited != true && <>
                                                                                        <i className="uil uil-arrow-circle-up font-size-18" onClick={() => index > 0 && handleTabOrder(value, "UP")}></i>
                                                                                        <i className="uil uil-arrow-circle-down mx-3 font-size-18" onClick={() => index < tabs.length - 1 && handleTabOrder(value, "DOWN")}></i>
                                                                                    </>}

                                                                                    <label className="mb-0" style={{cursor:"pointer"}} onClick={() => {
                                                                                             toggleV(index)
                                                                                        }}>
                                                                                        <span>{value.inherited && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}{props.t(value.name)}</span>
                                                                                    </label>

                                                                                    {value.inherited == true && <i className="uil uil-star mx-3 font-size-18 float-end" title={props.t("Inherited from assigned article group.")}></i>}
                                                                                </NavLink>
                                                                            )
                                                                    })}

                                                                    <Button
                                                                        color="primary"
                                                                        className="btn btn-primary btn-sm btn-block waves-effect waves-light"
                                                                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                                        onClick={handleAddTab}>
                                                                        <i className="fa fa-plus font-size-10"></i> &nbsp;&nbsp;
                                                                        {props.t("Add Tab")}
                                                                    </Button>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <Button
                                                                                color="primary"
                                                                                className="btn btn-primary btn-sm btn-block waves-effect waves-light mt-2 w-100"
                                                                                style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                                                onClick={handleExportToExcel}>
                                                                                <i className="fa fa-download font-size-10"></i> &nbsp;&nbsp;
                                                                                {props.t("Export to excel")}
                                                                            </Button>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label
                                                                                onChange={(e) => handleImportToExcel(e.target.files[0])}
                                                                                htmlFor="formId"
                                                                                className="btn btn-primary btn-sm btn-block waves-effect waves-light mt-2 w-100"
                                                                                style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                                            >
                                                                                <input
                                                                                    type="file"
                                                                                    name="xls"
                                                                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                                                    hidden
                                                                                    id="formId"
                                                                                />
                                                                                <i className="fa fa-upload font-size-10"></i> &nbsp;&nbsp;
                                                                                {props.t("Import from excel")}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </Nav>
                                                            </Col>
                                                            <Col md={9} key={"default_content_" + product.id}>
                                                                <TabContent key={starChange} activeTab={activeTabV} className="text-muted mt-4 mt-md-0">
                                                                    {tabs && tabs.map(function (tab, index) {
                                                                        return (
                                                                        <TabPane tabId={index} key={"content-row-" + tab.id + "-" + editField.length}>
                                                                            <Row className="mb-3">
                                                                                <Col md={6}>

                                                                                </Col>
                                                                                <Col md={6}>
                                                                                    {/*<select className="form-select form-select-sm float-right m-2" style={{display:"inline-block", width: "38%"}} onChange={(e)=> handleDefaultTabPermission(type, e.target.value)}>
                                                                                        <option value="">{props.t("Default Permission")}</option>
                                                                                        <option value="ROLE_CUSTOMER_USER">{props.t("Customer")}</option>
                                                                                        <option value="ROLE_TENANT_USER">{props.t("Tenant")}</option>
                                                                                    </select>*/}
                                                                                    {tab.articleId && <>
                                                                                    <Button color="danger"
                                                                                        className="btn btn-danger btn-sm waves-effect waves-light float-right m-2"
                                                                                        onClick={() => handleDeleteCustomTab(tab.id)}>
                                                                                        {props.t("Delete")}
                                                                                    </Button>
                                                                                    {tab.categoryType == "DATA" && <Button color="primary"
                                                                                            style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                                                            className="btn btn-secondary btn-sm waves-effect waves-light float-right m-2"
                                                                                            onClick={() => handleEditCustomTab(tab.id, tab.name, tab.layoutId, tab.categoryType)}>
                                                                                        {props.t("Edit")}
                                                                                    </Button>}
                                                                                    {!tab.layoutId && tab.categoryType == "DATA" && <ContentItem content={tab} onGetContentTypes={reloadDefaultLayout} button={true}/>}
                                                                                    </>}
                                                                                </Col>
                                                                            </Row>


                                                                            {tab.categoryType == 'FILES' &&
                                                                                <ArticleDownloadTab type={tab}
                                                                                                    article={article}
                                                                                                    handleAcceptedFilesDownloadCustomContent={handleAcceptedMediaContent}
                                                                                                    handleDeleteContent={handleDeleteContent}
                                                                                                    getFileContent={getFileContent}/>
                                                                            }

                                                                            {tab.categoryType == 'IMAGES' &&
                                                                                <ArticleCertificateTab type={tab}
                                                                                                       article={article}
                                                                                                       handleAcceptedFilesCertificateCustomContent={handleAcceptedMediaContent}
                                                                                                       handleDeleteContent={handleDeleteContent}
                                                                                                       getFileContent={getFileContent}/>

                                                                            }

                                                                            {["DATA", "FREE_TEXT"].includes(tab.categoryType) && tab.fields?.map(function (content, index) {
                                                                                return (<CustomField tab={tab} article={article} field={content} handleCustomFieldChange={handleCustomFieldChange}
                                                                                                     handleCustomRangeMinFieldChange={handleCustomRangeMinFieldChange}
                                                                                                     handleCustomRangeMaxFieldChange={handleCustomRangeMaxFieldChange}
                                                                                                     getContentValue={getContentValue}
                                                                                                     handleAccessLevel={handleAccessLevel}
                                                                                                     getEditorContent={getEditorContent}
                                                                                                     handleCustomFreeTextFieldChange={handleCustomFreeTextFieldChange}
                                                                                                     handleAcceptedFilesCustomContent={handleAcceptedFilesCustomContent} getFileContent={getFileContent}
                                                                                                     fileUpload={fileUpload} handleImportantChange={handleImportantChange}
                                                                                                     brandSettings={brandSettings}
                                                                                                     toggleInheritance={toggleInheritance}
                                                                                                     />)
                                                                            })}
                                                                        </TabPane>)
                                                                    })}
                                                                </TabContent>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </TabPane>
                                            <TabPane tabId={6}>
                                                <Card>
                                                    <CardBody>
                                                        <div>
                                                            <label>{props.t("Upload image")}</label>
                                                            <br/>
                                                            <Form>
                                                                <Dropzone
                                                                    onDrop={acceptedFiles => {
                                                                        handleAcceptedFiles(acceptedFiles)
                                                                    }}
                                                                >
                                                                    {({getRootProps, getInputProps}) => (
                                                                        <div className="dropzone">
                                                                            <div
                                                                                className="dz-message needsclick"
                                                                                {...getRootProps()}
                                                                            >
                                                                                <input {...getInputProps()} />
                                                                                <div className="mb-3">
                                                                                    <i className="display-4 text-muted uil uil-cloud-upload"/>
                                                                                </div>
                                                                                <h4>{props.t("Drop files here or click to upload")}</h4>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                                <div className="dropzone-previews mt-3" id="file-previews" key={changeOrder}>
                                                                    {uploadedFiles.map((f, i) => {
                                                                        return (
                                                                            <Card
                                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                                key={i + "-upload-1-file"}
                                                                            >
                                                                                <div className="p-2">
                                                                                    <Row className="align-items-center">
                                                                                        <Col md={1}>
                                                                                            {f.inherited == false && <>
                                                                                                <label onClick={() => handleUp(f, i)}><i
                                                                                                    className="uil uil-arrow-circle-up mx-2 text-primary font-size-18"></i></label>
                                                                                                <label onClick={() => handleDown(f, i)}><i className="uil uil-arrow-circle-down mx-2 text-primary font-size-18"></i></label>
                                                                                            </>
                                                                                            }
                                                                                        </Col>
                                                                                        <Col className="col-auto">
                                                                                            <img
                                                                                                data-dz-thumbnail=""
                                                                                                height="80"
                                                                                                className="avatar-sm rounded bg-light"
                                                                                                alt={f.name}
                                                                                                src={BASE_IMAGE_URL + "/" + f.s3Key}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col>
                                                                                             <label>
                                                                                                {f.name}
                                                                                             </label>
                                                                                            <p className="mb-0">
                                                                                                <strong>{f.formattedSize}</strong>
                                                                                            </p>

                                                                                            {f.inherited == false && <button className="btn btn-danger pull-right float-right btn-sm"
                                                                                                    onClick={(e) => {e.preventDefault(); handleDeleteImg(f, i)}}
                                                                                            ><i className="uil-trash-alt"></i></button>}

                                                                                            {f.inherited == true && <i className="uil uil-exclamation-triangle mx-3 font-size-18 float-end" title={props.t("Inherited from assigned article group.")}></i>}
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Card>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </Form>

                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </TabPane>
                                            <TabPane tabId={7}>
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={2}>
                                                                <label style={{marginTop:"10px"}}>{props.t("Package")}</label>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <input
                                                                    className="form-check-input"
                                                                    style={{marginTop: "12.5px"}}
                                                                    type="checkbox"
                                                                    defaultChecked={hasPackage}
                                                                    onChange={(e) => setHasPackage(!hasPackage)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        {hasPackage && <Row>
                                                            <Col lg={2}>
                                                                <label style={{marginTop:"10px"}}>{props.t("Package Type")}</label>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <select
                                                                    className="form-control"
                                                                    onChange={e => setPackageType(e.target.value)}
                                                                    disabled={article?.articlePackage?.length > 0}
                                                                    value={packageType}
                                                                >
                                                                    <option value="">{props.t("Select package type")}</option>
                                                                    <option value="MANUAL">{props.t("Manual")}</option>
                                                                    <option value="DYNAMIC">{props.t("Dynamic")}</option>
                                                                </select>
                                                            </Col>
                                                        </Row>}
                                                    </CardBody>
                                                </Card>
                                                {(hasPackage && packageType) && <EditArticlePackage article={product} type={packageType} handleAddEditPackage={handleAddEditPackage} handleDeleteArticlePackage={handleDeleteArticlePackage} />}
                                            </TabPane>
                                            <TabPane tabId={8}>
                                                <Card>
                                                    <CardBody>
                                                        <div className="row">
                                                            {Object.keys(editField).map((key, index) => (
                                                                <Col lg="12" key={"edited-" + index}>
                                                                    <div className="mb-3">
                                                                        {editField[key].content == null &&
                                                                        <>
                                                                            <Label>
                                                                                {editField[key].name} : &nbsp;
                                                                            </Label>
                                                                            <span dangerouslySetInnerHTML={{__html: editField[key].value}}></span>
                                                                        </>
                                                                        }
                                                                        {editField[key].content && editField[key].content.contentType != "FILE" &&
                                                                        <>
                                                                            <Label>
                                                                                {editField[key].name} : &nbsp;
                                                                            </Label>
                                                                            <span dangerouslySetInnerHTML={{__html: editField[key].value}}></span>
                                                                        </>
                                                                        }
                                                                        {editField[key].content != null && editField[key].content.contentType == "FILE" && <>
                                                                            <label>
                                                                                {editField[key].name} : &nbsp;
                                                                                <a href={DOWNLOAD_URL + "/" + editField[key].value.id}>{editField[key].value.name}</a>
                                                                            </label>
                                                                        </>}
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </div>
                                                        <div className="row">
                                                            <Col lg="12">
                                                                <div className="col-md-3">
                                                                    {addCategory.length > 0 && <label>{props.t("New Categories")}</label>}
                                                                </div>
                                                                <div className="col-mb-3">
                                                                    {addCategory.map(function (category, index) {
                                                                        return (<div className="col-md-12 margin-bottom-10px">
                                                                            <div className="badge bg-soft-success font-size-14">
                                                                                <ol className="breadcrumb margin-top-bottom-2px">
                                                                                    {category.parents.map(function (cat, index) {
                                                                                        return (
                                                                                            <BreadcrumbItem>
                                                                                                <span key={"bcat-" + index}>{cat.name}</span>
                                                                                            </BreadcrumbItem>
                                                                                        )
                                                                                    })}
                                                                                    <BreadcrumbItem>
                                                                                        <span>{category.name}  &nbsp;&nbsp;</span>
                                                                                    </BreadcrumbItem>
                                                                                </ol>
                                                                            </div>
                                                                        </div>)
                                                                    })}
                                                                </div>
                                                            </Col>
                                                        </div>
                                                        <div className="row">
                                                            <Col lg="12">
                                                                <div className="col-mb-3">
                                                                    {deleteCategory.length > 0 && <><br/><br/><label>{props.t("Removed Categories")}</label></>}
                                                                </div>
                                                                <div className="col-mb-3">
                                                                    {deleteCategory.map(function (category, index) {
                                                                        return (<div className="col-md-12 margin-bottom-10px">
                                                                            <div className="badge bg-soft-danger font-size-14">
                                                                                <ol className="breadcrumb margin-top-bottom-2px">
                                                                                    {category.parents.map(function (cat, index) {
                                                                                        return (
                                                                                            <BreadcrumbItem>
                                                                                                <span key={"bcat-" + index}>{cat.name}</span>
                                                                                            </BreadcrumbItem>
                                                                                        )
                                                                                    })}
                                                                                    <BreadcrumbItem>
                                                                                        <span>{category.name}  &nbsp;&nbsp;</span>
                                                                                    </BreadcrumbItem>
                                                                                </ol>
                                                                            </div>
                                                                        </div>)
                                                                    })}
                                                                </div>
                                                            </Col>
                                                        </div>
                                                        <div className="row">
                                                            {
                                                                deleteContent.map(function (content, index) {
                                                                    if (content?.content?.contentType == "FILE" || content?.content?.contentType == "IMAGE") {
                                                                        return (<Col lg={12}>
                                                                            <label>{content.content.name} : &nbsp;</label>
                                                                            <a href={DOWNLOAD_URL + "/" + content.value}>{props.t("Download")}</a>
                                                                        </Col>)
                                                                    } else {
                                                                        return (<Col lg={12}>
                                                                            <label>{content.content?.name} : &nbsp;</label>
                                                                            <span dangerouslySetInnerHTML={{__html: content.content?.value}}></span>
                                                                        </Col>)
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </CardBody></Card>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div>
                                <div className="actions clearfix">
                                    <ul>
                                        <li
                                            className={
                                                activeTab === 1 ? "previous disabled" : "previous"
                                            }
                                        >
                                            <Link
                                                to="#"
                                                className="btn btn-secondary"
                                                style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                onClick={() => {
                                                    toggleTab(activeTab - 1)
                                                }}
                                            >
                                                {props.t("Previous")}
                                            </Link>
                                        </li>
                                        {activeTab !== 8 && <li className={activeTab === 8 ? "next disabled" : "next"}>
                                            <Link
                                                to="#"
                                                className="btn btn-primary"

                                                style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                onClick={() => {
                                                    toggleTab(activeTab + 1)
                                                }}>
                                                {props.t("Next")}
                                            </Link>
                                        </li>}
                                        {activeTab === 8 && <>
                                            <button className="btn btn-primary"
                                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                    onClick={() => handleUpdate(false)}>{props.t("Update")}</button>
                                            &nbsp;&nbsp;
                                            <button className="btn btn-primary"
                                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                    onClick={() => handleUpdate(true)}>{props.t("Update & Publish")}</button>
                                        </>}
                                    </ul>
                                </div></>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard()
                }}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Add Tab")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label">
                            {props.t("Type")}
                        </label>
                        <div className="col-md-9">
                            <select className="form-control" value={tabType} onChange={(e) => setTabType(e.target.value)}>
                                <option value="">{props.t("Select")}</option>
                                <option value="DATA">{props.t("Data")}</option>
                                <option value="FILES">{props.t("Files")}</option>
                                <option value="IMAGES">{props.t("Images")}</option>
                                <option value="FREE_TEXT">{props.t("Free Text")}</option>
                            </select>
                        </div>
                    </Row>
                    {tabType == "DATA" && <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label">
                            {props.t("Template")}
                        </label>
                        <div className="col-md-9">
                            <select className="form-control" value={tabLayout} onChange={(e) => {setTabLayout(e.target.value); handleChooselayout(e.target.value);}}>
                                <option value="">{props.t("Select")}</option>
                                {layoutList?.map(function (layout, index) {
                                    return (<option key={"cat2-" + layout.id} value={layout.id}>{layout.name}</option>)
                                })}
                            </select>
                        </div>
                    </Row> }
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label">
                            {props.t("Label")}
                        </label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setTabName(e.target.value)}
                                placeholder={props.t("Enter field label")}
                                value={tabName}
                            />
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard()
                        }}
                        className="col-md-2 btn btn-secondary btn-sm waves-effect"
                        data-dismiss="modal">
                        {props.t("Close")}
                    </button>
                    <Button color="primary"
                            onClick={handleAddTabSave}
                            className="col-md-2 btn btn-primary btn-sm waves-effect waves-light m-2">
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>



            <Modal
                isOpen={modal_fields}
                toggle={() => {
                    tog_field()
                }}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Manage Fields")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_fields(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{maxHeight:"400px",overflowY:"scroll"}}>
                    <Row key={"fieldList-"+productFieldList?.length}>
                    {productFieldList?.map((fieldValue) => {
                        if(fieldValue.field.fieldType === 'INPUT_FIELD'){
                            return (<Row className="mb-3">
                                <label
                                    htmlFor="example-text-input"
                                    className="col-form-label">
                                    {fieldValue.field.name}
                                    <i className="uil uil-trash-alt px-3 text-danger font-size-18 pull-right float-right"
                                    onClick={() => handleDeleteField(fieldValue)}></i>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={props.t("Enter field label")}
                                    value={fieldValue.value}
                                />
                            </Row>)
                        }
                        if(fieldValue.field.fieldType === 'NUMBER_FIELD'){
                            return (<Row className="mb-3">
                                <label
                                    htmlFor="example-text-input"
                                    className="col-form-label">
                                    {fieldValue.field.name}
                                    <i className="uil uil-trash-alt px-3 text-danger font-size-18 pull-right float-right"
                                    onClick={() => handleDeleteField(fieldValue)}></i>
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={props.t("Enter field label")}
                                    value={fieldValue.value}
                                />
                                <span>{fieldValue.field.unit}</span>
                            </Row>)
                        }
                        if(fieldValue.field.fieldType === 'BOOLEAN'){
                            return (<Row className="mb-3">
                                <label
                                    htmlFor="example-text-input"
                                    className="col-form-label">
                                    {fieldValue.field.name}
                                    <i className="uil uil-trash-alt px-3 text-danger font-size-18 pull-right float-right"
                                       onClick={() => handleDeleteField(fieldValue)}></i>
                                </label>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="exampleRadios"
                                        id="exampleRadios2"
                                        defaultChecked={fieldValue.value}/>
                                    <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios2"
                                    >
                                        {props.t("Yes")}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="exampleRadios"
                                        id="exampleRadios2"
                                        defaultChecked={fieldValue.value}/>
                                    <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios2"
                                    >
                                        {props.t("No")}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="exampleRadios"
                                        id="exampleRadios2"
                                        defaultChecked={fieldValue.value}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios2"
                                    >
                                        {props.t("Not Applicable")}
                                    </label>
                                </div>
                            </Row>)
                        }
                        if(fieldValue.field.fieldType === 'FREE_TEXT'){
                            return (<Row className="mb-3">
                                <label
                                    htmlFor="example-text-input"
                                    className="col-form-label">
                                    {fieldValue.field.name}
                                    <i className="uil uil-trash-alt px-3 text-danger font-size-18 pull-right float-right"
                                       onClick={() => handleDeleteField(fieldValue)}></i>
                                </label>
                                <textarea className="form-control"
                                          defaultValue={fieldValue.value}
                                          value={fieldValue.value}></textarea>
                            </Row>)
                        }
                        if(fieldValue.field.fieldType === 'DROPDOWN'){
                            return (<Row className="mb-3">
                                <label
                                    htmlFor="example-text-input"
                                    className="col-form-label">
                                    {fieldValue.field.name}
                                    <i className="uil uil-trash-alt px-3 text-danger font-size-18 pull-right float-right"
                                       onClick={() => handleDeleteField(fieldValue)}></i>
                                </label>
                                <select className="form-control" defaultValue={fieldValue.value}>
                                    <option value={""}></option>
                                    {fieldValue?.field?.fieldOptions?.map((option) => {
                                        return (<option value={option.name}>{option.name}</option>)
                                    })}
                                </select>
                            </Row>)
                        }
                    })}
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_field()
                        }}
                        className="col-md-2 btn btn-secondary btn-sm waves-effect"
                        data-dismiss="modal">
                        {props.t("Close")}
                    </button>
                </div>
            </Modal>

        </React.Fragment>
    )
}

ArticleEdit.prototype = {
    article: PropTypes.object,
    onGetProductDetail: PropTypes.func,
    onGetContentTypes: PropTypes.func,
    onGetCategoryList: PropTypes.func,
    onGetLayoutList: PropTypes.func,
    defaultLayout: PropTypes.array,
    onGetDefaultLayout: PropTypes.func,
    loading: PropTypes.bool,
    groupList: PropTypes.array,
    onGetGroupList: PropTypes.func,
    groupByArticle: PropTypes.array,
    onGetGroupListByArticle: PropTypes.func,
    onGetTemplates: PropTypes.func,
    templateList: PropTypes.array
}

const mapStateToProps = ({ecommerce}) => ({
    article: ecommerce.product,
    contentTypes: ecommerce.contentTypes,
    categoryList: ecommerce.categoryList,
    layoutList: ecommerce.layoutList,
    defaultLayout: ecommerce.defaultLayoutList,
    loading: ecommerce.productDetailLoading,
    groupList: ecommerce.groupList,
    groupByArticle: ecommerce.groupByArticle,
    templateList: ecommerce.templateList,
})

const mapDispatchToProps = dispatch => ({
    onGetProductDetail: id => dispatch(getProductDetail(id)),
    onGetContentTypes: () => dispatch(getContentTypes()),
    onGetCategoryList: () => dispatch(getCateoryList()),
    onGetLayoutList: () => dispatch(getLayoutList()),
    onGetDefaultLayout: id => dispatch(getDefaultLayout(id)),
    onGetGroupList: () => dispatch(getArticleGroupList()),
    onGetGroupListByArticle: id => dispatch(getGroupsByArticle(id)),
    onGetTemplates: () => dispatch(getTemplateList())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(ArticleEdit))

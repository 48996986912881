import {withTranslation} from "react-i18next";
import {Badge} from "reactstrap";

const InvoiceStatus = (props) => {
    let {status} = props;

    return (<>
        {status === 'UN_SENT' && <span className={"badge bg-soft-dark"}>{props.t("Unsent")}</span>}
        {status === 'PAID' && <span className="badge bg-soft-success">{props.t("Paid")}</span>}
        {status === 'PARTIAL' && <span className="badge bg-soft-warning">{props.t("Partial")}</span>}
        {status === 'SENT' && <span className="badge bg-soft-info">{props.t("Sent")}</span>}
        {status === 'OVERDUE' && <span className="badge bg-soft-danger">{props.t("Overdue")}</span>}
        {status === 'CANCELLED' && <span className="badge bg-soft-danger">{props.t("Cancelled")}</span>}
    </>)
}

export default withTranslation()(InvoiceStatus)

import {checkIfLoading} from "../../store/selector";
import {GET_ARTICLE_GROUP_LIST} from "../../store/e-commerce/actionTypes";
import {useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {getArticleGroupList, getArticleGroupListReset, getTemplateList, getTemplateListReset} from "../../store/e-commerce/actions";
import {Alert, Card, CardBody, CardHeader, Col, Container, Modal, Row} from "reactstrap";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import Loader from "../../components/Common/Loader";
import TableToolbar from "../../components/Common/TableToolbar";
import React, {useEffect, useState} from "react";
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {orderBy} from "lodash";
import {get, postMultipart} from "../../helpers/api_helper";
import cogoToast from "cogo-toast";
import {toastOptions} from "../../util/dataUtil";
import {DOWNLOAD_URL} from "../../helpers/url_helper";
import withRouter from "../../components/Common/withRouter";

const ArticleBulkActions = (props) => {
    let {groups, onGetGroupList, templateList, onGetTemplates, onGetTemplatesReset, loading, error, onGetGroupListReset} = props;

    const [brandSettings, setBrandSettings] = useState(null);
    const [groupList, setGroupList] = useState([]);
    const [sortState, setSortState] = useState(null);
    const [modal_standard, setmodal_standard] = useState(false);
    const numericColumns = ['noOfArticles'];
    const [file, setFile] = useState(null);
    const history = useNavigate();
    const [template, setTemplate] = useState(null);
    const [importProgress, setImportProgress] = useState(false);
    const [exportJobList, setExportJobList] = useState(null);


    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetGroupList();
        onGetTemplates();
        onGetExportJob();
        return () => {
            onGetGroupListReset();
            onGetTemplatesReset();
        }
    }, [])

    useEffect(() => {
        if (groups) {
            setGroupList(groups);
        }
    }, [groups])

    useEffect(() => {
        if (sortState) {
            const {column, direction} = sortState;
            setGroupList(orderBy(groupList, o => {
                return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
            }, [direction]))
        }
    }, [sortState])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                onGetGroupListReset()
            }, 5000);
        }
    }, [error])

    const onGetExportJob = async () => {
        setExportJobList([])
        let response = await get("/api/export-job");
        if (response.status === 200) {
            setExportJobList(response?.data)
        }
    }
    const getDatatableData = () => {
        let rows = [];
        let data = {
            columns: [{
                label: props.t("Date"), field: "createdAt", autoWidth: false, width: "70%"
            }, {
                label: props.t("status"), field: "status", autoWidth: false, width: "20%"
            }, {
                label: props.t("action"), field: "action", autoWidth: false, width: "20%"
            }], rows: exportJobList?.map((item, key) => ({
                createdAt: <span>{new Date(item.createdAt).toLocaleString()}</span>,
                status: item?.status,
                action: <i className="fa fa-download font-size-10"></i>,
                clickEvent: function () {
                    handleOpen(item);
                }
            }))
        }
        return data;
    }

    const handleAdd = () => {
        history("/groups/create")
    }

    const handleOpen = (item) => {
        if(item?.media){
            window.location.href = DOWNLOAD_URL + "/" + item?.media?.id;
        }

    }

    const handleImport = async () => {
        let formData = new FormData();
        formData.append("file", file);
        let url = `api/article/import/content?templateId=${template}`;
        if (!template) {
            url = `api/article/import/content`
        }
        setImportProgress(true)
        let response = await postMultipart(url, formData);
        if (response.status === 202) {
            cogoToast.success(props.t("File imported successfully."), toastOptions);
        } else {
            cogoToast.error(props.t("There was an error while importing article data."), toastOptions);
        }
        setImportProgress(false)
    }

    const handleExport = async () => {
        let response = await get("/api/article/content");
        onGetExportJob();
    }

    return (<React.Fragment>
        <div className="page-content">
            <Container fluid>
                <PageToolbarTop
                    title={props.t("Data import/export")}
                    breadcrumbs={[{title: props.t("Products"), link: "#"}]}
                />
                <Loader loading={loading} title={"Data import/export"}/>
                <Row>
                    <Col className="col-12 article-table">
                        <Card>
                            <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                <TableToolbar
                                    title={props.t("Import")}
                                    buttons={[{
                                        text: props.t("Import"), onClick: tog_standard,
                                    }, {
                                        text: props.t("Export"), onClick: handleExport,
                                    }]}
                                />
                            </CardHeader>

                            {!loading && <CardBody>
                                {error && <Alert className={"mt-4"} color="danger" role="alert">
                                    {error}
                                </Alert>}
                                <MDBDataTable
                                    responsive
                                    hover
                                    barReverse={true}
                                    borderless
                                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                                    entriesLabel={props.t("Show entries")}
                                    infoLabel={[props.t("Showing"), props.t("to"), props.t("of"), props.t("entries"),]}
                                    searchLabel={props.t("Search") + "..."}
                                    noRecordsFoundLabel={props.t("No matching records found")}
                                    noBottomColumns={true}
                                    entries={50}
                                    entriesOptions={[50, 100, 500]}
                                    data={getDatatableData()}
                                    searching={true}
                                    sortRows={[]}
                                    onSort={({column, direction}) => {
                                        if (sortState && sortState.column == column) {
                                            setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                        } else {
                                            setSortState({column, direction});
                                        }
                                    }}
                                />
                            </CardBody>}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>

        <Modal
            isOpen={modal_standard}
            toggle={() => {
                tog_standard()
            }}>
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {props.t("Import Article Content")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        setmodal_standard(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Row className="mb-3">
                    <Col lg="4" md={4}>
                        <label>{props.t("Default Template")}</label>
                    </Col>
                    <Col lg="8" md={8}>
                        <select className="form-control" onChange={(e) => setTemplate(e.target.value)}>
                            <option value="">{props.t("Select Template")}</option>
                            {templateList?.map(function (content) {
                                return (<option value={content?.id}>{content.name}</option>)
                            })}
                        </select>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col lg="4" md={4}>
                        <label>{props.t("Select Excel")}</label>
                    </Col>
                    <Col lg="8" md={8}>
                        <label
                            onChange={(e) => setFile(e.target.files[0])}
                            htmlFor="formId"
                            className="btn btn-primary btn btn-sm waves-effect waves-light m-0"
                            style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                        >
                            <input
                                type="file"
                                name="xls"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                hidden
                                id="formId"
                            />
                            {props.t("Upload")}
                        </label>
                        &nbsp; &nbsp; {file ? file.name : ""}
                    </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                        tog_standard()
                    }}
                    className="col-md-2 btn btn-secondary btn-sm waves-effect"
                    data-dismiss="modal">
                    {props.t("Close")}
                </button>
                <button
                    type="button"
                    disabled={importProgress}
                    onClick={() => {
                        handleImport()
                    }}
                    className="col-md-4 btn btn-secondary btn-sm waves-effect"
                    data-dismiss="modal">
                    {importProgress ? props.t("Importing...") : props.t("Run Import")}
                </button>
            </div>
        </Modal>
    </React.Fragment>)
}

ArticleBulkActions.prototype = {
    groups: PropTypes.array, onGetGroupList: PropTypes.func,
    templateList: PropTypes.array,
    onGetTemplates: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        groups: state.ecommerce.groupList, error: state.ecommerce.groupListError, loading: checkIfLoading(state, GET_ARTICLE_GROUP_LIST),
        templateList: state.ecommerce.templateList,
    }

}


const mapDispatchToProps = dispatch => ({
    onGetGroupList: () => dispatch(getArticleGroupList()), onGetGroupListReset: () => dispatch(getArticleGroupListReset()),
    onGetTemplates: () => dispatch(getTemplateList()),
    onGetTemplatesReset: () => dispatch(getTemplateListReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ArticleBulkActions)))

import React, {useEffect, useState} from "react";
import {Alert, Card, CardBody, CardHeader, Container} from "reactstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next"
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getTenants, getTenantsReset} from "../../store/tenant/actions"
//import Loader from "../../components/Common/Loader";
//import TableToolbar from "../../components/Common/TableToolbar";
import {impersonateUser} from "../../store/actions";
import {orderBy} from "lodash";
import withRouter from "../../components/Common/withRouter";
import TableToolbar from "../../components/Common/TableToolbar";

export const userDetailContext = React.createContext(null)

const TenantList = (props) => {

    const {tenants, onGetTenants, loading, error, onImpersonateUser, onGetTenantsReset} = props
    const [TenantList, setTenantList] = useState([])
    const history = useNavigate();
    const [brandSettings, setBrandSettings] = useState(null);
    const [sortState, setSortState] = useState(null);
    const numericColumns = [];
    const location = useLocation();

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetTenants();
        return () => {
            onGetTenantsReset();
        }
    }, [])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                onGetTenantsReset()
            }, 5000);
        }
    }, [error])

    useEffect(() => {
        if(tenants.length > 0){
            setTenantList(tenants);
        }
    }, [tenants])

    useEffect(() => {
        if (sortState) {
            const {column, direction} = sortState;
            setTenantList(orderBy(TenantList, o => {
                return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
            }, [direction]))
        }
    }, [sortState])

    const handleTenantClick = (tenant) => {
        history(`/tenants/${tenant.id}/details`);
    }

    const getDatatableData = () => {
        console.log("Tenants - ", tenants);
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("URL"),
                    field: "domain",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: props.t("Impersonate"),
                    field: "impersonate",
                }
            ],
            rows: tenants?.map((tenant, key) => ({
                name: <label onClick={() => handleTenantClick(tenant)}>{tenant.name}</label>,
                domain: <label onClick={() => handleTenantClick(tenant)}>{tenant.domain}</label>,
                impersonate: <>
                    <button onClick={() => handleImpersonate(tenant)} className="btn btn-primary btn-sm"
                            style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}><i className="fa fa-sign-in-alt"></i></button>
                </>
            }))
        }
        return data;
    }

    const handleImpersonate = (tenant) => {
        onImpersonateUser({tenantId: tenant.id}, history, location)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardHeader
                            className="table-header-bg"
                            style={{backgroundColor: brandSettings?.primaryColor}}
                        >
                            <TableToolbar title={props.t("Tenants")}
                                          buttons={[{
                                              text: props.t("New Tenant"),
                                              onClick: () => (history('/addTenant'))
                                          }]}/>
                        </CardHeader>
                        {/*<Loader loading={loading} title="Tenants"/>*/}

                        {!loading &&
                            <CardBody className={"article-table"}>
                                {error &&
                                    <Alert className={"mt-4"} color="danger" role="alert">
                                        {error}
                                    </Alert>
                                }

                                <MDBDataTable
                                    responsive
                                    barReverse={true}
                                    hover
                                    borderless
                                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                                    entriesLabel={props.t("Show entries")}
                                    infoLabel={[props.t('Showing'), props.t('to'), props.t('of'), props.t('entries')]}
                                    searchLabel={props.t("Search") + "..."}
                                    noRecordsFoundLabel={props.t("No matching records found")}
                                    noBottomColumns={true}
                                    data={getDatatableData()}
                                    onSearch={(text) => console.log(text)}
                                    sortRows={[]}
                                    onSort={({column, direction}) => {
                                        if (sortState && sortState.column == column) {
                                            setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                        } else {
                                            setSortState({column, direction});
                                        }
                                    }}
                                />
                            </CardBody>}
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

TenantList.propTypes = {
    tenants: PropTypes.array,
    onGetTenants: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.object,
}

const mapStateToProps = state => {
    return {
        tenants: state.tenant.tenantList,
        loading: state.tenant.getTenantsLoading,
        error: state.tenant.getTenantsError,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetTenants: () => dispatch(getTenants()),
    onImpersonateUser: (obj, history, location) =>
        dispatch(impersonateUser(obj, history, location)),
    onGetTenantsReset: () => dispatch(getTenantsReset()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(TenantList)));

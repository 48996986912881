import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { withTranslation } from "react-i18next";
import { isEmpty, orderBy } from "lodash";
import { MDBDataTable } from "mdbreact";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TenantAdminGetUsers, TenantAdminGetUsersReset } from "../../store/users/tenant/actions";
import {
  TENANT_ADMIN_RESET_DELETE_USER,
  TENANT_ADMIN_RESET_REGENERATE_TOKEN,
} from "../../store/users/tenant/actionTypes";
import { filter, includes, isEqual, union } from "lodash-es";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";
import { impersonateUser } from "../../store/actions";
import withRouter from "../../components/Common/withRouter";
export const userDetailContext = React.createContext(null);

const TenantUsers = (props) => {
  let history = useNavigate();
  const {
    users,
    onGetUsers,
    tokenRegenerate,
    userDeleted,
    onResetRegenerateToken,
    onResetDeleteUser,
    userActivated,
    error,
    loading,
    onImpersonateUser,
    onGetUsersReset,
  } = props;
  const [usersList, setUsersList] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [brandSettings, setBrandSettings] = useState(null);  
  const [filter, setFilter] = useState("active")
  const [sortState, setSortState] = useState(null);
  const numericColumns = [];
  const location = useNavigate();


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"));
    setBrandSettings(user?.brandConfig);
    const prevFilter = localStorage.getItem("settingsTenantUsersFilter");
    if(prevFilter){
      setFilter(prevFilter);
    } else {
      setFilter("active");
    }
    onGetUsers(filter);
    return () => {
      onGetUsersReset();
    }
  }, [])

  useEffect(() => {
    if(error){
      setErrorMessage(error);
      setTimeout(() => {onGetUsersReset()}, 5000);
      setTimeout(() => {setErrorMessage('')}, 5000);
    }
  }, [error])

  useEffect(() => {
    console.log(users);
    if (!isEmpty(users)) setUsersList(users);
  }, [users]);

  useEffect(() => {
    if(sortState){
      const {column, direction} = sortState;
      setUsersList(orderBy(usersList, o => {
          if(column === 'customer') return o[column]?.name?.toLowerCase()
          if(column === 'role') return getRoleName(o[column])?.toLowerCase()
          if(column === 'enabled') return o[column]
          return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
      }, [direction]))
    }
  }, [sortState])

  useEffect(() => {
    // localStorage.setItem("settingsTenantUsersFilter", filter);
    onGetUsers(filter);
  }, [filter]);

  useEffect(() => {
    if (tokenRegenerate && tokenRegenerate.status) {
      setSuccessMessage(props.t("Activation link sent to user."));
      setTimeout(() => {
        setSuccessMessage("");
        onResetRegenerateToken();
      }, 3000);
    } else if (tokenRegenerate) {
      setErrorMessage(props.t("Error sending activation link"));
      setTimeout(() => {
        setErrorMessage("");
        onResetRegenerateToken();
      }, 3000);
    }
  }, [tokenRegenerate]);

  useEffect(() => {
    if (userDeleted && userDeleted.status) {
      setSuccessMessage(props.t("User deactivated successfully."));
      setTimeout(() => {
        setSuccessMessage("");
        onResetDeleteUser();
      }, 3000);
      onGetUsers();
    } else if (userDeleted) {
      setErrorMessage(props.t("Error deleting user."));
      setTimeout(() => {
        setErrorMessage("");
        onResetDeleteUser();
      }, 3000);
    }
  }, [userDeleted]);

  useEffect(() => {
    if (userActivated && userActivated.status) {
      setSuccessMessage(props.t("User activated successfully."));
      setTimeout(() => {
        setSuccessMessage("");
        onResetDeleteUser();
      }, 3000);
      onGetUsers();
    } else if (userActivated) {
      setErrorMessage(props.t("Error activated user."));
      setTimeout(() => {
        setErrorMessage("");
        onResetDeleteUser();
      }, 3000);
    }
  }, [userActivated]);
  const handleSearch = () => {
    if (!isEmpty(search)) {
      const results = filter(
        usersList,
        (user) =>
          includes(user.name?.toLowerCase(), search.toLowerCase()) ||
          includes(user.city?.toLowerCase(), search.toLowerCase()) ||
          filter(user.phone, (c) =>
            includes(c.name?.toLowerCase(), search.toLowerCase())
          ).length > 0
      );

      setSearchResults(results);
    } else {
      setSearchResults(usersList);
    }
    setSearchValue(search);
  };
  function getRoleName(role) {
    if (role === "ROLE_TENANT_ADMIN") {
      return props.t("Tenant Admin");
    } else if (role === "ROLE_TENANT_USER") {
      return props.t("Tenant User");
    } else if (role === "ROLE_CUSTOMER_ADMIN") {
      return props.t("Customer Admin");
    } else if (role === "ROLE_CUSTOMER_USER") {
      return props.t("Customer User");
    } else if (role === "ROLE_CONTACT_USER") {
      return props.t("Contact");
    } else if (role === "ROLE_SUPER_ADMIN") {
      return props.t("Super Admin");
    }
    return role;
  }
  const handleOnClick = () => {
    history("/settings/users/add");
  };
  const getDatatableData = () => {
    const rows = users?.filter((row) =>
      row.name?.toLowerCase().includes(search?.toLowerCase())
    );
    let data = {
      columns: [
        {
          label: props.t("Name"),
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: props.t("Username"),
          field: "username",
          sort: "asc",
          width: 100,
        },
        {
          label: props.t("City"),
          field: "city",
          sort: "asc",
        },
        {
          label: props.t("Phone"),
          field: "phone",
          sort: "asc",
          width: 150,
        },
        {
          label: props.t("Role"),
          field: "role",
          width: 150,
        },
        {
          label: props.t("Status"),
          field: "enabled",
          width: 150,
        },
        {
          label: props.t("Impersonate"),
          field: "impersonate",
        }
      ],
      rows: rows?.map((user, key) => ({
        name: <span onClick={() => handleOpen(user.id)}>{user.name}</span>,
        username: <span onClick={() => handleOpen(user.id)}>{user.username}</span>,
        city: <span onClick={() => handleOpen(user.id)}>{user.city}</span>,
        phone: <span onClick={() => handleOpen(user.id)}>{user.phone}</span>,
        role: <span onClick={() => handleOpen(user.id)}>{getRoleName(user.role)}</span>,
        enabled: <span onClick={() => handleOpen(user.id)}>{user.enabled ? props.t("Active") : props.t("Disabled")}</span>,
        impersonate: <><button onClick={()=> handleImpersonate(user)} className="btn btn-primary btn-sm" style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}><i className="fa fa-sign-in-alt"></i></button></> 
      })),
    };
    return data;
  };

  const handleImpersonate = (user) => {
    onImpersonateUser({ userEmail: user.username }, history, location)
  }

  const handleOpen = (id) => {
    history(`/settings/users/${id}`);
  };

  const handleFilter = (option) => {
    setFilter(option);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
              <Row>
                <Col className="col-12 article-table">
                  <Card>
                    <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                      <TableToolbar
                        title={props.t("Users")}
                        buttons={[{
                          text: props.t("New User"),
                          onClick: handleOnClick,
                        }]}
                        filter={{
                            selected: filter,
                            options : [
                                {name: props.t("Active"), value:"active"},
                                {name: props.t("Disabled"), value:"disabled"},
                                {name: props.t("All"), value: null},
                            ],
                            onSelect: handleFilter
                        }}

                      />
                    </CardHeader>
                    <Loader loading={loading} title="Tenant Users" />
          
                    {!loading && 
                    <CardBody key={"key-" + users.length}>
                      {successMessage && (
                        <Row>
                          <Col className="col-12">
                            <Alert color="success">{successMessage}</Alert>
                          </Col>
                        </Row>
                      )}
                      {errorMessage && (
                        <Row>
                          <Col className="col-12">
                            <Alert color="danger">{errorMessage}</Alert>
                          </Col>
                        </Row>
                      )}
                      <MDBDataTable
                        responsive
                        hover
                        barReverse={true}
                        borderless
                        paginationLabel={[props.t("Previous"), props.t("Next")]}
                        entriesLabel={props.t("Show entries")}
                        infoLabel={[
                          props.t("Showing"),
                          props.t("to"),
                          props.t("of"),
                          props.t("entries"),
                        ]}
                        searchLabel={props.t("Search") + "..."}
                        noRecordsFoundLabel={props.t(
                          "No matching records found"
                        )}
                        noBottomColumns={true}
                        data={getDatatableData()}
                        entries={50}
                        entriesOptions={[50, 100, 500]}
                        onSearch={(text) => console.log(text)}
                        searching={true}
                        sortRows={[]}
                        onSort={({column, direction}) => {
                        if(sortState && sortState.column == column){
                            setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                        } else {
                            setSortState({column, direction});
                        }
                        }}
                      />
                    </CardBody>}
                  </Card>
                </Col>
              </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

TenantUsers.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    users: state.TenantAdmin.users,
    tokenRegenerate: state.TenantAdmin.tokenRegenerate,
    userDeleted: state.TenantAdmin.userDeleted,
    userActivated: state.TenantAdmin.userActivated,
    loading: state.TenantAdmin.adminUsersLoading,
    error: state.TenantAdmin.adminUsersError,
    brandSettings: state.BrandSettings
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetUsers: (filter) => dispatch(TenantAdminGetUsers(filter)),
  onResetRegenerateToken: () =>
    dispatch({
      type: TENANT_ADMIN_RESET_REGENERATE_TOKEN,
    }),
  onResetDeleteUser: () =>
    dispatch({
      type: TENANT_ADMIN_RESET_DELETE_USER,
    }),
    onImpersonateUser: (obj, history, location) =>
        dispatch(impersonateUser(obj, history, location)),
  onGetUsersReset: () => dispatch(TenantAdminGetUsersReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(TenantUsers)));

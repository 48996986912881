import {Link, useNavigate, useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {checkIfLoading} from "../../store/selector";
import {GET_ARTICLE_GROUP} from "../../store/e-commerce/actionTypes";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getArticleGroup, getArticleGroupReset, getFields, getFieldsReset, getLayoutList, getTemplateList} from "../../store/e-commerce/actions";
import {Alert, Button, Card, CardBody, Col, Container, Form, Input, Label, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import {isEmpty} from "lodash";
import {del, get, post, postMultipart, put} from "../../helpers/api_helper";
import Loader from "../../components/Common/Loader";

import classnames from "classnames"
import ArticleDownloadTab from "../Articles/ArticleDownloadTab";
import ArticleCertificateTab from "../Articles/ArticleCertificateTab";
import CustomField from "../Articles/CustomField";
import draftToHtml from "draftjs-to-html";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import Dropzone from "react-dropzone";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import withRouter from "../../components/Common/withRouter";

const CreateGroup = (props) => {
    let {articleGroup, onGetArticleGroup, layoutList, onGetLayout, loading, error, onGetArticleGroupReset, fields, templateList, onGetTemplates,
        onGetFields} = props;
    let params = useParams();
    const [activeTab, setactiveTab] = useState(1)
    const [activeTabV, setactiveTabV] = useState(0)
    const [modal_standard, setmodal_standard] = useState(false);
    const [brandSettings, setBrandSettings] = useState(null);
    const [group, setGroup] = useState();
    const [conditions, setConditions] = useState();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [tabs, setTabs] = useState([]);
    const [tabName, setTabName] = useState("");
    const [tabType, setTabType] = useState("");
    const [editTabId, setEditTabId] = useState("");
    const [tabLayout, setTabLayout] = useState(null);
    const [editField, setEditField] = useState({});
    const [fileUpload, setFileUpload] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [changeOrder, setChangeOrder] = useState(new Date());
    const [deleteImage, setDeleteImage] = useState([]);
    const history = useNavigate();

    useEffect(() => {
        onGetFields();
        onGetLayout();
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        setName("");
        setDescription("");

        return () => {
            setGroup(null);
            setName(null);
            setDescription(null)
            onGetArticleGroupReset();
            setConditions([]);
        }
    }, [])

    useEffect(() => {
        onGetTemplates()
        if (params.id) {
            onGetArticleGroup(params.id)
        } else {
            setGroup({name: "", description: "", fields: [{id: "", name: ""}]});
            setConditions([{id: "", name: ""}])
        }
        return () => {
            setGroup(null);
            setName(null);
            setDescription(null)
            onGetArticleGroupReset();
            setConditions([]);
        }
    }, [params.id])

    useEffect(() => {
        if(!isEmpty(group)){
            group.name = name;
            setGroup(group);
        }
    }, [name])

    useEffect(() => {
        if(!isEmpty(group)){
            group.description = description;
            setGroup(group);
        }
    }, [description])

    function toggleV(tab) {
        if (activeTabV !== tab) {
            setactiveTabV(tab)
        }
    }


    useEffect(() => {
        setName(null);
        setConditions([]);
        if (!isEmpty(articleGroup)) {
            setGroup(articleGroup);
            setName(articleGroup.name)
            setDescription(articleGroup.description)
            setSelectedTemplateId(articleGroup?.tabTemplate?.id)
            /*for(var i = 0; i < articleGroup?.fields?.length; i++){
                let layout = getLayoutById(articleGroup.fields[i]?.layoutId)
                if(articleGroup.fields[i]){
                    articleGroup.fields[i]["layout"] = layout
                }
            }*/
            setConditions(articleGroup.fields);

            articleGroup?.articleGroupImages?.map(function(articleImage, index){
                let exist = false;
                for(let i = 0; i < uploadedFiles.length; i++){
                    if(uploadedFiles[i].id == articleImage.media.id){
                        exist = true;
                    }

                }
                if(!exist){
                    setUploadedFiles(prevState => [...prevState, articleImage.media])
                }
            })
        }
        //console.log("Group", articleGroup)
        //console.log("Conditions", conditions);

    }, [articleGroup, fields])

    const getLayoutById = (id) => {
        for (let j = 0; j < layoutList.length; j++) {
            if(layoutList[j].id == id){
                return layoutList[j];
            }
        }
        return null;
    }

    const handleAddCondition = () => {
        setConditions(prevState => [...prevState, {id: "", name: ""}])
    }

    const handleRemoveCondition = (removeIndex) => {
        if (conditions?.length >= 1) {
            let newArray = conditions.filter((item, index) => {
                return index !== removeIndex
            })
            setConditions(newArray);
        }
    }

    const handleSelectLayout = (index, id) => {
        let tempArray = conditions.slice();
        tempArray[index]["layout"] = null;
        if (id) {
            let layout = getLayoutById(id);
            if (layout) {
                tempArray[index]["layout"] = layout;
            }
        }
        setConditions(tempArray);
    }

    const handleSelectContent = (index, id) => {
        let tempArray = conditions.slice();
        tempArray[index].id = id;
        setConditions(tempArray);
    }

    const handleEditSave = async (step = 2) => {
        if(group?.name){
            var data = {
                id: group?.id,
                name: group?.name,
                description: group?.description,
                contentId: []
            }
            for (var i = 0; i < conditions.length; i++) {
                data.contentId.push(parseInt(conditions[i].id))
            }
            let response = await post("/api/article/group", data);
            setGroup(response?.data);
            setTabs(response?.data?.inheritedTabList);
            setSelectedTemplateId(response?.data?.tabTemplate?.id)
            toggleTab(step);
        }
    }

    const handleBack = () => {
        setGroup({});
        setName("");
        setDescription("")
        history("/groups")
    }

    const handleDelete = async () => {
        if(group?.id){
            let response = await del(`/api/article/group/${group.id}`)
            history("/groups")
        }

    }

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab)
            }
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    const handleChooselayout = (id) => {
        let name = "";
        for(var i = 0; i < layoutList.length; i++){
            if(layoutList[i].id == id){
                name = layoutList[i].name;
                break;
            }
        }
        setTabName(name);
    }

    const handleAddTabSave = async () => {
        let url = `/api/article/group/${group.id}`;
        if(tabLayout != null){
            url += "?layoutId="+tabLayout;
        }
        let response = await put(url, {id: editTabId, name: tabName, categoryType: tabType});
        setGroup(response?.data);
        setTabs(response?.data?.inheritedTabList);

        tog_standard();
    }

    const handleTabOrder = async (tab, orderType) => {
        let response = await get(`/api/article/group/tab-order/${orderType}?tabId=${tab.id}&&groupId=${group.id}`)
        setGroup(response?.data);
        setTabs(response?.data?.inheritedTabList);
    }

    const handleAddTab = () => {
        setEditTabId("");
        setTabName("");
        setTabLayout(null);
        tog_standard();
    }

    const handleDeleteCustomTab = async(id) => {
        let response = await del(`/api/content/type/${id}`);

    }

    const handleEditCustomTab = async(id, name, layoutId, type) => {
        setEditTabId(id);
        setTabName(name);
        setTabLayout(layoutId);
        setTabType(type)
        tog_standard();
    }


    const handleChange = (key, name, value) => {
        editField[key] = {name: name, value: value};
        setEditField(editField);
    }

    const getContentValue = (field) => {
        let value = {value: "", accessTo: field?.permission, important: false};
        group?.fieldList?.map(function (fieldValue, index) {
            if (fieldValue.field?.id === field.id) {
                value = fieldValue;
            }
        });
        return value;
    }

    const handleCustomFieldChange = (tab, field, value) => {
        var accessTo = getAccessLevelValue(field);
        if(editField[field.id]){
            accessTo = editField[field.id].accessTo;
        }
        editField[field.id] = {field: field, value: value, type: tab, accessTo : accessTo}
        setEditField(editField);
    }

    const handleCustomRangeMinFieldChange = (tab, field, value, minValue) => {
        var accessTo = getAccessLevelValue(field);
        var maxValue = getContentValue(field)?.maxValue;
        if(editField[field.id]){
            accessTo = editField[field.id].accessTo;
            maxValue = editField[field.id].maxValue;
        }
        editField[field.id] = {field: field, value: value, minValue: minValue, maxValue: maxValue, type: tab, accessTo : accessTo}
        setEditField(editField);
    }

    const handleCustomRangeMaxFieldChange = (tab, field, value, maxValue) => {
        var accessTo = getAccessLevelValue(field);
        var minValue = getContentValue(field)?.minValue;
        if(editField[field.id]){
            accessTo = editField[field.id].accessTo;
            minValue = editField[field.id].minValue;
        }
        editField[field.id] = {field: field, value: value, minValue: minValue, maxValue: maxValue, type: tab, accessTo : accessTo}
        setEditField(editField);
    }

    const getAccessLevelValue = (field) => {
        let value = "ROLE_CUSTOMER_USER";
        group?.fieldList?.map(function (articleFieldValue, index) {
            if (articleFieldValue.contentValue?.id === field.id) {
                value = articleFieldValue.accessTo;
            }
        });
        return value;
    }

    const handleAccessLevel = (field, value) => {
        var articleFieldValue = getContentValue(field);
        if(editField[field.id]){
            editField[field.id].accessTo = value;
        } else {
            editField[field.id] = {articleFieldValueId: articleFieldValue.id ,accessTo: value};
        }
        articleFieldValue.accessTo = value;
    }

    const handleCustomFreeTextFieldChange = (tab, field, editorState) => {
        var accessTo = getAccessLevelValue(field);
        if(editField[field.id]){
            accessTo = editField[field.id].accessTo;
        }
        editField[field.id] = {field: field, value: draftToHtml(convertToRaw(editorState.getCurrentContent())), type: tab, accessTo: accessTo}
        setEditField(editField);
    }

    const handleAcceptedFilesCustomContent = async (type, field, files) => {
        let formData = new FormData();
        files.map(file => formData.append("files[]", file));
        let response = await postMultipart("/api/media", formData);
        if (response.status === 200) {
            editField[field.id] = {name: field.name, value: response.data[0], contentId: field.id, type: type.name, content: field}
            setEditField(editField);

            var obj = {content: field.id, file: response.data[0]};
            setFileUpload(prevState => ([...fileUpload, obj]));
        }
    }

    /*const getFileContent = (content) => {
        let value = "";
        let id = "";

        group?.fieldList?.map(function (pContent, index) {
                if (pContent.content?.id === content?.id) {
                    value = pContent.value;
                    id = pContent.id;
                }
            });
        if (value.length != null && id.length != null) {
            return <Col><a href={DOWNLOAD_URL + "/" + value}>{props.t("Download")}</a> &nbsp;&nbsp;&nbsp; <i className="uil uil-trash-alt text-danger font-size-18 cursor-pointer"
                                                                                                             onClick={() => handleDeleteContent(content, value, id)}></i></Col>
        }
        return "";
    }*/

    const handleDeleteContent = (content, value, id) => {
        var obj = {content: content, value: value, id: id}
        var removeIndex = -1;

        group?.fieldList?.map(function (pContent, index) {
            if (pContent?.content?.id === content?.id) {
                removeIndex = index;
            }
        });
        if (removeIndex !== -1) {
            group?.fieldList?.splice(removeIndex, 1);
            setGroup(group);
        }

    }

    const handleImportantChange = (field, value) => {
        var articleFieldValue = getContentValue(field);
        if(editField[field.id]){
            editField[field.id].important = value;
        } else {
            editField[field.id] = {articleFieldValueId: articleFieldValue.id, important: value};
        }
        field.important = value;
        articleFieldValue.important = value;
    }


    const getEditorContent = (field) => {
        let value = "";
        if(field){
            group?.fieldList?.map(function (fieldValue, index) {
                if (fieldValue?.field?.id === field?.id) {
                    value = fieldValue.value;
                }
            });
        }
        const contentBlock = htmlToDraft(value);
        if (contentBlock) {
            return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock));
        }
        return EditorState.createWithText(value);
    }

    const handleAcceptedMediaContent = async (tab, field, data) => {
        editField[data.name] = {name: data.name, value: data.id, type: tab, field: field}
        setEditField(editField);
    }

    const [contentBtn, setContentBtn] = useState(false);
    const handleSaveContent = async () => {
        setContentBtn(true);
        var data = {
            fields : [],
            images: [],
            deleteImage: [],
            templateId: selectedTemplateId
        };
        Object.keys(editField).map(key => {
            data.fields.push({key: key,
                name: editField[key]?.name,
                value: editField[key].value,
                minValue: editField[key].minValue,
                maxValue: editField[key].maxValue,
                fieldId: editField[key].field?.id,
                accessTo: editField[key].accessTo,
                articleFieldValueId: editField[key].articleFieldValueId,
                important: editField[key].important
            });
        });
        uploadedFiles.map(image => data.images.push(image.id));
        deleteImage.map(image => data.deleteImage.push(image.id));
        let response = await put(`/api/article/group/content/${group.id}`, data);
        setContentBtn(false)
        if (response.status === 200) {
            handleBack();
        }

    }

    const handleSelectTemplate = (id) => {
        var template =  null;
        templateList?.map((temp) => {
            if(temp.id == id){
                template = temp;
            }
        })
        if(template != null){
            setTabs(template.tabList);
        } else {
            setTabs(group.inheritedTabList);
        }
        setSelectedTemplateId(id);
    }

    const handleAcceptedFiles = async (files) => {
        let formData = new FormData();
        files.map(file => formData.append("files[]", file));
        let response = await postMultipart("/api/media", formData);
        if (response.status === 200) {
            setUploadedFiles(prevState => ([...uploadedFiles, ...response.data]));
        }
    }

    const handleUp = (f, index) => {
        if(index > 0){
            var orderedList = uploadedFiles;
            swapArrayElements(orderedList, index, index - 1);
            setUploadedFiles(orderedList);
            setChangeOrder(new Date());
        }
    }

    const handleDown = (f, index) => {
        if(index < uploadedFiles.length - 1){
            var orderedList = uploadedFiles;
            swapArrayElements(orderedList, index, index + 1);
            setUploadedFiles(orderedList);
            setChangeOrder(new Date());
        }
    }

    const handleDeleteImg = (image, index) => {
        setDeleteImage(prevState => ([...prevState, image]));

        let deletedList = uploadedFiles;
        deletedList.splice(index, 1);
        setUploadedFiles(deletedList);
    }


    const swapArrayElements = function(arr, indexA, indexB) {
        let temp = arr[indexA];
        arr[indexA] = arr[indexB];
        arr[indexB] = temp;
    };

    return (<React.Fragment>
        <div className="page-content">
            <Container fluid>
                <PageToolbarTop
                    noBackBtn={true}
                    title={props.t(params?.id ? "Edit Article Group" : "Create Article Group")}
                    breadcrumbs={[{title: props.t("Article Groups"), link: "/groups"}]}
                    canDelete={handleDelete}
                />
                <Row key={params.id}>
                    <div className="wizard clearfix">
                        <div className="steps clearfix">
                            <ul>
                                <NavItem
                                    className={classnames({ current: activeTab === 1 })}>
                                    <NavLink
                                        className={classnames({ current: activeTab === 1 })}
                                        onClick={() => {
                                            setactiveTab(1)
                                        }}
                                    >
                                        <span className="number">1.</span>{" "}
                                        {props.t("Details")}
                                    </NavLink>
                                </NavItem>
                                <NavItem className={classnames({ current: activeTab === 2 })}>
                                    <NavLink
                                        className={classnames({ active: activeTab === 2 })}
                                        onClick={() => {
                                            handleEditSave(2);
                                        }}
                                    >
                                        <span className="number me-2">2.</span>{" "}
                                        {props.t("Content")}
                                    </NavLink>
                                </NavItem>
                                <NavItem className={classnames({ current: activeTab === 3 })}>
                                    <NavLink
                                        className={classnames({ active: activeTab === 3 })}
                                        onClick={() => {
                                            handleEditSave(3);
                                        }}
                                    >
                                        <span className="number me-2">3.</span>{" "}
                                        {props.t("Images")}
                                    </NavLink>
                                </NavItem>

                            </ul>
                        </div>
                        <div className="body">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <Card>
                                        {/*<CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                            <TableToolbar
                                                title={props.t(params?.id ? "Edit Article Group" : "Create Article Group")}
                                            />
                                        </CardHeader>*/}
                                        <Loader loading={loading} title={"Article Group Details"}/>
                                        {error &&
                                            <Alert className={"mt-4 mx-4"} color="danger" role="alert">
                                                {error}
                                            </Alert>
                                        }
                                        {!loading && !error && <CardBody key={"condition" + params.id}>
                                            <Row>
                                                <Col lg="12" md={12}>
                                                    <div className="mb-3">
                                                        <Label>
                                                            {props.t("Name")}
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            onInput={(e) => setName(e.target.value)}
                                                            value={name}
                                                            defaultValue={group?.name}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12}>
                                                    <div className="mb-3">
                                                        <Label>
                                                            {props.t("Description")}
                                                        </Label>
                                                        <textarea className="form-control"
                                                                  onInput={(e) => setDescription(e.target.value)}
                                                                  defaultValue={description}
                                                                  value={description}></textarea>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {conditions?.map(function (content, index) {
                                                    return (<><Row>
                                                        {/*<Col lg="3">
                                        <div className="mb-3">
                                            <Label>
                                                {props.t("Template")}
                                            </Label>
                                            <select className="form-control" value={content?.layout?.id} onChange={(e) => handleSelectLayout(index, e.target.value)}>
                                                <option value="">{props.t("Select template")}</option>
                                                {layoutList.map(function (layout) {
                                                    return (<option value={layout.id}>{layout.name}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </Col>*/}
                                                        <Col lg="3" key={"content-list" + fields?.length}>
                                                            <div className="mb-3">
                                                                <Label>
                                                                    {props.t("Content")}
                                                                </Label>
                                                                <select className="form-control" value={content?.id} onChange={(e) => handleSelectContent(index, e.target.value)}>
                                                                    <option value="">{props.t("Select content")}</option>
                                                                    {fields?.map(function (content) {
                                                                        return (<option value={content?.id}>{content.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col lg="3">
                                                            {conditions.length >= 1 && <div className="mb-3 mt-1">
                                                                <Button onClick={() => handleRemoveCondition(index)} className="btn btn-danger btn-block mt-4"><i className="fa fa-trash-alt"></i> </Button>
                                                            </div>}
                                                        </Col>
                                                    </Row>
                                                    </>)
                                                })}
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <Button
                                                        color="primary"
                                                        className="col-md-2 btn btn-primary btn-sm btn-block waves-effect waves-light"
                                                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                        onClick={handleAddCondition}
                                                    >
                                                        <i className="fa fa-plus font-size-10"></i> &nbsp;&nbsp;
                                                        {props.t("Add Condition")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardBody>}
                                    </Card>

                                </TabPane>
                                <TabPane tabId={2}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col lg={2}>
                                                    <label style={{marginTop:"10px"}}>{props.t("Select Template")}</label>
                                                </Col>
                                                <Col lg={4}>
                                                    <select value={selectedTemplateId} className="form-control" onChange={(e) => handleSelectTemplate(e.target.value)}>
                                                        <option value="">{props.t("Select")}</option>
                                                        {templateList?.map((template) => {
                                                            return <option value={template.id}>{template.name}</option>
                                                        })}
                                                    </select>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <Row className="mb-3">
                                                <Col md={3}>
                                                    <Nav pills className="flex-column">
                                                        {tabs && tabs.map(function (value, index) {
                                                            return (
                                                                <NavLink
                                                                    style={{cursor: "pointer"}}
                                                                    className={classnames('mb-2', {
                                                                        active: activeTabV === index,
                                                                    })}
                                                                    style={activeTabV === index ? { backgroundColor: brandSettings?.primaryColor, borderColor: brandSettings?.primaryColor } : {}}

                                                                >
                                                                    <i className="uil uil-arrow-circle-up font-size-18" onClick={() => index > 0 && handleTabOrder(value, "UP")}></i>
                                                                    <i className="uil uil-arrow-circle-down mx-3 font-size-18" onClick={() => index < tabs.length - 1 && handleTabOrder(value, "DOWN")}></i>
                                                                    <label className="mb-0" style={{cursor:"pointer"}} onClick={() => {
                                                                        toggleV(index)
                                                                    }}>
                                                                        <span>{props.t(value.name)}</span>
                                                                    </label>
                                                                </NavLink>
                                                            )
                                                        })}

                                                        <Button
                                                            color="primary"
                                                            className="btn btn-primary btn-sm btn-block waves-effect waves-light"
                                                            style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                            onClick={handleAddTab}>
                                                            <i className="fa fa-plus font-size-10"></i> &nbsp;&nbsp;
                                                            {props.t("Add Tab")}
                                                        </Button>
                                                    </Nav>
                                                </Col>
                                                <Col md={9} key={"default_content_" + articleGroup.id}>
                                                    <TabContent activeTab={activeTabV} className="text-muted mt-4 mt-md-0">
                                                        {tabs && tabs.map(function (tab, index) {
                                                            return (
                                                                <TabPane tabId={index} key={"content-row-" + tab.id}>
                                                                    <Row className="mb-3">
                                                                        <Col md={6}>

                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <Button color="danger"
                                                                                    className="btn btn-danger btn-sm waves-effect waves-light float-right m-2"
                                                                                    onClick={() => handleDeleteCustomTab(tab.id)}>
                                                                                {props.t("Delete")}
                                                                            </Button>
                                                                            <Button color="primary"
                                                                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                                                    className="btn btn-secondary btn-sm waves-effect waves-light float-right m-2"
                                                                                    onClick={() => handleEditCustomTab(tab.id, tab.name, tab.layoutId, tab.categoryType)}>
                                                                                {props.t("Edit")}
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                    {tab.categoryType == 'FILES' &&
                                                                        <ArticleDownloadTab type={tab}
                                                                                            article={group}
                                                                                            handleAcceptedFilesDownloadCustomContent={handleAcceptedMediaContent}
                                                                                            handleDeleteContent={handleDeleteContent}
                                                                                            getFileContent={null}/>
                                                                    }

                                                                    {tab.categoryType == 'IMAGES' &&
                                                                        <ArticleCertificateTab type={tab}
                                                                                               article={group}
                                                                                               handleAcceptedFilesCertificateCustomContent={handleAcceptedMediaContent}
                                                                                               handleDeleteContent={handleDeleteContent}
                                                                                               getFileContent={null}/>

                                                                    }

                                                                    {["DATA", "FREE_TEXT"].includes(tab.categoryType) && tab.fields?.map(function (content, index) {
                                                                        return (<CustomField tab={tab} field={content} handleCustomFieldChange={handleCustomFieldChange}
                                                                                             handleCustomRangeMinFieldChange={handleCustomRangeMinFieldChange}
                                                                                             handleCustomRangeMaxFieldChange={handleCustomRangeMaxFieldChange}
                                                                                             getContentValue={getContentValue}
                                                                                             handleAccessLevel={handleAccessLevel}
                                                                                             getEditorContent={getEditorContent}
                                                                                             handleCustomFreeTextFieldChange={handleCustomFreeTextFieldChange}
                                                                                             handleAcceptedFilesCustomContent={handleAcceptedFilesCustomContent} getFileContent={null}
                                                                                             fileUpload={fileUpload} handleImportantChange={handleImportantChange} isGroup={true}/>)
                                                                    })}
                                                                </TabPane>)
                                                        })}
                                                    </TabContent>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId={3}>
                                    <Card>
                                        <CardBody>
                                            <div>
                                                <label>{props.t("Upload image")}</label>
                                                <br/>
                                                <Form>
                                                    <Dropzone
                                                        onDrop={acceptedFiles => {
                                                            handleAcceptedFiles(acceptedFiles)
                                                        }}
                                                    >
                                                        {({getRootProps, getInputProps}) => (
                                                             <div className="dropzone">
                                                                <div
                                                                    className="dz-message needsclick"
                                                                    {...getRootProps()}
                                                                >
                                                                    <input {...getInputProps()} />
                                                                    <div className="mb-3">
                                                                        <i className="display-4 text-muted uil uil-cloud-upload"/>
                                                                    </div>
                                                                    <h4>{props.t("Drop files here or click to upload")}</h4>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                    <div className="dropzone-previews mt-3" id="file-previews" key={changeOrder}>
                                                        {uploadedFiles.map((f, i) => {
                                                            return (
                                                                <Card
                                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                    key={i + "-upload-1-file"}
                                                                >
                                                                    <div className="p-2">
                                                                        <Row className="align-items-center">
                                                                            <Col md={1}>
                                                                                <label onClick={() => handleUp(f, i)}><i className="uil uil-arrow-circle-up mx-2 text-primary font-size-18"></i></label>
                                                                                <label onClick={() => handleDown(f, i)}><i className="uil uil-arrow-circle-down mx-2 text-primary font-size-18"></i></label>
                                                                            </Col>
                                                                            <Col className="col-auto">
                                                                                <img
                                                                                    data-dz-thumbnail=""
                                                                                    height="80"
                                                                                    className="avatar-sm rounded bg-light"
                                                                                    alt={f.name}
                                                                                    src={BASE_IMAGE_URL + "/" + f.s3Key}
                                                                                />
                                                                            </Col>
                                                                            <Col>
                                                                                <label>
                                                                                    {f.name}
                                                                                </label>
                                                                                <p className="mb-0">
                                                                                    <strong>{f.formattedSize}</strong>
                                                                                </p>

                                                                                <button className="btn btn-danger pull-right float-right btn-sm"
                                                                                        onClick={(e) => {e.preventDefault(); handleDeleteImg(f, i)}}
                                                                                ><i className="uil-trash-alt"></i></button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Card>
                                                            )
                                                        })}
                                                    </div>
                                                </Form>

                                            </div>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                            </TabContent>
                        </div>
                        <div className="actions clearfix">
                            <ul>
                                <li
                                    className={"previous"}
                                >
                                    <Link
                                        to="#"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            activeTab == 1 ? handleBack() : toggleTab(activeTab - 1)
                                        }}
                                    >
                                        {props.t("Back")}
                                    </Link>
                                </li>
                                {activeTab === 1 &&

                                        <li
                                            className={activeTab === 2 ? "next disabled" : "next"}
                                        >
                                            <Button
                                                to="#"
                                                className="btn btn-primary"
                                                style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                onClick={() => {
                                                    handleEditSave();
                                                }}
                                            >
                                                {props.t("Save & Continue")}
                                            </Button>
                                        </li>
                                }
                                {activeTab === 2 &&
                                <li
                                    className={"next"}
                                >
                                    <Button
                                        to="#"
                                        className="btn btn-primary"
                                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                        onClick={() => setactiveTab(3)}
                                    >
                                        {props.t("Next")}
                                    </Button>
                                </li>}
                                {activeTab === 3 &&
                                    <li
                                        className={"next"}
                                    >
                                        <Button
                                            className="btn btn-primary"
                                            disabled={contentBtn}
                                            style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                            onClick={handleSaveContent}
                                        >
                                            {contentBtn ? props.t("Saving"): props.t("Save")}
                                        </Button>
                                    </li>}
                            </ul>
                        </div>

                    </div>

                </Row>
            </Container>
        </div>

        <Modal
            isOpen={modal_standard}
            toggle={() => {
                tog_standard()
            }}>
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {props.t("Add Tab")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        setmodal_standard(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">

                <Row className="mb-3">
                    <label
                        htmlFor="example-text-input"
                        className="col-md-3 col-form-label">
                        {props.t("Type")}
                    </label>
                    <div className="col-md-9">
                        <select className="form-control" value={tabType} onChange={(e) => setTabType(e.target.value)}>
                            <option value="">{props.t("Select")}</option>
                            <option value="DATA">{props.t("Data")}</option>
                            <option value="FILES">{props.t("Files")}</option>
                            <option value="IMAGES">{props.t("Images")}</option>
                            <option value="FREE_TEXT">{props.t("Free Text")}</option>
                        </select>
                    </div>
                </Row>
                {tabType == "DATA" && <Row className="mb-3">
                    <label
                        htmlFor="example-text-input"
                        className="col-md-3 col-form-label">
                        {props.t("Template")}
                    </label>
                    <div className="col-md-9">
                        <select className="form-control" value={tabLayout} onChange={(e) => {setTabLayout(e.target.value); handleChooselayout(e.target.value);}}>
                            <option value="">{props.t("Select")}</option>
                            {layoutList?.map(function (layout, index) {
                                return (<option key={"cat2-" + layout.id} value={layout.id}>{layout.name}</option>)
                            })}
                        </select>
                    </div>
                </Row> }
                <Row className="mb-3">
                    <label
                        htmlFor="example-text-input"
                        className="col-md-3 col-form-label">
                        {props.t("Label")}
                    </label>
                    <div className="col-md-9">
                        <input
                            className="form-control"
                            type="text"
                            onChange={(e) => setTabName(e.target.value)}
                            placeholder={props.t("Enter field label")}
                            value={tabName}
                        />
                    </div>
                </Row>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => {
                        tog_standard()
                    }}
                    className="col-md-2 btn btn-secondary btn-sm waves-effect"
                    data-dismiss="modal">
                    {props.t("Close")}
                </button>
                <Button color="primary"
                        onClick={handleAddTabSave}
                        className="col-md-2 btn btn-primary btn-sm waves-effect waves-light m-2">
                    {props.t("Save")}
                </Button>
            </div>
        </Modal>

    </React.Fragment>)

}

CreateGroup.prototype = {
    articleGroup: PropTypes.object,
    onGetArticleGroup: PropTypes.func,
    layoutList: PropTypes.array,
    templateList: PropTypes.array,
    onGetLayout: PropTypes.func,
    fields: PropTypes.array,
    onGetFields: PropTypes.func,
    onGetTemplates: PropTypes.func
}

const mapStateToProps = state => {
    return {
        articleGroup: state.ecommerce.articleGroup,
        error: state.ecommerce.articleGroupError,
        layoutList: state.ecommerce.layoutList,
        fields: state.ecommerce.fields,
        templateList: state.ecommerce.templateList,
        loading: checkIfLoading(state, GET_ARTICLE_GROUP)
    }
}

const mapDispatchToProps = dispatch => ({
    onGetArticleGroup: (id) => dispatch(getArticleGroup(id)),
    onGetLayout: () => dispatch(getLayoutList()),
    onGetArticleGroupReset: () => dispatch(getArticleGroupReset()),
    onGetFields: () => dispatch(getFields('ARTICLE_FIELD_TYPE')),
    onGetFieldsReset: () => dispatch(getFieldsReset()),
    onGetTemplates: () => dispatch(getTemplateList())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(CreateGroup)))

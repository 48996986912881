import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Modal, Row} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next"
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getCateoryList, getCateoryListReset} from "../../store/e-commerce/actions";
import {get, post} from "../../helpers/api_helper";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import {ecommerceActionTypes} from "../../store/e-commerce/actionTypes";
import {checkIfLoading} from "../../store/selector";
import Loader from "../../components/Common/Loader";
import { orderBy } from "lodash";
import withRouter from "../../components/Common/withRouter";

const CategoryList = (props) => {
    const [modal_standard, setmodal_standard] = useState(false)
    const {categoryList, onGetCategoryList, error, loading, onGetCategoryListReset } = props
    const [contentTypeList, setContentTypeList] = useState([])
    const [name, setName] = useState("");
    const [editCategory, setEditCategory] = useState();
    const [search, setSearch] = useState("");
    const history = useNavigate();
    const [brandSettings, setBrandSettings] = useState(null)
    const [sortState, setSortState] = useState(null);
    const numericColumns = [];

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        return () => {
          onGetCategoryListReset();
        }
    }, [])

    useEffect(() => {
      if(error){
        setTimeout(() => {onGetCategoryListReset()}, 5000);
      }
    }, [error])

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    useEffect(() => {
        onGetCategoryList()
        //setContentTypeList(categoryList)
    }, [])

    useEffect(() => {
        setContentTypeList(categoryList)
    }, [categoryList])

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setContentTypeList(orderBy(contentTypeList, o => {
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])

    const handleSave = async () => {
        var data = {name : name};
        if(editCategory){
            data = {id: editCategory.id, name: name};
        }
        let response = await post("/api/category", data);
        setName("");
        setEditCategory(null);
        tog_standard();
        onGetCategoryList();
    }

    const handleAdd = () => {
        setEditCategory(null);
        setName("")
        tog_standard();
    }

    const handleOpen = (item) => {
        history(`/categories/${item.id}/subcategory`)
    }

    const handleOrder = async (type, item) => {
        let response = await get(`/api/category/order/${type}?id=${item.id}`)
        onGetCategoryList();
    }

    const getDatatableData = () => {
        const rows = contentTypeList?.filter(row => row.name?.toLowerCase().includes(search?.toLowerCase()))
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    autoWidth: false,
                    width: "70%"
                },
                {
                    label: props.t("Created Date"),
                    field: "createdAt",
                    autoWidth: false,
                    width: "20%"
                }
            ],
            rows: categoryList?.map((item, key) => ({
                name: <>
                        <i className="uil uil-arrow-circle-up text-primary font-size-18" onClick={()=> key > 0 && handleOrder("UP", item)}></i>
                        <i className="uil uil-arrow-circle-down mx-3 text-primary font-size-18" onClick={()=> key < contentTypeList.length - 1 && handleOrder("DOWN", item)}></i>
                        <span onClick={()=> handleOpen(item)}>{item.name}</span>
                    </>,
                createdAt: <span onClick={()=> handleOpen(item)}>{new Date(item.createdAt).toLocaleString()}</span>,
                /*clickEvent: function (){
                    handleOpen(item);
                }*/
            }))
        }
        return data;
   }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <PageToolbarTop
              title={props.t("Categories")}
              breadcrumbs={[{ title: props.t("Products"), link: "#" }]}
            />
            <Row>
              <Col className="col-12 article-table">
                <Card>
                  <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor}}>
                    <TableToolbar
                      title={props.t("Categories")}
                      buttons={[{
                        text: props.t("Add Category"),
                        onClick: handleAdd,
                      }]}
                    />
                  </CardHeader>
                  <Loader loading={loading} title="Categories" />
                  {!loading &&
                  <CardBody key={"category_"+contentTypeList.length}>
                    {error &&
                      <Alert className={"mt-4"} color="danger" role="alert">
                          {error}
                      </Alert>
                    }
                    <MDBDataTable
                      responsive
                      barReverse={true}
                      hover
                      borderless
                      paginationLabel={[props.t("Previous"), props.t("Next")]}
                      entriesLabel={props.t("Show entries")}
                      infoLabel={[
                        props.t("Showing"),
                        props.t("to"),
                        props.t("of"),
                        props.t("entries"),
                      ]}
                      searchLabel={props.t("Search") + "..."}
                      noRecordsFoundLabel={props.t("No matching records found")}
                      noBottomColumns={true}
                      entries={50}
                      entriesOptions={[50, 100, 500]}
                      data={getDatatableData()}
                      searching={true}
                      sortRows={[]}
                      onSort={({column, direction}) => {
                          if(sortState && sortState.column == column){
                              setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                          } else {
                              setSortState({column, direction});
                          }
                      }}
                    />
                  </CardBody>}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <Modal
          isOpen={modal_standard}
          toggle={() => {
            tog_standard();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {editCategory && "Edit Category"}
              {!editCategory && "Add Category"}
            </h5>
            <button
              type="button"
              onClick={() => {
                setmodal_standard(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row className="mb-3">
              <label
                htmlFor="example-text-input"
                className="col-md-3 col-form-label"
              >
                {props.t("Name")}
              </label>
              <div className="col-md-9">
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  placeholder={props.t("Enter Category Name")}
                  value={name}
                  defaultValue={name}
                />
              </div>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                tog_standard();
              }}
              className="col-md-2 btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              {props.t("Close")}
            </button>
            <Button
              color="primary"
              onClick={handleSave}
              style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
              className="col-md-2 btn btn-primary waves-effect waves-light"
            >
              {props.t("Save")}
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    );
};

CategoryList.propTypes = {
    categoryList: PropTypes.array,
    onGetCategoryList: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.object,
}

const mapStateToProps = state => {
    return {
        categoryList: state.ecommerce.categoryList,
        error: state.ecommerce.getCategoryListError,
        loading: checkIfLoading(state, ecommerceActionTypes.GET_CATEGORY_LIST)
    }
}

const mapDispatchToProps = dispatch => ({
    onGetCategoryList: () => dispatch(getCateoryList()),
    onGetCategoryListReset: () => dispatch(getCateoryListReset())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(CategoryList)));

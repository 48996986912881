import React from "react";
import {Col, Container, Row,} from "reactstrap";
import {withTranslation} from "react-i18next";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import {useParams} from "react-router-dom";
import CustomerUserForm from "./CustomerUserForm";

const CustomerUserFormContainer = (props) => {
    const loggedInUser = JSON.parse(localStorage.getItem("authUser"));
    const { userId } = useParams();

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <PageToolbarTop
                    noBackBtn={true}
                    breadcrumbs={[{title: props.t("Users"), link: loggedInUser?.customerId ? '/customer/users' : '/users'}]}
                    title={userId ? props.t("Edit User") : props.t("Create User")}
                />
                <Row>
                    <Col>
                        <CustomerUserForm location={props.location} customerId={props.customerId} />
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>;
};

export default withTranslation()(CustomerUserFormContainer);

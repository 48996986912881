import {Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import TableToolbar from "../../components/Common/TableToolbar";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Loader from "../../components/Common/Loader";
import {MDBDataTable} from "mdbreact";
import {get} from "../../helpers/api_helper";
import moment from "moment/moment";
import withRouter from "../../components/Common/withRouter";
import {hasModuleEnabled, hasModuleEnabledForLoggedInUser} from "../../util/helperFunctions";


const MONTH = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const getCurrentWeek = () => {
    let today = new Date();
    const startOfTheWeek = JSON.parse(localStorage.getItem("authUser"))?.config?.startOfTheWeek;
    if ("SUN" === startOfTheWeek) {
        return {
            startDate: new Date(today.setDate(today.getDate() - today.getDay())),
            endDate: new Date(today.setDate(today.getDate() - today.getDay() + 6)),
            type: 'Week'
        }
    } else {
        let startDate = moment(today).startOf('isoWeek');
        var date = {
            startDate: startDate.toDate(),
            endDate: new Date(startDate.toDate().setDate(startDate.toDate().getDate() - startDate.toDate().getDay() + 7)),
            type: 'Week'
        }
        return date;
    }

}

const getCurrentMonth = () => {
    const date = new Date();
    return {
        startDate: new Date(date.getFullYear(), date.getMonth(), 1),
        endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
        type: 'Month'
    }

}

const getCurrentYear = () => {
    return {
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(new Date().getFullYear(), 11, 31),
        type: 'Year'
    }
}

const get5CurrentYear = () => {
    const currentYear = new Date().getFullYear();
    const startDate = new Date(currentYear - 5, 0, 1);
    const endDate = new Date(currentYear, 11, 31);

    return {
        startDate,
        endDate,
        type: '5Year'
    };
}

const getCurrentQuarter = () => {
    const today = new Date();
    const quarter = Math.floor((today.getMonth() / 3))
    const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
    const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
    return {
        startDate: startFullQuarter,
        endDate: endFullQuarter,
        type: 'Quarter'
    }
}
const Statistics = (props) => {
    const {} = props;

    const [loading, setLoading] = useState(false);
    const history = useNavigate();

    const user = JSON.parse(localStorage.getItem("authUser"));
    const level = localStorage.getItem("level");
    const [brandSettings, setBrandSettings] = useState(user.brandConfig);

    const STATISTICS_TYPE = [{"module": "ARTICLES", "key": "ARTICLE", "value": "Articles"},
        {"module":"ARTICLES", "key": "ARTICLE_GROUP", "value": "Article Groups"},
        {"module":"CUSTOMERS", "key": "CUSTOMER", "value": "Customers"},
        {"module":"PROJECTS", "key": "PROJECT", "value": "Projects"},
        {"module":"ARTICLES", "key": "MANUFACTURER", "value": "Manufacturers"}];
    const [sType, setSType] = useState(STATISTICS_TYPE[0].key);
    const [statistics, setStatistics] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [date, setDate] = useState(getCurrentMonth());
    const [type, setType] = useState("Month");
    const [typeLabel, setTypeLabel] = useState("Month");


    const getStatisticsType = () => {
        let statisticsType = [];
        STATISTICS_TYPE.forEach(item => {
            if (hasModuleEnabled(user?.tenantFeatures, item?.module)) {
                statisticsType.push(item);
            }
        })
        return getStatisticsType;
    }

    useEffect(() => {

        getStatistics(moment(date?.startDate).format("YYYY-MM-DD"), moment(date?.endDate).format("YYYY-MM-DD"));

        if (type === "Week") {
            const startOfTheWeek = JSON.parse(localStorage.getItem("authUser"))?.config?.startOfTheWeek;
            let weekNumber = moment(date?.endDate).format('W');
            if (startOfTheWeek === "SUN") {
                weekNumber = moment(date?.endDate).startOf('isoWeek').format('W');
            }
            setTypeLabel(props.t("Week") + " " + weekNumber)
        }
        if (type === "Month") {
            setTypeLabel(props.t(MONTH[date?.startDate.getMonth()]) + " " + date?.startDate.getFullYear())
        }
        if (type === "Quarter") {
            setTypeLabel(props.t(MONTH[date?.startDate.getMonth()]) + " - " + props.t(MONTH[date?.endDate.getMonth()]) + ", " + date?.endDate.getFullYear())
        }
        if (type === "Year") {
            setTypeLabel(date?.startDate.getFullYear())
        }
        if (type === "5Year") {
            setTypeLabel(date?.startDate.getFullYear() + " - " + date?.endDate.getFullYear())
        }
    }, [date, sType])

    useEffect(() => {
        if (type === "Week") {
            let currentWeek = getCurrentWeek();
            setDate(currentWeek);
        }
        if (type === "Month") {
            let currentMonth = getCurrentMonth();
            setDate(currentMonth);
        }
        if (type === "Quarter") {
            let currentQuarter = getCurrentQuarter();
            setDate(currentQuarter);
        }
        if (type === "Year") {
            let currentYear = getCurrentYear();
            setDate(currentYear)
        }
        if (type === "5Year") {
            let last5Years = get5CurrentYear();
            setDate(last5Years);
        }


    }, [type])

    const getLabel = () => {
        if(sType === "ARTICLE"){
           return props.t("Quantity")
        }
        if(sType === "ARTICLE_GROUP"){
            return props.t("Quantity")
        }
        if(sType === "CUSTOMER"){
            return props.t("No. of Orders")
        }
        if(sType === "PROJECT"){
            return props.t("No. of Orders")
        }
        if(sType === "MANUFACTURER"){
            return props.t("Quantity")
        }
    }
    const getDatatableData = () => {
        let cols = [
            {
                label: props.t("Name"),
                field: "name",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("Document Number"),
                field: "documentNumber",
                autoWidth: true,
            },
            {
                label: getLabel(),
                field: "count",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("Revenue"),
                field: "revenue",
                sort: "asc",
                autoWidth: true,
            }
        ];

        if(sType === "CUSTOMER"){
            cols.push({
                label: props.t("Customer Manager"),
                field: "customerManager",
                sort: "asc",
                autoWidth: true,
            })
        }
        if(sType === "PROJECT"){
            cols.push({
                label: props.t("Project Leader"),
                field: "projectLeader",
                sort: "asc",
                autoWidth: true,
            })
        }
        let data = {
            columns: cols,
            rows: statistics?.map((item, key) => ({
                name: <span onClick={(e) => openClick(item)}>{item?.name}</span>,
                documentNumber: <span onClick={(e) => openClick(item)}>{item?.documentNumber}</span>,
                count: <span onClick={(e) => openClick(item)}>{item?.count}</span>,
                revenue: <span onClick={(e) => openClick(item)}>{item?.revenue.toFixed(2)}</span>,
                customerManager: <span onClick={(e) => openClick(item)}>{item?.customerManager}</span>,
                projectLeader: <span onClick={(e) => openClick(item)}>{item?.projectLeader}</span>,
            })),
        };
        return data;
    };

    const getStatistics = async (startDate, endDate) => {
        setLoading(true)
        setDisabled(true)
        let response = await get(`/api/report/top-sales?type=${sType}&startDate=${startDate}&endDate=${endDate}`);
        setLoading(false);
        setDisabled(false);
        if (response.status === 200) {
            setStatistics(response?.data);
        }
    }

    const substract = () => {
        if (type === 'Week') {
            let startDate = date.startDate;
            let endDate = date.endDate;

            startDate.setDate(startDate.getDate() - 7);
            endDate.setDate(endDate.getDate() - 7)
            setDate({
                startDate: startDate,
                endDate: endDate,
                type: type
            })
        }
        if (type === "Month") {
            let startDate = date.startDate;
            let endDate = date.endDate;

            setDate({
                startDate: moment(startDate).subtract(1, "months").set("date", 1).toDate(),
                endDate: moment(startDate).subtract(1, "months").endOf("months").toDate(),
                type: type
            })
        }
        if (type === "Quarter") {
            const today = date.startDate;
            const quarter = Math.floor((today.getMonth() / 3)) - 1

            const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
            const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
            setDate({
                startDate: startFullQuarter,
                endDate: endFullQuarter,
                type: type
            })

        }
        if (type === "Year") {
            let startDate = date.startDate;
            let endDate = date.endDate;
            setDate({
                startDate: new Date(startDate.getFullYear() - 1, 0, 1),
                endDate: new Date(endDate.getFullYear() - 1, 11, 31),
                type: type
            })
        }
        if (type === "5Year") {
            let startDate = date.startDate;
            let endDate = date.endDate;
            setDate({
                startDate: new Date(startDate.getFullYear() - 5, 0, 1),
                endDate: new Date(endDate.getFullYear() - 5, 11, 31),
                type: '5Year'
            })
        }

    }

    const addition = () => {

        if (type === 'Week') {
            let startDate = date.startDate;
            let endDate = date.endDate;

            startDate.setDate(startDate.getDate() + 7);
            endDate.setDate(endDate.getDate() + 7)
            setDate({
                startDate: startDate,
                endDate: endDate,
                type: type
            })
        }
        if (type === "Month") {
            let startDate = date.startDate;
            let endDate = date.endDate;

            let lastMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);

            setDate({
                startDate: moment(startDate).add(1, "months").set('date', 1).toDate(),
                endDate: moment(startDate).add(1, "months").endOf("months").toDate(),
                type: type
            })
        }
        if (type === "Quarter") {
            const today = date.startDate;
            const quarter = Math.floor((today.getMonth() / 3)) + 1
            const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
            const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
            setDate({
                startDate: startFullQuarter,
                endDate: endFullQuarter,
                type: type
            })
        }
        if (type === "Year") {
            let startDate = date.startDate;
            let endDate = date.endDate;
            setDate({
                startDate: new Date(startDate.getFullYear() + 1, 0, 1),
                endDate: new Date(endDate.getFullYear() + 1, 11, 31),
                type: type
            })
        }
        if (type === "5Year") {
            let startDate = date.startDate;
            let endDate = date.endDate;
            setDate({
                startDate: new Date(startDate.getFullYear() + 5, 0, 1),
                endDate: new Date(endDate.getFullYear() + 5, 11, 31),
                type: '5Year'
            });
        }


    }

    const handleTypeChange = (t) => {
        setType(t);
    }

    const openClick = (item) => {
        if(sType === "ARTICLE"){
            history(`/articles/${item.documentNumber}`);
        }
        if(sType === "ARTICLE_GROUP"){
            history(`/groups/detail/${item.document}`);
        }
        if(sType === "CUSTOMER"){
            history(`/customers/${item.documentNumber}/profile`);
        }
        if(sType === "PROJECT"){
            history(`/projects/${item.documentNumber}`);
        }
        if(sType === "MANUFACTURER"){
            history(`/manufacturers/${item.documentNumber}/details`);
        }

    }

    return (<React.Fragment>
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col className="col-12 article-table">
                        <Card>
                            <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                <TableToolbar
                                    title={props.t("Statistics")}
                                />
                            </CardHeader>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col xs={4}>
                                        <div
                                            className="btn-group"
                                            role="group"
                                            aria-label="Basic example"
                                        >
                                            <Button color="primary" disabled={disabled} onClick={substract} style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}> <i
                                                class="uil uil-arrow-left"></i> </Button>
                                            <Button color="light" outline={true} style={{borderColor: brandSettings?.primaryBtnColor}}>{typeLabel}</Button>
                                            <Button color="primary" disabled={moment(date?.endDate).isSameOrAfter(new Date(), 'day') || disabled} onClick={addition}
                                                    style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}> <i class="uil uil-arrow-right"></i></Button>
                                        </div>
                                    </Col>
                                    <Col xs={5}>
                                    </Col>
                                    <Col xs={3}>
                                        <div
                                            className="btn-group float-end"
                                            role="group"
                                            aria-label="Basic example"
                                        >
                                            <Button color="primary" disabled={disabled} style={{backgroundColor: type == 'Week' ? brandSettings?.secondaryBtnColor : brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                    onClick={() => handleTypeChange("Week")}>{props.t("Week")}</Button>
                                            <Button color="primary" disabled={disabled} style={{backgroundColor: type == 'Month' ? brandSettings?.secondaryBtnColor : brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                    onClick={() => handleTypeChange("Month")}>{props.t("Month")}</Button>
                                            <Button color="primary" disabled={disabled}
                                                    style={{backgroundColor: type == 'Quarter' ? brandSettings?.secondaryBtnColor : brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                    onClick={() => handleTypeChange("Quarter")}>{props.t("Quarter")}</Button>
                                            <Button color="primary" disabled={disabled} style={{backgroundColor: type == 'Year' ? brandSettings?.secondaryBtnColor : brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                    onClick={() => handleTypeChange("Year")}>{props.t("Year")}</Button>
                                            <Button color="primary" disabled={disabled}
                                                    style={{backgroundColor: type == '5Year' ? brandSettings?.secondaryBtnColor : brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                    onClick={() => handleTypeChange("5Year")}>{props.t("Last 5 Years")}</Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <label
                                        htmlFor="example-text-input"
                                        className="col-md-2 col-form-label"
                                    >
                                        {props.t("Type")}
                                    </label>
                                    <div className="col-md-10">
                                        <select defaultValue={STATISTICS_TYPE.filter(value => hasModuleEnabledForLoggedInUser(value?.module))[0]} className="form-control" onChange={(e) => setSType(e.target.value)}>
                                            <option value={""}>{props.t("Choose type")}</option>
                                            {STATISTICS_TYPE?.map(function (lang) {
                                                if(hasModuleEnabledForLoggedInUser(lang?.module)) {
                                                    return <option value={lang.key}>{lang.value}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </Row>
                                <Row>
                                    <Col className="col-12 article-table" key={type + "-" + statistics.length}>
                                        <Loader loading={loading} title="Logs"/>
                                        <MDBDataTable
                                            responsive
                                            searching={true}
                                            barReverse={true}
                                            hover
                                            borderless
                                            paging={false}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            data={getDatatableData()}
                                            onSearch={(text) => console.log(text)}
                                            sortRows={[]}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>

    </React.Fragment>)
}

Statistics.propTypes = {
    /*tenants: PropTypes.array,
    onGetTenants: PropTypes.func*/
}

const mapStateToProps = (state) => {
    return {
        //tenants: state.tenant.tenantList,
    };
};

const mapDispatchToProps = (dispatch) => ({
    //onGetTenants: () => dispatch(getTenants()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(Statistics)));

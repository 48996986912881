import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardTitle, Col, Row,} from "reactstrap";
import {useNavigate, useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {del, post, postMultipart, put} from "../../helpers/api_helper";
import {API_ERR_MSG} from "../../util/constants";
import {Avatar, IconButton} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import loadingIcon from "../../assets/images/loading.gif";
import avatar from "../../assets/images/users/user-img-placeholder-2.png";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import {editProfile, GetCustomers, getTenantUserDetails, getTenantUserDetailsReset} from "../../store/actions";
import Loader from "../../components/Common/Loader";
import {toastOptions} from "../../util/dataUtil";
import cogoToast from "cogo-toast";
import Select from "react-select";
import moment from 'moment-timezone';
import withRouter from "../../components/Common/withRouter"; //import moment-timezone

const timezones = moment.tz.names();
const options = timezones.map((tz) => {
    return { value: tz, label: tz }
});
export const customerUserManagementContext = React.createContext(null);

const CustomerUserForm = (props) => {
    const {
        onEditProfile, 
        editProfileLoading,
        onGetUserDetails,
        error,
        success,
        user,
        onGetUserDetailsReset,
        customers, 
        onGetCustomers,
        useModal, tog_standard,
        customerId
    } = props;
    let params = useParams();
    const [customerUsersList, setCustomerUsersList] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedCustomerUser, setSelectedCustomerUser] = useState(undefined);
    const [brandSettings, setBrandSettings] = useState(null)

    const [customerList, setCustomerList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [deleteBtnText, setDeleteBtnText] = useState("Delete Profile Picture");
    const [profileImageId, setProfileImageId] = useState(null);
    const [imageUploading, setImageUploading] = useState(false);
    const [selectedFile, setselectedFile] = useState(null)
    const ERR_MSG = "Error occurred while updating profile. If issue persists, Please contact system administrator";

    let history = useNavigate();
    const loggedInUser = JSON.parse(localStorage.getItem("authUser"));

    const initFormFields = {
        name: "",
        email: "",
        title: "",
        id: 0,
        city: "",
        phone: "",
        mobile: "",
        role: "ROLE_CONTACT_USER",
        timezone: loggedInUser?.timezone
    };

    const [formFields, setFormFields] = useState(initFormFields);

    const clearError = () => setErrorMessage(false);

    useEffect(() => {
        setBrandSettings(loggedInUser?.brandConfig);
        if(!params.userId && !useModal){
            onGetCustomers();
        }
       /* return () => {
            onGetUserDetailsReset();
        };*/
    }, []);


    useEffect( () => {
        if(params.userId && !useModal){
            onGetUserDetails(params.userId);
        }
    }, [params.userId]);

    useEffect(() => {
        if(user && !useModal){
            setFormFields({...user, username: user.email, role: user.roles[0]?.name, customer: user.customer?.id});
            setProfileImageId(user?.profileImage?.s3Key)
        }
    }, [user])

    useEffect(() => {
        if(customerId){
            setFormFields({...formFields, customer: customerId});
        }
    }, [customerId])
    
    useEffect(() => {
        if(customers){
            setCustomerList(customers);
        }
    }, [customers])

    useEffect(() => {
        if (editMode) {
            document.querySelector("select[id=role]").value = formFields.role;
        }
    }, [formFields]);

    useEffect(() => {
        if (error) {
            setErrorMessage(API_ERR_MSG);
        }
    }, [error])

    const handleTimezoneChange = selectedOption => {
        setFormFields({...formFields, timezone: selectedOption.value})
    };

    const updateUser = (fields) => {
        let formData = fields.reduce(
            (obj, cur) => ({...obj, [cur.id]: cur.value}),
            {}
        );
        const cid = loggedInUser?.customerId ? loggedInUser?.customerId : params.customerId;
        put(`/api/customers/${cid}/users/${params.userId}`, formData)
        .then((data) => {
            if (data?.status !== 200) {
                setErrorMessage(props.t(data?.data));
            } else {
                let data = customerUsersList.filter(
                    (customer) => customer.id != formData.id
                );
                data = [...data, formData];
                setCustomerUsersList(data);
                setEditMode(false);
                if(formFields.profileImageId){
                    onEditProfile({userId: formFields.id, name: formFields.name, title: formFields.title, email: formFields.email, phone:formFields.phone, mobile:formFields.mobile, profileImageId: formFields.profileImageId})
                } else if(user?.profileImage?.s3Key){
                    try {
                        let response = del(`/api/user/${formFields.id}/profile-picture`);
                    } catch (e) {
                        setErrorMessage(props.t(API_ERR_MSG));
                    }
                }
                history(`/customers/${cid}/users/${params.userId}`);
            }
        })
        .catch((err) => {
            setErrorMessage(props.t(API_ERR_MSG));
        });
        setLoading(false);
    };

    const createUser = (fields) => {
        let formData = fields.reduce(
            (obj, cur) => ({...obj, [cur.id]: cur.value}),
            {}
        );
        if(loggedInUser?.customerId){
            formData = {...formData, customer: loggedInUser.customerId}
        }
        if(params?.customerId){
            formData = {...formData, customer: params.customerId}
        }
        post(`/api/customers/${formData.customer}/users`, formData)
            .then(data => {
                if (data?.status !== 200) {
                    setErrorMessage([props.t(data.data)])
                    setLoading(false);
                } else {
                    if(formFields.profileImageId){
                        let u = data?.data;
                        onEditProfile({userId: u.id, name: u.name, title: u.title, email: u.email, phone: u.phone, mobile: u.mobile, profileImageId: formFields.profileImageId})
                    }
                    if(useModal){
                        cogoToast.success(props.t("User created successfully!"), toastOptions)
                        tog_standard(data?.data);
                        return;
                    }
                    else if (props.callback) {
                        props.callback();
                    }
                    if (props.avoidRedirect) return;
                    history(`/customers/${formData.customer}/users/${data?.data?.id}`)

                }
            })
            .catch(err => {
                if(useModal){
                    cogoToast.error(props.t("Error occured while creating user!"), toastOptions)
                }
                setErrorMessage([API_ERR_MSG])
            })
            setLoading(false);
    }

    const validations = {
        name: ["required"],
        email: [
            "required",
            "regex::^[a-zA-Z0-9_.]+@[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9][.][a-zA-Z]{2,}$",
        ],
        city: ["required"],
        phone: ["required"],
        customer: ["required"],
    };

    const submitForm = (event) => {
        const err = [];
        let fields = Array.from(event.target.getElementsByTagName("input"));
        fields = [
            ...fields,
            ...Array.from(event.target.getElementsByTagName("select")),
        ];
        fields.forEach((field) => {
            validations[field.id]?.forEach((type) => {
                let validationType = type.split("::")[0];
                switch (validationType) {
                    case "required":
                        !(field.value && field.value.length > 0) &&
                        err.push(`${field.id} is required field`);
                        break;
                    case "regex":
                        if (
                            field.value?.length > 0 &&
                            !new RegExp(type.split("::")[1]).test(field.value)
                        )
                            err.push(`Please enter valid input in field ${field.id}`);
                        break;
                }
            });
        });
        setErrorMessage(`${err.join("\r\n")}`);
        if (err.length === 0) {
            setLoading(true);
            if(params.userId && !useModal) {
                updateUser(fields)
            } else {
                createUser(fields)
            }
        }
    };

    const handleCancel = () => {
        useModal ? tog_standard() : history(-1);
    }

    const handleDeleteProfilePicture = async () => {
        // setDeleteBtnText("Deleting");
        // let response = await del(`/api/user/${formFields.id}/profile-picture`);
        setProfileImageId(null);
        setFormFields({...formFields, profileImageId: null})
        // setDeleteBtnText("Delete Profile Picture");
        // const cid = loggedInUser?.customerId ? loggedInUser?.customerId : params.customerId;
        // history(`/customers/${cid}/users/${params.userId}`);
    }

    const handleAcceptedFile = async (e) => {
        if(e.target.files){
            setImageUploading(true);
            let formData = new FormData();
            formData.append("files[]", e.target.files[0]);
            let response;
            try {
                response = await postMultipart("/api/media", formData);

            } catch (ex){
                setErrorMessage(props.t(ERR_MSG));
                setTimeout(() => setErrorMessage(null), 3000);
            }
            if (response && response.status === 200) {
                setselectedFile(response.data[0])
                setProfileImageId(response.data[0].s3Key);
                setFormFields({...formFields, profileImageId: response.data[0]?.id})
            } else {
                setErrorMessage(props.t(ERR_MSG));
                setTimeout(() => setErrorMessage(null), 3000);
            }

            setImageUploading(false);
        }
    }

    return (
                <>

                    {successMessage && (
                        <Row>
                            <Alert color="success">{successMessage}</Alert>
                        </Row>
                    )}
                    {errorMessage && (
                        <Row>
                            <Alert color="danger">
                                <pre>{errorMessage}</pre>
                            </Alert>
                        </Row>
                    )}

                    <Loader loading={loading} />
                    <Row>
                        <Card className={loading ? "low-opacity" : null}>
                            <CardBody>
                                {!useModal && <CardTitle className="h4">
                                    {params.userId ? props.t("Update User") : props.t("Create User")}
                                </CardTitle>}
                                <Col>
                                    <div className="text-center">
                                        <input
                                            accept='image/*'
                                            id='icon-button-file'
                                            type='file'
                                            onChange={handleAcceptedFile}
                                            style={{
                                                display: 'none'
                                            }}
                                        />
                                        <label htmlFor='icon-button-file'>
                                            <IconButton
                                                color='primary'
                                                aria-label='upload picture'
                                                component='span'
                                            >
                                                <Avatar
                                                    variant="rounded"
                                                    src={imageUploading ? loadingIcon : profileImageId == null ? avatar : BASE_IMAGE_URL + "/" + profileImageId}
                                                    style={{
                                                        margin: "10px",
                                                        width: "100px",
                                                        height: "100px",
                                                        borderRadius: "100px"
                                                    }}
                                                >
                                                    <PersonIcon/>
                                                </Avatar>
                                            </IconButton>
                                        </label>
                                        {profileImageId && 
                                        <h6>
                                            <Button className="btn-sm btn-danger"
                                                onClick={handleDeleteProfilePicture}
                                                color="danger">
                                                {props.t(deleteBtnText)}
                                            </Button>
                                        </h6>}
                                        <h5 className="mb-1">{formFields?.name}</h5>
                                        {formFields?.username && <p className="text-muted"><a href={"mailto:"+ formFields?.username}>{formFields?.username}</a></p>}
                                    </div>
                                    <form
                                        onSubmit={(event) => {
                                            event.preventDefault();
                                            submitForm(event);
                                        }}
                                    >
                                        <p className="card-title-desc"></p>
                                        {(!loggedInUser.customerId && !params.customerId) &&
                                        <Row className="mb-3">
                                            <label
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Customer")}
                                            </label>
                                            <div className="col-md-10">
                                                <select
                                                    className="form-control"
                                                    name="customer"
                                                    id="customer"
                                                    type="select"
                                                    value={formFields.customer}
                                                    disabled={loading || params.userId}
                                                    onChange={(e) => setFormFields({...formFields, customer: e.target.value})}
                                                >
                                                    <option value={''}>{props.t("Select Customer")}</option>
                                                    {customerList?.map(customer => {
                                                        return <option value={customer.id}>{customer?.favorite ? '★' : " "} {customer.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </Row>}

                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Title")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter title")}
                                                    id="title"
                                                    defaultValue={formFields.title}
                                                    onChange={clearError}
                                                />
                                            </div>
                                        </Row>

                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Name")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter name")}
                                                    id="name"
                                                    defaultValue={formFields.name}
                                                    onChange={clearError}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Username/Email")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    placeholder={props.t("Enter email")}
                                                    id="username"
                                                    defaultValue={formFields.username}
                                                    onChange={clearError}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("City")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter city")}
                                                    id="city"
                                                    defaultValue={formFields.city}
                                                    onChange={clearError}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Timezone")}
                                            </label>
                                            <div className="col-md-10">
                                                <Select
                                                    value={{label: formFields.timezone, value: formFields.timezone}}
                                                    onChange={handleTimezoneChange}
                                                    options={options}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Phone")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter phone")}
                                                    id="phone"
                                                    defaultValue={formFields.phone}
                                                    onChange={clearError}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Mobile")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter mobile")}
                                                    id="mobile"
                                                    defaultValue={formFields.mobile}
                                                    onChange={clearError}
                                                />
                                            </div>
                                        </Row>

                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Privilege")}
                                            </label>
                                            <div className="col-md-10">
                                                <select
                                                    className="form-control"
                                                    id="role"
                                                    onChange={(e) => {setFormFields({...formFields, role:e.target.value})
                                                    }}
                                                    value={formFields.role}
                                                >
                                                    <option value={"ROLE_CONTACT_USER"}>
                                                        {props.t("Contact")}
                                                    </option>
                                                    <option value={"ROLE_CUSTOMER_USER"}>
                                                        {props.t("User")}
                                                    </option>
                                                    <option value={"ROLE_CUSTOMER_ADMIN"}>
                                                        {props.t("Admin")}
                                                    </option>
                                                </select>
                                            </div>
                                        </Row>
                                        {selectedCustomerUser && (
                                            <input
                                                type="hidden"
                                                id="id"
                                                defaultValue={formFields.id}
                                            />
                                        )}
                                        <Row className="mb-3">
                                            <Col align="center">
                                                <Button
                                                    type="button"
                                                    color="secondary"
                                                    className="btn btn-secondary w-lg waves-effect waves-light"
                                                    onClick={handleCancel}
                                                >
                                                    {props.t("Cancel")}
                                                </Button>{" "}
                                                <Button
                                                    type="submit"
                                                    color="success"
                                                    className="btn btn-success w-lg waves-effect waves-light"
                                                    style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                    disabled={imageUploading || loading}
                                                >
                                                    {props.t("Save")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                </Col>
                            </CardBody>
                        </Card>
                    </Row>
                </>
    );
};

CustomerUserForm.propTypes = {
};

const mapStateToProps = (state) => {
    return {
        user: state.TenantAdmin.user,
        editProfileLoading: state.Profile.editProfileLoading,
        error: state.Profile.error,
        success: state.Profile.success,
        customers: state.UserImpersonation.customers
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetUserDetails: (id) => dispatch(getTenantUserDetails(id)),
    onGetUserDetailsReset: () => dispatch(getTenantUserDetailsReset()),
    onEditProfile: (data) => dispatch(editProfile(data)),
    onGetCustomers: () => dispatch(GetCustomers())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(CustomerUserForm)));

import {call, put, takeEvery} from "redux-saga/effects";
import {getCustomerListForImpersonation, getImpersonateUserExit, getTenantList, getUsersForImpersonation, postImpersonateUser} from "../../../helpers/fakebackend_helper";
import {
    exitUserImpersonationSuccess,
    getCustomerListImpersonationFail,
    getCustomerListImpersonationSuccess,
    getTenantListFail,
    getTenantListSuccess,
    getUserForImpersonationFail,
    getUserForImpersonationSuccess,
    impersonateUserSuccess
} from "./actions";
import {GET_CUSTOMER_LIST_IMPERSONATION, GET_IMPERSONATE_USER_EXIT, GET_IMPERSONATION_USERS, GET_TENANT_LIST, POST_IMPERSONATE_USER} from "./actionTypes";
import {apiError, loginSuccess} from "../../auth/login/actions";
import {profileSuccess} from "../../auth/profile/actions";

function* fetchUsersForImpersonation(action) {
    try {
        const response = yield call(getUsersForImpersonation, action.data);
        yield put(getUserForImpersonationSuccess(response));
    } catch (error) {
        yield put(getUserForImpersonationFail(error));
    }
}

function* impersonate({payload: {obj, history, location, isEventImpersonation}}) {
    try {
        const response = yield call(postImpersonateUser, {
            ...obj
        });
        if (response.isAxiosError) throw response;
        localStorage.setItem("authUser", JSON.stringify(response.data));
        localStorage.setItem("themeMode", response.data.brandConfig.themeMode);
        yield put(loginSuccess(response.data));
        yield put(impersonateUserSuccess(obj))
        //history.push("/dashboard");
        let currentLevel = localStorage.getItem("level");
        const isUserProfilePath = location.pathname === "/settings/user-profile";
        if (response.data.roles.includes("ROLE_CUSTOMER_ADMIN") || response.data.roles.includes("ROLE_CUSTOMER_USER")) {
            let pathName = "/customer/categories";
            if (currentLevel === "CUSTOMER" || isUserProfilePath || isEventImpersonation) {
                pathName = location.pathname;
            }
            localStorage.setItem("level", "CUSTOMER")
            window.location.href = pathName;
        } else {
            let pathName = "/dashboard";
            if (currentLevel === "TENANT" || isUserProfilePath) {
                pathName = location.pathname;
            }
            localStorage.setItem("level", "TENANT")
            window.location.href = pathName;
        }
    } catch (error) {
        console.error(error);
        yield put(apiError("Error! Please confirm email and password."));
    }
}

function* exitImpersonation({payload: {history}}) {
    try {
        const response = yield call(getImpersonateUserExit);
        if (response.isAxiosError) throw response;
        localStorage.setItem("authUser", JSON.stringify(response.data));
        localStorage.setItem("themeMode", response.data.brandConfig.themeMode);
        if (response.data.roles.includes("ROLE_CUSTOMER_ADMIN") || response.data.roles.includes("ROLE_CUSTOMER_USER")) {
            localStorage.setItem("level", "CUSTOMER")
        } else if (response.data.roles.includes("ROLE_TENANT_ADMIN") || response.data.roles.includes("ROLE_TENANT_USER")) {
            localStorage.setItem("level", "TENANT")
        } else {
            localStorage.setItem("level", "SUPERADMIN")
        }

        yield put(loginSuccess(response.data));
        yield put(profileSuccess(response.data))
        yield put(exitUserImpersonationSuccess(response.data))
        //history.push("/dashboard");
        window.location.href = "/dashboard"
    } catch (error) {
        console.error(error);
        yield put(apiError("Error! Please confirm email and password."));
    }
}

function* fetchTenantList() {
    try {
        const response = yield call(getTenantList);
        yield put(getTenantListSuccess(response));
    } catch (error) {
        yield put(getTenantListFail(error));
    }
}

function* fetchCustomersForImpersonation() {
    try {
        const response = yield call(getCustomerListForImpersonation);
        yield put(getCustomerListImpersonationSuccess(response))
    } catch (error) {
        yield put(getCustomerListImpersonationFail(error))
    }
}


function* userImpersonationSaga() {
    yield takeEvery(GET_IMPERSONATION_USERS, fetchUsersForImpersonation);
    yield takeEvery(POST_IMPERSONATE_USER, impersonate)
    yield takeEvery(GET_IMPERSONATE_USER_EXIT, exitImpersonation)
    yield takeEvery(GET_TENANT_LIST, fetchTenantList)
    yield takeEvery(GET_CUSTOMER_LIST_IMPERSONATION, fetchCustomersForImpersonation)
}

export default userImpersonationSaga;

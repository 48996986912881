import {takeEvery, fork, put, all, call} from "redux-saga/effects"

// Login Redux States
import {FORGET_PASSWORD} from "./actionTypes"
import {userForgetPasswordSuccess, userForgetPasswordError} from "./actions"

//Include Both Helper File with needed methods
import {getFirebaseBackend} from "../../../helpers/firebase_helper"
import {
    postForgetPassword
} from "../../../helpers/fakebackend_helper"

const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
//If user exists in db, send mail link then dispatch redux actions directly from here.
function* forgetPassword({payload: {user, history}}) {
    try {
        yield call(postForgetPassword, user.email, user.domain)
        yield put(userForgetPasswordSuccess("Password reset link is sent to your mailbox"))
    } catch (err) {
        if (err.response !== 'undefined' && err.response !== null && Array.isArray(err.response.data)) {
            yield put(userForgetPasswordError(err.response.data[0]))
        } else {
            yield put(userForgetPasswordError("Error occured while sending password reset link to the email " + user.email
                + ". If issue persists, Please contact system administrator"))
        }
    }
}

export function* watchUserPasswordForget() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword)
}

function* forgetPasswordSaga() {
    yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga

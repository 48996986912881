import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Alert, Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";
import React, {useEffect, useState} from "react";
import {del, get} from "../../helpers/api_helper";
import {find} from "lodash";
import classnames from 'classnames';
import PropTypes from "prop-types";
import {checkIfLoading} from "../../store/selector";
import {ecommerceActionTypes} from "../../store/e-commerce/actionTypes";
import {getOrderReset, getOrders} from "../../store/e-commerce/actions";
import {MDBDataTable} from "mdbreact";
import {downloadPDF, getFilterClassName, hasModuleEnabledForLoggedInUser} from "../../util/helperFunctions";
import CustomerTimeslips from "../Customer/CustomerTimeslips";
import TaskManagement from "../Tasks/TaskManagement";
import {getProjectList, getProjectUsers} from "../../store/project/actions";
import DeleteModal from "../../components/Common/DeleteModal";
import CustomerAddressManagement from "../Customer/CustomerAddressManagement";
import {getOffers} from "../../store/offer/actions";
import {formatCurrency} from "../../helpers/currency_helper";
import CustomerInvoiceTable from "../Customer/CustomerInvoiceTable";
import CustomerOrderTable from "../Customer/CustomerOrderTable";
import CustomerOfferTable from "../Customer/CustomerOfferTable";
import withRouter from "../../components/Common/withRouter";
import TenantDashboard from "../Dashboard/TenantDashboard";

const ProjectDetails = (props) => {
    const {
        orders,
        onGetOrders,
        users,
        onGetUsers,
        offers,
        onGetOffers,
        projects,
        onGetProjects
    } = props;

    const history = useNavigate();
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [activeTab, setActiveTab] = useState('1');
    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState(null);
    const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
    const [brandSettings, setBrandSettings] = useState(null);
    const [offerType, setOfferType] = useState('all');
    const [orderType, setOrderType] = useState('all');
    let user = JSON.parse(localStorage.getItem("authUser"));
    let params = useParams();

    useEffect( () => {
        (async () => {
            onGetProjects();
            if (params.id) {
                setLoading(true);
                let response = await get(`/api/projects/${params.id}`);
                let data = response.data;
                setProject(data);
                setLoading(false);
                //onGetOrders("All");
                //onGetOffers("All");
                onGetUsers(params.id);

            }
        })();
    }, [params.id]);

    useEffect(() => {
        setBrandSettings(user?.brandConfig);

        const tab = JSON.parse(localStorage.getItem("projectDetails"));
        if (tab) {
            setActiveTab(tab.toString());
        }
    }, [])


    useEffect(() => {
        onGetOffers(offerType);
    }, [offerType])

    useEffect(() => {
        onGetOrders(orderType);
    }, [orderType])

    const profiletoggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const handleEdit = () => {
        history(`${user.customerId ? '/customer' : ''}/projects/${project?.ProjectNumber}/edit`)
    }

    const openOrderDetails = (order) => {
        const fromUrl = "?from=" + encodeURIComponent(props.location.pathname);
        history(`/orders/${order.DocumentNumber + fromUrl}`);
    };

    const getUsersDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Username"),
                    field: "username",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: props.t("City"),
                    field: "city",
                    sort: "asc",
                },
                {
                    label: props.t("Phone"),
                    field: "phone",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Role"),
                    field: "role",
                    width: 150,
                }
            ],
            rows: users?.map((user, key) => ({
                name: <span onClick={() => handleOpen(user.id)}>{user.name}</span>,
                username: <span onClick={() => handleOpen(user.id)}>{user.username}</span>,
                city: <span onClick={() => handleOpen(user.id)}>{user.city}</span>,
                phone: <span onClick={() => handleOpen(user.id)}>{user.phone}</span>,
                role: <span onClick={() => handleOpen(user.id)}>{getRoleName(user?.roles[0]?.name)}</span>
            })),
        };
        return data;
    }

    function toggleDeleteProject() {
        setShowDeleteProjectModal(!showDeleteProjectModal);
    }

    const handleDeleteProject = async () => {
        await del(`/api/projects/${params.id}`);
        history(`/projects`);
    };

    const handleOpen = (user) => {
        const level = localStorage.getItem("level");
        let url = `/customers/${project.customerNumber}/users/${user}`
        if (level == "TENANT") {
            url = `/settings/users/${user}`
        }
        history(url)
    }

    function getRoleName(role) {
        if (role == "ROLE_TENANT_ADMIN") {
            return props.t("Tenant Admin");
        } else if (role == "ROLE_TENANT_USER") {
            return props.t("Tenant User");
        } else if (role == "ROLE_CUSTOMER_ADMIN") {
            return props.t("Customer Admin");
        } else if (role == "ROLE_CUSTOMER_USER") {
            return props.t("Customer User");
        } else if (role == "ROLE_CONTACT_USER") {
            return props.t("Contact");
        } else if (role == "ROLE_SUPER_ADMIN") {
            return props.t("Super Admin");
        }
        return role;
    }

    const getOrdersDatatableData = () => {
        let cols = [
            {
                label: props.t("Customer"),
                field: "customerName",
                sort: "asc",
                width: 150,
            },
            {
                label: props.t("Order Number"),
                field: "orderNumber",
                sort: "asc",
                width: 150,
            },
            {
                label: props.t("Status"),
                field: "status",
                sort: "asc",
                width: 150,
            },
            {
                label: props.t("Total"),
                field: "amount",
                sort: "asc",
                width: 150,
            },
            {
                label: props.t("OrderDate"),
                field: "date",
                sort: "asc",
                width: 150,
            },
            {
                label: props.t("Download"),
                field: "download",
            }
        ]

        let data = {
          columns: cols,
          rows: orders?.filter(order => order.Project && order.Project == project?.ProjectNumber).map(order => ({
            status: <div onClick={() => openOrderDetails(order)} className={`badge ${getFilterClassName(order.status)} p-1`}>{order.status}</div>,
            customerName: <div onClick={() => openOrderDetails(order)}>{order.CustomerName}</div>,
            orderNumber: <div onClick={() => openOrderDetails(order)}>{order.DocumentNumber}</div>,
            amount: <div onClick={() => openOrderDetails(order)}>{order.Total}</div>,
            date: <div onClick={() => openOrderDetails(order)}>{order.OrderDate}</div>,
            download: <div onClick={() => downloadPDF(order.DocumentNumber, 'orders')}><i className="fa fa-download font-size-15"></i></div>
          })),
        };
        return data;
    };


    const getOffersDatatableData = () => {
        let columnsArr = [
            {
                label: props.t("Customer Name"),
                field: "CustomerName",
                width: 150,
            },
            {
                label: props.t("Offer Number"),
                field: "DocumentNumber",
                width: 150,
            },
            {
                label: props.t("Status"),
                field: "status",
                width: 150,
            },
            {
                label: props.t("OfferDate"),
                field: "OfferDate",
                width: 150,
            },
            {
                label: props.t("Total"),
                field: "Total",
                width: 150,
            }
        ]
        let data = {
            columns: columnsArr,
            rows: offers?.filter(offer => offer.Project && offer.Project == project?.ProjectNumber)?.map((offer, key) => ({
                status: <div onClick={() => openOfferDetails(offer)} className={`badge ${getFilterClassName(offer.status)} p-1`}>{props.t(offer.status)}</div>,
                OfferDate: <div onClick={() => openOfferDetails(offer)}>{offer.OfferDate} {project?.id}</div>,
                CustomerName: <div onClick={() => openOfferDetails(offer)}>{offer.CustomerName}</div>,
                DocumentNumber: <div onClick={() => openOfferDetails(offer)}>{offer.DocumentNumber}</div>,
                Sent: <div onClick={() => openOfferDetails(offer)}>{offer.Sent == true ? props.t("Yes") : props.t("No")}</div>,
                Total: <div onClick={() => openOfferDetails(offer)}>{formatCurrency(offer?.Currency,offer?.Total)}</div>,
            })),
        };
        return data;
    };

    const openOfferDetails = (offer) => {
        history(`${user.customerId ? '/customer' : ''}/offers/${offer.DocumentNumber}/types/${offer?.status}?from=` + encodeURIComponent(props.location.pathname));
    };

    const handleOfferFilter = (value) => {
        setOfferType(value);
    }

    const handleOrderFilter = (value) => {
        setOrderType(value)
    }

    const handleProjectChange = (project) => {
        window.location.href = `/projects/${project.projectNumber}`;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        breadcrumbs={[{title: props.t("Projects"), link: "/projects"}]}
                        title={project?.Description}
                    />
                    <>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                        <TableToolbar
                                            title={project ? project.Description : props.t("Project Details")}
                                            titleDropdown={{
                                                isSearchableDropdown: true,
                                                value: find(projects, {id : project?.id}),
                                                options: projects,
                                                placeholder: props.t("Select Project"),
                                                isHeader: true,
                                                handleSelect: (op) => {
                                                    handleProjectChange(op);
                                                },
                                                keyToDisplay: "description",
                                            }}
                                            canEdit={!loading ? handleEdit : null}
                                            canDelete={!project?.referenced && !loading ? toggleDeleteProject : null}
                                        />
                                    </CardHeader>
                                </Card>
                            </Col>

                            {successMessage && (
                                <Col className="col-12">
                                    <Alert color="success">{successMessage}</Alert>
                                </Col>
                            )}
                            {errorMessage && (
                                <Col className="col-12">
                                    <Alert color="danger">{errorMessage}</Alert>
                                </Col>
                            )}

                            <Loader loading={loading} title="Project Details"/>
                            {!loading && <>
                                <Col xl="4">
                                    <Card className="card h-100">
                                        <CardBody>
                                            <div className="text-muted">
                                                <div className="table-responsive">
                                                    <div>
                                                        <p className="mb-1">{props.t("Project Number")} :</p>
                                                        <h5 className="font-size-16">{project?.ProjectNumber}</h5>
                                                    </div>
                                                    <div className="mt-3">
                                                        <p className="mb-1">{props.t("Customer Name")} :</p>
                                                        <h5
                                                            className={`font-size-16 ${project?.customerNumber ? "cursor-pointer" : ""}`}
                                                            style={{color: `${project?.customerId ? "#0404bd" : ""}`}}
                                                            onClick={() => {
                                                                if (project?.customerNumber) {
                                                                    history(`/customers/${project?.customerNumber}/profile`)
                                                                }
                                                            }}>
                                                            {project?.customerName}
                                                        </h5>
                                                    </div>
                                                    <div className="mt-3">
                                                        <p className="mb-1">{props.t("Name")} :</p>
                                                        <h5 className="font-size-16">{project?.Description}</h5>
                                                    </div>

                                                    {project?.ProjectLeader && <div className="mt-3">
                                                        <p className="mb-1">{props.t("Project Leader")} :</p>
                                                        <h5 className="font-size-16">{project?.ProjectLeader}</h5>
                                                    </div>}
                                                    {project?.Status && <div className="mt-3">
                                                        <p className="mb-1">{props.t("Status")} :</p>
                                                        <h5 className="font-size-16">{props.t(project?.Status)}</h5>
                                                    </div>}
                                                    {project?.StartDate && <div className="mt-3">
                                                        <p className="mb-1">{props.t("Start Date")} :</p>
                                                        <h5 className="font-size-16">{project?.StartDate}</h5>
                                                    </div>}
                                                    {project?.EndDate && <div className="mt-4">
                                                        <p className="mb-1">{props.t("End Date")} :</p>
                                                        <h5 className="font-size-16">{project?.EndDate}</h5>
                                                    </div>}
                                                    {project?.projectFieldValueList?.map(function(field){
                                                        return (<div className="mt-3">
                                                            <p className="mb-1">{field?.field?.name} :</p>
                                                            <h5 className="font-size-16">{field?.value}</h5>
                                                        </div>)
                                                    })}

                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="8">
                                    <Card className="mb-0">
                                        <Nav tabs className="nav-tabs-custom nav-justified">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '1'})}
                                                    style={{height:"100%"}}
                                                    onClick={() => {
                                                        profiletoggle('1');
                                                    }}
                                                >
                                                    <i className="uil uil-users-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Users")}</span>
                                                </NavLink>
                                            </NavItem>
                                            {hasModuleEnabledForLoggedInUser("Offers") &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '8'})}
                                                    style={{height:"100%"}}
                                                    onClick={() => {
                                                        profiletoggle('8');
                                                    }}
                                                >
                                                    <i className="uil uil-bolt-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Offers")}</span>
                                                </NavLink>
                                            </NavItem>}
                                            {hasModuleEnabledForLoggedInUser("ORDERS") &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '6'})}
                                                    style={{height:"100%"}}
                                                    onClick={() => {
                                                        profiletoggle('6');
                                                    }}
                                                >
                                                    <i className="uil uil-shopping-cart-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Orders")}</span>
                                                </NavLink>
                                            </NavItem>}
                                            {hasModuleEnabledForLoggedInUser("INVOICES") &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '2'})}
                                                    style={{height:"100%"}}
                                                    onClick={() => {
                                                        profiletoggle('2');
                                                    }}
                                                >
                                                    <i className="uil uil-invoice font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Invoices")}</span>
                                                </NavLink>
                                            </NavItem>}
                                            {hasModuleEnabledForLoggedInUser("TASKS") &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '3'})}
                                                    style={{height:"100%"}}
                                                    onClick={() => {
                                                        profiletoggle('3');
                                                    }}
                                                >
                                                    <i className="uil-channel-add font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Tasks")}</span>
                                                </NavLink>
                                            </NavItem>}
                                            {hasModuleEnabledForLoggedInUser("TASKS") &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '4'})}
                                                    style={{height:"100%"}}
                                                    onClick={() => {
                                                        profiletoggle('4');
                                                    }}
                                                >
                                                    <i className="uil uil-clock font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Timeslips")}</span>
                                                </NavLink>
                                            </NavItem>}
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '5'})}
                                                    style={{height:"100%"}}
                                                    onClick={() => {
                                                        profiletoggle('5');
                                                    }}
                                                >
                                                    <i className="uil uil-envelope-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Delivery Address")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '7'})}
                                                    style={{height:"100%"}}
                                                    onClick={() => {
                                                        profiletoggle('7');
                                                    }}
                                                >
                                                    <i className="uil uil-chart font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Statistics")}</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={activeTab} className="p-4">
                                            <TabPane tabId="1">
                                                <MDBDataTable
                                                    responsive
                                                    hover
                                                    borderless
                                                    barReverse={true}
                                                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                                                    entriesLabel={props.t("Show entries")}
                                                    infoLabel={[
                                                        props.t("Showing"),
                                                        props.t("to"),
                                                        props.t("of"),
                                                        props.t("entries"),
                                                    ]}
                                                    searchLabel={props.t("Search") + "..."}
                                                    noBottomColumns={true}
                                                    data={getUsersDatatableData()}
                                                    entries={50}
                                                    entriesOptions={[50, 100, 500]}
                                                    onSearch={(text) => console.log(text)}
                                                    searching={true}
                                                    noRecordsFoundLabel={props.t("No matching records found")}
                                                />
                                            </TabPane>
                                            <TabPane tabId="2">
                                                {activeTab == "2" && <CustomerInvoiceTable projectNumber={project?.ProjectNumber}/> }
                                            </TabPane>
                                            <TabPane tabId="3">
                                                {activeTab == "3" && <TaskManagement projectId={params?.id} cid={project?.customerId} />}
                                            </TabPane>
                                            <TabPane tabId="4">
                                                {activeTab == "4" && <CustomerTimeslips filter={{projectId: project?.ProjectNumber}} showAddTask={true}/>}
                                            </TabPane>
                                            <TabPane tabId="5">
                                                {activeTab == "5" && <CustomerAddressManagement projectId={params?.id}/>}
                                            </TabPane>
                                            <TabPane tabId="6">
                                                {activeTab == "6" && <CustomerOrderTable projectNumber={project?.ProjectNumber}/>}
                                            </TabPane>
                                            <TabPane tabId="7">
                                                {activeTab == "7" && <TenantDashboard projectNumber={project?.ProjectNumber}/>}
                                            </TabPane>
                                            <TabPane tabId="8">
                                                {activeTab == "8" && <CustomerOfferTable projectNumber={project?.ProjectNumber} />}
                                            </TabPane>
                                        </TabContent>
                                    </Card>
                                </Col></>}
                        </Row>
                    </>
                </Container>
            </div>
            <DeleteModal
                deleteCallback={() => handleDeleteProject()}
                element={"Project"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteProjectModal}
                toggleModal={() => toggleDeleteProject()}
                warningMsg={null}
            />
        </React.Fragment>
    );
}


ProjectDetails.propTypes = {
    orders: PropTypes.array,
    onGetOrders: PropTypes.func,
    orderLoading: PropTypes.bool,
    users: PropTypes.array,
    onGetUsers: PropTypes.func,
    offers: PropTypes.array,
    onGetOffers: PropTypes.func,
    projects: PropTypes.array,
    onGetProjects: PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
        orders: state.ecommerce.orders?.data?.Orders,
        orderLoading: checkIfLoading(state, ecommerceActionTypes.GET_ORDERS),
        users: state.Project.users,
        offers: state.Offers.offers?.Offers,
        projects: state.Project.projects,
    }
}

const mapDispatchToProps = (dispatch) => ({
    onGetOrders: (type) => dispatch(getOrders(type)),
    onGetOrderReset: () => dispatch(getOrderReset()),
    onGetUsers: (projectId) => dispatch(getProjectUsers(projectId)),
    onGetOffers: (offerType) => dispatch(getOffers(offerType)),
    onGetProjects: () => dispatch(getProjectList()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ProjectDetails)));

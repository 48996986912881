import React from "react"
import {Navigate} from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"


//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

import MyAccount from "../pages/Authentication/user-profile"
//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import TenantList from "../pages/Tenant/TenantList";
import TenantDetails from "../pages/Tenant/TenantDetails";
import AddTenant from "../pages/Tenant/AddTenant";
import TaskList from "../pages/Tasks/TaskList";
import CustomerWorkLogList from "../pages/Tasks/CustomerWorkLogList";
import Audit from "../pages/Audit/Audit";
import SuperAdminUserDetails from "../pages/Tenant/SuperAdminUserDetails";
import TaskDetail from "../pages/Tasks/TaskDetail";
import MyAccountEdit from "../pages/Users/MyAccountEdit";
import CustomerList from "../pages/Customer/CustomerList";
import CustomerProfile from "../pages/Customer/CustomerProfile";
import UsersList from "../pages/Users/UsersList";
import UserProfile from "../pages/Users/UserProfile";
import CustomerUserFormContainer from "../pages/Customer/CustomerUserFormContainer";
import TenantUsers from "../pages/Tenant/TenantUsers";
import Offers from "../pages/Offer/Offers";
import OfferDetails from "../pages/Offer/OfferDetails";
import Orders from "../pages/Customer/Orders";
import OrderDetails from "../pages/Customer/OrderDetails";
import Invoices from "../pages/Invoice/Invoices";
import InvoiceDetail from "../pages/Invoice/InvoiceDetail";
import ProjectList from "../pages/Projects/ProjectList";
import ProjectDetails from "../pages/Projects/ProjectDetails";
import ManufacturerList from "../pages/Manufacturer/ManufacturerList";
import ManufacturerDetails from "../pages/Manufacturer/ManufacturerDetails";
import ManufacturerDetailsEdit from "../pages/Manufacturer/ManufacturerDetailsEdit";
import FavouriteList from "../pages/FavouriteList/FavouriteList";
import ProductListArticles from "../pages/FavouriteList/ProductListArticles";
import CategoryList from "../pages/Category/CategoryList";
import SubCategoryList from "../pages/Category/SubCategoryList";
import ArticleGroupList from "../pages/ArticleGroups/ArticleGroupList";
import CreateGroup from "../pages/ArticleGroups/CreateGroup";
import ArticleGroupDetails from "../pages/ArticleGroups/ArticleGroupDetails";
import Articles from "../pages/Articles";
import ArticlePreview from "../pages/Articles/ArticlePreview";
import ArticleEdit from "../pages/Articles/ArticleEdit";
import FortnoxConfig from "../pages/Settings/FortnoxConfig";
import FortnoxConfigActivation from "../pages/Settings/FortnoxConfigActivation";
import DeepSync from "../pages/DeepSync/DeepSync";
import SockekAuditLog from "../pages/Socket/SockekAuditLog";
import ArticleBulkActions from "../pages/BulkActions/ArticleBulkActions";
import SMTPConfig from "../pages/Settings/SMTPConfig";
import StockPointConfig from "../pages/Settings/StockPointConfig";
import CronList from "../pages/Cron/CronList";
import DateConfig from "../pages/Settings/DateConfig";
import CurrencyConfig from "../pages/Settings/CurrencyConfig";
import CurrencyList from "../pages/Settings/CurrencyList";
import ProjectConfig from "../pages/Settings/ProjectConfig";
import BrandSettings from "../pages/Settings/BrandSettings";
import LanguageList from "../pages/Language/LanguageList";
import LanguageLabelsList from "../pages/Language/LanguageLabelsList";
import PhraseList from "../pages/Language/PhraseList";
import FieldList from "../pages/Field/FieldList";
import ContentList from "../pages/Content/ContentList";
import LayoutList from "../pages/Layout/LayoutList";
import TemplateList from "../pages/Templates/TemplateList";
import TemplateDetails from "../pages/Templates/TemplateDetails";
import ProjectTemplateList from "../pages/ProjectTemplate/ProjectTemplateList";
import ProjectTemplateDetails from "../pages/ProjectTemplate/ProjectTemplateDetails";
import WorklogType from "../pages/Tasks/WorklogType";
import TaskCategory from "../pages/Tasks/TaskCategory";
import TaskPriority from "../pages/Tasks/TaskPriority";
import CustomerProfileEdit from "../pages/Customer/CustomerProfileEdit";
import TenantUserDetails from "../pages/Tenant/TenantUserDetails";
import TenantUserFormContainer from "../pages/Tenant/TenantUserFormContainer";
import ProjectFormContainer from "../pages/Projects/ProjectFormContainer";
import OfferFormContainer from "../pages/Tenant/OfferFormContainer";
import OrderFormContainer from "../pages/Tenant/OrderFormContainer";
import Statistics from "../pages/Statistics/Statistics";


const SUPER_ADMIN = ["ROLE_SUPER_ADMIN"];
const TENANT_ADMIN = ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"];
const SUPER_ADMIN_AND_TENANT_ADMIN = ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]
const CUSTOMER_ADMIN = ["ROLE_CUSTOMER_ADMIN"];

const userRoutes = [
    {path: "/dashboard", component: <Dashboard/>},

    { path: "/settings/user-profile", component: <MyAccount/>, initState: { title: 'Profile' } },
    { path: "/settings/user-profile/edit/:userId", component: <MyAccountEdit/>, initState: {title: 'Edit Profile'}},

    { path: "/settings/fortnox-config", component: <FortnoxConfig/>, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN"], initState: { header: 'Fortnox Config', title: 'Fortnox Config' }, eventMap: { entityTypes: ['FortnoxConfig'] , impersonationType: null }},
    { path: "/settings/fortnox-config/activation", component: <FortnoxConfigActivation/>, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN"], initState: { header: 'Fortnox Config', title: 'Fortnox Config' }, eventMap: { entityTypes: ['FortnoxConfig'] , impersonationType: null }},

    {path: "/deep-sync", component: <DeepSync/>, allowedRoles: ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"], initState: {title: 'Deepsync Logs'}},
    {path: "/socket-log", component: <SockekAuditLog/>, allowedRoles: ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"], initState: {title : 'Socket Logs'}},
    {path: "/bulk-actions", component: <ArticleBulkActions/>, allowedRoles: TENANT_ADMIN, initState: {title: 'Bulk Actions'}},

    { path: "/settings/smtp-config", component: <SMTPConfig/>, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN"], initState: { header: 'SMTP Config', title: 'SMTP Config' }, eventMap: { entityTypes: ['SMTPConfig'] , impersonationType: null }},
    { path: "/settings/brand", component: <BrandSettings/>, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN"], initState: { title: 'Brand Settings' }, eventMap: { entityTypes: ['BrandConfig'] , impersonationType: null }},
    { path: "/settings/stock", component: <StockPointConfig/>, allowedRoles: ["ROLE_TENANT_ADMIN"], initState: { header: 'Default Stock Point', title: 'Default Stock Point' }, eventMap: { entityTypes: ['StockPointConfig'] , impersonationType: 'TENANT' }},
    { path: "/settings/sync", component: <CronList/>, allowedRoles: ["ROLE_SUPER_ADMIN"], initState: { header: 'Cron', title: 'Cron' }},
    { path: "/settings/date-config", component: <DateConfig/>, allowedRoles: ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"], initState: { header: 'Date Config', title: 'Date'}},
    { path: "/settings/default-currency-config", component: <CurrencyConfig/>, allowedRoles: ["ROLE_TENANT_ADMIN"], initState: { header: 'Currency Config', title: 'Currency'}},
    { path: "/settings/currency-config", component: <CurrencyList/>, allowedRoles: ["ROLE_SUPER_ADMIN"], initState: { header: 'Currency Config', title: 'Currency'}},
    { path: "/settings/project-config", component: <ProjectConfig/>, allowedRoles: ["ROLE_TENANT_ADMIN"], initState: { header: 'Project Config', title: 'Project Config' }, eventMap: { entityTypes: ['ProjectConfig'] , impersonationType: 'TENANT' }},
    { path: "/settings/timeslip-types", component: <WorklogType/>, allowedRoles: ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"], initState: {title: 'Timeslip Types'}, eventMap: { entityTypes: ['WorkLogType']}},
    { path: "/settings/task-categories", component: <TaskCategory/>, allowedRoles: ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"], initState: {title: 'Task Category'}, eventMap: { entityTypes: ['TaskCategory']}},
    { path: "/settings/task-priorities", component: <TaskPriority/>, allowedRoles: ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"], initState: {title: 'Task Priority'}, eventMap: { entityTypes: ['TaskPriority']}},


    { path: "/translations", component: <LanguageList/>, allowedRoles: SUPER_ADMIN_AND_TENANT_ADMIN, initState: {title : 'Languages'}},
    { path: "/languages/labels/:locale", component: <LanguageLabelsList/>, allowedRoles: SUPER_ADMIN_AND_TENANT_ADMIN, initState: {title : 'Labels'}},
    { path: "/phrases", component: <PhraseList/>, allowedRoles: SUPER_ADMIN, initState: {title: "Languages"}},

    {path: "/tenants", component: <TenantList/>, allowedRoles: SUPER_ADMIN, initState: {title: 'Tenants'}},
    {path: "/tenants/:tenantId/details", component: <TenantDetails/>, allowedRoles: SUPER_ADMIN, initState: { title: 'Tenants Details' }, eventMap: { entityTypes: ['User', 'Tenant'] , impersonationType: null }},
    {path: "/tenants/:tenantId/user/:id", component: <SuperAdminUserDetails/>, allowedRoles: SUPER_ADMIN, initState: { title: 'User Details' }, eventMap: { entityTypes: ['User'] , impersonationType: null }},
    {path: "/addTenant", component: <AddTenant/>, allowedRoles: SUPER_ADMIN, initState: { header: 'Add Tenant', title: 'Add Tenant' } },
    {path: "/tenants/edit/:userId", component: <AddTenant/>, allowedRoles: SUPER_ADMIN, initState: { header: 'Edit Tenant', title: 'Edit Tenant' } },
    { path: "/settings/users", component: <TenantUsers/>, allowedRoles: ["ROLE_TENANT_ADMIN"], initState: { header: 'Add User', title: 'Users' } },
    { path: "/settings/users/:id", component: <TenantUserDetails/>, allowedRoles: TENANT_ADMIN, initState: { title: 'User Details' }, eventMap: { entityTypes: ['User'] , impersonationType: 'TENANT' }},
    { path: "/settings/users/edit/:userId", component: <TenantUserFormContainer/>, allowedRoles: TENANT_ADMIN, initState: { header: 'Edit User', title: 'Edit User' } },

    {path: "/articles", component: <Articles/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: {title: 'Articles'}},
    {path: "/articles/:id", component: <ArticlePreview/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: {title: 'Articles - Details'}, eventMap: { entityTypes: ['Article', 'Content', 'ContentCategory', 'ArticleFieldValue', 'ArticleContent'] , impersonationType: 'TENANT' }},
    {path: "/articles/:id/edit", component: <ArticleEdit/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: {title: 'Articles - Edit'}},


    { path: "/categories", component: <CategoryList/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: { title: 'Categories' } },
    { path: "/categories/:id/subcategory", component: <SubCategoryList/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: { title: 'Category - Subcategory' }, eventMap: { entityTypes: ['Category'] , impersonationType: 'TENANT' }},

    {path: "/groups", component: <ArticleGroupList/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: {title: 'Article Groups'}},
    {path: "/groups/create", component: <CreateGroup/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: {title: 'Article Groups'}},
    {path: "/groups/detail/:id", component: <ArticleGroupDetails/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: {title: 'Article Groups'}},
    {path: "/groups/edit/:id", component: <CreateGroup/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: {title: 'Article Groups'}, eventMap: { entityTypes: ['ArticleGroup'] , impersonationType: 'TENANT' }},

    { path: "/offers", component: <Offers/>, allowedRoles: TENANT_ADMIN, permission:"OFFERS", initState: { title: 'Offers' } },
    { path: "/offers/new", component: <OfferFormContainer/>, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"], permission:"OFFERS", initState: {title: 'Offers - New'} },
    { path: "/offers/:id", component: <OfferDetails/>, allowedRoles: TENANT_ADMIN, permission:"OFFERS", initState: { title: 'Offer Details' }, eventMap: { entityTypes: ['Offer'] , impersonationType: 'TENANT' }},
    { path: "/offers/:id/types/:offerType", component: <OfferDetails/>, permission:"OFFERS", initState: { title: 'Offer Details' } },

    { path: "/content/:id/tab", component: <ContentList/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: { title: 'Layout - Content' }},
    { path: "/fields/:type", component: <FieldList/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: { title: 'Fields' }, eventMap: { entityTypes: ['Field', 'FieldOption'] , impersonationType: 'TENANT' }},

    { path: "/tabs", component: <LayoutList/>, allowedRoles: TENANT_ADMIN, initState: { title: 'Tabs' } },
    { path: "/tabs/:id/details", component: <ContentList/>, allowedRoles: TENANT_ADMIN, initState: { title: 'Tab - Content' }, eventMap: { entityTypes: ['Template'] , impersonationType: 'TENANT' } },


    {path : "/templates", component: <TemplateList/>, allowedRoles: TENANT_ADMIN, initState: {title: 'Templates'} },
    {path : "/templates/:id", component: <TemplateDetails/>, allowedRoles: TENANT_ADMIN, initState: {title: 'Template Details'}, eventMap: { entityTypes: ['TabTemplate'] , impersonationType: 'TENANT' }},

    { path: "/project-templates", component: <ProjectTemplateList/>, permission:"PROJECTS", initState: {title : 'Project Templates'}},
    { path: "/project-fields/:type", component: <FieldList/>, allowedRoles: TENANT_ADMIN, permission:"PROJECTS", initState: { title: 'Fields' }, eventMap: { entityTypes: ['Field', 'FieldOption'] , impersonationType: 'TENANT' }},
    { path: "/project-template/:id", component: <ProjectTemplateDetails/>, permission:"PROJECTS", initState: {title : 'Project Templates Details'}},


    { path: "/orders", component: <Orders/>, allowedRoles: TENANT_ADMIN, permission:"ORDERS", initState: { title: 'Orders' } },
    { path: "/orders/:id", component: <OrderDetails/>, allowedRoles: TENANT_ADMIN, permission:"ORDERS", initState: { title: 'Order Details' }, eventMap: { entityTypes: ['Order'] , impersonationType: 'TENANT' }},
    { path: "/order/new", component: <OrderFormContainer/>, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"], permission:"ORDERS", initState: {title: 'Orders - New'} },

    { path: "/invoice", component: <Invoices/>, allowedRoles: TENANT_ADMIN, permission:"INVOICES", initState: { title: 'Invoices' } },
    { path: "/invoice/:id", component: <InvoiceDetail/>, permission:"INVOICES", initState: { title: 'Invoice - Details' }, eventMap: { entityTypes: ['Invoice'] , impersonationType: 'TENANT' }},


    {path: "/tasks", component: <TaskList/>, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_TENANT_USER"], permission: 'TASKS', initState: { title: 'Tasks' }},
    { path: "/tasks/:id", component: <TaskDetail/>, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_TENANT_USER"], permission: 'TASKS', initState: { title: 'Task Details' }, eventMap: { entityTypes: ['Task'] , impersonationType: 'TENANT' }},

    {path: "/timeslips", component: <CustomerWorkLogList/>, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_TENANT_USER"], permission: 'TASKS', initState: {title: 'Timeslips'}},

    { path: "/projects", component: <ProjectList/>, permission:"PROJECTS", initState: { title: 'Projects' } },
    { path: "/projects/:id", component: <ProjectDetails/>, permission:"PROJECTS", initState: { title: 'Projects' }, eventMap: { entityTypes: ['Project'] , impersonationType: 'CUSTOMER' }},
    { path: "/projects/:id/edit", allowedRoles: TENANT_ADMIN, component: <ProjectFormContainer/>, permission:"PROJECTS", initState: { header: 'Update Project', title: 'Projects - Edit' }},

    { path: "/statistics", component: <Statistics/>, allowedRoles: TENANT_ADMIN, initState: {title: 'Statistics'}},

    {path: "/manufacturers", component: <ManufacturerList/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: {title: 'Manufacturer'}},
    {path: "/manufacturers/:id/details", component: <ManufacturerDetails/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: {title: 'Manufacturer Details'}},
    {path: "/manufacturers/:id/edit", component: <ManufacturerDetailsEdit/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: {title: 'Edit Manufacturer Details'}},

    { path: "/productLists", component: <FavouriteList/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: { title: 'Product Lists' } },
    { path: "/productLists/:id/articles", component: <ProductListArticles/>, allowedRoles: TENANT_ADMIN, permission:"ARTICLES", initState: { title: 'Product List Articles' }, eventMap: { entityTypes: ['FavouriteList'] , impersonationType: 'TENANT' }},

    {path: "/events", component: <Audit/>, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_TENANT_USER"], permission:"EVENTS", initState: { title: 'Events' } },

    { path: "/customers", component: <CustomerList/>, allowedRoles: TENANT_ADMIN, permission:"CUSTOMERS", initState: { title: 'Customers' } },
    { path: "/customers/:id/profile", component: <CustomerProfile/>, allowedRoles: TENANT_ADMIN, permission:"CUSTOMERS", initState: { title: 'Customer - Details' }, eventMap: { entityTypes: ['Customer'] , impersonationType: 'TENANT' }},
    { path: "/customers/:id/profile/edit", component: <CustomerProfileEdit/>, allowedRoles: TENANT_ADMIN, permission:"CUSTOMERS", initState: { title: 'Customer - Details' } },

    { path: "/users", component: <UsersList/>, allowedRoles: TENANT_ADMIN, initState: {title : 'Users'}},
    { path: "/users/create", component: <CustomerUserFormContainer/>, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER", "ROLE_CUSTOMER_ADMIN"], initState: {title: 'User - Create user'}},
    { path: "/customers/:customerId/users/:id", component: <UserProfile/>, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER", "ROLE_CUSTOMER_ADMIN"], initState: {title: 'User - Details'}, eventMap: { entityTypes: ['User'] , impersonationType: 'TENANT' }},
    { path: "/customers/:customerId/users/:userId/edit", component: <CustomerUserFormContainer/>, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"], initState: { title: 'Customer - Users' } },
    { path: "/customers/:customerId/users/create", component: <CustomerUserFormContainer/>, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER", "ROLE_CUSTOMER_ADMIN"], initState: {title: 'User - Create user'}},


    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {
        path: "/",
        exact: true,
        component: <Navigate to="/login"/>,
    },
    {path: "*", component: <Navigate to="/login"/>},
];

const authRoutes = [

    {path: "/logout", component: <Logout/>},
    {path: "/login", component: <Login/>},
    {path: "/forgot-password", component: <ForgetPwd/>},
    {path: "/register", component: <Register/>},

    {path: "/pages-maintenance", component: <PagesMaintenance/>},
    {path: "/pages-comingsoon", component: <PagesComingsoon/>},
    {path: "/pages-404", component: <Pages404/>},
    {path: "/pages-500", component: <Pages500/>},

    // Authentication Inner
    {path: "/pages-login", component: <Login1/>},
    {path: "/pages-register", component: <Register1/>},
    {path: "/page-recoverpw", component: <Recoverpw/>},
    {path: "/auth-lock-screen", component: <LockScreen/>},
]

export {userRoutes, authRoutes}

import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import ChangePassword from "./auth/changepwd/reducer"
import UserImpersonation from "./users/impersonation/reducer";
import Users from "./users/reducer";
import Project from "./project/reducer";
import Language from "./language/reducer";
import tenant from "./tenant/reducer";
import ui from "./ui/reducer";
import TenantAdmin from "./users/tenant/reducer";
import task from "./task/reducer";
import Customer from "./customer/reducer";
import ecommerce from "./e-commerce/reducer"
import Audit from "./audit/reducer"
import invoices from "./invoices/reducer"
import Offers from "./offer/reducer"
import ProjectTemplate from "./project-template/reducer"
import Labels from "./label/reducer"
import Cart from "./e-commerce/cart/reducer"
import Manufacturer from "./manufacturer/reducers"
import Config from "./config/reducer"
import BrandSettings from "./brandsettings/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  ChangePassword,
  Profile,
  UserImpersonation,
  Users,
  Project,
  Language,
  tenant,
  ui,
  TenantAdmin,
  task,
  Customer,
  ecommerce,
  Audit,
  invoices,
  Offers,
  ProjectTemplate,
  Labels,
  Cart,
  Manufacturer,
  Config,
  BrandSettings
})

export default rootReducer

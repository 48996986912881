import React from "react";
import {withTranslation} from "react-i18next";
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from "./Loader";

const InfiniteScrollbarWithLoader = props => {
  const {items, loading, totalItems, page, renderCallback, setPage, type} = props;

    return <>
    <InfiniteScroll 
        dataLength={items?.length}
        next={() => setPage(page + 1)} 
        hasMore={items?.length < totalItems}
        scrollThreshold={0.2}
        className="flex flex-wrap row overflow-visible">
            {items?.map(
                (product, key) => {
                    return renderCallback(product, key)
                }
            )}
    </InfiniteScroll>
    {(loading && page > 1) && (
        <div align="center" className="mx-auto mb-1">
            <Loader loading={true} title={props.t("More") + " " + type} defaultLoader={true}/>
        </div>
    )}
</>
}

export default withTranslation()(InfiniteScrollbarWithLoader);

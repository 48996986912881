import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Alert, Button, Col, Label, Row} from "reactstrap"
import {withTranslation} from "react-i18next";
import {connect} from "react-redux"
import {getDefaultTimeslipType, getTaskList, getWorkLogType} from "../../store/task/actions"
import {get, post} from "../../helpers/api_helper"
import {AvForm} from "availity-reactstrap-validation"
import {getDateObject, parseDateToString} from "../../util/helperFunctions";
import DatePicker from "react-date-picker";
import {getUserForImpersonation} from "../../store/users/impersonation/actions";
import {filter, find, parseInt} from "lodash";
import Select from "react-select";
import {API_ERR_MSG} from "../../util/constants";
import cogoToast from "cogo-toast";
import {toastOptions} from "../../util/dataUtil";
import {TimePicker} from "antd";
import dayjs from 'dayjs';
import {GetCustomers} from "../../store/actions";
import TaskModal from "./TaskModal";
import DropdownSelect from "../../components/Common/DropdownSelect";
import moment from "moment-timezone";
import withRouter from "../../components/Common/withRouter";

const { RangePicker } = TimePicker;

const WorkLogEdit = (props) => {
    const {
        cid,
        projectId,
        taskId,
        workLog,
        showForm,
        setShowForm,
        onGetWorkLogTypes,
        workLogTypes,
        userDropdown,
        realodTaskWorkLog,
        onGetTasks,
        tasks,
        onGetUserForImpersonation,
        users,
        usersLoading,
        avoidRedirect,
        defaultTimeslipType,
        onGetDefaultTimeslipType,
        task,
        customerOptions,
        onGetCustomerOptions,
        tasksLoading,
        hideCustomer,
        hideTask
    } = props;
    const [brandSettings, setBrandSettings] = useState(null);
    const user = JSON.parse(localStorage.getItem("authUser"));
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState("0");
    const [description, setDescription] = useState(null);
    const [logType, setLogType] = useState(null);
    const [date, setDate] = useState(parseDateToString(new Date()));
    const [worklogUser, setWorklogUser] = useState(null);
    const [worklogError, setWorklogError] = useState(null);
    const [editId, setEditId] = useState(null);
    const [selectedTask, setSelectedTask] = useState(null);
    const [taskList, setTaskList] = useState([]);
    const [tId, setTid] = useState(null);
    const [level, setLevel] = useState(null);
    const [superAdminUser, setSuperAdminUser] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [error, setError] = useState(null);
    const [taskCustomerList, setTaskCustomerList] = useState([]);
    const [filteredTaskList, setFilteredTaskList] = useState([]);
    const [showAddTaskModal, setShowAddTaskModal] = useState(false);
    const [customerId, setCustomerId] = useState(null);

    let minInterval = user?.config?.minInterval ? parseInt(user?.config?.minInterval) : 5;

    const allowFutureTimeLog = user?.config?.timeslipFutureTimeLog;

    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        onGetWorkLogTypes();
        setLevel(localStorage.getItem("level"))
        getAndSetSuperAdminUser();
        onGetDefaultTimeslipType();
        onGetCustomerOptions();
    }, [])

    useEffect(() => {
        if(level == "TENANT"){
            onGetUserForImpersonation(user.tenantId, null, [
                "ROLE_TENANT_ADMIN",
                "ROLE_TENANT_USER",
            ]);
        }
    }, [level]);

    useEffect(() => {
        if(customerOptions){
            const vals = [{ label: props.t("Select customer"), value: null }];
            customerOptions.map(c => ({ label: (c.favorite ? '★' + " " : " " ) + c.name, value: c.id })).forEach(o => vals.push(o));
            setTaskCustomerList(vals);
        }
    }, [customerOptions])

    useEffect(() => {
        setTid(taskId);
    }, [taskId])

    useEffect(() => {
        if(cid){
            setCustomerId(cid);
        }
    }, [cid])

    useEffect(() => {
        if(!editId && users && user?.tenantId && superAdminUser?.id != user.uid){
            let loggedInUser = find(users, {id: parseInt(user.uid)});
            setWorklogUser(loggedInUser ? {value :loggedInUser?.id, label: loggedInUser?.name} : null);
        }
    }, [users, superAdminUser])

    useEffect(() => {
        if(defaultTimeslipType && !workLog && !task?.taskCategoryDefaultTimeslipType){
            setLogType(defaultTimeslipType.timeslipType?.id);
        }
    }, [defaultTimeslipType])

    useEffect(() => {
        if(selectedTask){
            setDefaultTimeslipType(selectedTask.taskCategoryDefaultTimeslipType);
        } else if(taskId && task){
            setDefaultTimeslipType(task.taskCategoryDefaultTimeslipType)
        }
    }, [selectedTask, taskId, task])

    useEffect(() => {
        let groupedOptions = [];

        var myTask = {label: props.t("My Tasks"), options: []}
        var allTasks = {label: props.t("All"), options: []};
        let activeTasks = filter(tasks, t => t?.taskDetails?.status != "DONE");
        for(let i = 0; i < activeTasks.length; i++){
            let project = activeTasks[i]?.project;
            let value = "";
            if(!projectId && project){
                value = " ("+project+")";
            }

            if(activeTasks[i]?.taskDetails?.assigned?.id == user?.uid){
                myTask.options.push({
                    value: activeTasks[i]?.taskDetails.id,
                    label : activeTasks[i]?.taskDetails.subject + value,
                    taskCategoryDefaultTimeslipType: activeTasks[i]?.taskCategoryDefaultTimeslipType,
                    customerId: activeTasks[i]?.taskDetails?.customer?.id
                })
            } else {
                allTasks.options.push({
                    value: activeTasks[i]?.taskDetails.id,
                    label : activeTasks[i]?.taskDetails.subject + value,
                    taskCategoryDefaultTimeslipType: activeTasks[i]?.taskCategoryDefaultTimeslipType,
                    customerId: activeTasks[i]?.taskDetails?.customer?.id
                })
            }
        }

        myTask.options.sort((a, b) => {
            const nameA = a.label.toLowerCase();
            const nameB = b.label.toLowerCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });

        allTasks.options.sort((a, b) => {
            const nameA = a.label.toLowerCase();
            const nameB = b.label.toLowerCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        groupedOptions.push(myTask);
        groupedOptions.push(allTasks);
        /*var taskArray = [];
        for(let i = 0; i < tasks.length; i++){
            taskArray.push({id: tasks[i].taskDetails.id, name : tasks[i].taskDetails.subject});
        }*/
        setTaskList(groupedOptions);
        if(customerId){
            setFilteredTaskList(groupedOptions.map(t => {
                const options = t.options.filter(o => o.customerId == customerId);
                return {...t, options};
            }))
        } else {
            setFilteredTaskList(groupedOptions);
        }
    }, [tasks])

    useEffect(() => {
        /*if(!projectId){*/
            onGetTasks({
                /*customerId: cid,*/
                assignedUserId: null,
                reporterUserId: null,
                projectId: projectId
            });
        /*}*/

        /*console.log("Timezone -",  dayjs(moment.utc(workLog?.startTime).tz(user?.timezone).toDate()));
        console.log("day JS -", dayjs(workLog?.startTime, "YYYY-MM-DDTHH:mm:ss"));*/

        setEditId(workLog?.id);
        setDescription(workLog?.description);
        setLogType(workLog?.timeslipType?.id);
        setWorklogUser({value : workLog?.user?.id, label : workLog?.user?.name});
        setHours(workLog?.hours);
        setMinutes(workLog?.minutes);
        setCustomerId(workLog?.task?.customer?.id);
        setStartTime(workLog?.startTime ? dayjs(moment.utc(workLog?.startTime).tz(user?.timezone).toDate()) : null);
        setEndTime(workLog?.endTime ? dayjs(moment.utc(workLog?.endTime).tz(user?.timezone).toDate()): null);
        setSelectedTask({value : workLog?.task?.id, label: workLog?.task?.subject})
        if(workLog?.date){
            //setDate(parseDateToString(new Date(workLog?.date)));
            //setDate(moment(workLog?.date.substring(0, 19)).format("YYYY-MM-DD"))
            setDate(moment.utc(workLog?.date).format("YYYY-MM-DD"))
        }
    }, [workLog]);

    const setTaskId = (obj) => {
        setTid(obj?.value)
        setSelectedTask(obj);
    }

    function toggleAddTaskModal(t) {
        if(filteredTaskList && t){
            setTaskId({ 
                value: t.taskDetails.id,
                label: t.taskDetails.subject,
                customerId: t.taskDetails.customer?.id,
                taskCategoryDefaultTimeslipType: t.taskCategoryDefaultTimeslipType
             });
            onGetTasks({
                customerId: customerId ? customerId : cid,
                assignedUserId: null,
                reporterUserId: null,
            });
        }
        setShowAddTaskModal(!showAddTaskModal);
    }

    const setDefaultTimeslipType = (taskCategoryDefaultTimeslipType) => {
        if(!workLog){
            if(taskCategoryDefaultTimeslipType){
                setLogType(taskCategoryDefaultTimeslipType.id)
            } else if(defaultTimeslipType) {
                setLogType(defaultTimeslipType.timeslipType?.id)
            } else {
                setLogType(null);
            }
        }
    }

    const getAndSetSuperAdminUser = async () => {
        try {
            const response = await get(`/api/user/adminUserByRole?roleName=ROLE_SUPER_ADMIN`);
            if(response.status === 200) {
                return setSuperAdminUser(response.data);
            }
        } catch (err) {
        }
        return setSuperAdminUser(null);
    };

    const handleAddWorkLog = async () => {
        if (!startTime && !endTime) {
            setWorklogError("Please provide start time and end time.");
            return;
        }
        let createError = false;
        try {
            let response = await post(`/api/timeslip/task/${tId}`, {
                id: editId,
                hours: hours,
                minutes: minutes,
                description: description,
                timeslipType: {id: logType},
                date: date,
                user: {id: worklogUser?.value},
                startTime: date + "T" + dayjs(startTime).format("HH:mm") +":00",
                endTime: date + "T" + dayjs(endTime).format("HH:mm") +":00",
            })
            createError = response.status != 202;
        } catch(err){
            createError = true;
        }
        if(!avoidRedirect){
            createError ?  setError(props.t(API_ERR_MSG)) : realodTaskWorkLog();
        } else {
            createError ? cogoToast.error(props.t("Error occurred while creating timeslip!"), toastOptions) : cogoToast.success(props.t("Timeslip created successfully!"), toastOptions);
        }
        if (!createError) {
            setHours("");
            setMinutes("");
            setDescription("");
            setStartTime("");
            setEndTime("");
            handleAddTask();
            setShowForm(!showForm);
        }
    }

    const handleAddTask = () => {
        setEditId(null);
        setDescription(null);
        setLogType(null);
        setWorklogUser(null);
        setHours(null);
        setMinutes(null);
        setDate(null);
    }

    const getMinutes = (maxValue, interval) => {
        const result = [0];
        let currentValue = interval;
        while (currentValue < maxValue) {
            result.push(currentValue);
            currentValue += interval;
        }
        //result.push(maxValue);
        return result;
    }


    return (<div className="mt-4">
                <AvForm onValidSubmit={(e, v) => {
                    e.preventDefault();
                    handleAddWorkLog(e, v)
                }}>
                    <Row className="mb-3">
                        <div className="col-md-12">
                            {error && <Alert color="danger">{error}</Alert>}
                            <Row>
                                {/*{!taskId &&*/}
                                    <div className="col-md-12 mb-3">
                                        <Row>
                                            {!hideCustomer && <div className="col-md-12 mb-3">
                                                <label>{props.t("Customer")}</label>
                                                <Select
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={({value}) => {
                                                        setCustomerId(value);
                                                        setFilteredTaskList(!value ? taskList : taskList.map(t => {
                                                            const options = t.options.filter(o => o.customerId == value);
                                                            return {...t, options};
                                                        }))
                                                    }}
                                                    value={find(taskCustomerList, { value: customerId })}
                                                    placeholder={props.t("Select customer")}
                                                    options={taskCustomerList}
                                                    isDisabled={projectId}
                                                    />
                                            </div>}
                                            {!hideTask &&
                                            <div className="col-md-12">
                                                <Row>
                                                    <div className="col-md-11">
                                                        <label>{props.t("Task")}</label>
                                                        <Select
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            value={selectedTask}
                                                            onChange={(vals) => {
                                                                setTaskId(vals)
                                                            }}
                                                            placeholder={props.t("Select task")}
                                                            options={filteredTaskList}/>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label></label>
                                                        <div 
                                                            className="p-2 rounded" 
                                                            style={{
                                                                border: "1px solid lightgrey", 
                                                                cursor: "pointer", 
                                                                backgroundColor: !tasksLoading ? 'white' : '#f1f3f5',
                                                                maxWidth: "34px",
                                                                marginTop: "7px"
                                                            }}
                                                            onClick={() => !tasksLoading ? setShowAddTaskModal(true) : null}
                                                        >
                                                            <i className="uil uil-plus"></i>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div> }
                                        </Row>
                                    </div>
                                {/*}*/}
                                <div className="col-md-12 mb-3">
                                      <label>{props.t("Description")}</label>
                                      <textarea
                                          className="form-control task-textarea"
                                          type="text"
                                          onChange={(e) => {
                                            setError(null)
                                            setDescription(e.target.value)
                                          }}
                                          placeholder={props.t("Enter description of work")}
                                          value={description}
                                          rows="3"
                                      />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label>{props.t("Category")}</label>
                                    {/*<select
                                        className="form-control col-md-6"
                                        value={logType}
                                        onChange={(e) => setLogType(e.target.value)}
                                    >
                                        <option value="">{props.t("Select Category")}</option>
                                        {workLogTypes?.map((type) => type.enabled && <option value={type.id}>{props.t(type.name)}</option>)}
                                    </select>*/}

                                    <DropdownSelect
                                        defaultValue={{value : logType, label : find(workLogTypes, {id : logType})?.name}}
                                        values={logType ? {value : logType, label : find(workLogTypes, {id : logType})?.name} : null}
                                        onChange={(e) => {
                                            setLogType(e?.value);
                                        }}
                                        placeholder={props.t("Select Category")}
                                        options={workLogTypes?.filter(type => type.enabled).map(o => ({value: o.id, label: o.name}))}
                                    />
                                </div>
                                <div className="col-md-3 mb-3 d-flex flex-column">
                                    <Label className="me-3">{props.t("User")}</Label>
                                    <Select
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={worklogUser}
                                        onChange={(vals) => {
                                            setError(null);
                                            setWorklogUser(vals)
                                        }}
                                        isDisabled={usersLoading}
                                        options={!user?.tenantId ? [{value : superAdminUser?.id, label : superAdminUser?.name}] :  users.map(o => ({value: o.id, label: o.name}))}/>
                                </div>
                                <div className="col-md-3 mb-3 d-flex flex-column">
                                    <Label className="me-3">{props.t("Date")}</Label>
                                    <DatePicker
                                        onChange={(date) => {
                                            setError(null);
                                            setDate(parseDateToString(date))

                                        }}
                                        value={getDateObject(date)}
                                        name={"Date"}
                                        format={"yyyy-MM-dd"}
                                        dayPlaceholder={"dd"}
                                        monthPlaceholder={"mm"}
                                        yearPlaceholder={"yyyy"}
                                        maxDate={allowFutureTimeLog ? null : new Date()}
                                    />
                                </div>
                                <div className="col-md-2 d-flex flex-column">
                                    <Label className="me-3">{props.t("Start time")}</Label>
                                    <TimePicker
                                                defaultValue={workLog?.startTime  ? dayjs(workLog?.startTime, "YYYY-MM-DDTHH:mm:ss") : null}
                                                format={"HH:mm"}
                                                placeholder={props.t("Start time")}
                                                value={startTime ? startTime : null}
                                                minuteStep={minInterval}
                                                onChange={(time) => {
                                                    setError(null);
                                                    setStartTime(time)
                                                }}/>
                                </div>
                                <div className="col-md-2 d-flex flex-column">
                                    <Label className="me-3">{props.t("Hours")}</Label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        onChange={(e) => setHours(e.target.value)}
                                        placeholder={props.t("Enter hours")}
                                        value={hours}
                                        required
                                    />
                                </div>
                                <div className="col-md-2 d-flex flex-column">
                                    <Label className="me-3">{props.t("Minutes")}</Label>
                                    {/*<input
                                        className="form-control"
                                        type="number"
                                        onChange={(e) => setMinutes(e.target.value)}
                                        placeholder={props.t("Enter Minutes")}
                                        value={minutes}
                                        required
                                    />*/}
                                    <select className="form-control"
                                            onChange={(e) => setMinutes(e.target.value)}
                                            placeholder={props.t("Enter Minutes")}
                                            value={minutes}
                                            required
                                    >
                                        {getMinutes(60, minInterval).map(min => {
                                            return <option value={min}>{min}</option>
                                        })}
                                    </select>
                                </div>
                            </Row>
                            <Row className="mb-3 mt-5">
                                <Col align="center">
                                    <Button
                                        color="secondary"
                                        style={{backgroundColor: brandSettings?.secondaryBtnColor, borderColor: brandSettings?.secondaryBtnColor}}
                                        onClick={() => setShowForm(!showForm)}
                                        className="btn btn-primary waves-effect waves-light mt-2"
                                    >
                                        {props.t("Back")}
                                    </Button>
                                    {" "}
                                    <Button
                                        color="primary"
                                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                        //   onClick={() => handleAddWorkLog()}
                                        className="btn btn-primary waves-effect waves-light  mt-2 ml-2"
                                        disabled={!logType || !description || (!startTime  && !endTime) || !worklogUser}
                                        type="submit"
                                    >
                                        {props.t("Save")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                    {worklogError &&
                        <Row>
                            <Col>
                                <div>
                                    <Alert color="danger">{worklogError}</Alert>
                                </div>
                            </Col>
                        </Row>}
                </AvForm>
                <TaskModal 
                    modal_standard={showAddTaskModal} 
                    setmodal_standard={setShowAddTaskModal} 
                    tog_standard={toggleAddTaskModal}
                    avoidRedirect={true}
                    cid={customerId}
                    projectId={projectId}
                />
            </div>)
}

WorkLogEdit.propTypes = {
    workLog: PropTypes.object,
    workLogTypes: PropTypes.array,
    tasks: PropTypes.array,
    onGetTasks: PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
        workLogTypes: state.task.workLogType,
        tasks: state.task.tasks,
        tasksLoading: state.task.loading,
        users: state.UserImpersonation.users,
        usersLoading: state.UserImpersonation.getImpersonationUsersLoading,
        defaultTimeslipType: state.task.defaultTimeslipType,
        task: state.task.task,
        customerOptions: state.Customer.customers,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetWorkLogTypes: () => dispatch(getWorkLogType()),
    onGetTasks: (filter) => dispatch(getTaskList(filter)),
    /*onGetTasks: (filter) => {
        if(filter?.customerId || filter?.assignedUserId || filter?.reporterUserId){
            dispatch(getTaskList(filter));
        }
    },*/
    onGetUserForImpersonation: (tenantId, customerId, roles) =>
        dispatch(getUserForImpersonation(tenantId, customerId, roles)),
    onGetDefaultTimeslipType: () => dispatch(getDefaultTimeslipType()),
    onGetCustomerOptions: () => dispatch(GetCustomers()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(WorkLogEdit)));

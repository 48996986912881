import { orderBy, size } from "lodash";
import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Modal, Row } from "reactstrap";
import Loader from "../../components/Common/Loader";
import TableToolbar from "../../components/Common/TableToolbar";
import { post } from "../../helpers/api_helper";
import { getCurrencyList, getCurrencyListReset } from "../../store/actions";
import withRouter from "../../components/Common/withRouter";

const CurrencyList = (props) => {
  const {
    currencies,
    error,
    loading,
    onGetCurrencyList,
    onGetCurrencyListReset,
  } = props;

  const [currencyList, setCurrencyList] = useState([]);
  const [brandSettings, setBrandSettings] = useState(null);

  const [editSymbol, setEditSymbol] = useState("");
  const [editSymbolPosition, setEditSymbolPosition] = useState("");
  const [editCurrency, setEditCurrency] = useState(null);
  const [modal_standard, setmodal_standard] = useState(false);

  const [sortState, setSortState] = useState(null);
  const numericColumns = ["buyRate", "sellRate"];

  const user = JSON.parse(localStorage.getItem("authUser"));

  const POST_FIX = 'POST_FIX';
  const PRE_FIX = 'PRE_FIX';

  useEffect(() => {
    setBrandSettings(user?.brandConfig);
    onGetCurrencyList();
    return () => {
      onGetCurrencyListReset();
    };
  }, []);

  useEffect(() => {
    if (currencies) {
      setCurrencyList(currencies);
    }
  }, [currencies]);

  useEffect(() => {
    if (sortState) {
      const { column, direction } = sortState;
      setCurrencyList(
        orderBy(
          currencyList,
          (o) => {
            //   if(column === 'customer') return o[column] ? o[column]?.name?.toLowerCase() : props.t('all')
            return numericColumns.includes(column)
              ? Number(o[column])
              : o[column]?.toLowerCase();
          },
          [direction]
        )
      );
    }
  }, [sortState]);

  const getPositionLabel = (position) => {
    return position == POST_FIX ? props.t('Post Fix') : props.t('Pre Fix');
  }

  const handleEditCurrency = (currency) => {
    setEditCurrency(currency);
    setEditSymbol(currency.symbol);
    setEditSymbolPosition(currency.currencySymbolPosition);
    tog_standard();
  };

  const handleSaveCurrency = async () => {
    let response = await post(`/api/currency/${editCurrency.code}`, { ...editCurrency, symbol: editSymbol, currencySymbolPosition: editSymbolPosition });
    setEditCurrency(null);
    setEditSymbol("");
    setEditSymbolPosition("")
    onGetCurrencyList()
    tog_standard();
  };

  function tog_standard() {
      setmodal_standard(!modal_standard)
      removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const getDatatableData = () => {
    let cols = [
      {
        label: props.t("Name"),
        field: "name",
        autoWidth: true,
      },
      {
        label: props.t("Code"),
        field: "code",
        width: 200,
      },
      {
        label: props.t("Symbol"),
        field: "symbol",
        autoWidth: true,
      },
      {
        label: props.t("Symbol Position"),
        field: "currencySymbolPosition",
        autoWidth: true,
      },
      {
        label: props.t("Buy Rate"),
        field: "buyRate",
        width: 200,
      },
      {
        label: props.t("Sell Rate"),
        field: "sellRate",
        width: 200,
      },
      {
          label: props.t("Actions"),
          field: "actions",
          autoWidth: true,
      },
    ];
    let data = {
      columns: cols,
      rows: currencyList?.map((item, key) => ({
        name: item.name,
        code: item.code,
        symbol: item.symbol,
        currencySymbolPosition: getPositionLabel(item.currencySymbolPosition),
        buyRate: item.buyRate,
        sellRate: item.sellRate,
        actions: (
            <div className='d-flex'>
                <i
                    className={`uil uil-pen me-3 text-warning`}
                    onClick={() => handleEditCurrency(item)}
                ></i>
            </div>
          ),
      }))
    };
    if(user?.tenantId){
      data.columns.splice(6, 1);
    }
    return data;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col className="col-12 article-table">
              <Card>
                <CardHeader
                  className="table-header-bg"
                  style={{ backgroundColor: brandSettings?.primaryColor }}
                >
                  <TableToolbar
                    title={props.t("Currency List")}
                  />
                </CardHeader>
                <Loader loading={loading} title="Currency List" />
                {!loading && (
                  <CardBody>
                    {error && (
                      <Row>
                        <Alert color="danger">{error}</Alert>
                      </Row>
                    )}
                    <MDBDataTable
                      key={size(currencyList)}
                      responsive
                      searching={true}
                      barReverse={true}
                      hover
                      borderless
                      paginationLabel={[props.t("Previous"), props.t("Next")]}
                      entriesLabel={props.t("Show entries")}
                      infoLabel={[
                        props.t("Showing"),
                        props.t("to"),
                        props.t("of"),
                        props.t("entries"),
                      ]}
                      searchLabel={props.t("Search") + "..."}
                      noRecordsFoundLabel={props.t("No matching records found")}
                      noBottomColumns={true}
                      data={getDatatableData()}
                      entries={50}
                      entriesOptions={[50, 100, 500]}
                      onSearch={(text) => console.log(text)}
                      sortRows={[]}
                      onSort={({ column, direction }) => {
                        if (sortState && sortState.column == column) {
                          setSortState({
                            ...sortState,
                            direction:
                              sortState.direction == "asc" ? "desc" : "asc",
                          });
                        } else {
                          setSortState({ column, direction });
                        }
                      }}
                    />
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
          isOpen={modal_standard}
          toggle={() => {
              tog_standard();
          }}
      >
          <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                  {props.t("Edit Currency")}
              </h5>
              <button
                  type="button"
                  onClick={() => {
                      setmodal_standard(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
              >
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div className="modal-body">
              <Row className="mb-3">
                  <label
                      htmlFor="example-text-input"
                      className="col-md-3 col-form-label"
                  >
                      {props.t("Name")}
                  </label>
                  <div className="col-md-9">
                      <input
                          className="form-control"
                          type="text"
                          disabled={true}
                          value={editCurrency?.name}
                      />
                  </div>
              </Row>
              <Row className="mb-3">
                  <label
                      htmlFor="example-text-input"
                      className="col-md-3 col-form-label"
                  >
                      {props.t("Code")}
                  </label>
                  <div className="col-md-9">
                      <input
                          className="form-control"
                          type="text"
                          disabled={true}
                          value={editCurrency?.code}
                      />
                  </div>
              </Row>
              <Row className="mb-3">
                  <label
                      htmlFor="example-text-input"
                      className="col-md-3 col-form-label"
                  >
                      {props.t("Symbol")}
                  </label>
                  <div className="col-md-9">
                      <input
                          className="form-control"
                          type="text"
                          onChange={(e) => setEditSymbol(e.target.value)}
                          placeholder={props.t("Enter Symbol")}
                          value={editSymbol}
                      />
                  </div>
              </Row>
              <Row className="mb-3">
                  <label
                      htmlFor="example-text-input"
                      className="col-md-3 col-form-label"
                  >
                      {props.t("Symbol")}
                  </label>
                  <div className="col-md-9">
                      <select className="form-control" value={editSymbolPosition} onChange={(e) => setEditSymbolPosition(e.target.value)}>
                          <option value={POST_FIX}>{getPositionLabel(POST_FIX)}</option>
                          <option value={PRE_FIX}>{getPositionLabel(PRE_FIX)}</option>
                      </select>
                  </div>
              </Row>
          </div>
          <div className="modal-footer">
              <Button
                  color="primary"
                  style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                  onClick={() => handleSaveCurrency()}
                  className="col-md-2 btn btn-primary waves-effect waves-light"
              >
                  {props.t("Save")}
              </Button>
          </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    currencies: state.Config.currencyList,
    loading: state.Config.currencyListLoading,
    error: state.Config.currencyListError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetCurrencyList: () => dispatch(getCurrencyList()),
  onGetCurrencyListReset: () => dispatch(getCurrencyListReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(CurrencyList)));

import React, {useEffect, useState} from "react";
import {Alert, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {isEmpty, orderBy} from "lodash";
import PropTypes from "prop-types";
import {getInvoices, getInvoicesReset} from "../../store/actions";
import {connect} from "react-redux";
import {MDBDataTable} from "mdbreact";
import TableToolbar from "../../components/Common/TableToolbar";
import {filter, includes} from "lodash-es";
import {checkIfLoading} from "../../store/selector";
import {invoicesActionTypes} from "../../store/invoices/actionTypes";
import {API_ERR_MSG} from "../../util/constants";
import Loader from "../../components/Common/Loader";
import InvoiceStatus from "./InvoiceStatus";
import {downloadPDF} from "../../util/helperFunctions";
import {formatCurrency} from "../../helpers/currency_helper";
import withRouter from "../../components/Common/withRouter";

const Invoices = (props) => {
  const { invoices, onGetInvoices, loading, error, onGetInvoicesReset } = props;
  const [invoiceList, setInvoiceList] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const history = useNavigate();
  const [brandSettings, setBrandSettings] = useState(null)
  const [invoiceType, setInvoiceType] = useState(null)
  const [sortState, setSortState] = useState(null);
  const numericColumns = ['DocumentNumber', 'Total'];
  let user = JSON.parse(localStorage.getItem("authUser"));

  useEffect(() => {
    setBrandSettings(user?.brandConfig);
    const prevFilter = localStorage.getItem("salesInvoicesFilter");
    if(prevFilter){
      setInvoiceType(prevFilter);
    } else {
      setInvoiceType("all");
    }
    return () => {
      onGetInvoicesReset();
    }
  }, [])

  useEffect(() => {
    if(error){
      setTimeout(() => {onGetInvoicesReset()}, 5000);
    }
  }, [error])

  useEffect(() => {
    if(invoiceType){
      localStorage.setItem("salesInvoicesFilter", invoiceType);
      onGetInvoices(invoiceType);
    }
  }, [invoiceType])

  useEffect(() => {
    setInvoiceList(invoices);
  }, [invoices]);

  useEffect(() => {
    if(sortState){
      const {column, direction} = sortState;
      setInvoiceList(orderBy(invoiceList, o => numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase(), [direction]))
    }
  }, [sortState])

  const handleOpen = (customerId, invoiceId) => {
    history(`${user.customerId ? '/customer' : ''}/invoice/${invoiceId}?from=` + encodeURIComponent(props?.location?.pathname));
  };
  const handleSearch = () => {
    if (!isEmpty(search)) {
      const results = filter(
        invoiceList,
        (offer) =>
          includes(offer.displayName?.toLowerCase(), search.toLowerCase()) ||
          includes(offer.description?.toLowerCase(), search.toLowerCase()) ||
          filter(offer.categories, (c) =>
            includes(c.name?.toLowerCase(), search.toLowerCase())
          ).length > 0
      );

      setSearchResults(results);
    } else {
      setSearchResults(invoiceList);
    }
    setSearchValue(search);
  };
  const getDatatableData = () => {
    const rows = invoices?.filter((row) =>
      row.CustomerName?.toLowerCase().includes(search?.toLowerCase())
    );
    let data = {
      columns: [
        {
          label: props.t("Customer"),
          field: "CustomerName",
          width: 150,
        },
        {
          label: props.t("Invoice Number"),
          field: "DocumentNumber",
          width: 150,
        },
        {
          label: props.t("Balance"),
          field: "Balance",
          width: 150,
        },
        {
          label: props.t("Amount"),
          field: "Total",
          width: 150,
        },
        {
          label: props.t("Invoice Date"),
          field: "InvoiceDate",
          width: 150,
        },
        {
          label: props.t("Due Date"),
          field: "DueDate",
          width: 150,
        },
        {
          label: props.t("Our Reference"),
          field: "OurReference",
          width: 150,
        },
        {
          label: props.t("Status"),
          field: "status",
          width: 150,
        },
        {
          label: props.t("Download"),
          field: "download",
        }
      ],
      rows: rows?.map((invoice, key) => ({
        CustomerName: <div onClick={() => handleOpen(invoice.CustomerNumber, invoice.DocumentNumber)}>{invoice.CustomerName}</div>,
        DocumentNumber: <div onClick={() => handleOpen(invoice.CustomerNumber, invoice.DocumentNumber)}>{invoice.DocumentNumber}</div>,
        Total: <div onClick={() => handleOpen(invoice.CustomerNumber, invoice.DocumentNumber)}>{formatCurrency(invoice?.Currency, invoice.TotalWithoutTax)}</div>,
        Balance: <div onClick={() => handleOpen(invoice.CustomerNumber, invoice.DocumentNumber)}>{formatCurrency(invoice?.Currency, invoice.Balance)}</div>,
        InvoiceDate: <div onClick={() => handleOpen(invoice.CustomerNumber, invoice.DocumentNumber)}>{invoice.InvoiceDate}</div>,
        DueDate: <div onClick={() => handleOpen(invoice.CustomerNumber, invoice.DocumentNumber)}>{invoice.DueDate}</div>,
        OurReference: <div onClick={() => handleOpen(invoice.CustomerNumber, invoice.DocumentNumber)}>{invoice.OurReference}</div>,
        status: <div onClick={() => handleOpen(invoice.CustomerNumber, invoice.DocumentNumber)}><InvoiceStatus status={invoice.status}/></div>,
        download: <div onClick={() => downloadPDF(invoice.DocumentNumber, 'invoices')}><i className="fa fa-download font-size-15"></i></div>
      })),
    };
    return data;
  };

  const handleFilter = (value) => {
    setInvoiceType(value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <Row>
            <Col className="col-12 article-table">
              <Card>
                <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor}}>
                  <TableToolbar
                    title={props.t("Invoices")}
                    filter={{
                      selected: invoiceType,
                      options : [
                        {name: props.t("All"), value: "all"},
                        {name: props.t("Unpaid"), value: "unpaid"},
                        {name: props.t("Overdue"), value: "unpaidoverdue"},
                        {name: props.t("Fully Paid"), value: "fullypaid"},
                        {name: props.t("Unbooked"), value: "unbooked"},
                        {name: props.t("Cancelled"), value: "cancelled"}
                      ],
                      onSelect: handleFilter
                    }}
                  />
                </CardHeader>
                <Loader loading={loading} title="Invoices" />
                {!loading &&
                <CardBody>
                  {error &&
                    <Alert className={"mt-4"} color="danger" role="alert">
                        {props.t(API_ERR_MSG)}
                    </Alert>
                  }
                  <MDBDataTable
                    responsive
                    hover
                    barReverse={true}
                    borderless
                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                    entriesLabel={props.t("Show entries")}
                    infoLabel={[
                      props.t("Showing"),
                      props.t("to"),
                      props.t("of"),
                      props.t("entries"),
                    ]}
                    searching={true}
                    searchLabel={props.t("Search") + "..."}
                    noRecordsFoundLabel={props.t("No matching records found")}
                    noBottomColumns={true}
                    data={getDatatableData()}
                    entries={50}
                    entriesOptions={[50, 100, 500]}
                    // onSearch={(text) => console.log(text)}
                    sortRows={[]}
                    onSort={({column, direction}) => {
                      if(sortState && sortState.column == column){
                        setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                      } else {
                        setSortState({column, direction});
                      }
                    }}
                  />
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Invoices.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    invoices: state.invoices.invoices?.Invoices,
    loading: checkIfLoading(state, invoicesActionTypes.GET_INVOICES),
    error: state.invoices.error
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetInvoices: (invoiceType) => dispatch(getInvoices(invoiceType)),
  onGetInvoicesReset: () => dispatch(getInvoicesReset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Invoices)));

import PropTypes from "prop-types";
import React, {useCallback, useEffect} from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "../Common/withRouter";
import {Link, useLocation} from "react-router-dom";

//i18n
import {withTranslation} from "react-i18next";
import {equalsAny, hasAnyModuleEnabled, hasModuleEnabled} from "../../util/helperFunctions";

const SidebarContent = props => {
    // const ref = useRef();
    const user = JSON.parse(localStorage.getItem("authUser"));


    const activateParentDropdown = useCallback((item) => {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];

        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            // scrollElement(item);
            return false;
        }
        // scrollElement(item);
        return false;
    }, []);

    const removeActivation = (items) => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;

            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                const parent2El =
                    parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null;
                if (parent2El && parent2El.id !== "side-menu") {
                    parent2El.classList.remove("mm-show");
                }

                parent.classList.remove("mm-active");
                const parent2 = parent.parentElement;

                if (parent2) {
                    parent2.classList.remove("mm-show");

                    const parent3 = parent2.parentElement;
                    if (parent3) {
                        parent3.classList.remove("mm-active"); // li
                        parent3.childNodes[0].classList.remove("mm-active");

                        const parent4 = parent3.parentElement; // ul
                        if (parent4) {
                            parent4.classList.remove("mm-show"); // ul
                            const parent5 = parent4.parentElement;
                            if (parent5) {
                                parent5.classList.remove("mm-show"); // li
                                parent5.childNodes[0].classList.remove("mm-active"); // a tag
                            }
                        }
                    }
                }
            }
        }

    };

    const path = useLocation();
    const activeMenu = useCallback(() => {
        const pathName = path.pathname;
        let matchingMenuItem = null;
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        removeActivation(items);

        for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [path.pathname, activateParentDropdown]);

    // useEffect(() => {
    //   ref.current.recalculate();
    // }, []);

    useEffect(() => {
        new MetisMenu("#side-menu");
    }, []);

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        activeMenu();
    }, [activeMenu]);

    // function scrollElement(item) {
    //   if (item) {
    //     const currentPosition = item.offsetTop;
    //     if (currentPosition > window.innerHeight) {
    //       ref.current.getScrollElement().scrollTop = currentPosition - 300;
    //     }
    //   }
    // }
    return (
        <React.Fragment>

            <SimpleBar style={{maxHeight: "100%"}}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {/*<li className="menu-title">{props.t("Menu")} </li>*/}
                        {equalsAny(user?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER", "ROLE_CUSTOMER_ADMIN"]) && (
                            <li>
                                <Link
                                    to="/dashboard"
                                    className="waves-effect"
                                >
                                    <i className="uil-home-alt"></i>
                                    <span>{props.t("Dashboard")}</span>
                                </Link>
                            </li>
                        )}

                        {user?.roles?.includes("ROLE_SUPER_ADMIN") && (
                            <li>
                                <Link
                                    to="/tenants"
                                    className="waves-effect"
                                >
                                    <i className="uil-users-alt"></i>
                                    <span>{props.t("Tenants")}</span>
                                </Link>
                            </li>
                        )}

                        {hasModuleEnabled(user?.tenantFeatures, "CUSTOMERS") && equalsAny(user?.roles, ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="uil-users-alt"></i>
                                    <span>{props.t("Customers")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link to="/customers">
                                            {props.t("Companies")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/users">
                                            {props.t("Users")}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        )}


                        {hasModuleEnabled(user?.tenantFeatures, "ARTICLES") && equalsAny(user?.roles, ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="uil-store"></i>
                                    <span>{props.t("Products")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link to="/articles">
                                            {props.t("Articles")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/groups">
                                            {props.t("Article Groups")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/categories">
                                            {props.t("Categories")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/productLists">
                                            {props.t("Product Lists")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/manufacturers">
                                            {props.t("Manufacturers")}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        )}


                        {hasAnyModuleEnabled(user?.tenantFeatures, ["OFFERS", "ORDERS", "INVOICES", "STATISTICS"]) && equalsAny(user?.roles, ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="uil-shopping-cart-alt"></i>
                                    <span>{props.t("Sales")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    {hasModuleEnabled(user?.tenantFeatures, "OFFERS") && <>
                                        <li>
                                            <Link
                                                to="/offers"
                                                className="waves-effect"
                                            >
                                                <span>{props.t("Offers")}</span>
                                            </Link>
                                        </li>
                                    </>}
                                    {hasModuleEnabled(user?.tenantFeatures, "ORDERS") && <>
                                        <li>
                                            <Link
                                                to="/orders"
                                                className="waves-effect"
                                            >
                                                <span>{props.t("Orders")}</span>
                                            </Link>
                                        </li>
                                    </>}
                                    {hasModuleEnabled(user?.tenantFeatures, "INVOICES") && <>
                                        <li>
                                            <Link
                                                to="/invoice"
                                                className="waves-effect"
                                            >
                                                <span>{props.t("Invoices")}</span>
                                            </Link>
                                        </li>
                                    </>}
                                    {hasModuleEnabled(user?.tenantFeatures, "STATISTICS") && <>
                                        <li>
                                            <Link
                                                to="/statistics"
                                                className="waves-effect">
                                                <span>{props.t("Statistics")}</span>
                                            </Link>
                                        </li>
                                    </>}
                                </ul>
                            </li>
                        )}

                        {hasModuleEnabled(user?.tenantFeatures, "PROJECTS") && equalsAny(user?.roles, ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) && (
                            <li>
                                <Link
                                    to="/projects"
                                    className="waves-effect"
                                >
                                    <i className="uil-apps"></i>
                                    <span>{props.t("Projects")}</span>
                                </Link>
                            </li>
                        )}

                        {hasModuleEnabled(user?.tenantFeatures, "TASKS") && equalsAny(user?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) && (
                            <li>
                                <Link
                                    to="/tasks"
                                    className="waves-effect"
                                >
                                    <i className="uil-channel-add"></i>
                                    <span>{props.t("Tasks")}</span>
                                </Link>
                            </li>
                        )}

                        {hasModuleEnabled(user?.tenantFeatures, "TASKS") && equalsAny(user?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) && (
                            <li>
                                <Link
                                    to="/timeslips"
                                    className="waves-effect"
                                >
                                    <i className="uil uil-clock font-size-20"></i>
                                    <span>{props.t("Timeslips")}</span>
                                </Link>
                            </li>
                        )}

                        {hasModuleEnabled(user?.tenantFeatures, "EVENTS") && equalsAny(user?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"]) && (
                            <li>
                                <Link
                                    to="/events"
                                    className="waves-effect"
                                >
                                    <i className="uil-clipboard-notes"></i>
                                    <span>{props.t("Events")}</span>
                                </Link>
                            </li>
                        )}

                        {equalsAny(user?.roles, ["ROLE_TENANT_ADMIN"]) && (
                            <li>
                                <Link
                                    to="/settings/users"
                                    className="waves-effect"
                                >


                                    <i className="uil-users-alt"></i>
                                    <span>{props.t("Users")}</span>
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
};

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));


import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Row} from "reactstrap";
import {withTranslation} from "react-i18next"
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import DeliveryAddressForm from "./DeliveryAddressForm";
import {getAddressList, getAddressListReset} from "../../store/actions";
import {orderBy} from "lodash";
import {del} from "../../helpers/api_helper";
import withRouter from "../../components/Common/withRouter";

export const customerAddressManagementContext = React.createContext(null)

const CustomerAddressManagement = (props) => {

    const {addresses, onGetAddresses, customerId, projectId, onGetAddressesReset, error} = props
    const [editMode, setEditMode] = useState(false);
    const [selectedCustomerAddress, setSelectedCustomerAddress] = useState(undefined);
    const [addressList, setAddressList] = useState([])
    const [brandSettings, setBrandSettings] = useState(null);
    const [sortState, setSortState] = useState(null);
    const numericColumns = [];

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user.brandConfig);
        onGetAddresses(customerId ? customerId : null, projectId ? projectId : null);
        return () => {
            onGetAddressesReset();
        }
    }, [])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                onGetAddressesReset()
            }, 5000);
        }
    }, [error])

    useEffect(() => {
        if (addresses) {
            setAddressList(addresses);
        }
    }, [addresses]);

    useEffect(() => {
        if (!addressList) return;
        if (sortState) {
            const {column, direction} = sortState;
            setAddressList(orderBy(addressList, o => {
                if (!o[column]) return '';
                return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
            }, [direction]))
        }
    }, [sortState])

    function handleAddEditAddress(addressId) {
        onGetAddresses(customerId ? customerId : null, projectId ? projectId : null)
        setEditMode(false);
    }

    const handleDelete = (deliveryAddressId) => {
        (async () => {
            const response = await del(`/api/customers/deliveryAddress/${deliveryAddressId}`);
            onGetAddresses(customerId ? customerId : null, projectId ? projectId : null)
        })();
    }

    const getAddressDatatableData = () => {
        let list = [];
        addressList.filter(address => address.projectId == null).sort((a, b) => a?.name.localeCompare(b?.name)).map(obj => list.push(obj));
        addressList.filter(address => address.projectId != null).sort((a, b) => a?.projectId?.label.localeCompare(b?.projectId?.label)).map(obj => list.push(obj));
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Delivery Address"),
                    field: "address",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: props.t("City"),
                    field: "city",
                    sort: "asc"
                },
                {
                    label: props.t("Country"),
                    field: "country",
                    sort: "asc"
                },
                {
                    label: props.t("Zip Code"),
                    field: "zipCode",
                    sort: "asc"
                },
                {
                    label: props.t("Phone"),
                    field: "phone",
                    sort: "asc"
                },
                {
                    label: props.t("Project"),
                    field: "project",
                    sort: "asc",
                    width: 100
                },
                {
                    label: props.t("Actions"),
                    field: "actions",
                    sort: "asc",
                    autoWidth: true
                },
            ],
            rows: list?.map((address, key) => ({
                name: address.name,
                address: address.address,
                city: address.city,
                country: address.country,
                zipCode: address.zipCode,
                phone: address.phone,
                project: address?.projectId?.label,
                actions: <customerAddressManagementContext.Provider value={address.id}>
                    <i class="uil uil-pen font-size-18" onClick={() => {
                        setEditMode(true);
                        setSelectedCustomerAddress(address.id);
                    }}></i> &nbsp;&nbsp;
                    <i className="uil uil-trash-alt font-size-18 text-danger" onClick={() => {
                        handleDelete(address.id);
                    }}></i>
                </customerAddressManagementContext.Provider>
            }))
        }
        return data;
    }

    return (
        <React.Fragment>
            {
                !editMode && <React.Fragment>
                    {error &&
                        <Alert className={"mt-4"} color="danger" role="alert">
                            {error}
                        </Alert>
                    }
                    <Row className="mb-3">
                        <Col className="col-12">
                            <Button
                                color="primary"
                                className="btn btn-primary waves-effect waves-light float-end"
                                style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                onClick={() => {
                                    setEditMode(true);
                                    setSelectedCustomerAddress(undefined);
                                }}
                            >
                                {props.t("New Delivery Address")}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12 article-table">
                            <MDBDataTable
                                hover
                                barReverse={true}
                                responsive
                                borderless
                                paginationLabel={[props.t("Previous"), props.t("Next")]}
                                entriesLabel={props.t("Show entries")}
                                infoLabel={[props.t('Showing'), props.t('to'), props.t('of'), props.t('entries')]}
                                searchLabel={props.t("Search") + "..."}
                                noBottomColumns={true}
                                data={getAddressDatatableData()}
                                entries={50}
                                entriesOptions={[50, 100, 500]}
                                noRecordsFoundLabel={props.t("No matching records found")}
                                sortRows={[]}
                                onSort={({column, direction}) => {
                                    if (sortState && sortState.column == column) {
                                        setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                    } else {
                                        setSortState({column, direction});
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            }
            {
                editMode && <React.Fragment>
                    <Row>
                        <Col>
                            <DeliveryAddressForm
                                location={props.location}
                                id={selectedCustomerAddress}
                                clearField={true}
                                projectId={projectId}
                                customerId={customerId}
                                setEditMode={setEditMode}
                                useModal={false}
                                inlineForm={true}
                                callback={handleAddEditAddress}
                                avoidRedirect={true}/>
                        </Col>
                    </Row>
                </React.Fragment>
            }
        </React.Fragment>
    );
};

CustomerAddressManagement.propTypes = {
    addresses: PropTypes.array,
    onGetAddresses: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        addresses: state.Customer.addresses,
        error: state.Customer.getAddressesError,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetAddresses: (customerId, projectId) => dispatch(getAddressList(customerId, projectId)),
    onGetAddressesReset: () => dispatch(getAddressListReset()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(CustomerAddressManagement)));

import { find, orderBy } from 'lodash';
import { findIndex } from 'lodash';
import { isEmpty } from 'lodash';
import { MDBDataTable } from 'mdbreact';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Alert, Card, CardBody, CardHeader, Col, Container, Row, Modal, Button, } from 'reactstrap';
import DeleteModal from '../../components/Common/DeleteModal';
import Loader from '../../components/Common/Loader';
import TableToolbar from '../../components/Common/TableToolbar';
import { post, del, get } from '../../helpers/api_helper';
import { getWorkLogType } from '../../store/actions';
import withRouter from "../../components/Common/withRouter";

const WorklogType = (props) => {

    let { onGetWorkLogTypes, workLogTypes, worklogTypeLoading, worklogTypeError } = props;

    const [brandSettings, setBrandSettings] = useState(null);
    const [modal_standard, setmodal_standard] = useState(false);
    const [name, setName] = useState(null);
    const [defaultTimeslipType, setDefaultTimeslipType] = useState(false);
    const [editWorklogType, setEditWorklogType] = useState(null);
    const [deleteWorklogTypeId, setDeleteWorklogTypeId] = useState(null);
    const [showDeleteWorklogTypeModal, setShowDeleteWorklogTypeModal] = useState(false);
    const [deleteWorklogTypeWorklogCount, setDeleteWorklogTypeWorklogCount] = useState(0);
    const [workLogTypeList, setWorkLogTypeList] = useState([]);
    const [sortState, setSortState] = useState(null);
    const numericColumns = [];
    const user = JSON.parse(localStorage.getItem("authUser"));

    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        onGetWorkLogTypes();
    }, [])

    useEffect(() => {
        if (deleteWorklogTypeId) {
            toggleDeleteWorklogType();
        }
    }, [deleteWorklogTypeId])

    useEffect(() => {
        if(workLogTypes){
            setWorkLogTypeList(workLogTypes);
        }
    }, [workLogTypes]);

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setWorkLogTypeList(orderBy(workLogTypeList, o => {
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])

    const handleSaveWorklogType = async () => {
        let response = await post(`/api/timeslipTypes`, { ...editWorklogType, name, defaultTimeslipType });
        setName(null);
        setDefaultTimeslipType(false);
        onGetWorkLogTypes()
        tog_standard();
    };

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function toggleDeleteWorklogType() {
        if (showDeleteWorklogTypeModal) {
            setDeleteWorklogTypeId(null);
        }
        setShowDeleteWorklogTypeModal(!showDeleteWorklogTypeModal);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const getDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Actions"),
                    field: "actions",
                    autoWidth: true,
                },
            ],
            rows: workLogTypes?.map((workLogType, key) => ({
                name: <div className='d-flex'>
                        {workLogType.name}
                        {workLogType.defaultTimeslipType && 
                        <div className="product-ribbon badge bg-soft-warning bg-warning ms-2 px-2 d-flex align-items-center justify-content-center">
                            {props.t("Default")}
                        </div>}
                      </div>,
                actions: (
                    <div className='d-flex'>
                        <i
                            className={`uil uil-pen me-3 text-warning font-size-18`}
                            // title={(!user.tenantId || workLogType.tenant) ? null : 'Timeslip type is generic and cannot be edited.'}
                            onClick={() => handleEditWorklogType(workLogType)}
                        ></i>
                        <i
                            className={`uil uil-trash-alt px-3 ${(!user.tenantId || workLogType.tenant) && workLogType.timeslipCount == 0 ? 'text-danger' : 'text-muted'} font-size-18`}
                            title={workLogType.timeslipCount > 0 ? `Timeslip type cannot be deleted because it contains ${workLogType.timeslipCount} timeslip record(s).` : 
                                ((!user.tenantId || workLogType.tenant) ? null : 'Timeslip type is generic and cannot be deleted.')}
                            onClick={() => {
                                if((!user.tenantId || workLogType.tenant) && workLogType.timeslipCount == 0){
                                    setDeleteWorklogTypeId(workLogType.id);
                                }
                            }}
                        ></i>
                        <div className="form-check form-switch ms-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={workLogType.enabled}
                                style={workLogType.enabled ? {
                                    backgroundColor: brandSettings?.primaryBtnColor,
                                    borderColor: brandSettings?.primaryBtnColor
                                } : {}}
                                onChange={() => toggleWorklogTypeState(workLogType)}
                            />
                            {workLogType.enabled && <div className="badge bg-soft-success p-1 ms-2">{props.t("Enabled")}</div>}
                            {!workLogType.enabled && <div className="badge bg-soft-warning p-1 ms-2">{props.t("Disabled")}</div>}
                        </div>
                    </div>
                ),
            })),
        };
        return data;
    };

    const toggleWorklogTypeState = async (item) => {
        let response = item.enabled ? await post(`/api/timeslipTypes/disabled/${item.id}`) 
            : await del(`/api/timeslipTypes/disabled/${item.id}`);
        if(response.status == 202) {
            let updatedTypes = [...workLogTypeList];
            var index = findIndex(updatedTypes, {id: item.id});
            updatedTypes[index] = {...updatedTypes[index], enabled: !item.enabled}
            setWorkLogTypeList(updatedTypes);
        }
    };

    const handleAddWorklogType = () => {
        setEditWorklogType(null);
        setName(null);
        setDefaultTimeslipType(false);
        tog_standard();
    };

    const handleEditWorklogType = (workLogType) => {
        setEditWorklogType(workLogType);
        setName(workLogType.name);
        setDefaultTimeslipType(workLogType.defaultTimeslipType)
        tog_standard();
    };

    const handleDeleteWorklogType = async () => {
        let response = await del(`/api/timeslipTypes/${deleteWorklogTypeId}`);
        setDeleteWorklogTypeWorklogCount(0)
        onGetWorkLogTypes();
        setDeleteWorklogTypeId(null);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor }}>
                                    <TableToolbar
                                        title={props.t("Timeslip Types")}
                                        buttons={[{
                                            text: props.t("New Timeslip Type"),
                                            onClick: handleAddWorklogType,
                                        }]}
                                    />
                                </CardHeader>
                                <Loader loading={worklogTypeLoading} title="Timeslip Types" />
                                {!worklogTypeLoading && (
                                    <CardBody>
                                        {worklogTypeError && (
                                            <Row>
                                                <Col className="col-12">
                                                    <Alert color="danger">{worklogTypeError}</Alert>
                                                </Col>
                                            </Row>
                                        )}

                                        <MDBDataTable
                                            responsive
                                            hover
                                            barReverse={true}
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t(
                                                "No matching records found"
                                            )}
                                            noBottomColumns={true}
                                            data={getDatatableData()}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            onSearch={(text) => console.log(text)}
                                            searching={true}
                                            key={workLogTypeList.length}
                                            sortRows={[]}
                                            onSort={({column, direction}) => {
                                                if(sortState && sortState.column == column){
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />
                                    </CardBody>)}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {editWorklogType ? props.t("Edit Timeslip Type") : props.t("Add Timeslip Type")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className='px-3 pt-3'>
                    <Row className="mb-2">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-4 col-form-label"
                        >
                            {props.t("Timeslip Type")}
                        </label>
                        <div className="col-md-8">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                placeholder={props.t("Enter Timeslip Type")}
                                value={name}
                                disabled={editWorklogType && !(!user.tenantId || editWorklogType.tenant)}
                            />
                        </div>
                    </Row>
                </div>
                <div className='px-3'>
                    <Row className="mb-3">
                        <label className='col-md-4'>
                            {props.t(" ")}
                        </label>
                        <div className="col-md-8">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                defaultChecked={defaultTimeslipType}
                                onChange={(e) => setDefaultTimeslipType(e.target.checked)}
                            />
                            <label>
                                {props.t("Use as default timeslip type")}
                            </label>
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <Button
                        color="primary"
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        onClick={() => handleSaveWorklogType()}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                        disabled={!name}
                    >
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>
            <DeleteModal
                deleteCallback={() => handleDeleteWorklogType()}
                element={"Timeslip Type"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteWorklogTypeModal}
                toggleModal={() => toggleDeleteWorklogType()}
                confirmation={true}
                warningMsg={deleteWorklogTypeWorklogCount > 0 ? "The timeslip type is referenced inside Task Timeslips." : null}
            />

        </React.Fragment>
    )
}




const mapStateToProps = (state) => {
    return {
        workLogTypes: state.task.workLogType,
        worklogTypeLoading: state.task.worklogTypeLoading,
        worklogTypeError: state.task.worklogTypeError,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetWorkLogTypes: () => dispatch(getWorkLogType()),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(WorklogType)));

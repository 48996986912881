import PropTypes from 'prop-types'
import React, {useEffect} from "react"

import {Alert, Col, Container, Form, FormFeedback, Input, Row} from "reactstrap";

// Redux
import {Link} from "react-router-dom"
import withRouter from '../../components/Common/withRouter';

import {connect, useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
// Formik validation
import * as Yup from "yup";
import {useFormik} from "formik";

import {getBrand, loginUser} from "../../store/actions"
import {withTranslation} from "react-i18next";
import {LOGO_URL} from "../../helpers/url_helper";

const Login = (props) => {

    let {brand, getBrand, loginUser} = props;

    const dispatch = useDispatch();

    useEffect(() => {
        getBrand(window.location.hostname);
    }, [])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: false,

        initialValues: {
            email: '',
            password: '',
            domain: window.location.hostname,
        },
        validationSchema: Yup.object({
            email: Yup.string().required(props.t("Please Enter Your Email")),
            password: Yup.string().required(props.t("Please Enter Your Password")),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate));
        }
    });


    const selectLoginState = (state) => state.Login;
    const LoginProperties = createSelector(
        selectLoginState,
        (login) => ({
            error: login.error
        })
    );

    const {
        error
    } = useSelector(LoginProperties);

    useEffect(() => {
        document.body.className = "authentication-bg";
        if (brand?.primaryBackgroundColor) {
            document.body.style = `background-color: ${brand?.primaryBackgroundColor}`;
        }
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    }, [brand]);

    return (
        <React.Fragment>
            <div className="account-pages mt-15-per">
                <Container>
                    <Row className="align-items-center justify-content-center">
                        <Col md={8} lg={6} xl={4}>
                            <div className="text-center p-2">
                                <Link to="/">
                                    <img src={`${LOGO_URL}?domain=${window.location.hostname}`} alt="" width="100%" className="logo logo-dark"/>
                                    <img src={`${LOGO_URL}?domain=${window.location.hostname}`} alt="" width="100%" className="logo logo-light"/>
                                </Link>
                            </div>
                            <div className="p-2 mt-2">
                                <h3 className="text-primary">{props.t("Sign In")}</h3>
                                <p className="text-muted">{brand && props.t(`Welcome to ${brand?.tenantName ? brand.tenantName : 'Lightab'}`)}</p>
                            </div>
                            <div className="p-2 mt-0">
                                <Form
                                    className="form-horizontal"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}
                                >
                                    {error ? <Alert color="danger">{error}</Alert> : null}
                                    <Input name="domain" type="hidden" value={window.location.hostname}/>
                                    <div className="mb-3">
                                        <div className={"login-form-text-icon"}>
                                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon mx-2" data-component-name="Icon-C"
                                                 data-name="Hero--HeroEnvelope">
                                                <path
                                                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                                                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                        <Input
                                            name="email"
                                            className="form-control login-form-control"
                                            placeholder="Username"
                                            type="email"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.email || ""}
                                            invalid={
                                                validation.touched.email && validation.errors.email ? true : false
                                            }
                                        />
                                        {validation.touched.email && validation.errors.email ? (
                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="mb-3">
                                        <div className={"login-form-text-icon"}>
                                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon mx-2" data-component-name="Icon-C"
                                                 data-name="Hero--HeroKey">
                                                <path
                                                    d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z"
                                                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                        <Input
                                            name="password"
                                            value={validation.values.password || ""}
                                            className="form-control login-form-control"
                                            type="password"
                                            placeholder="Password"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.password && validation.errors.password ? true : false
                                            }
                                        />
                                        {validation.touched.password && validation.errors.password ? (
                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="mt-3">
                                        <button
                                            className="btn btn-primary w-100 waves-effect waves-light"
                                            type="submit"
                                        >
                                            Sign In
                                        </button>
                                    </div>

                                    <div className="mt-4 text-center">
                                        <p className="mb-0"><a href="/forgot-password" className="fw-medium text-primary"> Forgot Password ? </a></p>
                                    </div>

                                </Form>

                            </div>
                            <div className="mt-5 text-center">
                                <p>© {new Date().getFullYear()} Flowsense. {props.t("Design & Develop by Flowsense")}</p>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

Login.propTypes = {
    error: PropTypes.any,
    history: PropTypes.object,
    loginUser: PropTypes.func,
    getBrand: PropTypes.func
}

const mapStateToProps = state => {
    const {error, brand} = state.Login
    return {error, brand}
}

const mapDispatchToProps = (dispatch) => ({
    loginUser: (user, history) => dispatch(loginUser(user, history)),
    getBrand: (domain) => dispatch(getBrand(domain))
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Login)))
)

import {call, put, takeEvery} from "redux-saga/effects"

// Login Redux States
import {EDIT_PROFILE, GET_USER_INFO} from "./actionTypes"
import {getUserError, getUserProfileSuccess, profileError, profileSuccess} from "./actions"

//Include Both Helper File with needed methods
import {getUser, putEditProfile} from "../../../helpers/fakebackend_helper"

function* editProfile({ payload: { user } }) {
  try{
    yield call(putEditProfile, user)
    yield put(profileSuccess("Profile updated successfully!"))
    let loggedInUser = JSON.parse(localStorage.getItem("authUser"));
    // if(loggedInUser?.uid === user?.userId){
    //   setTimeout(() => {
    //     window.location.reload(false);
    //   }, 3000);
    // }
  } catch(err) {
    if(err.response !== 'undefined' && err.response !== null && Array.isArray(err.response.data)){
      yield put(profileError(err.response.data[0]))
    } else {
      yield put(profileError("Error occured while updating profile. If issue persists, Please contact system administrator"))
    }
  }
}

function* fetchUserInfo( { userId }) {
  try{
    const response = yield call(getUser, userId)
    yield put(getUserProfileSuccess(response.data))
  } catch(err) {
    if(err.response !== 'undefined' && err.response !== null && Array.isArray(err.response.data)){
      yield put(getUserError(err.response.data[0]))
    } else {
      yield put(getUserError("Error occured while updating profile. If issue persists, Please contact system administrator"))
    }
  }
}

function* ProfileSaga() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(GET_USER_INFO, fetchUserInfo)
}

export default ProfileSaga

import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types'
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {post} from "../../helpers/api_helper";
import {API_ERR_MSG} from "../../util/constants";
import TableToolbar from "../../components/Common/TableToolbar";
import {find} from "lodash";
import { useNavigate } from "react-router-dom";

const CurrencyConfig = (props) => {

    const history = useNavigate();

    const {} = props

    const [currencyConfig, setCurrencyConfig] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [brandSettings, setBrandSettings] = useState(null);

    const clearError = () => setError(null)
    const clearSuccess = () => setSuccess(null)

    const CURRENCY_LIST = JSON.parse(localStorage.getItem("currency"));

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);

        const currency = user?.config?.currency?.id;
        setCurrencyConfig(currency);
    }, [])

    const submitCurrencyConfigForm = (event) => {
        post('/api/settings/currency-config?currencyId='+currencyConfig , null)
            .then(data => {
                if (data?.status === 200) {
                    setSuccess(props.t("Currency configuration updated successfully"))
                    setTimeout(clearSuccess, 5000)
                    const authUser = JSON.parse(localStorage.getItem("authUser"));
                    authUser.config["currency"] = find(CURRENCY_LIST, {id : parseInt(currencyConfig)})
                    localStorage.setItem("authUser", JSON.stringify(authUser));
                } else {
                    setError(API_ERR_MSG)
                    setTimeout(clearError, 5000)
                }
            }).catch(error => {
            console.log("Error ", error)
            setError("Fail to set article config.");
            setTimeout(clearError, 5000)
        });
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader
                                    className="table-header-bg"
                                    style={{backgroundColor: brandSettings?.primaryColor}}
                                >
                                    <TableToolbar title={props.t("Currency Config")}/>
                                </CardHeader>

                                    <CardBody>
                                        {error ? (
                                            <Alert color="danger">{error}</Alert>
                                        ) : null}

                                        {success ? (
                                            <Alert color="success">{success}</Alert>
                                        ) : null}

                                        <form onSubmit={(event) => {
                                            event.preventDefault()
                                            submitCurrencyConfigForm(event)
                                        }}>
                                            <Row className="mb-3 article-config-quantity-setting">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label article-config-quantity-setting-label"
                                                >
                                                    {props.t("Currency")}
                                                </label>
                                                <div className="col-md-3 col-sm-10">
                                                    <select
                                                        className="form-control"
                                                        value={currencyConfig}
                                                        onChange={(e) => setCurrencyConfig(e.target.value)}>
                                                        {CURRENCY_LIST.map(function(currency){
                                                            return (<option value={currency.id}>
                                                                {currency.code} - {currency.name} - {currency.symbol}
                                                            </option>)
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="col-md-3 col-sm-10">
                                                    <Button 
                                                        color="success"
                                                        className="btn btn-success w-lg waves-effect waves-light me-2"
                                                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                        onClick={() => history('/currencies')}
                                                    >
                                                        {props.t("View Detailed List")}
                                                    </Button>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label className={"col-md-2 col-form-label article-config-quantity-setting-label"}></label>
                                                <Col align="left">
                                                    <Button
                                                        type="submit"
                                                        color="success"
                                                        className="btn btn-success w-lg waves-effect waves-light me-2"
                                                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                    >
                                                        {props.t("Save")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

CurrencyConfig.propTypes = {
    loading: PropTypes.bool,
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => (
    {}
)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(CurrencyConfig));

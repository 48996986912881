import React from "react";
import {Container} from "reactstrap";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TenantDashboard from "./TenantDashboard";


const Dashboard = () => {
    let level = localStorage.getItem("level");


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {level === "TENANT" && <TenantDashboard/>}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;

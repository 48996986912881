import React from "react";
import {Col, Container, Modal, Row,} from "reactstrap";
import {withTranslation} from "react-i18next";
import TenantUserForm from "./TenantUserForm";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import { useParams } from "react-router-dom";

const TenantUserFormContainer = (props) => {
    const loggedInUser = JSON.parse(localStorage.getItem("authUser"));
    const { userId } = useParams();

  /*  const TenantUserPage = (props) =>*/
       return <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        breadcrumbs={[{title: props.t("Users"), link: loggedInUser?.tenantId ? '/settings/users' : '/tenants'}]}
                        title={userId ? props.t("Edit User") : props.t("Create User")}
                    />
                    <Row>
                        <Col>
                            <TenantUserForm location={props.location} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>

    /*const TenantUserModal = ({modal_standard, tog_standard, useModal}) =>
        <Modal
            isOpen={modal_standard}
            backdrop="static"
            toggle={() => {
                tog_standard();
            }}
            className="add-order-modal"
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {props.t("Create User")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        tog_standard();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body m-2">
                <TenantUserForm
                    // projectId={params.id}
                    location={props.location}
                    clearField={true}
                    useModal={useModal}
                    tog_standard={tog_standard}
                />
            </div>
        </Modal>

    return props.useModal ? <TenantUserModal {...props} /> : <TenantUserPage {...props} />;*/
};

export default withTranslation()(TenantUserFormContainer);

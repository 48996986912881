import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {orderBy} from "lodash";
import PropTypes from "prop-types";
import {getOrderForAM, getOrderForAMReset, getOrderReset, getOrders, getOrdersByReference, getOrdersByReferenceReset} from "../../store/actions";
import {connect} from "react-redux";
import {MDBDataTable} from "mdbreact";
import {Alert, Col, Row} from "reactstrap";
import {downloadPDF, getFilterClassName} from "../../util/helperFunctions";
import ButtonGroup from "../../components/Common/ButtonGroup";
import {ecommerceActionTypes} from "../../store/e-commerce/actionTypes";
import {checkIfLoading} from "../../store/selector";
import Loader from "../../components/Common/Loader";
import {formatCurrency} from "../../helpers/currency_helper";
import {API_ERR_MSG} from "../../util/constants";
import withRouter from "../../components/Common/withRouter";

const CustomerOrdersTable = (props) => {
    const {
        orders, ordersByReference, ordersForAM,
        onGetOrders, onGetOrdersByReference, onGetOrdersForAM,
        loading, ordersByReferenceLoading, ordersForAMLoading,
        error, ordersByReferenceError, orderForAMError,
        onGetOrderReset, onGetOrdersByReferenceReset, onGetOrdersForAMReset,
        customerId, articleNumber, referenceId, accountManagerId, ourReference, yourReference,  projectNumber, articleGroupId, manufacturer,
    } = props
    const [orderList, setOrderList] = useState([])
    const [brandSettings, setBrandSettings] = useState(null);
    const [orderType, setOrderType] = useState('all');
    const [sortState, setSortState] = useState(null);
    const numericColumns = ['DocumentNumber', 'Total'];
    const history = useNavigate();
    
    const user = JSON.parse(localStorage.getItem("authUser"));

    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        if(customerId || articleNumber || ourReference || yourReference || projectNumber || articleGroupId || manufacturer){
            //onGetOrders(orderType, customerId, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference)
        }
        /*return () => {
            if(referenceId) {
                onGetOrdersByReferenceReset()
            } 
            else if(accountManagerId){
                onGetOrdersForAMReset();
            } 
            else {
                onGetOrderReset()
            }
        }*/
    }, [])

    useEffect(() => {
      if(accountManagerId && ordersForAM){
        setOrderList(ordersForAM);
      } 
      else if(referenceId && ordersByReference){
        setOrderList(ordersByReference);
      } 
      else if(orders){
        setOrderList(orders);
      }
    }, [orders, ordersByReference, ordersForAM]);

    useEffect(() => {
        if(orderForAMError){
          setTimeout(() => {onGetOrdersForAMReset()}, 5000);
        } 
        else if(ordersByReferenceError){
          setTimeout(() => {onGetOrdersByReferenceReset()}, 5000);
        } 
        else if(error){
          setTimeout(() => {onGetOrderReset()}, 5000);
        }
    }, [error, orderForAMError, ordersByReferenceError])


    useEffect(() => {
        if(orderType){
          if(accountManagerId){
            onGetOrdersForAM(accountManagerId, orderType);
          } 
          else if(referenceId){
            onGetOrdersByReference(referenceId, orderType);
          }
          else if(customerId || articleNumber || ourReference || yourReference || projectNumber || articleGroupId || manufacturer){
              onGetOrders(orderType, customerId, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference)
          }
        }
    }, [orderType])

    useEffect(() => {
      if(sortState?.column){
        const {column, direction} = sortState;
        setOrderList(orderBy(orderList, o => numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase(), [direction]))
      }
    }, [sortState])

    const openOrderDetails = (order) => {
        let fromUrl = "";
        if(props.from){
            fromUrl = `?from=${props.from}`;
        }
        history(`/orders/${order.DocumentNumber + fromUrl}`)
    }


    const getDatatableData = () => {
        let cols = [
            {
                label: props.t("Document Number"),
                field: "DocumentNumber",
                width: 150,
            },
            {
              label: props.t("Status"),
              field: "status",
              width: 150,
            },
            {
                label: props.t("Total"),
                field: "Total",
                width: 150,
            },
            {
                label: props.t("OrderDate"),
                field: "OrderDate",
                width: 150,
            },
            {
              label: props.t("Download"),
              field: "download",
            }
        ]

        let data = {
            columns: cols,
            rows: orderList?.map((order, key) => ({
                status: <div onClick={() => openOrderDetails(order)} className={`badge ${getFilterClassName(order?.status)} p-1`}>{props.t(order.status)}</div>,
                DocumentNumber: <div onClick={() => openOrderDetails(order)}>{order?.DocumentNumber}</div>,
                Total: <div onClick={() => openOrderDetails(order)}>{formatCurrency(order?.Currency, order.TotalWithoutTax)}</div>,
                OrderDate: <div onClick={() => openOrderDetails(order)}>{order?.OrderDate}</div>,
                download: <div onClick={() => downloadPDF(order?.DocumentNumber, 'orders')}><i className="fa fa-download font-size-15"></i></div>
            }))

        }
        return data;
    }

    const handleFilter = (value) => {
      setOrderType(value);
    }

    return (
        <React.Fragment>
            {(error || ordersByReferenceError || orderForAMError) &&
            <Alert className={"mt-4"} color="danger" role="alert">
                {props.t(API_ERR_MSG)}
            </Alert>
            }
            <Row className="mb-3">
                <Col className="col-12">
                    <div className="d-flex justify-content-end">
                        <ButtonGroup 
                            filter={{
                                selected: orderType,
                                options : [
                                    {name: props.t("All"), value:"all"},
                                    {name: props.t("Ongoing"), value:"invoicenotcreated"},
                                    {name: props.t("Invoiced"), value:"invoicecreated"},
                                    {name: props.t("Delayed"), value: "expired"},
                                    {name: props.t("Cancelled"), value: "cancelled"}
                                ],
                                onSelect: handleFilter
                            }}
                            brandSettings={brandSettings}     
                        />
                    </div>
                </Col>
            </Row>
            
            <Loader loading={loading || ordersByReferenceLoading || ordersForAMLoading} title="Orders" />
                    
            {!(loading || ordersByReferenceLoading || ordersForAMLoading) && 
            <Row>
                <Col className="col-12 article-table">
                    <MDBDataTable
                        responsive
                        hover
                        barReverse={true}
                        borderless
                        paginationLabel={[props.t("Previous"), props.t("Next")]}
                        entriesLabel={props.t("Show entries")}
                        infoLabel={[props.t('Showing'), props.t('to'), props.t('of'), props.t('entries')]}
                        searchLabel={props.t("Search") + "..."}
                        noRecordsFoundLabel={props.t("No matching records found")}
                        noBottomColumns={true}
                        data={getDatatableData()}
                        entries={50}
                        entriesOptions={[50, 100, 500]}
                        onSearch={(text) => console.log(text)}
                        sortRows={[]}       
                        onSort={({column, direction}) => {
                        if(sortState && sortState.column == column){
                            setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                        } else {
                            setSortState({column, direction});
                        }
                        }}
                    />
                </Col>
            </Row>}
        </React.Fragment>
    );
};

CustomerOrdersTable.propTypes = {
    orders: PropTypes.array,
    onGetOrders: PropTypes.func,
    onGetOrderReset: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.object,
}

const mapStateToProps = state => {
    return {
        orders: state.ecommerce?.orders?.data?.Orders,
        loading: checkIfLoading(state, ecommerceActionTypes.GET_ORDERS),
        error: state.ecommerce.getOrdersError,
        ordersByReference: state.ecommerce.ordersByReference?.Orders,
        ordersByReferenceError: state.ecommerce.ordersByReferenceError,
        ordersByReferenceLoading: state.ecommerce.ordersByReferenceLoading,
        ordersForAM: state.ecommerce.orderForAM?.Orders,
        ordersForAMLoading: state.ecommerce.orderForAMLoading,
        ordersForAMError: state.ecommerce.orderForAMError,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetOrders: (orderType, customerId, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference) => dispatch(getOrders(orderType, customerId, articleNumber, null, null, projectNumber, articleGroupId, manufacturer, ourReference, yourReference)),
    onGetOrderReset: () => dispatch(getOrderReset()),
    onGetOrdersByReference: (id, type) => dispatch(getOrdersByReference(id, type)),
    onGetOrdersByReferenceReset: () => dispatch(getOrdersByReferenceReset()),
    onGetOrdersForAM: (id, type) => dispatch(getOrderForAM(id, type)),
    onGetOrdersForAMReset: () => dispatch(getOrderForAMReset()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(CustomerOrdersTable)));

import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types'
import {Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Row} from "reactstrap";
import ReactLoading from "react-loading";
import {withTranslation} from "react-i18next";
import {getSMTPConfig, getSMTPConfigReset} from "../../store/config/actions";
import {connect} from "react-redux";
import {del, post} from "../../helpers/api_helper";
import { configActionTypes } from "../../store/config/actionTypes";
import { checkIfLoading } from "../../store/selector";
import { API_ERR_MSG, SPINNER_DEFAULT_COLOR } from "../../util/constants";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";

const SMTPConfig = (props) => {
    const header = "SMTP Config"

    const {onGetSMTPConfig, smtpConfig, loading, onGetSMTPConfigReset, getSmtpConfigError } = props

    const [smtp, setSMTP] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null)
    const [testEmail, setTestEmail] = useState("");
    const [brandSettings, setBrandSettings] = useState(null);

    const clearError = () => setError(null)
    const clearSuccess = () => setSuccess(null)

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetSMTPConfig();
        return () => {
            onGetSMTPConfigReset();
        }
    }, [])

    useEffect(() => {
        if (smtpConfig.length > 0) {
            setSMTP(smtpConfig[0]);
        }
    }, [smtpConfig])

    useEffect(() => {
        if(getSmtpConfigError){
            setError(getSmtpConfigError);
            setTimeout(() => {onGetSMTPConfigReset()}, 5000);
            setTimeout(() => {setError(null)}, 5000);
        }
    }, [getSmtpConfigError])

    const submitForm = event => {
        let fields = Array.from(event.target.getElementsByTagName('input'))
        post('/api/settings/smtp?testEmail=' + testEmail, fields.reduce((obj, cur) => ({...obj, [cur.id]: cur.value}), {}))
            .then(data => {
                if (data?.status !== 202) {
                    setError(API_ERR_MSG)
                    setTimeout(clearError, 5000)
                } else {
                    setSuccess(props.t("SMTP configuration updated successfully"))
                    setTimeout(clearSuccess, 5000)
                }
            }).catch(error => {
            setError("Fail to send test email, please check SMTP Configuration");
            setTimeout(clearError, 5000)
        });
    }

    const handleReset = async () => {
        let response = await del('/api/settings/smtp');
        setSMTP({});
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader
                                    className="table-header-bg"
                                    style={{backgroundColor: brandSettings?.primaryColor}}
                                >
                                    <TableToolbar title={props.t(header)}/>
                                </CardHeader>
                                <Loader loading={loading} title="Email Settings" />
                                {!loading &&
                                <form onSubmit={(event) => {
                                    event.preventDefault()
                                    submitForm(event)
                                }}>
                                    <CardBody>
                                        {error ? (
                                            <Alert color="danger">{error}</Alert>
                                        ) : null}

                                        {success ? (
                                            <Alert color="success">{success}</Alert>
                                        ) : null}

                                        <input
                                            className="form-control"
                                            type="hidden"
                                            defaultValue={smtp.id}
                                            id="id"
                                            disabled={loading}
                                        />
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Host")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter SMTP host")}
                                                    defaultValue={smtp.host}
                                                    id="host"
                                                    onChange={clearError}
                                                    disabled={loading}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Port")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter SMTP port")}
                                                    defaultValue={smtp.port}
                                                    id="port"
                                                    onChange={clearError}
                                                    disabled={loading}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Username")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter Username")}
                                                    defaultValue={smtp.username}
                                                    id="username"
                                                    onChange={clearError}
                                                    disabled={loading}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Password")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter Password")}
                                                    defaultValue={smtp.password}
                                                    id="password"
                                                    onChange={clearError}
                                                    disabled={loading}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Domain")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter Domain")}
                                                    defaultValue={smtp.domain}
                                                    id="domain"
                                                    onChange={clearError}
                                                    disabled={loading}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Test Email Address")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter Test email")}
                                                    defaultValue={testEmail}
                                                    onChange={(e) => setTestEmail(e.target.value)}
                                                    disabled={loading}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col align="center">
                                                <Button
                                                    type="submit"
                                                    color="success"
                                                    className="btn btn-success w-lg waves-effect waves-light me-2"
                                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                    disabled={loading}
                                                >
                                                    {props.t("Save")}
                                                </Button>
                                                <Button
                                                    type="button"
                                                    color="danger"
                                                    onClick={handleReset}
                                                    className="btn btn-danger w-lg waves-effect waves-light me-2"
                                                    disabled={loading}
                                                >
                                                    {props.t("Reset")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </form>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

SMTPConfig.propTypes = {
    smtpConfig: PropTypes.array,
    getSmtpConfigError: PropTypes.object,
    loading: PropTypes.bool,
}

const mapStateToProps = state => {
    return {
        smtpConfig: state.Config.smtpConfig,
        loading: checkIfLoading(state, configActionTypes.GET_SMTP_CONFIG),
        getSmtpConfigError: state.Config.smtpConfigError,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetSMTPConfig: () => dispatch(getSMTPConfig()),
    onGetSMTPConfigReset: () => dispatch(getSMTPConfigReset())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(SMTPConfig));

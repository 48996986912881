import PropTypes from 'prop-types';
import React from "react";

import {Route, Routes} from "react-router-dom";
import {connect} from "react-redux";

// Import Routes all
import {authRoutes, userRoutes} from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"
// Import Fack Backend
import fakeBackend from "./helpers/AuthType/fakeBackend";
import {changeLayoutMode} from "./store/layout/actions";

// Activating fake backend
fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = props => {

    function getLayout() {
        let layoutCls = VerticalLayout;

        let themeMode = localStorage.getItem("themeMode");
        props.layout.layoutModeType = themeMode ? themeMode : "white";
        props.layout.leftSideBarTheme = themeMode ? themeMode : "white";
        props.layout.topbarTheme = themeMode ? themeMode : "white";

        switch (props.layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    }

    const Layout = getLayout();
    return (
        <React.Fragment>
            <Routes>
                {authRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            <NonAuthLayout>
                                {route.component}
                            </NonAuthLayout>
                        }
                        key={idx}
                        isAuthProtected={false}
                    />
                ))}

                {userRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            <Authmiddleware permission={route.permission ? route.permission : null}>
                                <Layout>
                                    {route.component}
                                </Layout>
                            </Authmiddleware>
                        }
                        key={idx}
                        isAuthProtected={true}
                        exact
                    />
                ))}

            </Routes>
        </React.Fragment>
    );
};

App.propTypes = {
    layout: PropTypes.any,
    changeLayoutMode: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        layout: state.Layout,
    };
};

export default connect(mapStateToProps, {
    changeLayoutMode,
})(App);

import React, {useEffect, useState} from "react";
import {Alert, Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getTenantUserDetails, getTenantUserDetailsReset} from "../../store/users/tenant/actions";
import {
    TENANT_ACTIVATE_USER,
    TENANT_ADMIN_DELETE_USER,
    TENANT_ADMIN_REGENERATE_TOKEN,
    TENANT_ADMIN_RESET_DELETE_USER,
    TENANT_ADMIN_RESET_REGENERATE_TOKEN,
} from "../../store/users/tenant/actionTypes";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import store from "../../store";
import OverridePwdModal from "../../components/Common/OverridePwdModal";
import DeleteModal from "../../components/Common/DeleteModal";
import classnames from 'classnames';
import {getProjectListByParticipant, getProjectListByParticipantReset} from "../../store/project/actions";
import ProjectActions from "../Projects/ProjectActions";
import {projectIdContext} from "../Projects/ProjectList";
import Loader from "../../components/Common/Loader";
import {equalsAny, hasModuleEnabledForLoggedInUser} from "../../util/helperFunctions";
import {impersonateUser} from "../../store/actions";
import EventManagement from "../Audit/EventManagement";
import TaskManagement from "../Tasks/TaskManagement";
import CustomerOrderTable from "../Customer/CustomerOrderTable";
import {Avatar} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import loadingIcon from "../../assets/images/loading.gif";
import avatar from "../../assets/images/users/user-img-placeholder-2.png";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import ProjectListManagement from "../Projects/ProjectListManagement";
import CustomerInvoiceTable from "../Customer/CustomerInvoiceTable";
import CustomerOfferTable from "../Customer/CustomerOfferTable";
import withRouter from "../../components/Common/withRouter";
import TenantDashboard from "../Dashboard/TenantDashboard";
/*import UserDetail from "./UserDetail";*/

export const userDetailContext = React.createContext(null);

const UserProfile = (props) => {
    let history = useNavigate();
    const {
        user,
        onGetUserDetails,
        projects,
        tokenRegenerate,
        userDeleted,
        onResetRegenerateToken,
        onResetDeleteUser,
        userActivated,
        error,
        loading,
        onGetProjectsReset,
        getProjectsError,
        onGetUserDetailsReset,
        editProfileLoading,
        onImpersonateUser
    } = props;

    const [activeUser, setActiveUser] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showOverridePwdModal, setShowOverridePwdModal] = useState(false);
    const [modal_standard, setmodal_standard] = useState(false);
    const [brandSettings, setBrandSettings] = useState(null);
    const [activeTab, setActiveTab] = useState('1');
    const [loggedInUser, setLoggedInUser] = useState(JSON.parse(localStorage.getItem("authUser")));
    const [deleteBtnText, setDeleteBtnText] = useState("Delete Profile Picture");
    const [profileImageId, setProfileImageId] = useState(null);
    const [imageUploading, setImageUploading] = useState(false);
    const [selectedFile, setselectedFile] = useState(null)
    const location = useLocation();
    let params = useParams();
    const profiletoggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        let loggedInUser = JSON.parse(localStorage.getItem("authUser"));
        setLoggedInUser(loggedInUser);
        setBrandSettings(loggedInUser?.brandConfig);

        const tab = JSON.parse(localStorage.getItem("customerUserProfile"));
        if (tab) {
            setActiveTab(tab.toString());
        }
        return () => {
            onGetProjectsReset();
            onGetUserDetailsReset();
        }
     }, [])

    useEffect(() => {
        if (getProjectsError) {
            setTimeout(() => {
                onGetProjectsReset()
            }, 5000);
        }
    }, [getProjectsError])

    useEffect(() => {
        if (activeTab) {
            localStorage.setItem("customerUserProfile", activeTab);
        }
    }, [activeTab])

    useEffect(() => {
        if (!editProfileLoading) {
            setImageUploading(false);
        }
    }, [editProfileLoading])

    useEffect(() => {
        if (!editProfileLoading) {
            onGetUserDetails(params.id);
            /*onGetProjects(params.id);*/
        }
    }, [params.id, editProfileLoading]);

    useEffect(() => {
        setActiveUser(user);
        setProfileImageId(user?.profileImage?.s3Key)
    }, [user])

    useEffect(() => {
        setErrorMessage(error);
    }, [error]);

    useEffect(() => {
        if (tokenRegenerate && tokenRegenerate.status) {
            setSuccessMessage(props.t("Activation link sent to user."));
            setTimeout(() => {
                setSuccessMessage("");
                onResetRegenerateToken();
            }, 3000);
        } else if (tokenRegenerate) {
            setErrorMessage(props.t("Error sending activation link"));
            setTimeout(() => {
                setErrorMessage("");
                onResetRegenerateToken();
            }, 3000);
        }
    }, [tokenRegenerate]);

    useEffect(() => {
        if (userDeleted && userDeleted.status) {
            setSuccessMessage(props.t("User deactivated successfully."));
            setTimeout(() => {
                setSuccessMessage("");
                onResetDeleteUser();
            }, 3000);
            onGetUserDetails(params.id);
        } else if (userDeleted) {
            setErrorMessage(props.t("Error deleting user."));
            setTimeout(() => {
                setErrorMessage("");
                onResetDeleteUser();
            }, 3000);
        }
    }, [userDeleted]);

    useEffect(() => {
        if (userActivated && userActivated.status) {
            setSuccessMessage(props.t("User activated successfully."));
            setTimeout(() => {
                setSuccessMessage("");
                onResetDeleteUser();
            }, 3000);
            onGetUserDetails(params.id);
        } else if (userActivated) {
            setErrorMessage(props.t("Error activated user."));
            setTimeout(() => {
                setErrorMessage("");
                onResetDeleteUser();
            }, 3000);
        }
    }, [userActivated]);

    function getRoleName(role) {
        if (role === "ROLE_TENANT_ADMIN") {
            return props.t("Tenant Admin");
        } else if (role === "ROLE_TENANT_USER") {
            return props.t("Tenant User");
        } else if (role === "ROLE_CUSTOMER_ADMIN") {
            return props.t("Customer Admin");
        } else if (role === "ROLE_CUSTOMER_USER") {
            return props.t("Customer User");
        } else if (role === "ROLE_CONTACT_USER") {
            return props.t("Contact");
        } else if (role === "ROLE_SUPER_ADMIN") {
            return props.t("Super Admin");
        }
        return role;
    }

    const handleOnClick = () => {
        history("/settings/users/add");
    };

    const handleRegenerateClick = () =>
        store.dispatch({
            type: TENANT_ADMIN_REGENERATE_TOKEN,
            userId: activeUser.id,
        });

    const handleActiveUser = async () => {
        store.dispatch({type: TENANT_ACTIVATE_USER, userId: activeUser.id});
    };

    function togglePwdModal() {
        setShowOverridePwdModal(!showOverridePwdModal);
    }

    function tog_standard() {
        setmodal_standard(!modal_standard);
        document.body.classList.add("no_padding");
    }

    const handleDeleteUser = async () => {
        await store.dispatch({type: TENANT_ADMIN_DELETE_USER, userId: activeUser.id});
        tog_standard();
    };

    const handleEdit = async () => {
        if (props.setEditMode && props.setSelectedTenantUser) {
            await props.setSelectedTenantUser(activeUser.id)
            props.setEditMode(true);
            return;
        }
        history(`${loggedInUser?.customerId ? '/customer' : `/customers/${activeUser.customer.id}`}/users/${activeUser.id}/edit`);
    }

    const getActionButtons = () => {
        let actions = [
            {
                text: props.t("Override Password"),
                onClick: togglePwdModal,
            },
        ];

        if (activeUser) {
            if (activeUser.role != "ROLE_CONTACT_USER") {
                actions.push({
                    text: props.t("Send Activation Email"),
                    onClick: handleRegenerateClick,
                });
            }

            if (activeUser.enabled == true) {
                actions.push({
                    text: props.t("De-activate"),
                    onClick: tog_standard,
                });
            } else {
                actions.push({
                    text: props.t("Activate"),
                    onClick: handleActiveUser,
                });
            }
        }

        return actions;
    };

    const getDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Description"),
                    field: "description",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Start Date"),
                    field: "startDate",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: props.t("Status"),
                    field: "status",
                    sort: "asc",
                },
            ],
            rows: projects?.map((project, key) => ({
                description: project.description,
                startDate: project.startDate,
                status: project.status,
                actions: (
                    <projectIdContext.Provider value={project.id}>
                        <ProjectActions projectId={project.id}/>
                    </projectIdContext.Provider>
                ),
            })),
        };
        return data;
    }

    const handleImpersonate = () => {
        onImpersonateUser({userEmail: user?.email}, history, location)
    }


    function getImpersonateButton() {
        const role = activeUser?.roles[0]?.name;
        return <>
            {(role && role != "ROLE_CONTACT_USER") &&
            <button onClick={() => handleImpersonate(activeUser)} className="btn btn-primary"
                    style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}>
                <i className="fa fa-sign-in-alt"></i>
            </button>}</>

    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        breadcrumbs={[{title: props.t("Users"), link: loggedInUser?.customerId ? "/customer/users" : "/users"}]}
                        title={activeUser ? activeUser?.name : "User"}
                    />
                    <>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                        <TableToolbar
                                            title={activeUser?.name ? activeUser?.name : props.t("User Details")}
                                            buttons={getActionButtons()}
                                            canEdit={handleEdit}
                                            customComponent={getImpersonateButton}
                                        />
                                    </CardHeader>
                                </Card>
                            </Col>

                            {successMessage && (
                                <Col className="col-12">
                                    <Alert color="success">{successMessage}</Alert>
                                </Col>
                            )}
                            {errorMessage && (
                                <Col className="col-12">
                                    <Alert color="danger">{errorMessage}</Alert>
                                </Col>
                            )}

                            <Loader loading={loading} title="User"/>
                            {!loading && <>
                                <Col xl="4">
                                    <Card className="card">
                                        <CardBody>
                                            <div className="text-center">
                                                <label htmlFor='icon-button-file'>
                                                    <Avatar
                                                        variant="rounded"
                                                        src={imageUploading ? loadingIcon : profileImageId == null ? avatar : BASE_IMAGE_URL + "/" + profileImageId}
                                                        style={{
                                                            margin: "10px",
                                                            width: "100px",
                                                            height: "100px",
                                                            borderRadius: "100px"
                                                        }}
                                                    >
                                                        <PersonIcon/>
                                                    </Avatar>
                                                </label>
                                                <h5 className="mb-1">{user?.name}</h5>
                                                {user?.email && <p className="text-muted"><a href={"mailto:" + user?.email}>{user?.email}</a></p>}
                                            </div>

                                            <hr className="my-4"/>

                                            <div className="text-muted">
                                                <div className="table-responsive mt-4">
                                                    {equalsAny(loggedInUser?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) && (
                                                        <div>
                                                            <p className="mb-1">{props.t("Company")} :</p>
                                                            <Link to={`/customers/${user?.customer?.customerNumber}/profile`}><h5 className="font-size-16">{user?.customer?.name} <i
                                                                style={{color: 'grey', marginLeft: '10px'}} className="fas fa-external-link-alt"></i></h5></Link>
                                                        </div>)}
                                                    {props.user?.title && <div className="mt-4">
                                                        <p className="mb-1">{props.t("Title")} :</p>
                                                        <h5 className="font-size-16">{user?.title}</h5>
                                                    </div>}
                                                    <div className="mt-4">
                                                        <p className="mb-1">{props.t("Name")} :</p>
                                                        <h5 className="font-size-16">{user?.name}</h5>
                                                    </div>
                                                    {props.user?.phone && <div className="mt-4">
                                                        <p className="mb-1">{props.t("Phone")} :</p>
                                                        <h5 className="font-size-16"><a href={"tel:" + user?.phone}>{user?.phone}</a></h5>
                                                    </div>}
                                                    {props.user?.mobile && <div className="mt-4">
                                                        <p className="mb-1">{props.t("Mobile")} :</p>
                                                        <h5 className="font-size-16"><a href={"tel:" + user?.mobile}>{user?.mobile}</a></h5>
                                                    </div>}
                                                    {user?.email && <div className="mt-4">
                                                        <p className="mb-1">{props.t("E-mail")} :</p>
                                                        <h5 className="font-size-16"><a href={"mailto:" + user?.email}>{user?.email}</a></h5>
                                                    </div>}
                                                    <div className="mt-4">
                                                        <p className="mb-1">{props.t("Timezone")} :</p>
                                                        <h5 className="font-size-16">{user?.timezone}</h5>
                                                    </div>
                                                    {user?.city && <div className="mt-4">
                                                        <p className="mb-1">{props.t("City")} :</p>
                                                        <h5 className="font-size-16">{user?.city}</h5>
                                                    </div>}
                                                    {user?.fortnoxUsername && <div className="mt-4">
                                                        <p className="mb-1">{props.t("Fortnox username")} :</p>
                                                        <h5 className="font-size-16">{user?.fortnoxUsername}</h5>
                                                    </div>}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="8">
                                    <Card className="mb-0">
                                        <Nav tabs className="nav-tabs-custom nav-justified">
                                            {hasModuleEnabledForLoggedInUser("PROJECTS") && <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '1'})}
                                                    onClick={() => {
                                                        profiletoggle('1');
                                                    }}
                                                >
                                                    <i className="uil uil-apps font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Projects")}</span>
                                                </NavLink>
                                            </NavItem> }
                                            {hasModuleEnabledForLoggedInUser("ORDERS") &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '2'})}
                                                    onClick={() => {
                                                        profiletoggle('2');
                                                    }}
                                                >
                                                    <i className="uil uil-shopping-cart-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Orders")}</span>
                                                </NavLink>
                                            </NavItem>}
                                            {hasModuleEnabledForLoggedInUser("INVOICES") &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '3'})}
                                                    onClick={() => {
                                                        profiletoggle('3');
                                                    }}
                                                >
                                                    <i className="uil uil-invoice font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Invoices")}</span>
                                                </NavLink>
                                            </NavItem>}
                                            {hasModuleEnabledForLoggedInUser("OFFERS") &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '4'})}
                                                    onClick={() => {
                                                        profiletoggle('4');
                                                    }}
                                                >
                                                    <i className="uil uil-bolt-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Offers")}</span>
                                                </NavLink>
                                            </NavItem>}
                                            {hasModuleEnabledForLoggedInUser("EVENTS") &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '5'})}
                                                    onClick={() => {
                                                        profiletoggle('5');
                                                    }}
                                                >
                                                    <i className="uil uil-shopping-cart-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Events")}</span>
                                                </NavLink>
                                            </NavItem>}
                                            {hasModuleEnabledForLoggedInUser("TASKS") &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '6'})}
                                                    onClick={() => {
                                                        profiletoggle('6');
                                                    }}
                                                >
                                                    <i className="uil-channel-add font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Tasks")}</span>
                                                </NavLink>
                                            </NavItem>}
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '7'})}
                                                    onClick={() => {
                                                        profiletoggle('7');
                                                    }}
                                                >
                                                    <i className="uil uil-chart font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Statistics")}</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={activeTab} className="p-4">
                                            <TabPane tabId="1">
                                                {activeTab == "1" && <ProjectListManagement uid={params.id}/> }
                                            </TabPane>
                                            <TabPane tabId="2">
                                                {user?.name && activeTab == "2" && <CustomerOrderTable yourReference={user?.name}/>}
                                            </TabPane>
                                            <TabPane tabId="3">
                                                {user?.name && activeTab == "3" && <CustomerInvoiceTable yourReference={user?.name}/>}
                                            </TabPane>
                                            <TabPane tabId="4">
                                                {user?.name && activeTab == "4" && <CustomerOfferTable yourReference={user?.name}/>}
                                            </TabPane>
                                            <TabPane tabId="5">
                                                {activeTab == "5" && <EventManagement userId={params.id}/> }
                                            </TabPane>
                                            <TabPane tabId="6">
                                                {activeTab == "6" && <TaskManagement customerUser={user}/>}
                                            </TabPane>
                                            <TabPane tabId="7">
                                                {user?.name && activeTab == "7" && <TenantDashboard yourReference={user?.name}/>}
                                            </TabPane>
                                        </TabContent>
                                    </Card>
                                </Col></>}
                        </Row>
                    </>
                </Container>

                <DeleteModal
                    deleteCallback={() => handleDeleteUser()}
                    element={"User"}
                    t={(text) => props.t(text)}
                    showDeleteModal={modal_standard}
                    toggleModal={() => tog_standard()}
                />
                <OverridePwdModal
                    t={(text) => props.t(text)}
                    showOverridePwdModal={showOverridePwdModal && activeUser?.id}
                    toggleModal={() => togglePwdModal()}
                    userId={activeUser?.id}
                />
            </div>
        </React.Fragment>
    );
};

UserProfile.propTypes = {
    onEditProfile: PropTypes.func,
    users: PropTypes.array,
    projects: PropTypes.array,
    onGetUserDetails: PropTypes.func,
    onGetProjects: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        user: state.TenantAdmin.user,
        projects: state.Project.projectByParticipant,
        projectsLoading: state.Project.projectByParticipantLoading,
        tokenRegenerate: state.TenantAdmin.tokenRegenerate,
        userDeleted: state.TenantAdmin.userDeleted,
        userActivated: state.TenantAdmin.userActivated,
        loading: state.TenantAdmin.userLoading,
        error: state.TenantAdmin.adminUsersError,
        getProjectsError: state.Project.error,
        editProfileLoading: state.Profile.editProfileLoading,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetUserDetails: (id) => dispatch(getTenantUserDetails(id)),
    onGetUserDetailsReset: () => dispatch(getTenantUserDetailsReset()),
    onGetProjects: (id) => dispatch(getProjectListByParticipant(id)),
    onResetRegenerateToken: () =>
        dispatch({
            type: TENANT_ADMIN_RESET_REGENERATE_TOKEN,
        }),
    onResetDeleteUser: () =>
        dispatch({
            type: TENANT_ADMIN_RESET_DELETE_USER,
        }),
    onGetProjectsReset: () => dispatch(getProjectListByParticipantReset()),
    onImpersonateUser: (obj, history, location) =>
        dispatch(impersonateUser(obj, history, location)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(UserProfile)));

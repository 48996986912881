import {BreadcrumbItem, Button, Col, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/EditOutlined";
import DuplicateIcon from "@material-ui/icons/FileCopy";
import useStyles from "../../util/styles";
import {Tooltip} from "@material-ui/core";

const PageToolbarTop = ({
  title,
  breadcrumbs,
  toggle,
  backBtn,
  canDelete,
  canEdit,
  canDuplicate,
  noBackBtn,
  customComponent,
  ...props
}) => {
  const classes = useStyles();
  const history = useNavigate();
  const [brandSettings, setBrandSettings] = useState(null);
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("authUser"));
    setBrandSettings(user.brandConfig);
  }, [])

  const handleBack = () => {
    history(-1);
  };

  return (
    <Row>
      <Col className="col-12 d-flex flex-column flex-md-row align-items-start align-md-items-center justify-content-between">
        <div className="page-title-box d-flex">
          {noBackBtn && (
            <Button
              color="primary"
              className="btn"
              onClick={backBtn ? backBtn : handleBack}
              style={{ marginRight: "8px", borderColor: brandSettings?.primaryBtnColor, backgroundColor: brandSettings?.primaryBtnColor }}
            >
              <i className="fa fa-arrow-left"></i>
            </Button>
          )}
          <ol className="breadcrumb">
            {breadcrumbs?.map((crumb, i) => (
              <BreadcrumbItem>
                <Link to={crumb.link} key={i}>
                  {crumb.title}
                </Link>
              </BreadcrumbItem>
            ))}
            <BreadcrumbItem active>
              <span className={classes.breadcrumbTitle}>{title}</span>
            </BreadcrumbItem>
          </ol>
          <div className={classes.breadcrumbActions}>
            {canEdit && (
              <Tooltip title={props.t("Edit")}>
                <EditIcon onClick={canEdit} />
              </Tooltip>
            )}
            {canDuplicate && (
              <Tooltip title={props.t("Duplicate")}>
                <DuplicateIcon
                  style={{ height: "20px" }}
                  onClick={canDuplicate}
                />
              </Tooltip>
            )}
            {canDelete && (
              <Tooltip title={props.t("Delete")}>
                <DeleteIcon htmlColor="#CF5A5A" onClick={canDelete} />
              </Tooltip>
            )}
            {customComponent && customComponent()}
          </div>
        </div>
        {toggle && (
          <div className="form-check form-switch form-switch-lg mb-3 float-right position-relative">
            <input
              type="checkbox"
              className="form-check-input"
              id="customSwitchsizelg"
              style={toggle.active ? { backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor } : {}}
              defaultChecked={toggle.active}
              onChange={toggle.onClick}
            />
            {toggle.label}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default withTranslation()(PageToolbarTop);

import {call, put, takeEvery} from "redux-saga/effects"

// Login Redux States
import {BRAND_CONFIG, LOGIN_USER, LOGOUT_USER} from "./actionTypes"
import {apiError, brandFail, brandSuccess, loginSuccess} from "./actions"

//Include Both Helper File with needed methods
import {getFirebaseBackend} from "../../../helpers/firebase_helper"
import {getBranding, getCurrencyList, postJwtLogin,} from "../../../helpers/fakebackend_helper"

import {changeLayout, changeLayoutMode, changeSidebarTheme, changeTopbarTheme} from "../../actions";

const fireBaseBackend = getFirebaseBackend()

function* loginUser({payload: {user, history}}) {
    try {
        const response = yield call(postJwtLogin, {
            email: user.email,
            password: user.password,
            domain: user.domain
        });
        if (response.isAxiosError) throw response;
        localStorage.setItem("authUser", JSON.stringify(response.data));
        localStorage.setItem("themeMode", response.data?.brandConfig?.themeMode);
        const currencyResponse = yield call(getCurrencyList);
        if (currencyResponse.status === 200) {
            localStorage.setItem("currency", JSON.stringify(currencyResponse.data))
        }
        if(response.data?.brandConfig?.themeMode){
            console.log("Set theme mode to :", response.data?.brandConfig?.themeMode)
            yield put(changeTopbarTheme(response.data?.brandConfig?.themeMode))
            yield put(changeLayoutMode(response.data?.brandConfig?.themeMode))
            yield put(changeSidebarTheme(response?.data?.brand?.themeMode))
        }
        yield put(loginSuccess(response.data));
        if (response.data.roles.includes("ROLE_CUSTOMER_ADMIN") || response.data.roles.includes("ROLE_CUSTOMER_USER")) {
            localStorage.setItem("level", "CUSTOMER")
            yield put(changeLayout("horizontal"));
            //history("/customer/categories", { replace: true });
            window.location.href = "/customer/categories"
        } else {
            if (response.data.roles.includes("ROLE_TENANT_ADMIN") || response.data.roles.includes("ROLE_TENANT_USER")) {
                localStorage.setItem("level", "TENANT")
            } else {
                localStorage.setItem("level", "SUPERADMIN")
            }
            yield put(changeLayout("vertical"));
            //history("/dashboard", { replace: true });
            window.location.href = "/dashboard"
        }
    } catch (error) {
        //console.log("Called, error block", error.response.data)
        yield put(apiError("Invalid username or password."))
    }
}

function* logoutUser({payload: {history}}) {
    try {
        localStorage.removeItem("authUser");
        //CartService.clearCart();
        //history.push("/login");
        window.location.href = "/login"
        history('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}


function* fetchBrand({domain}) {
    try {
        let response = yield call(getBranding, domain);
        localStorage.setItem("themeMode", response.data.themeMode);
        yield put(brandSuccess(response))
    } catch (error) {
        yield put(brandFail(error))
    }
}


function* authSaga() {
    yield takeEvery(LOGIN_USER, loginUser)
    yield takeEvery(LOGOUT_USER, logoutUser)
    yield takeEvery(BRAND_CONFIG, fetchBrand)
}

export default authSaga

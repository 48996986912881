export const GET_LANGUAGE_LIST = "GET_LANGUAGE_LIST"
export const GET_LANGUAGE_LIST_SUCCESS = "GET_LANGUAGE_LIST_SUCCESS"
export const GET_LANGUAGE_LIST_ERROR = "GET_LANGUAGE_LIST_ERROR"

export const GET_LANGUAGE_LABELS = "GET_LABELS"
export const GET_LANGUAGE_LABELS_SUCCESS = "GET_LABELS_SUCCESS"
export const GET_LANGUAGE_LABELS_ERROR = "GET_LABELS_ERROR"

export const GET_PHRASES = "GET_PHRASES"
export const GET_PHRASES_SUCCESS = "GET_PHRASES_SUCCESS"
export const GET_PHRASES_ERROR = "GET_PHRASES_ERROR"

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {getOffers, getOffersReset} from "../../store/actions";
import {connect} from "react-redux";
import {MDBDataTable} from "mdbreact";
import {Alert, Col, Row} from "reactstrap";
import {downloadPDF, getFilterClassName} from "../../util/helperFunctions";
import Loader from "../../components/Common/Loader";
import ButtonGroup from "../../components/Common/ButtonGroup";
import {checkIfLoading} from "../../store/selector";
import {offersActionTypes} from "../../store/offer/actionTypes";
import {orderBy} from "lodash";
import {formatCurrency} from "../../helpers/currency_helper";
import withRouter from "../../components/Common/withRouter";


const CustomerOfferTable = (props) => {
    let {
        offers, loading, 
        onGetOffers, onGetOffersReset, customerId, error, ourReference, yourReference, articleNumber, projectNumber, articleGroupId, manufacturer
    } = props;

    const [offerList, setOfferList] = useState([])
    const [brandSettings, setBrandSettings] = useState(null);
    const [offerType, setOfferType] = useState('all')
    const [sortState, setSortState] = useState(null);
    const numericColumns = ['DocumentNumber', 'Total', 'Project'];

    let user = JSON.parse(localStorage.getItem("authUser"));

    const history = useNavigate()

    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        if (customerId || articleNumber || projectNumber || articleGroupId || manufacturer || ourReference || yourReference) {
            //onGetOffers('all', customerId, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference)
        }
        return () => {
            onGetOffersReset();
        }
    }, [])

    useEffect(() => {
      if(error){
        setTimeout(() => {onGetOffersReset()}, 5000);
      }
    }, [error])

    useEffect(() => {
        if (offers) {
            setOfferList(offers);
        }
    }, [offers])

    useEffect(() => {
      if(sortState?.column){
        const {column, direction} = sortState;
        setOfferList(orderBy(offerList, o => {
            if(column === 'Sent') return o[column];
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
          }, 
        [direction]
        ))
      }
    }, [sortState])

    useEffect(() => {
      if(offerType){
        onGetOffers(offerType, customerId, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference);
      }
    }, [offerType]);

    const getOffersDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Document Number"),
                    field: "DocumentNumber",
                    sort: "asc",
                    width: 150,
                },
                {
                  label: props.t("Status"),
                  field: "status",
                  sort: "asc",
                  width: 150,
                },
                {
                    label: props.t("OfferDate"),
                    field: "OfferDate",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Project"),
                    field: "Project",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Sent"),
                    field: "Sent",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Total"),
                    field: "Total",
                    sort: "asc",
                    width: 150,
                },
                {
                  label: props.t("Download"),
                  field: "download",
                }
            ],
            rows: offerList?.map((offer, key) => ({
                status: <div onClick={() => openOfferDetails(offer)} className={`badge ${getFilterClassName(offer.status)} p-1`}>{props.t(offer.status)}</div>,
                OfferDate: <div onClick={() => openOfferDetails(offer)}>{offer.OfferDate}</div>,
                DocumentNumber: <div onClick={() => openOfferDetails(offer)}>{offer.DocumentNumber}</div>,
                Project: <div onClick={() => openOfferDetails(offer)}>{offer.Project}</div>,
                Sent: <div onClick={() => openOfferDetails(offer)}>{offer.Sent == true ? props.t("Yes") : props.t("No")}</div>,
                Total: <div onClick={() => openOfferDetails(offer)}>{formatCurrency(offer?.Currency, offer.Total)}</div>,
                download: <div onClick={() => downloadPDF(offer.DocumentNumber, 'offer')}><i className="fa fa-download font-size-15"></i></div>
            }))
        }
        return data;
    }


    const openOfferDetails = (offer) => {
        history(`/offers/${offer.DocumentNumber}?from=` + encodeURIComponent(props.location.pathname));
    }

    const handleFilter = (value) => {
        setOfferType(value);
    }


    return (<React.Fragment>
        {error &&
        <Alert className={"mt-4"} color="danger" role="alert">
            {error}
        </Alert>
        }
        <Row className="mb-3">
            <Col className="col-12">
                <div className="d-flex justify-content-end">
                    <ButtonGroup 
                        filter={{
                            selected: offerType,
                            options : [
                                {name: props.t("All"), value:"all"},
                                {name: props.t("Ongoing"), value:"ordernotcreated"},
                                {name: props.t("Accepted"), value:"ordercreated"},
                                {name: props.t("Expired"), value: "expired"},
                                {name: props.t("Cancelled"), value: "cancelled"}
                            ],
                            onSelect: handleFilter
                        }}
                        brandSettings={brandSettings}     
                    />
                </div>
            </Col>
        </Row>
        
        <Loader loading={loading} title="Offers" />
                
        {!loading && offers &&
        <Row>
            <Col className="col-12 article-table">
                <MDBDataTable
                    hover
                    responsive
                    barReverse={true}
                    borderless
                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                    entriesLabel={props.t("Show entries")}
                    infoLabel={[props.t('Showing'), props.t('to'), props.t('of'), props.t('entries')]}
                    searchLabel={props.t("Search") + "..."}
                    noBottomColumns={true}
                    data={getOffersDatatableData()}
                    entries={50}
                    entriesOptions={[50, 100, 500]}
                    onSearch={(text) => console.log(text)}
                    noRecordsFoundLabel={props.t("No matching records found")}
                    sortRows={[]}
                    onSort={({column, direction}) => {
                        if(sortState && sortState.column == column){
                            setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                        } else {
                            setSortState({column, direction});
                        }
                    }}
                />
            </Col>
        </Row>}
    </React.Fragment>)

}


CustomerOfferTable.propTypes = {
    offers: PropTypes.array,
    onGetOffers: PropTypes.func,
    onGetOfferReset: PropTypes.func
}

const mapStateToProps = state => {
    return {
        offers: state.Offers.offers?.Offers,
        loading: checkIfLoading(state, offersActionTypes.GET_OFFERS),
        error: state.Offers.getOffersError,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetOffers: (offerType, id, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference) => dispatch(getOffers(offerType, id, null, null, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference)),
    onGetOffersReset: () => dispatch(getOffersReset())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(CustomerOfferTable)));

import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import {Button, ButtonDropdown, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input,} from "reactstrap";
import {exitUserImpersonation, getCustomerListImpersonation, getTenantList, getUserForImpersonation, impersonateUser,} from "../../store/users/impersonation/actions";
import PropTypes from "prop-types";
import {equalsAny} from "../../util/helperFunctions";
import {getImpersonationProjectList} from "../../store/project/actions";
import withRouter from "../../components/Common/withRouter";

const UserImpersonation = (props) => {
    const [tenantToggle, setTenantToggle] = useState(false);
    const [customerToggle, setCustomerToggle] = useState(false);
    const [userToggle, setUserToggle] = useState(false);
    const [projectToggle, setProjectToggle] = useState(false);
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem("authUser"));

    const {
        users,
        onGetUserForImpersonation,
        onImpersonateUser,
        history,
        onGetTenants,
        tenants,
        onGetCustomers,
        customers,
        impersonateSuccess,
        impersonateExit,
        exitUserImpersonation,
        projects,
        onGetProjects,
    } = props;

    const [userList, setUserList] = useState([]);
    const [tenantList, setTenantList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [tenantColor, setTenantColor] = useState("secondary");
    const [customerColor, setCustomerColor] = useState("secondary");
    const [customerSearch, setCustomerSearch] = useState("");
    const [userColor, setUserColor] = useState("secondary");
    const [projectColor, setProjectColor] = useState("secondary");
    const [showMe, setShowMe] = useState(false);
    const [brandSettings, setBrandSettings] = useState(null);

    const resetColor = () => {
        setTenantColor("secondary");
        setCustomerColor("secondary");
        setUserColor("secondary");
        setProjectColor("secondary");
    };

    useEffect(() => {
        if (
            user?.roles?.includes("ROLE_SUPER_ADMIN") ||
            user?.roles?.includes("ROLE_ACTING_USER")
        ) {
            onGetTenants();
        }
        if (equalsAny(user?.roles, ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER", "ROLE_ACTING_USER"])) {
            onGetCustomers();
        }
    }, [impersonateExit]);

    useEffect(() => {
        setUserList(users);
    }, [users]);

    useEffect(() => {
        if (userList.length > 0) {
            setProjectList([]);
        } else {
            if (user?.customerId) {
                onGetProjects(user?.customerId);
            } else {
                onGetProjects();
            }
        }
    }, [user?.customerId, userList]);

    useEffect(() => {
        setTenantList(tenants);
    }, [tenants]);

    useEffect(() => {
        setCustomerList(customers);
    }, [customers]);

    useEffect(() => {
        if (projects) {
            setProjectList(projects);
        }
    }, [projects]);

    useEffect(() => {
        if (impersonateSuccess.tenantId) {
            onGetCustomers();
            onGetUserForImpersonation(impersonateSuccess.tenantId, null, [
                "ROLE_TENANT_ADMIN",
                "ROLE_TENANT_USER",
            ]);
        } else if (impersonateSuccess.customerId) {
            onGetUserForImpersonation(null, impersonateSuccess.customerId, [
                "ROLE_CUSTOMER_ADMIN",
                "ROLE_CUSTOMER_USER",
            ]);
        }
    }, [impersonateSuccess]);

    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        if (user?.roles?.includes("ROLE_ACTING_USER")) {
            if (user?.roles?.includes("ROLE_TENANT_ADMIN")) {
                onGetUserForImpersonation(user.tenantId, null, [
                    "ROLE_TENANT_ADMIN",
                    "ROLE_TENANT_USER",
                ]);
            } else if (user?.roles?.includes("ROLE_CUSTOMER_ADMIN") || user?.roles?.includes("ROLE_CUSTOMER_USER")) {
                onGetUserForImpersonation(null, user.customerId, [
                    "ROLE_CUSTOMER_ADMIN",
                    "ROLE_CUSTOMER_USER",
                ]);
            }
        }
    }, []);

    useEffect(() => {
        highlight();
    }, [userList, customerList, tenantList, projectList]);

    const handleTenantImpersonate = () => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        if (user && user.roles?.includes("ROLE_ACTING_USER") && user.tenantId) {
            onImpersonateUser({tenantId: user.tenantId}, history, location);
        }
    };

    const handleCustomerImpersonate = () => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        if (user && user.roles?.includes("ROLE_ACTING_USER") && user.customerId) {
            onImpersonateUser({customerId: user.customerId}, history, location);
        }
    };

    const handleUserImpersonate = () => {
        if (user && user.roles?.includes("ROLE_ACTING_USER") && user.email) {
            onImpersonateUser({userEmail: user.email, tenantId: user?.tenant?.id}, history, location);
        }
    };

    const getTenantName = () => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        if (user && user.roles?.includes("ROLE_ACTING_USER") && user.tenantId) {
            let label = tenantList.filter((tenant) => tenant.id === user.tenantId)[0]
                ?.name;
            if (label) {
                return getTrim(label);
            }
        }
        return null;
    };

    const getCustomerName = () => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        if (user && user.roles?.includes("ROLE_ACTING_USER") && user.customerId) {
            let label = customerList.filter(
                (customer) => customer.id === user.customerId
            )[0]?.name;
            if (label) {
                return getTrim(label);
            }
        }
        return null;
    };

    const getUserName = () => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        if (user && user.roles?.includes("ROLE_ACTING_USER") && user.email) {
            //setCurrentUser({userEmail: user.email})
            let label = userList.filter((item) => item.email === user.email)[0]?.name;
            if (label) {
                return getTrim(label);
            }
        }
        return null;
    };

    const getProjectName = () => {
        return null;
    };

    const handleProjectImpersonate = () => {
    };

    const highlight = () => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        let level = userList.filter((item) => item.email === user.email)[0]
            ? "user"
            : customerList.filter((customer) => customer.id === user.customerId)[0]
                ? "customer"
                : tenantList.filter((tenant) => tenant.id === user.tenantId)[0]
                    ? "tenant"
                    : projectList.filter((project) => project.id === user.projectId)[0]
                        ? "project"
                        : "";

        setShowMe(true);
        resetColor();
        switch (level) {
            case "tenant":
                setTenantColor("primary");
                break;
            case "customer":
                setCustomerColor("primary");
                break;
            case "user":
                setUserColor("primary");
                break;
            case "project":
                setProjectColor("primary");
                break;
            default:
                setShowMe(false);
        }
    };

    const btnStyle = {textOverflow: "ellipsis", whiteSpace: "nowrap"};

    const getTrim = (name) => {
        return name.length > 18 ? name.substr(0, 18) + ".." : name;
    };

    const handleCustomerAutoCompleteEvent = (e) => {
        if (e.key === 'Enter') {
            let selectedCustomer = customerList?.filter(c => c.name?.toLowerCase().includes(customerSearch?.toLowerCase())).at(0);
            onImpersonateUser(
                {customerId: selectedCustomer.id},
                history,
                location
            )
        }
    }

    return (
        <div className="flex-sm">
            {showMe && (
                <div className="btn-group me-1 my-1">
                    <Button
                        className="exit-btn"
                        color="danger"
                        onClick={() => {
                            exitUserImpersonation(props.history);
                        }}
                    >
                        <i className="uil uil-sign-out-alt"></i>
                    </Button>
                </div>
            )}

            {(() => {
                if (tenantList?.length > 0) {
                    return (
                        <div className="btn-group me-1 my-1">
                            <Dropdown
                                isOpen={tenantToggle}
                                toggle={() => setTenantToggle(!tenantToggle)}
                                id="tenant-dropdown"
                                className="sm-btn"
                            >
                                {getTenantName() != null && (
                                    <Button
                                        id="caret"
                                        color={tenantColor}
                                        onClick={() => handleTenantImpersonate()}
                                        style={{
                                            backgroundColor: brandSettings?.primaryBtnColor,
                                            borderColor: brandSettings?.primaryBtnColor,
                                            filter: getTenantName() == null ? 'brightness(80%)' : 'brightness(100%)',
                                            borderTopRightRadius:0,
                                            borderBottomRightRadius:0
                                        }}
                                        className="searchable-button-1"
                                    >
                                        {getTenantName()}
                                    </Button>
                                )}
                                <DropdownToggle
                                    caret
                                    className="dropdown-toggle-split"
                                    id="page-header-user-dropdown"
                                    style={{
                                        backgroundColor: brandSettings?.primaryBtnColor,
                                        borderColor: brandSettings?.primaryBtnColor,
                                        filter: getTenantName() == null ? 'brightness(80%)' : 'brightness(100%)',
                                        borderTopLeftRadius:getTenantName() == null ? "5px" : 0,
                                        borderBottomLeftRadius:getTenantName() == null ? "5px" : 0,
                                    }}
                                >
                                    {getTenantName() == null && (
                                        <span>{props.t("Select Tenant")} &nbsp;&nbsp;</span>
                                    )}
                                    <i className="mdi mdi-chevron-down"/>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end scrollable-list">
                                    {tenantList != null &&
                                        tenantList?.map((element, index) => (
                                            <DropdownItem
                                                key={index}
                                                onClick={() => {
                                                    onImpersonateUser(
                                                        {tenantId: element.id},
                                                        history,
                                                        location
                                                    );
                                                }}
                                            >
                                                {" "}
                                                <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
                                                {element.name}{" "}
                                            </DropdownItem>
                                        ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    );
                }
            })()}

            <div className="btn-group me-1 my-1">
                <Dropdown
                    isOpen={customerToggle}
                    toggle={() => {
                        setCustomerToggle(!customerToggle);
                        if (customerToggle === false) {
                            setCustomerSearch("")
                        }
                    }}
                    /*direction="right"*/
                    id="customer-dropdown"
                    className="sm-btn"
                >
                    {getCustomerName() != null && (
                        <Button
                            id="caret"
                            onClick={() => handleCustomerImpersonate()}
                            style={{
                                backgroundColor: brandSettings?.primaryBtnColor,
                                borderColor: brandSettings?.primaryBtnColor,
                                filter: getCustomerName() == null ? 'brightness(80%)' : 'brightness(100%)'
                            }}
                        >
                            {getCustomerName()}
                        </Button>
                    )}
                    <DropdownToggle
                        caret
                        style={{
                            backgroundColor: brandSettings?.primaryBtnColor,
                            borderColor: brandSettings?.primaryBtnColor,
                            filter: getCustomerName() == null ? 'brightness(80%)' : 'brightness(100%)'
                        }}
                        className="dropdown-toggle-split"
                        id="page-header-user-dropdown"
                    >
                        {getCustomerName() == null && (
                            <span>{props.t("Select Customer")} &nbsp;&nbsp;</span>
                        )}
                        <i className="mdi mdi-chevron-down"/>
                    </DropdownToggle>
                    {customerList?.length > 0 && (
                        <DropdownMenu className="dropdown-menu scrollable-list" style={{minWidth: "300px", paddingTop: 0}}>
                            <DropdownItem header style={{position: "sticky", top: 0, backgroundColor: "white"}}>
                                <Form
                                    className="ml-0   d-lg-block"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <Input
                                        type="text"
                                        className="bg-light border-light rounded toolbar-search"
                                        placeholder={props.t("Search") + "..."}
                                        onChange={(e) => {
                                            setCustomerSearch(e.target.value);
                                        }}
                                        onKeyDown={handleCustomerAutoCompleteEvent}
                                        autoFocus
                                    />
                                </Form>
                            </DropdownItem>
                            {/* >
                {" "}
                <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
                {element.name}{" "}
              </DropdownItem> */}
                            {
                                customerList?.filter(c => c.name?.toLowerCase().includes(customerSearch?.toLowerCase()))?.map((element, index) => (
                                    <DropdownItem
                                        key={index}
                                        onClick={() =>
                                            onImpersonateUser(
                                                {customerId: element.id},
                                                history,
                                                location
                                            )
                                        }
                                    >
                                        {" "}
                                        {element?.favorite ?
                                            <i className="fa fa-star font-size-16 align-middle text-muted me-1"></i> :
                                            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>}
                                        {element.name}{" "}
                                    </DropdownItem>
                                ))}
                        </DropdownMenu>
                    )}
                </Dropdown>
            </div>
            <div className="btn-group me-1 my-1">
                <Dropdown
                    isOpen={projectToggle}
                    toggle={() => setProjectToggle(!projectToggle)}
                    className="sm-btn  "
                    id="project-dropdown"
                >
                    {getProjectName() != null && (
                        <Button
                            id="caret"
                            onClick={() => handleProjectImpersonate()}
                            style={{
                                backgroundColor: brandSettings?.primaryBtnColor,
                                borderColor: brandSettings?.primaryBtnColor,
                                filter: getProjectName() == null ? 'brightness(80%)' : 'brightness(100%)'
                            }}
                        >
                            {getProjectName()}
                        </Button>
                    )}
                    <DropdownToggle
                        caret
                        className="dropdown-toggle-split "
                        id="page-header-project-dropdown"
                        style={{
                            backgroundColor: brandSettings?.primaryBtnColor,
                            borderColor: brandSettings?.primaryBtnColor,
                            filter: getProjectName() == null ? 'brightness(80%)' : 'brightness(100%)'
                        }}
                    >
                        {getProjectName() == null && (
                            <span>{props.t("Select Project")} &nbsp;&nbsp;</span>
                        )}
                        <i className="mdi mdi-chevron-down"/>
                    </DropdownToggle>
                    {user?.tenantId != null && projectList?.length > 0 && (
                        <DropdownMenu className="dropdown-menu-end">
                            {projectList?.map((element, index) => (
                                <DropdownItem
                                    key={index}
                                    onClick={() => console.log(element.Description)}
                                >
                                    {" "}
                                    <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
                                    {element.Description}{" "}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    )}
                </Dropdown>
            </div>
            <div className="btn-group me-1 my-1">
                <Dropdown
                    isOpen={userToggle}
                    toggle={() => setUserToggle(!userToggle)}
                    className="sm-btn  "
                    id="user-dropdown"
                >
                    {getUserName() != null && (
                        <Button
                            id="caret"
                            onClick={() => handleUserImpersonate()}
                            style={{
                                backgroundColor: brandSettings?.primaryBtnColor,
                                borderColor: brandSettings?.primaryBtnColor,
                                filter: getUserName() == null ? 'brightness(80%)' : 'brightness(100%)'
                            }}
                        >
                            {getUserName()}
                        </Button>
                    )}
                    <DropdownToggle
                        caret
                        className="dropdown-toggle-split "
                        id="page-header-user-dropdown"
                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor, filter: getUserName() == null ? 'brightness(80%)' : 'brightness(100%)'}}
                    >
                        {getUserName() == null && (
                            <span>{props.t("Select User")} &nbsp;&nbsp;</span>
                        )}
                        <i className="mdi mdi-chevron-down"/>
                    </DropdownToggle>
                    {userList?.length > 0 && (
                        <DropdownMenu className="dropdown-menu-end">
                            {userList?.map((element, index) => (
                                <DropdownItem
                                    key={index}
                                    onClick={() =>
                                        onImpersonateUser(
                                            {userEmail: element.email, tenantId: element?.tenant?.id},
                                            history,
                                            location
                                        )
                                    }
                                >
                                    {" "}
                                    <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
                                    {element.name}{" "}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    )}
                </Dropdown>
            </div>
        </div>
    );
};

UserImpersonation.propTypes = {
    users: PropTypes.array,
    onGetUserForImpersonation: PropTypes.func,
    onImpersonateUser: PropTypes.func,
    onGetTenants: PropTypes.func,
    tenants: PropTypes.array,
    onGetCustomers: PropTypes.func,
    impersonateSuccess: PropTypes.object,
    impersonateExit: PropTypes.object,
    exitUserImpersonation: PropTypes.func,
    project: PropTypes.array,
    onGetProjects: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        users: state.UserImpersonation.users,
        tenants: state.UserImpersonation.tenants,
        customers: state.UserImpersonation.customers,
        impersonateSuccess: state.UserImpersonation.impersonateSuccess,
        impersonateExit: state.UserImpersonation.impersonateExit,
        projects: state.UserImpersonation?.projects,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetUserForImpersonation: (tenantId, customerId, roles) =>
        dispatch(getUserForImpersonation(tenantId, customerId, roles)),
    onImpersonateUser: (obj, history, location) =>
        dispatch(impersonateUser(obj, history, location)),
    onGetTenants: () => dispatch(getTenantList()),
    onGetCustomers: () => dispatch(getCustomerListImpersonation()),
    exitUserImpersonation: (history) => dispatch(exitUserImpersonation(history)),
    onGetProjects: (customerId) => dispatch(getImpersonationProjectList(customerId)),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation()(UserImpersonation))
);

import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Row,} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {get, post} from "../../helpers/api_helper";
import {size} from "lodash-es";
import {API_ERR_MSG} from "../../util/constants";
import Loader from "../../components/Common/Loader";
import {getTaskCategories, getTaskList, getTaskListReset, getTaskPriorities} from "../../store/task/actions";
import {getPriorityLabelClassName} from "../../util/helperFunctions";
import {GetCustomers, GetCustomerUsers, GetCustomerUsersReset, getProducts, getProductsReset, getProjectList, getProjectListReset} from "../../store/actions";
import {find, orderBy} from "lodash";
import {getTenantAdmins, getTenantAdminsReset, getTenantCustomers, getTenants} from "../../store/tenant/actions";
import ButtonGroup from "../../components/Common/ButtonGroup";
import TaskModal from "./TaskModal";
import withRouter from "../../components/Common/withRouter";

const TaskList = (props) => {
    const {
        tasks,
        onGetTasks,
        loading,
        getTaskError,
        onGetTasksReset,
        cid,
        projectId,
        customerUser,
        tenantUser,
        profileUser,

        tenantOptions,
        tenantCustomerOptions,
        tenantCustomersLoading,
        customerOptions,
        productOptions,
        projectOptions,
        customerUserOptions, 
        customerUsersLoading, 
        tenantUserOptions, 
        taskCategories,
        taskPriorities,
        tenantUsersLoading,
        onGetTenantOptions,
        onGetCustomerOptions,
        onGetProductOptions,
        onGetProductsReset,
        onGetProjectOptions,
        onGetProjectsReset,
        onGetTenantUsers, 
        onGetTenantUsersReset,
        onGetCustomerUsers,
        onGetCustomerUsersReset,
        onGetTenantCustomers,
        onGetTaskCategories,
        onGetTaskPriorities
    } = props;

    const [taskList, setTaskList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [brandSettings, setBrandSettings] = useState(null);

    const [modal_standard, setmodal_standard] = useState(false);

    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState(null);
    const [category, setCategory] = useState('SUPPORT');
    const [status, setStatus] = useState('TODO');
    const [dueDate, setDueDate] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [reporters, setReporters] = useState([]);
    const [assigned, setAssigned] = useState(null);
    const [article, setArticle] = useState(null);
    const [project, setProject] = useState(null);
    const [tenantId, setTenantId] = useState(null);
    const [assignedOptions, setAssignedOptions] = useState([]);
    const [reporterOptions, setReporterOptions] = useState([]);
    const [taskCustomerOptions, setTaskCustomerOptions] = useState([]);
    const [taskType, setTaskType] = useState(null);
    const [hiddenToCustomer, setHiddenToCustomer] = useState(false);

    const [higherLevelAdminUser, setHigherLevelAdminUser] = useState(null);
    const [superAdmin, setSuperAdmin] = useState(null);
    const [defaultCategory, setDefaultCategory] = useState(null);
    const [defaultPriority, setDefaultPriority] = useState(null);
    const [showHiddenToCustomerCheck, setShpwHiddenToCustomerCheck] = useState(false);

    const history = useNavigate();
    
    const user = JSON.parse(localStorage.getItem("authUser"));

    const SUPER_ADMIN_VIEW = 'SUPER_ADMIN_VIEW';
    const TENANT_VIEW = 'TENANT_VIEW';
    const CUSTOMER_VIEW = 'CUSTOMER_VIEW';

    const INTERNAL = 'INTERNAL';
    const ESCALATED = 'ESCALATED';
    const CUSTOMER = 'CUSTOMER';
    const TENANT = 'TENANT';

    const [view, setView] = useState(null);
    const [taskTypeFilter, setTaskTypeFilter] = useState('ALL');
    const [userType, setUserType] = useState(null);
    const [statusTypeFilter, setStatusTypeFilter] = useState('OPEN');
    const [sortState, setSortState] = useState(null);
    const numericColumns = ['id'];
    
    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        onGetTaskCategories();
        onGetTaskPriorities();
        onGetTasks({
            customerId: projectId ? null : getCustomerId(),
            projectId: projectId,
            assignedUserId: getUser()?.id, 
            reporterUserId: getUser()?.id,
            taskType: 'ALL',
            status: 'OPEN',
        });
        onGetCustomerOptions();
        initializeView();
        return () => {
            onGetTasksReset();
        }
    }, [])
    
    useEffect(() => {
        setTaskList(tasks);
    }, [tasks]);

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setTaskList(orderBy(taskList, t => {
            let o = t.taskDetails;
            if(column === 'category' || column === 'customer') return o[column]?.name
            if(column === 'assignedToUser') return getAssigneeName(t)?.toLowerCase()
            if(column === 'reporters') return o[column].length > 0 ? o[column][0]?.reporter?.name : '';
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])
    
    useEffect(() => {
        if (getTaskError) {
            setErrorMessage(props.t(API_ERR_MSG))
            setTimeout(() => {
                setErrorMessage('')
            }, 5000);
            setTimeout(() => {
                onGetTasksReset()
            }, 5000);
        }
    }, [getTaskError])

    useEffect(() => {
        (async() => {
        if(modal_standard){
            if(view === 'CUSTOMER_VIEW'){
                let tenantAdminUser = await getHigherLevelAdminUsersByRole(user?.tenantId, 'ROLE_TENANT_ADMIN');
                if (tenantAdminUser) {
                    setHigherLevelAdminUser(tenantAdminUser);
                    tenantAdminUser['name'] = 'Tenant';
                }
                if(getCustomerId()){
                    setCustomerId(getCustomerId());
                    onGetCustomerOptions();
                } else {
                    setCustomerId(user?.customerId);
                }
                // setTaskType(INTERNAL);
                onGetProjectOptions(null, user?.customerId);
                onGetProductOptions(user?.tenantId, user?.customerId);
            } else if(view === 'TENANT_VIEW'){
                let superAdminUser = await getHigherLevelAdminUsersByRole(user?.tenantId, 'ROLE_SUPER_ADMIN');
                if (superAdminUser) {
                    setHigherLevelAdminUser(superAdminUser);
                    superAdminUser['name'] = 'Super Admin';
                }
                setTenantId(user?.tenantId);
                if(getCustomerId()) {
                    setTaskType(CUSTOMER)
                    setCustomerId(getCustomerId());
                }
                // setTaskType(INTERNAL);
                onGetCustomerOptions();
                onGetProjectOptions(user?.tenantId, null);
                onGetProductOptions(user?.tenantId, null);
            } else if(view === 'SUPER_ADMIN_VIEW'){
                let superAdminUser = await getHigherLevelAdminUsersByRole(null, 'ROLE_SUPER_ADMIN');
                if(superAdminUser){
                    setSuperAdmin(superAdminUser);
                }
                // setTaskType(INTERNAL);
                onGetTenantOptions();
            }
        }
        })()
    }, [view, modal_standard]);

    useEffect(() => {
        if(tenantId){
            onGetTenantUsers(tenantId);
            if(!customerId){
                onGetProjectOptions(tenantId, null);
            }
            if(view === SUPER_ADMIN_VIEW){
                onGetTenantCustomers(tenantId);
                if(!customerId){
                    onGetProductOptions(tenantId, null);
                }
            }
        }
    }, [tenantId]);

    useEffect(() => {
        if(customerId){
            setProject(null);
            setArticle(null);
            onGetCustomerUsers(customerId);
            onGetProjectOptions(tenantId, customerId);
            onGetProductOptions(tenantId, customerId);
        } else if (tenantId){
            onGetProjectOptions(tenantId, null);
            onGetProductOptions(tenantId, null);
        }
    }, [customerId]);

    useEffect(() => {
        if(projectOptions && projectId){
            let projectView = find(projectOptions, {id: parseInt(projectId)});
            if(projectView){
                setProject(projectView);
                if(projectView?.customer){
                    setTaskType(CUSTOMER)
                    setCustomerId(projectView?.customer?.id);
                }
            }
        }
    }, [projectOptions, customerOptions]);

    useEffect(() => {
        if(customerUserOptions && tenantUserOptions){
            if(view === CUSTOMER_VIEW){
                setReporterOptions(customerUserOptions);
                // if(user?.uid != higherLevelAdminUser?.id){
                //     let loggedInUser = find([...customerUserOptions], {id: user?.uid});
                //     setReporters(loggedInUser ? [loggedInUser] : []);
                // } else {
                // }
                setReporters([]);
                
                setAssigned(taskType === ESCALATED && higherLevelAdminUser ? higherLevelAdminUser : null);
                const aos = taskType === ESCALATED ? (higherLevelAdminUser ? [higherLevelAdminUser] : []) : [...customerUserOptions]
                setAssignedOptions([].concat.apply([], aos));
            } else if(view === TENANT_VIEW){
                setReporterOptions([].concat.apply([], [...tenantUserOptions, ...customerUserOptions]));
                // if(user?.uid != higherLevelAdminUser?.id){
                //     let loggedInUser = find([...tenantUserOptions, ...customerUserOptions], {id: user?.uid});
                //     setReporters(loggedInUser ? [loggedInUser] : []);
                // } else {
                // }
                setReporters([]);

                if(getUser()){
                    setAssigned(getUser())
                } else {
                    setAssigned(taskType === ESCALATED && higherLevelAdminUser ? higherLevelAdminUser : null);
                }
                const aos = taskType === ESCALATED ? (higherLevelAdminUser ? [higherLevelAdminUser] : []) : (taskType === CUSTOMER ? [,...tenantUserOptions, ...customerUserOptions] : [...tenantUserOptions])
                setAssignedOptions([].concat.apply([], aos));
            } else if(view === SUPER_ADMIN_VIEW){
                const superAdminInArr = superAdmin ? [superAdmin] : [];
                const ops = taskType === INTERNAL ? (superAdmin ? [superAdmin] : []) : (getCustomerId() ? [...superAdminInArr, ...customerUserOptions, tenantUserOptions] : [...customerUserOptions, tenantUserOptions])
                setReporters([]);
                setReporterOptions([].concat.apply([], ops));

                setAssigned(taskType === INTERNAL && higherLevelAdminUser ? higherLevelAdminUser : null);
                setAssignedOptions([].concat.apply([], ops));
            }
        }
    }, [customerUserOptions, tenantUserOptions, taskType]);

    useEffect(() => {
        setTaskCustomerOptions(view === SUPER_ADMIN_VIEW ? tenantCustomerOptions : customerOptions)
    }, [customerOptions, tenantCustomerOptions])

    useEffect(() => {
        if(!(customerUser || tenantUser || profileUser)){ 
            onGetTasksReset();
            onGetTasks({
                taskType: taskTypeFilter ? taskTypeFilter : null,
                status: statusTypeFilter,
                customerId: cid && !projectId ? cid : null,
                projectId: projectId,
            });
        }
    }, [taskTypeFilter]);

    useEffect(() => {
        if(customerUser || tenantUser || profileUser){
            onGetTasksReset();
            onGetTasks({
                status: statusTypeFilter,
                taskType: taskTypeFilter ? taskTypeFilter : null,
                customerId: projectId ? null : getCustomerId(),
                projectId: projectId,
                assignedUserId: userType !== 'Reported' ? getUser()?.id : null,
                reporterUserId: userType !== 'Assigned' ? getUser()?.id : null,
            });
        }
    }, [userType, statusTypeFilter]);

    useEffect(() => {
            onGetTasksReset();
            onGetTasks({
                status: statusTypeFilter,
                taskType: taskTypeFilter ? taskTypeFilter : null,
                customerId: projectId ? null : getCustomerId(),
                projectId: projectId,
                assignedUserId: userType !== 'Reported' ? getUser()?.id : null,
                reporterUserId: userType !== 'Assigned' ? getUser()?.id : null,
            });
    }, [statusTypeFilter]);

    useEffect(() => {
        if(taskCategories){
            const supportCategory = find(taskCategories, {name: "SUPPORT"})
            if(supportCategory){
                setDefaultCategory(supportCategory.id)
                setCategory(supportCategory.id)
            }
        }
    }, [taskCategories])

    useEffect(() => {
        if(taskPriorities){
            const taskPriority = find(taskPriorities, {name: "Medium"})
            if(taskPriority){
                setDefaultPriority(taskPriority.id)
                setPriority(taskPriority.id)
            }
        }
    }, [taskPriorities])

    useEffect(() => {
        if(taskPriorities && priority){
            const taskPriorityDetails = find(taskPriorities, {id: parseInt(priority)})
            if(taskPriorityDetails){
                if(taskPriorityDetails.dueDays){
                    let dueDateUpdate = new Date();
                    dueDateUpdate.setDate(dueDateUpdate.getDate() + taskPriorityDetails.dueDays);
                    setDueDate(dueDateUpdate);
                } else {
                    setDueDate(null);
                }
            }
        }
    }, [priority, taskPriorities]);

    useEffect(() => {
        if((taskType == CUSTOMER || (taskType == TENANT && customerId)) && 
            (assigned && 
                (assigned.customer || assigned.role == "ROLE_CUSTOMER_USER" || assigned.role == "ROLE_CUSTOMER_ADMIN") && 
                reporters.filter(r => r.customer || r.role == "ROLE_CUSTOMER_USER" || r.role == "ROLE_CUSTOMER_ADMIN").length == reporters.length)){
            setShpwHiddenToCustomerCheck(true);
        } else {
            setHiddenToCustomer(false);
            setShpwHiddenToCustomerCheck(false);
        }
    }, [taskType, customerId, assigned, reporters])

    useEffect(() => {
        if(category && taskCategories){
            setHiddenToCustomer(find(taskCategories, { id: parseInt(category) })?.hiddenToCustomer);
        }
    }, [category])

    function initializeView(){
        if(!user?.customerId && !user?.tenantId){
            setView(SUPER_ADMIN_VIEW);
        } else if(user?.customerId){
            setView(CUSTOMER_VIEW);
        } else if(user?.tenantId){
            setView(TENANT_VIEW);
        }
    }
    
    function tog_standard() {
        setmodal_standard(!modal_standard)
    }

    const handleAddTask = () => {
        // clearModalData();
        initializeView();
        tog_standard();
    };

    const getUser = () => {
        if(customerUser){
            return customerUser;
        }
        else if(tenantUser){
            return tenantUser;
        }
        else if(profileUser){
            return profileUser;
        }

        return null;
    }

    const getCustomerId = () => {
        if(cid ){
            return cid;
        }
        else if(customerUser){
            return customerUser?.customer?.id;
        }
        else if(profileUser){
            return profileUser?.customer?.id;
        }
        return customerId;
    }

    const getHigherLevelAdminUsersByRole = async (tenantId, roleName) => {
        try {
            const response = await get(`/api/user/adminUserByRole?roleName=${roleName}${tenantId ? '&tenantId=' + tenantId : ''}`);
            if(response.status === 200) {
                return response.data;
            }
        } catch (err) {
        }
        return null;
    };

    const handleSaveTask = async () => {
        await post(`/api/task`, {
            taskDetails: {
                subject,
                description,
                priority: priority ? find(taskPriorities, {id: parseInt(priority)}) : null,
                category: category ? find(taskCategories, {id: parseInt(category)}) : null,
                status: status ? status : 'TODO',
                dueDate,
                customer: getCustomerId() ? {id: getCustomerId()} : null,
                reporters: reporters.map(r => ({reporter: r})),
                assigned: (view === SUPER_ADMIN_VIEW && !assigned) ? {id: user?.uid} : (assigned ? {id: assigned?.id} : null),
                hiddenToCustomer
            },
            articleId: article ? article?.id : null,
            projectId: project ? project?.id : null,
            tenantId: tenantId ? tenantId : null,
        });
        clearModalData();
        onGetTasks({
            status: statusTypeFilter,
            taskType: taskTypeFilter ? taskTypeFilter : null, 
            customerId: projectId ? null : getCustomerId(),
            assignedUserId: userType !== 'Reported' ? getUser()?.id : null,
            reporterUserId: userType !== 'Assigned' ? getUser()?.id : null,
        })
        tog_standard();
    };

    const clearModalData = () => {
        setSubject("");
        setDescription("");
        setPriority(defaultPriority);
        setCategory(defaultCategory);
        setStatus('TODO');
        setDueDate(null);
        setCustomerId(null);
        setReporters([]);
        setAssigned(null);
        setArticle(null);
        setProject(null);
        setTenantId(null);
        setTaskType(INTERNAL);
        setHiddenToCustomer(false);
        onGetProductsReset();
        // onGetProjectsReset();
        onGetTenantUsersReset();
        onGetCustomerUsersReset();
        setView(null);
    }

    const getTaskStatusLabel = (status) => {
        if(status === "TODO") return props.t("TODO");
        else if(status === "IN_PROGRESS") return props.t("IN PROGRESS");
        else if(status === "DONE") return props.t("DONE");
        else if(status === "REVIEW") return props.t("REVIEW");
        else return null;
    }

    function getAssigneeName(task){
        if(view === CUSTOMER_VIEW && task.taskDetails.assigned){
            const roles = task.taskDetails.assigned.roles;
            if(roles.length > 0 && (roles[0].name == 'ROLE_CUSTOMER_ADMIN' || roles[0].name == 'ROLE_CUSTOMER_USER' || roles[0].name == 'ROLE_CONTACT_USER')){
                return task.taskDetails.assigned?.name;
            } else {
                return props.t('Tenant');
            }
        }
        return (higherLevelAdminUser && task.taskDetails.assigned?.id == higherLevelAdminUser?.id) ? higherLevelAdminUser.name : task.taskDetails.assigned?.name
    }

    const getDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Subject"),
                    field: "subject",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Priority"),
                    field: "priority",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Status"),
                    field: "status",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Category"),
                    field: "category",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Reporters"),
                    field: "reporters",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Assigned to User"),
                    field: "assignedToUser",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Created Date"),
                    field: "createdDate",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Updated Date"),
                    field: "updatedDate",
                    sort: "asc",
                    autoWidth: true,
                }
            ],
            rows: taskList?.map((item, key) => ({
                subject: <div className="d-flex" onClick={() => handleOnRowClick(item)}>
                            {item.taskDetails.subject}
                            {(userType == null && getUserInTaskTab()) && (getUserInTaskTab()?.id === item.taskDetails.assigned?.id ? 
                            <div className="product-ribbon badge bg-soft-success ms-2 px-2 d-flex align-items-center justify-content-center" style={{height: "20px"}}>
                                {" "} {props.t("Assignee")}
                            </div> : 
                            <div className="product-ribbon badge bg-soft-info ms-2 px-2 d-flex align-items-center justify-content-center" style={{height: "20px"}}>
                                {" "} {props.t("Reporter")}
                            </div>)}
                            
                            {!(userType == null && getUserInTaskTab()) && user?.uid == item.taskDetails.assigned?.id &&
                            <div className="product-ribbon badge bg-soft-success ms-2 px-2 d-flex align-items-center justify-content-center" style={{height: "20px"}}>
                                {" "} {props.t("Assignee")}
                            </div>}
                            {!(userType == null && getUserInTaskTab()) && find(item.taskDetails.reporters.map(r => r.reporter), {id: user?.uid}) &&
                            <div className="product-ribbon badge bg-soft-info ms-2 px-2 d-flex align-items-center justify-content-center" style={{height: "20px"}}>
                                {" "} {props.t("Reporter")}
                            </div>} 
                            {item.internal ? 
                            <div className="product-ribbon badge bg-soft-warning ms-2 px-2 d-flex align-items-center justify-content-center" style={{height: "20px"}}>
                                {props.t("Internal")}
                            </div> : ""}
                            {item.overdue ? 
                            <div className="product-ribbon badge bg-soft-danger ms-2 px-2 d-flex align-items-center justify-content-center" style={{height: "20px"}}>
                                {props.t("Overdue")}
                            </div> : ""}
                        </div>,
                priority: <div className={`badge ${getPriorityLabelClassName(item.taskDetails.priority?.name)} p-1`} onClick={() => handleOnRowClick(item)}>
                            {props.t(item.taskDetails.priority?.name)}
                        </div>,
                status: <div className="d-flex" onClick={() => handleOnRowClick(item)}>{getTaskStatusLabel(item.taskDetails.status)}</div>,
                category: <div className="d-flex" onClick={() => handleOnRowClick(item)}>{item.taskDetails.category?.name}</div>,
                reporters: <div className="d-flex" onClick={() => handleOnRowClick(item)}>{item.taskDetails.reporters?.map(r => r.reporter?.name).join(", ")}</div>,
                assignedToUser: <div className="d-flex" onClick={() => handleOnRowClick(item)}>
                    {getAssigneeName(item)}
                </div>,
                createdDate: <div className="d-flex" onClick={() => handleOnRowClick(item)}>{new Date(item.taskDetails.createdAt).toLocaleString()}</div>,
                updatedDate: <div className="d-flex" onClick={() => handleOnRowClick(item)}>{new Date(item.taskDetails.updatedAt).toLocaleString()}</div>,
            })),
        };
        return data;
    };

    const getUserInTaskTab = () => {
        if(profileUser) return profileUser;
        if(customerUser) return customerUser;
        if(tenantUser) return tenantUser;
        return null;
    }

    const handleOnRowClick = (item) => {
        var url = `/customer/tasks/${item.taskDetails.id}`
        let currentLevel = localStorage.getItem("level");
        if(currentLevel == 'TENANT'){
            url = `/tasks/${item.taskDetails.id}`;
        }
        history(url)
    }

    return (
        <React.Fragment>
                    <Loader loading={loading} title="Tasks"/>
                    {errorMessage && (
                        <Row>
                            <Alert color="danger">{errorMessage}</Alert>
                        </Row>
                    )}
                    {!loading &&  <>          
                    <Row className="mb-3">
                        <Col className="col-12">
                            <div className="d-flex justify-content-end">
                                {/* {!(customerUser || tenantUser || profileUser) &&
                                <ButtonGroup 
                                    filter={{
                                        selected: taskTypeFilter,
                                        options : [
                                            {name: props.t("All"), value: 'ALL'},
                                            {name: props.t("Internal"), value:"INTERNAL"},
                                            {name: props.t("Escalated"), value: "ESCALATED"}
                                        ],
                                        onSelect: (option) => {
                                            setTaskTypeFilter(option);
                                        }
                                    }}
                                    brandSettings={brandSettings}     
                                />} */}
                                {(customerUser || tenantUser || profileUser) &&
                                <ButtonGroup 
                                    filter={{
                                        selected: userType,
                                        options : [
                                            {name: props.t("All"), value: null},
                                            {name: props.t("Assigned"), value:"Assigned"},
                                            {name: props.t("Reported"), value: "Reported"}
                                        ],
                                        onSelect: (option) => {
                                            setUserType(option);
                                        }
                                    }}
                                    brandSettings={brandSettings}     
                                />}  
                                <div className="me-1"></div>
                                <ButtonGroup 
                                    filter={{
                                        selected: statusTypeFilter,
                                        options : [
                                            {name: props.t("Open"), value:"OPEN"},
                                            {name: props.t("Closed"), value:"CLOSED"},
                                            {name: props.t("All"), value: 'ALL'},
                                        ],
                                        onSelect: (option) => {
                                                    setStatusTypeFilter(option);
                                                }
                                    }}
                                    brandSettings={brandSettings}     
                                />
                                <Button 
                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                    className="btn btn-primary waves-effect waves-light mx-1"
                                    onClick={handleAddTask}
                                >{props.t("New Task")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col className="col-12 article-table">
                            <MDBDataTable
                                key={size(taskList)}
                                responsive
                                searching={true}
                                barReverse={true}
                                hover
                                borderless
                                paginationLabel={[props.t("Previous"), props.t("Next")]}
                                entriesLabel={props.t("Show entries")}
                                infoLabel={[
                                    props.t("Showing"),
                                    props.t("to"),
                                    props.t("of"),
                                    props.t("entries"),
                                ]}
                                searchLabel={props.t("Search") + "..."}
                                noRecordsFoundLabel={props.t("No matching records found")}
                                noBottomColumns={true}
                                data={getDatatableData()}
                                entries={50}
                                entriesOptions={[50, 100, 500]}
                                onSearch={(text) => console.log(text)}
                                // sortRows={['id', 'subject']}
                                onSort={({column, direction}) => {
                                    if(sortState && sortState.column == column){
                                        setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                    } else {
                                        setSortState({column, direction});
                                    }
                                }}
                            />
                        </Col>
                    </Row></>}


                    <TaskModal
                        modal_standard={modal_standard}
                        setmodal_standard={setmodal_standard}
                        tog_standard={tog_standard}
                        projectId={projectId}
                        cid={cid}
                    />

        </React.Fragment>
    );
};

TaskList.propTypes = {
    tasks: PropTypes.array,
    onGetTasks: PropTypes.func,
    loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        tasks: state.task.tasks,
        getTasksError: state.task.getTasksError,
        loading: state.task.loading,
        customerOptions: state.Customer.customers,
        tenantOptions: state.tenant.tenantList,
        productOptions: state.ecommerce.products.data,
        projectOptions: state.Project.projects,
        tenantUserOptions: state.tenant.tenantAdmins,
        tenantUsersLoading: state.tenant.tenantAdminUsersLoading,
        customerUserOptions: state.Customer.customerUsers,
        customerUsersLoading: state.Customer.customerUsersLoading,
        tenantCustomersLoading: state.tenant.getTenantCustomersLoading,
        tenantCustomerOptions: state.tenant.customerList,
        taskCategories: state.task.taskCategories,
        taskPriorities: state.task.taskPriorities,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetTasks: (filter) => {
        if(filter?.customerId || filter?.assignedUserId || filter?.reporterUserId || filter?.projectId){
            dispatch(getTaskList(filter));
        }
    },
    onGetTasksReset: () => dispatch(getTaskListReset()),
    onGetTenantOptions: () => dispatch(getTenants()),
    onGetCustomerOptions: () => dispatch(GetCustomers()),
    onGetProductOptions: (tenantId, customerId) => dispatch(getProducts(-1, -1, customerId != null, null, null, null, tenantId)),
    onGetProductsReset: () => dispatch(getProductsReset()),
    onGetProjectOptions: (tenantId, customerId) => dispatch(getProjectList(customerId, null, tenantId)),
    onGetProjectsReset: () => dispatch(getProjectListReset()),
    onGetCustomerUsers: (customerId) => dispatch(GetCustomerUsers(customerId, "active")),
    onGetCustomerUsersReset: () => dispatch(GetCustomerUsersReset()),
    onGetTenantUsers: (tenantId) => dispatch(getTenantAdmins(tenantId, "active")),
    onGetTenantUsersReset: () => dispatch(getTenantAdminsReset()),
    onGetTenantCustomers: (tenantId) => dispatch(getTenantCustomers(tenantId, "active")),
    onGetTaskCategories: () => dispatch(getTaskCategories()),
    onGetTaskPriorities: () => dispatch(getTaskPriorities()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(TaskList)));

import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import withRouter from "../Common/withRouter";

//i18n
import {withTranslation} from "react-i18next";
import SidebarContent from "./SidebarContent";
import logoDark from "../../assets/images/logo-dark.png";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";

const Sidebar = props => {

    const user = JSON.parse(localStorage.getItem("authUser"));

    const getBrandLogo = () => {
        if (user?.brandConfig && user?.brandConfig?.brandLogo) {
            return BASE_IMAGE_URL + "/" + user.brandConfig.brandLogo;
        }
        return logoDark;
    }

    function tToggle() {
        var body = document.body;
        var windowSize = document.documentElement.clientWidth;

        body.classList.toggle("vertical-collpsed");
        body.classList.toggle("sidebar-enable");

        if (windowSize > 991) {
            (body.getAttribute('data-sidebar-size') === 'sm') ? body.setAttribute('data-sidebar-size', 'lg') : body.setAttribute('data-sidebar-size', 'sm');
        }
    }


    // useEffect(() => {
    //   // var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    //   if (document.body.classList.contains("vertical-collpsed") && document.body.classList.contains("sidebar-enable")) {
    //     // verticalOverlay[0].addEventListener("click", function () {
    //       document.body.classList.remove("vertical-collpsed");
    //       document.body.classList.remove("sidebar-enable");

    //     };
    //   // }
    // });

    return (
        <React.Fragment>
            <div className="vertical-menu">
                <div className="navbar-brand-box" style={{position: "relative", marginTop: "10%"}}>
                    <Link to="/dashboard" className="logo logo-dark">
            <span className="logo-sm">
              <img src={getBrandLogo()} alt="" height="22" className="img-fluid"/>
            </span>
                        <span className="logo-lg">
              <img src={getBrandLogo()} alt="" height="20" className="img-fluid light-tab-logo-1"/>
            </span>
                    </Link>

                    <Link to="/dashboard" className="logo logo-light">
            <span className="logo-sm">
              <img src={getBrandLogo()} alt="" height="22" className="img-fluid"/>
            </span>
                        <span className="logo-lg">
              <img src={getBrandLogo()} alt="" height="20" className="img-fluid light-tab-logo"/>
            </span>
                    </Link>
                </div>
                {/*<button
          onClick={() => {
            tToggle();
          }}
          type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn">
          <i className="fa fa-fw fa-bars"></i>
        </button>*/}
                <div className="sidebar-menu-scroll">
                    {props.type !== "condensed" ? <SidebarContent/> : <SidebarContent/>}
                </div>
            </div>

        </React.Fragment>
    );
};

Sidebar.propTypes = {
    type: PropTypes.string,
};

const mapStatetoProps = state => {
    return {
        layout: state.Layout,
    };
};
export default connect(
    mapStatetoProps,
    {}
)(withRouter(withTranslation()(Sidebar)));

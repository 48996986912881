import React from "react";
import {Card, Container, Modal} from "reactstrap";
import {withTranslation} from "react-i18next";
import ProjectForm from "./ProjectForm";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import { useParams } from "react-router-dom";
import {CardBody} from "mdbreact";

const ProjectFormModalContainer = (props) => {
    let params = useParams();

    return <React.Fragment>
        <Modal
            isOpen={props.modal_standard}
            backdrop="static"
            toggle={() => {
                props.tog_standard();
            }}
            className="add-order-modal"
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {props.t("Create Project")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        props.tog_standard();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body m-2">
                <ProjectForm
                    projectId={params.id}
                    location={props.location}
                    clearField={true}
                    useModal={true}
                    tog_standard={props.tog_standard}
                    setEditMode={false}
                    customerId={props.customerId}
                />
            </div>
        </Modal>
    </React.Fragment>
};

export default withTranslation()(ProjectFormModalContainer);
